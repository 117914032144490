{

siehe auch How to build a login page with TMS WEB Core

https://www.youtube.com/watch?v=QfUCb3kRUYo

Eigenschaft     AutoCompletion setzen um ausfüllen von Browser für Username und Passwort zu enablen

Login Button

Google Material Icons pack

 }
unit PasswortReset;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  XData.Web.Client, Vcl.Imaging.pngimage, WEBLib.Buttons;

type
  TPasswortResetForm = class(TForm)
    EnterLabel: TLabel;
    UsernameEdit: TEdit;
    OKBtn: TBitBtn;
    CancelBtn: TBitBtn;
    WebMemo1: TMemo;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    procedure OKBtnClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
    FormClosed : boolean;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  PasswortResetForm: TPasswortResetForm;

implementation

uses Main, KVT, DatenServiceClasses, CommonFunctions,MyMsgDialog,Accept_AGB, MyQiuOverview,
     myQiuTestverwaltung;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TPasswortResetForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TPasswortResetForm.OKBtnClick(Sender: TObject);
 procedure OnResponse(Response:TXDataClientResponse);
  begin
   if FormClosed then exit;
   case integer(TJSObject(Response.Result)['value']) of
    0: showmessage(SP.load(326));//'Das zurückgesetzte Passwort wurde Ihnen per Mail zugeschickt');
    1: showmessage(SP.load(327));//'Es wurde kein zu den Angaben passendes myQiu-Konto gefunden!');
    2: showmessage(SP.load(328));//'Das Passwort konnte nicht zurückgesetzt werden!');
    3: showmessage(SP.load(329));//'Das geänderte Passwort konnte nicht zugeschickt werden!');
   end;
  end;

begin
 MainForm.XDataWebClient.RawInvoke('ILoginService.ResetPassword',
        [trim(UsernameEdit.Text)],@OnResponse);
end;
{------------------------------------------------------------------------------}
procedure TPasswortResetForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 FormClosed := true;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TPasswortResetForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TPasswortResetForm.WebFormShow(Sender: TObject);
begin
 OKBtn.Caption := SP.Load(24);
 CancelBtn.Caption := SP.Load(27);
 EnterLabel.Caption := SP.Load(295);//'Benutzername oder E-Mail eingeben';
 WebMemo1.lines.clear;
 WebMemo1.Lines.Add(SP.Load(296));//'Geben Sie Ihren Benutzernamen oder die im myQiu-Zugang hinterlegte e-Mail Adresse in das obige Feld ein.');
 WebMemo1.Lines.Add(SP.Load(297));//'Mit "OK" wird das Passwort zurückgesetzt und an Ihre e-Mail Adresse verschickt.');
end;


procedure TPasswortResetForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  EnterLabel := TLabel.Create(Self);
  UsernameEdit := TEdit.Create(Self);
  OKBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  WebMemo1 := TMemo.Create(Self);

  EnterLabel.BeforeLoadDFMValues;
  UsernameEdit.BeforeLoadDFMValues;
  OKBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  try
    Name := 'PasswortResetForm';
    Width := 331;
    Height := 235;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    EnterLabel.SetParentComponent(Self);
    EnterLabel.Name := 'EnterLabel';
    EnterLabel.Left := 8;
    EnterLabel.Top := 13;
    EnterLabel.Width := 207;
    EnterLabel.Height := 16;
    EnterLabel.Caption := 'Benutzername oder E-Mail eingeben';
    EnterLabel.HeightPercent := 100.000000000000000000;
    EnterLabel.WidthPercent := 100.000000000000000000;
    UsernameEdit.SetParentComponent(Self);
    UsernameEdit.Name := 'UsernameEdit';
    UsernameEdit.Left := 8;
    UsernameEdit.Top := 35;
    UsernameEdit.Width := 305;
    UsernameEdit.Height := 22;
    UsernameEdit.AutoCompletion := acUserName;
    UsernameEdit.ChildOrder := 1;
    UsernameEdit.ElementClassName := '.form-control-static';
    UsernameEdit.HeightPercent := 100.000000000000000000;
    UsernameEdit.WidthPercent := 100.000000000000000000;
    OKBtn.SetParentComponent(Self);
    OKBtn.Name := 'OKBtn';
    OKBtn.Left := 56;
    OKBtn.Top := 174;
    OKBtn.Width := 96;
    OKBtn.Height := 30;
    OKBtn.Caption := 'OK';
    OKBtn.Color := clNone;
    OKBtn.HeightPercent := 100.000000000000000000;
    OKBtn.TabOrder := 1;
    OKBtn.WidthPercent := 100.000000000000000000;
    SetEvent(OKBtn, Self, 'OnClick', 'OKBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 158;
    CancelBtn.Top := 174;
    CancelBtn.Width := 96;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.TabOrder := 2;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 8;
    WebMemo1.Top := 63;
    WebMemo1.Width := 305;
    WebMemo1.Height := 105;
    WebMemo1.AutoSize := False;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
  finally
    EnterLabel.AfterLoadDFMValues;
    UsernameEdit.AfterLoadDFMValues;
    OKBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
  end;
end;

end.