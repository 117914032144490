unit MyMsgDialog;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.Buttons, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, System.Types,VCL.Graphics;

type

  TMyDialogTypes = (dtOK, dtYesNo, dtOKCancel, dtYesNoCancel);

  TMyDialogResults = (drClose, drYes, drNo, drCancel, drNone);


  TMyMessageDlgForm = class(TForm)
    Btn1: TBitBtn;
    Btn2: TBitBtn;
    Btn3: TBitBtn;
    TopPanel: TPanel;
    WebMemo1: TMemo;
    procedure InitForm;
    procedure Btn1Click(Sender: TObject);
    procedure Btn2Click(Sender: TObject);
    procedure Btn3Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private-Deklarationen }
    Btn1Result,
    Btn2Result,
    Btn3Result : TMyDialogResults;
  public
    { Public-Deklarationen }
    MessageText    : string;
    Titel          : string;
    DialogType     : TMyDialogTypes;
    _Result        : TMyDialogResults;
  protected procedure LoadDFMValues; override; end;

var
  MyMessageDlgForm: TMyMessageDlgForm;

implementation

uses KVT;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TMyMessageDlgForm.InitForm;
var
 _Left : integer;
begin
 case DialogType of
  dtOK           : begin
                    Btn3.Visible := true;
                    Btn3.Caption := SP.load(24);//'OK';
                    Btn3Result := drClose;
                    Btn2.Visible := false;
                    Btn1.Visible := false;
                   end;
  dtYesNo         : begin
                    Btn3.Visible := true;
                    Btn3.Caption := SP.load(25);//'Nein';
                    Btn3Result := drNo;
                    Btn2.Visible := true;
                    Btn2.Caption := SP.load(26);//'Ja';
                    Btn2Result := drYes;
                    Btn1.Visible := false;
                   end;
  dtYesNoCancel   : begin
                    Btn3.Visible := true;
                    Btn3.Caption := SP.load(27);//'Abbrechen';
                    Btn3Result := drCancel;
                    Btn2.Visible := true;
                    Btn2.Caption := SP.load(25);//'Nein';
                    Btn2Result := drNo;
                    Btn1.Visible := true;
                    Btn1.Caption :=SP.load(26);// 'Ja';
                    Btn1Result := drYes;
                   end;

 end;

 _Left                  := self.ClientWidth - (4) - Btn3.Width;
 Btn3.Left              := _Left;
 if Btn2.Visible then begin
  _Left := Btn3.Left - Btn2.Width - (2);
  Btn2.Left := _Left;
 end;
 if Btn1.Visible then begin
  _Left := Btn2.Left - Btn1.Width - (2);
  Btn1.Left := _Left;
 end;

 TopPanel.Caption := Titel;
 WebMemo1.Lines.Clear;
 WebMemo1.Lines.Add(MessageText);
end;
{------------------------------------------------------------------------------}
procedure TMyMessageDlgForm.WebFormShow(Sender: TObject);
begin
 InitForm;
end;
{------------------------------------------------------------------------------}
procedure TMyMessageDlgForm.Btn1Click(Sender: TObject);
begin
  _Result := Btn1Result;
  Close;
end;
{------------------------------------------------------------------------------}
procedure TMyMessageDlgForm.Btn2Click(Sender: TObject);
begin
  _Result := Btn2Result;
  Close;
end;
{------------------------------------------------------------------------------}
procedure TMyMessageDlgForm.Btn3Click(Sender: TObject);
begin
  _Result := Btn3Result;
  Close;
end;

procedure TMyMessageDlgForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Btn1 := TBitBtn.Create(Self);
  Btn2 := TBitBtn.Create(Self);
  Btn3 := TBitBtn.Create(Self);
  TopPanel := TPanel.Create(Self);
  WebMemo1 := TMemo.Create(Self);

  Btn1.BeforeLoadDFMValues;
  Btn2.BeforeLoadDFMValues;
  Btn3.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  try
    Name := 'MyMessageDlgForm';
    Width := 435;
    Height := 201;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    Btn1.SetParentComponent(Self);
    Btn1.Name := 'Btn1';
    Btn1.Left := 45;
    Btn1.Top := 135;
    Btn1.Width := 100;
    Btn1.Height := 30;
    Btn1.Caption := 'Btn1';
    Btn1.Color := clNone;
    Btn1.HeightPercent := 100.000000000000000000;
    Btn1.WidthPercent := 100.000000000000000000;
    SetEvent(Btn1, Self, 'OnClick', 'Btn1Click');
    Btn2.SetParentComponent(Self);
    Btn2.Name := 'Btn2';
    Btn2.Left := 168;
    Btn2.Top := 135;
    Btn2.Width := 100;
    Btn2.Height := 30;
    Btn2.Caption := 'Btn2';
    Btn2.Color := clNone;
    Btn2.HeightPercent := 100.000000000000000000;
    Btn2.TabOrder := 1;
    Btn2.WidthPercent := 100.000000000000000000;
    SetEvent(Btn2, Self, 'OnClick', 'Btn2Click');
    Btn3.SetParentComponent(Self);
    Btn3.Name := 'Btn3';
    Btn3.Left := 288;
    Btn3.Top := 135;
    Btn3.Width := 100;
    Btn3.Height := 30;
    Btn3.Caption := 'Btn3';
    Btn3.Color := clNone;
    Btn3.HeightPercent := 100.000000000000000000;
    Btn3.TabOrder := 2;
    Btn3.WidthPercent := 100.000000000000000000;
    SetEvent(Btn3, Self, 'OnClick', 'Btn3Click');
    TopPanel.SetParentComponent(Self);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 0;
    TopPanel.Width := 435;
    TopPanel.Height := 33;
    TopPanel.Align := alTop;
    TopPanel.Caption := 'TopPanel';
    TopPanel.ChildOrder := 4;
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 41;
    WebMemo1.Top := 42;
    WebMemo1.Width := 369;
    WebMemo1.Height := 73;
    WebMemo1.AutoSize := False;
    WebMemo1.BorderStyle := bsNone;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
  finally
    Btn1.AfterLoadDFMValues;
    Btn2.AfterLoadDFMValues;
    Btn3.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
  end;
end;

end.