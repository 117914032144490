unit CommonFunctions;

interface

 uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls,KVT, DatenServiceClasses;


 procedure ReplacePlatzhalter(var _text:string; _platzhalter:string; _inputstring:string);
 function IsValidEmail(const Value: string): boolean;
 function GetHelpDir:string;
 function GetManualLink:string;
 function GetAGBLink:string;
 function GetDatenschutzerklaerungLink:string;
 function GetMyQiuUserString:string;
 function myGetYear(_date:TDatetime):integer;

 procedure QuickSortMessungslisteDatumAbsteigend(var MyArray:TArray<TMessung>; erstes,letztes: integer);
 procedure QuickSortMessungslisteDatumAufsteigend(var MyArray:TArray<TMessung>; erstes,letztes: integer);
 procedure DeleteEntryFromMessungsList(var MyArray:TArray<TMessung>;_INX:integer);
 procedure DeleteEntryFromAntwortenList(var MyArray:TArray<TFragenAntwortenItemClass>;_INX:integer);

 procedure QuickSortBiomarkerListDatumAbsteigend(var MyArray:TArray<TBiomarkerItemClass>; erstes,letztes: integer);
 procedure QuickSortBiomarkerListDatumAufsteigend(var MyArray:TArray<TBiomarkerItemClass>; erstes,letztes: integer);
 procedure DeleteEntryFromBiomarkerList(var MyArray:TArray<TBiomarkerItemClass>;_INX:integer);
 function  GetDateTimeFromStr(_STR:string):TDateTime;

 procedure ShowPDF(_PDFLink:string;_ReportMode:integer);

 function EMailUnkenntlichmachen(_email:string):string;

implementation

uses Main,XData.Web.Client,MyQiuOverview,myQiuTestverwaltung,PDFViewer;
{------------------------------------------------------------------------------}
procedure ShowPDF(_PDFLink:string;_ReportMode:integer);
var
 PDFViewerForm : TPDFViewerForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   PDFViewerForm.WebBrowserControl1.URL := _PDFLink;
  end;
begin
 case _ReportMode of
   0: Application.Navigate(trim(_PDFLink),ntBlank);
   1: begin
       PDFViewerForm := TPDFViewerForm.CreateNew(@AfterCreate);
       PDFViewerForm.Popup := false;
       PDFViewerForm.ShowModal(@AfterShowModal);
      end;
 end;
end;
{------------------------------------------------------------------------------}
function GetHelpDir:string;
begin
 result := GLC_LokalHelpDir + 'Deutsch/';
 case GLV_AktUser.Sprache of
  0 :  result := GLC_LokalHelpDir + 'Deutsch/';
  1 :  result := GLC_LokalHelpDir + 'Englisch/';
 end;
end;
{------------------------------------------------------------------------------}
function GetManualLink:string;
begin
 result := 'https://www.biosign.de/myQiu-Web/Dokumentation%20myQiuWeb.pdf';
 if (GLV_AktUser.Sprache = 1) or (GLV_AppSprache = 1) then
  result := 'https://www.biosign.de/myQiu-Web/Dokumentation%20myQiuWeb%20EN.pdf'
end;
{------------------------------------------------------------------------------}
function GetAGBLink:string;
begin
 result := 'http://www.biosign.de/download_Qiu/AGB myQiu.pdf';
 case GLV_AktUser.Sprache of
  0 :  result := 'http://www.biosign.de/download_Qiu/AGB myQiu.pdf';
  1 :  result := 'http://www.biosign.de/download_Qiu/AGB myQiu EN.pdf';
 end;
end;
{------------------------------------------------------------------------------}
function GetDatenschutzerklaerungLink:string;
begin
 result := 'http://www.biosign.de/download_Qiu/Datenschutzerklaerung myQiu.pdf';
 case GLV_AktUser.Sprache of
  0 :  result := 'http://www.biosign.de/download_Qiu/Datenschutzerklaerung myQiu.pdf';
  1 :  result := 'http://www.biosign.de/download_Qiu/Datenschutzerklaerung myQiu EN.pdf';
 end;
end;
{------------------------------------------------------------------------------}
procedure ReplacePlatzhalter(var _text:string; _platzhalter:string; _inputstring:string);
var
 _Pos : integer;
begin
 _pos := pos(_platzhalter,_text);
 if _pos = 0 then exit;
 delete(_text,_pos,length(_Platzhalter));
 insert(_inputstring,_text,_pos);
end;
{----------------------------------------------------------------------------}
function IsValidEmail(const Value: string): boolean;
  function CheckAllowed(const s: string): boolean;
  var
    i: integer;
  begin
    Result:= False;
    for i:= 1 to Length(s) do
    begin
      // illegal char - no valid address
      if not (s[i] in ['a'..'z','A'..'Z','0'..'9','_','-','.','+']) then
        Exit;
    end;
    Result:= True;
  end;
var
  i: integer;
  namePart, serverPart: string;
begin
  Result:= False;

  i:= Pos('@', Value);
  if (i = 0) then
    Exit;

  if(pos('..', Value) > 0) or (pos('@@', Value) > 0) or (pos('.@', Value) > 0)then
    Exit;

  if(pos('.', Value) = 1) or (pos('@', Value) = 1) then
    Exit;

  namePart:= Copy(Value, 1, i - 1);
  serverPart:= Copy(Value, i + 1, Length(Value));
  if (Length(namePart) = 0)  or (Length(serverPart) < 5)    then
    Exit;                      // too short

  i:= Pos('.', serverPart);
  // must have dot and at least 3 places from end
  if (i=0) or (i>(Length(serverPart)-2)) then
    Exit;

  Result:= CheckAllowed(namePart) and CheckAllowed(serverPart);
end;
{------------------------------------------------------------------------------}
function GetMyQiuUserString:string;
begin
 result := string(GLV_AktUser.Name) + GLC_1Blank + string(GLV_AktUser.Vorname) + ', ' +  formatdatetime(GLV_Datummaske,MyDateFormatToTDate(GLV_AktUser.Geboren));
end;
{------------------------------------------------------------------------------}
function myGetYear(_date:TDatetime):integer;
var
 yyyy,mm,dd : word;
begin
 decodedate(_date,yyyy,mm,dd);
 result := yyyy;
end;
{------------------------------------------------------------------------------}
procedure QuickSortMessungslisteDatumAufsteigend(var MyArray:TArray<TMessung>; erstes,letztes: integer);

 procedure WertTauschen(var Werte: TArray<TMessung>; StelleA, StelleB: Integer);
 var tempI: TMessung;
  begin
   tempI := Werte[StelleA];
   Werte[StelleA] := Werte[StelleB];
   Werte[StelleB] := tempI;
  end;

var
 vonLinks, vonRechts, mitte : integer;
 vergleichsElement          : TDateTime;
begin
 if erstes < letztes then {mind. 2 Elemente} begin {Zerlegung vorbereiten}
  mitte := (erstes + letztes) div 2;
  vergleichsElement := MyDateTimeFormatToTDateTime(MyArray[mitte].Datum);
  vonLinks := erstes;
  vonRechts := letztes;
  while vonLinks <= vonRechts do {noch nicht fertig zerlegt?}  begin
    while MyDateTimeFormatToTDateTime(MyArray[vonLinks].Datum) < vergleichsElement do {linkes Element suchen}
    vonLinks := vonLinks + 1;
    while MyDateTimeFormatToTDateTime(MyArray[vonRechts].Datum) > vergleichsElement do {rechtes Element suchen}
    vonRechts := vonRechts - 1;
    if vonLinks <= vonRechts then begin
        WertTauschen(MyArray,vonLinks,vonRechts); {Elemente tauschen}
        vonLinks := vonLinks + 1;
        vonRechts := vonRechts - 1;
    end;
  end;
  QuickSortMessungslisteDatumAufsteigend(MyArray,erstes,vonRechts); {li. und re. Teilfeld zerlegen}
  QuickSortMessungslisteDatumAufsteigend(MyArray,vonLinks,letztes);
 end;
end;
{------------------------------------------------------------------------------}
procedure QuickSortMessungslisteDatumAbsteigend(var MyArray:TArray<TMessung>; erstes,letztes: integer);

 procedure WertTauschen(var Werte: TArray<TMessung>; StelleA, StelleB: Integer);
 var tempI: TMessung;
  begin
   tempI := Werte[StelleA];
   Werte[StelleA] := Werte[StelleB];
   Werte[StelleB] := tempI;
  end;

var
 vonLinks, vonRechts, mitte : integer;
 vergleichsElement          : TDateTime;
begin
 if erstes < letztes then {mind. 2 Elemente} begin {Zerlegung vorbereiten}
  mitte := (erstes + letztes) div 2;
  vergleichsElement := MyDateTimeFormatToTDateTime(MyArray[mitte].Datum);
  vonLinks := erstes;
  vonRechts := letztes;
  while vonLinks <= vonRechts do {noch nicht fertig zerlegt?}  begin
    while MyDateTimeFormatToTDateTime(MyArray[vonLinks].Datum) > vergleichsElement do {linkes Element suchen}
    vonLinks := vonLinks + 1;
    while MyDateTimeFormatToTDateTime(MyArray[vonRechts].Datum) < vergleichsElement do {rechtes Element suchen}
    vonRechts := vonRechts - 1;
    if vonLinks <= vonRechts then begin
        WertTauschen(MyArray,vonLinks,vonRechts); {Elemente tauschen}
        vonLinks := vonLinks + 1;
        vonRechts := vonRechts - 1;
    end;
  end;
  QuickSortMessungslisteDatumAbsteigend(MyArray,erstes,vonRechts); {li. und re. Teilfeld zerlegen}
  QuickSortMessungslisteDatumAbsteigend(MyArray,vonLinks,letztes);
 end;
end;
{------------------------------------------------------------------------------}
procedure DeleteEntryFromMessungsList(var MyArray:TArray<TMessung>;_INX:integer);
var
 i    : integer;
begin
 for i := _INX to length(MyArray)-2 do MyArray[i] := MyArray[i+1];
 setlength(MyArray,length(MyArray)-1);
end;
{------------------------------------------------------------------------------}
procedure DeleteEntryFromAntwortenList(var MyArray:TArray<TFragenAntwortenItemClass>;_INX:integer);
var
 i    : integer;
begin
 for i := _INX to length(MyArray)-2 do MyArray[i] := MyArray[i+1];
 setlength(MyArray,length(MyArray)-1);
end;
{------------------------------------------------------------------------------}
procedure QuickSortBioMarkerListDatumAufsteigend(var MyArray:TArray<TBiomarkerItemClass>; erstes,letztes: integer);

 procedure WertTauschen(var Werte: TArray<TBiomarkerItemClass>; StelleA, StelleB: Integer);
 var tempI: TBiomarkerItemClass;
  begin
   tempI := Werte[StelleA];
   Werte[StelleA] := Werte[StelleB];
   Werte[StelleB] := tempI;
  end;

var
 vonLinks, vonRechts, mitte : integer;
 vergleichsElement          : TDateTime;
begin
 if erstes < letztes then {mind. 2 Elemente} begin {Zerlegung vorbereiten}
  mitte := (erstes + letztes) div 2;
  vergleichsElement := MyDateTimeFormatToTDateTime(MyArray[mitte].Zeit);
  vonLinks := erstes;
  vonRechts := letztes;
  while vonLinks <= vonRechts do {noch nicht fertig zerlegt?}  begin
    while MyDateTimeFormatToTDateTime(MyArray[vonLinks].Zeit) < vergleichsElement do {linkes Element suchen}
    vonLinks := vonLinks + 1;
    while MyDateTimeFormatToTDateTime(MyArray[vonRechts].Zeit) > vergleichsElement do {rechtes Element suchen}
    vonRechts := vonRechts - 1;
    if vonLinks <= vonRechts then begin
        WertTauschen(MyArray,vonLinks,vonRechts); {Elemente tauschen}
        vonLinks := vonLinks + 1;
        vonRechts := vonRechts - 1;
    end;
  end;
  QuickSortBioMarkerListDatumAufsteigend(MyArray,erstes,vonRechts); {li. und re. Teilfeld zerlegen}
  QuickSortBioMarkerListDatumAufsteigend(MyArray,vonLinks,letztes);
 end;
end;
{------------------------------------------------------------------------------}
procedure QuickSortBioMarkerListDatumAbsteigend(var MyArray:TArray<TBiomarkerItemClass>; erstes,letztes: integer);

 procedure WertTauschen(var Werte: TArray<TBiomarkerItemClass>; StelleA, StelleB: Integer);
 var tempI: TBiomarkerItemClass;
  begin
   tempI := Werte[StelleA];
   Werte[StelleA] := Werte[StelleB];
   Werte[StelleB] := tempI;
  end;

var
 vonLinks, vonRechts, mitte : integer;
 vergleichsElement          : TDateTime;
begin
 if erstes < letztes then {mind. 2 Elemente} begin {Zerlegung vorbereiten}
  mitte := (erstes + letztes) div 2;
  vergleichsElement := MyDateTimeFormatToTDateTime(MyArray[mitte].Zeit);
  vonLinks := erstes;
  vonRechts := letztes;
  while vonLinks <= vonRechts do {noch nicht fertig zerlegt?}  begin
    while MyDateTimeFormatToTDateTime(MyArray[vonLinks].Zeit) > vergleichsElement do {linkes Element suchen}
    vonLinks := vonLinks + 1;
    while MyDateTimeFormatToTDateTime(MyArray[vonRechts].Zeit) < vergleichsElement do {rechtes Element suchen}
    vonRechts := vonRechts - 1;
    if vonLinks <= vonRechts then begin
        WertTauschen(MyArray,vonLinks,vonRechts); {Elemente tauschen}
        vonLinks := vonLinks + 1;
        vonRechts := vonRechts - 1;
    end;
  end;
  QuickSortBioMarkerListDatumAbsteigend(MyArray,erstes,vonRechts); {li. und re. Teilfeld zerlegen}
  QuickSortBioMarkerListDatumAbsteigend(MyArray,vonLinks,letztes);
 end;
end;
{------------------------------------------------------------------------------}
procedure DeleteEntryFromBioMarkerList(var MyArray:TArray<TBiomarkerItemClass>;_INX:integer);
var
 i    : integer;
begin
 for i := _INX to length(MyArray)-2 do MyArray[i] := MyArray[i+1];
 setlength(MyArray,length(MyArray)-1);
end;
{----------------------------------------------------------------------------}
function GetDateTimeFromStr(_STR:string):TDateTime;
var
 s : string;
 yyyy_s,mm_s,dd_s,hh_s,mi_s,ss_s : string;
 yyyy,mm,dd,hh,mi,ss : word;
begin
  try
  s      := _STR;
  yyyy_s := copy(s,1,4);   yyyy := strtoint(yyyy_s);
  mm_s   := copy(s,5,2);   mm   := strtoint(mm_s);
  dd_s   := copy(s,7,2);   dd   := strtoint(dd_s);
  hh_s   := copy(s,9,2);   hh   := strtoint(hh_s);
  mi_s   := copy(s,11,2);  mi   := strtoint(mi_s);
  ss_s   := copy(s,13,2);  ss   := strtoint(ss_s);
  result := EncodeDate(yyyy,mm,dd)+EncodeTime(hh,mi,ss,0);
  except
    result := 0;
  end;
end;
{----------------------------------------------------------------------------}
function EMailUnkenntlichmachen(_email:string):string;
var
 i : integer;
 c : char;
begin
 result := '';
 for i := 1 to length(_email) do begin
  if (i mod 3 <> 0) then c := _email[i] else c := '*';
  result := result + c;
 end;
end;

end.
