unit User;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.Buttons,XData.Web.Client, Vcl.Imaging.pngimage, WEBLib.ComCtrls,
  DatenServiceClasses;

type
  TUserForm = class(TForm)
    WebPageControl1: TPageControl;
    WebPageControl1Sheet1: TTabSheet;
    WebPageControl1Sheet2: TTabSheet;
    WebLabel1: TLabel;
    UsernameWebEdit: TEdit;
    WebLabel2: TLabel;
    PasswortWebEdit: TEdit;
    WebLabel3: TLabel;
    EMailWebEdit: TEdit;
    WebLabel4: TLabel;
    TitelWebEdit: TEdit;
    AnredeWebComboBox: TComboBox;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    NameWebEdit: TEdit;
    WebLabel8: TLabel;
    VornameWebEdit: TEdit;
    WebLabel9: TLabel;
    GebWebDateTimePicker: TDateTimePicker;
    WebLabel10: TLabel;
    SexWebComboBox: TComboBox;
    WebLabel11: TLabel;
    SpracheWebComboBox: TComboBox;
    NewsletterWebCheckBox: TCheckBox;
    WebLabel12: TLabel;
    HeightWebSpinEdit: TSpinEdit;
    SaveBtn: TBitBtn;
    CancelBtn: TBitBtn;
    WebLabel7: TLabel;
    EMail2WebEdit: TEdit;
    WebLabel13: TLabel;
    Passwort2WebEdit: TEdit;
    WebMemo1: TMemo;
    AGBWebButton: TButton;
    DatenschutzWebButton: TButton;
    WebMemo2: TMemo;
    WebPageControl1Sheet3: TTabSheet;
    WebPageControl1Sheet4: TTabSheet;
    WebLabel14: TLabel;
    ValidUntilWebEdit: TEdit;
    NewCodeWebBitBtn: TBitBtn;
    WebLabel15: TLabel;
    AboTypWebComboBox: TComboBox;
    WebLabel16: TLabel;
    CodeWebEdit: TEdit;
    WebPageControl1Sheet5: TTabSheet;
    DeleteAccountWebBitBtn: TBitBtn;
    Therapeut1WebMemo: TMemo;
    WebLabel17: TLabel;
    SelectTherapeut1WebBitBtn: TBitBtn;
    RemoveTherapeut1WebBitBtn: TBitBtn;
    Therapeut2WebMemo: TMemo;
    WebLabel18: TLabel;
    SelectTherapeut2WebBitBtn: TBitBtn;
    RemoveTherapeut2WebBitBtn: TBitBtn;
    AutoTimeZoneCB: TCheckBox;
    ComboBox_UTC: TComboBox;
    WebPageControl1Sheet6: TTabSheet;
    WebLabel19: TLabel;
    QiuSerial1WebEdit: TEdit;
    WebLabel20: TLabel;
    QiuSerial2WebEdit: TEdit;
    WebLabel21: TLabel;
    QiuSerial3WebEdit: TEdit;
    DeleteQiuSerial1WebBitBtn: TBitBtn;
    DeleteQiuSerial2WebBitBtn: TBitBtn;
    DeleteQiuSerial3WebBitBtn: TBitBtn;
    LoginModeLabel: TLabel;
    LoginModeCombobox: TComboBox;
    procedure ShowUser;
    procedure InitForm;
    procedure  GetTherapeutRecByUUID(_UUID:string;var _Memo:TMemo);
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WriteChangedUser;
    procedure WriteNewUser;
    procedure SaveBtnClick(Sender: TObject);
    function  CheckTab1: boolean;
    function  CheckTab2: boolean;
    procedure NewCodeWebBitBtnClick(Sender: TObject);
    procedure CodeWebEditChange(Sender: TObject);
    procedure AGBWebButtonClick(Sender: TObject);
    procedure DatenschutzWebButtonClick(Sender: TObject);
    procedure DeleteAccount;
    procedure DeleteAccountWebBitBtnClick(Sender: TObject);
    procedure SelectTherapeut1WebBitBtnClick(Sender: TObject);
    procedure SelectTherapeut2WebBitBtnClick(Sender: TObject);
    procedure RemoveTherapeut1WebBitBtnClick(Sender: TObject);
    procedure RemoveTherapeut2WebBitBtnClick(Sender: TObject);
    function  Set_UTC_Itemindex(_UTC_Hour,_UTC_Minute:integer):integer;
    procedure Get_UTC_Itemindex(_CBItemIndex:integer;var UTC_Hour:integer; var UTC_Minute:integer);
    procedure AutoTimeZoneCBClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure DeleteQiuSerial1WebBitBtnClick(Sender: TObject);
    procedure DeleteQiuSerial2WebBitBtnClick(Sender: TObject);
    procedure DeleteQiuSerial3WebBitBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
    OldSprache : byte;
  public
    { Public-Deklarationen }
    _Modus:byte; //0 = Registrieren, 1= Bearbeiten
  protected procedure LoadDFMValues; override; end;

var
  UserForm: TUserForm;

const
 Min_Username_length = 8;
 Min_Passwort_length = 8;

 Max_Username_length = 24;
 Max_Passwort_length = 24;


implementation

uses Main,KVT,CommonFunctions, MyMsgDialog, SelectTherapeut,MainMenuFrame;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TUserForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
case _Modus of
  1: begin  // User bearbeiten
      if OldSprache <> GLV_AktUser.Sprache then begin
        MainForm.InitForm;
        MainForm.MainMenuFrame1.UpdateFrame;
      end;
     end;
 end;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.WebFormCreate(Sender: TObject);
begin
 InitForm;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.WebFormResize(Sender: TObject);
begin
 SaveBtn.Left := self.Width div 2 - SaveBtn.Width - 10;
 CancelBtn.Left := self.Width div 2 + 10;
 AGBWebButton.Left := WebPageControl1Sheet1.Width div 2 - AGBWebButton.Width div 2;
 DatenschutzWebButton.Left := WebPageControl1Sheet1.Width div 2 - DatenschutzWebButton.Width div 2;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.WebFormShow(Sender: TObject);
begin
 WebPageControl1.ActivePageIndex := 0;
 ShowUser;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.InitForm;
var
 i,ii                  : integer;
 s,hh,mm               : string;
begin

 UsernameWebEdit.TabOrder := 1;
 PasswortWebEdit.TabOrder := 2;
 Passwort2WebEdit.TabOrder := 3;
 EMailWebEdit.TabOrder := 4;
 EMail2WebEdit.TabOrder := 5;

 AnredeWebComboBox.TabOrder := 6;
 TitelWebEdit.TabOrder := 7;
 VornameWebEdit.TabOrder := 8;
 NameWebEdit.TabOrder := 9;
 GebWebDateTimePicker.TabOrder := 10;
 SexWebComboBox.TabOrder := 11;
 HeightWebSpinEdit.TabOrder := 12;
 SpracheWebComboBox.TabOrder := 13;
 NewsletterWebCheckBox.TabOrder := 14;
 AutoTimeZoneCB.TabOrder := 15;
 ComboBox_UTC.TabOrder := 16;

 WebPageControl1.TabOrder := -1;
 WebPageControl1Sheet1.TabOrder := -1;
 WebPageControl1Sheet2.TabOrder := -1;
 SaveBtn.TabOrder := -1;
 CancelBtn.TabOrder := -1;
 WebMemo1.TabOrder := -1;
 AGBWebButton.TabOrder := -1;
 DatenschutzWebButton.TabOrder := -1;
 WebMemo2.TabOrder := -1;
 WebPageControl1Sheet3.TabOrder := -1;
 WebPageControl1Sheet4.TabOrder := -1;
 ValidUntilWebEdit.TabOrder := -1;
 NewCodeWebBitBtn.TabOrder := -1;
 AboTypWebComboBox.TabOrder := -1;
 CodeWebEdit.TabOrder := -1;
 WebPageControl1Sheet5.TabOrder := -1;
 DeleteAccountWebBitBtn.TabOrder := -1;
 Therapeut1WebMemo.TabOrder := -1;
 SelectTherapeut1WebBitBtn.TabOrder := -1;
 RemoveTherapeut1WebBitBtn.TabOrder := -1;
 Therapeut2WebMemo.TabOrder := -1;
 SelectTherapeut2WebBitBtn.TabOrder := -1;
 RemoveTherapeut2WebBitBtn.TabOrder := -1;

 AnredeWebComboBox.Items.Clear;
 AnredeWebComboBox.Items.Add(SP.Load(8));
 AnredeWebComboBox.Items.Add(SP.Load(9));
 AnredeWebComboBox.Items.Add(SP.Load(10));

 SexWebComboBox.Items.Clear;
 SexWebComboBox.Items.Add(SP.Load(34));//'männlich');
 SexWebComboBox.Items.Add(SP.Load(35));//'weiblich');

 SpracheWebComboBox.Items.Clear;
 SpracheWebComboBox.Items.Add(SP.Load(36));//'Deutsch');
 SpracheWebComboBox.Items.Add(SP.Load(37));//'Englisch');

 WebPageControl1Sheet1.Caption := SP.Load(69);
 WebPageControl1Sheet2.Caption := SP.Load(70);
 WebLabel1.Caption := SP.Load(28);
 WebLabel2.Caption := SP.Load(29);
 WebLabel3.Caption := SP.Load(75);
 WebLabel4.Caption := SP.Load(76);
 WebLabel5.Caption := SP.Load(77);
 WebLabel6.Caption := SP.Load(78);
 WebLabel8.Caption := SP.Load(79);
 WebLabel9.Caption := SP.Load(80);
 WebLabel10.Caption := SP.Load(81);
 WebLabel11.Caption := SP.Load(82);
 NewsletterWebCheckBox.Caption := SP.Load(84);
 WebLabel12.Caption := SP.Load(83);
 SaveBtn.Caption := SP.Load(74);
 CancelBtn.Caption := SP.Load(27);
 WebLabel7.Caption := SP.Load(85);
 WebLabel13.Caption := SP.Load(86);
 WebMemo1.Lines.Clear;
 WebMemo1.Lines.Add(SP.Load(100));
 WebMemo2.Lines.Clear;
 WebMemo2.Lines.Add(SP.Load(101));
 WebMemo2.Lines.Add(SP.Load(102));
 WebMemo2.Lines.Add(SP.Load(103));
 AGBWebButton.Caption := SP.Load(90);
 DatenschutzWebButton.Caption := SP.Load(91);
 WebPageControl1Sheet3.Caption := SP.Load(71);
 WebPageControl1Sheet4.Caption := SP.Load(72);
 WebLabel14.Caption := SP.Load(92);
 NewCodeWebBitBtn.Caption := SP.Load(93);
 WebLabel15.Caption := SP.Load(94);
 AboTypWebComboBox.Items.Clear;
 AboTypWebComboBox.Items.Add(SP.Load(99));
 WebLabel16.Caption := SP.Load(95);
 WebPageControl1Sheet5.Caption := SP.Load(73);
 DeleteAccountWebBitBtn.Caption := SP.Load(96);
 WebLabel17.Caption := SP.Load(97);
 SelectTherapeut1WebBitBtn.Caption := SP.Load(87);
 RemoveTherapeut1WebBitBtn.Caption := SP.Load(88);
 WebLabel18.Caption := SP.Load(98);
 SelectTherapeut2WebBitBtn.Caption := SP.Load(87);
 RemoveTherapeut2WebBitBtn.Caption := SP.Load(88);
 AutoTimeZoneCB.Caption := SP.Load(89);


 WebPageControl1Sheet6.Caption := SP.Load(332);
 WebLabel19.Caption := SP.Load(333) + GLC_1Blank + '1';
 WebLabel20.Caption := SP.Load(333) + GLC_1Blank + '2';
 WebLabel21.Caption := SP.Load(333) + GLC_1Blank + '3';


 ComboBox_UTC.Items.Clear;
 hh := '12'; mm := '00';
 s := 'UTC - ' + hh + ':' + mm;
 ComboBox_UTC.Items.Add(s);
 for i := 11 downto 0 do begin
  hh := inttostr(i);
  if length(hh) = 1 then hh := '0' + hh;
  for ii := 3 downto 0 do begin
   mm := inttostr(ii*15);
   if length(mm) = 1 then mm := '0' + mm;
   s := 'UTC - ' + hh + ':' + mm;
   ComboBox_UTC.Items.Add(s)
  end;
 end;
 ComboBox_UTC.Items.Delete(ComboBox_UTC.Items.Count-1);
 for i := 0 to 11 do begin
  hh := inttostr(i);
  if length(hh) = 1 then hh := '0' + hh;
  for ii := 0 to 3 do begin
   mm := inttostr(ii*15);
   if length(mm) = 1 then mm := '0' + mm;
   s := 'UTC + ' + hh + ':' + mm;
   ComboBox_UTC.Items.Add(s)
  end;
 end;
 hh := '12'; mm := '00';
 s := 'UTC + ' + hh + ':' + mm;
 ComboBox_UTC.Items.Add(s);

 LoginModeLabel.Caption := SP.Load(347);
 LoginModeCombobox.Items.Clear;
 LoginModeCombobox.Items.Add(SP.Load(348));
 LoginModeCombobox.Items.Add(SP.Load(349));


 case _Modus of
  0: begin  // neuer User
      WebPageControl1Sheet3.TabVisible := false;
      WebPageControl1Sheet4.TabVisible := false;
      WebPageControl1Sheet5.TabVisible := false;
      WebPageControl1Sheet6.TabVisible := false;
      LoginModeLabel.Visible := false;
      LoginModeCombobox.Visible := false;
     end;
  1: begin  // User bearbeiten
      OldSprache := GLV_AktUser.Sprache;
     end;
 end;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.AutoTimeZoneCBClick(Sender: TObject);
begin
 ComboBox_UTC.Enabled := not AutoTimeZoneCB.Checked;
 ComboBox_UTC.Visible := ComboBox_UTC.Enabled ;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.ShowUser;
begin
 if MyDateFormatToTDate(GLV_AktUser.ValidUntil) < now then ValidUntilWebEdit.Font.Color := clred else ValidUntilWebEdit.Font.Color := clblack;
 case _Modus of
  0: begin  // neuer User
      UsernameWebEdit.Text := '';
      PasswortWebEdit.Text := '';
      Passwort2WebEdit.Text := '';
      EMailWebEdit.Text := '';
      EMail2WebEdit.Text := '';
      AnredeWebComboBox.Text := '';
      TitelWebEdit.Text := '';
      NameWebEdit.Text := '';
      VornameWebEdit.Text := '';
      GebWebDateTimePicker.Date := MyDateFormatToTDate(GLV_AktUser.Geboren);
      SexWebComboBox.ItemIndex  := GLV_AktUser.Geschlecht;
      SpracheWebComboBox.ItemIndex  := GLV_AppSprache;
      NewsletterWebCheckBox.Checked := true;
      HeightWebSpinEdit.Value := 0;
      Therapeut1WebMemo.Lines.Clear;
      Therapeut2WebMemo.Lines.Clear;
      AutoTimeZoneCB.Checked := true;
      AutoTimeZoneCBClick(Self);
      AboTypWebComboBox.ItemIndex := 0;
      ValidUntilWebEdit.Text := '';
      CodeWebEdit.Text := '';
      ComboBox_UTC.ItemIndex := Set_UTC_Itemindex(GLV_AktUser.UTC_Hour,GLV_AktUser.UTC_Minute);
      showmessage(SP.Load(273));
      QiuSerial1WebEdit.Text := '';
      QiuSerial2WebEdit.Text := '';
      QiuSerial3WebEdit.Text := '';
      LoginModeCombobox.ItemIndex := 0;
     end;
  1: begin  // User bearbeiten
      UsernameWebEdit.Text := GLV_AktUser.Username;
      PasswortWebEdit.Text := GLV_AktUser.Password;
      Passwort2WebEdit.Text := GLV_AktUser.Password;
      EMailWebEdit.Text := GLV_AktUser.EMail;
      EMail2WebEdit.Text := GLV_AktUser.EMail;
      AnredeWebComboBox.ItemIndex := GLV_AktUser.Anrede;
      TitelWebEdit.Text := GLV_AktUser.Titel;
      NameWebEdit.Text := GLV_AktUser.Name;
      VornameWebEdit.Text := GLV_AktUser.Vorname;
      GebWebDateTimePicker.Date := MyDateFormatToTDate(GLV_AktUser.Geboren);
      SexWebComboBox.ItemIndex  := GLV_AktUser.Geschlecht;
      SpracheWebComboBox.ItemIndex  := GLV_AktUser.Sprache;
      NewsletterWebCheckBox.Checked :=  GLV_AktUser.Newsletter;
      HeightWebSpinEdit.Value := GLV_AktUser.Groesse;
      ValidUntilWebEdit.Text := FormatDatetime(GLV_DatumMaske,MyDateFormatToTDate(GLV_AktUser.ValidUntil));
      CodeWebEdit.Text := '';
      Therapeut1WebMemo.Lines.Clear;
      Therapeut2WebMemo.Lines.Clear;
      if trim(GLV_AktUser.Therapeut1_UUID) <> '' then GetTherapeutRecByUUID(trim(GLV_AktUser.Therapeut1_UUID),Therapeut1WebMemo);
      if trim(GLV_AktUser.Therapeut2_UUID) <> '' then GetTherapeutRecByUUID(trim(GLV_AktUser.Therapeut2_UUID),Therapeut2WebMemo);
      AutoTimeZoneCB.Checked :=  GLV_AktUser.SetAutoTimezone;
      AutoTimeZoneCBClick(Self);
      ValidUntilWebEdit.Text := FormatDatetime(GLV_DatumMaske,MyDateFormatToTDate(GLV_AktUser.ValidUntil));
      CodeWebEdit.Text := '';
      ComboBox_UTC.ItemIndex := Set_UTC_Itemindex(GLV_AktUser.UTC_Hour,GLV_AktUser.UTC_Minute);
      QiuSerial1WebEdit.Text := GLV_AktUser.QiuSerial1;
      QiuSerial2WebEdit.Text := GLV_AktUser.QiuSerial2;
      QiuSerial3WebEdit.Text := GLV_AktUser.QiuSerial3;
      LoginModeCombobox.ItemIndex := GLV_AktUser.LoginMode;
     end;
 end;
 CodeWebEdit.OnChange(self);
end;
{------------------------------------------------------------------------------}
procedure TUserForm.GetTherapeutRecByUUID(_UUID:string;var _Memo:TMemo);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.GetTherapeutByUUID',[GLV_AktUser.Token,trim(_UUID)],
   procedure (Response: TXDataClientResponse)
   var
    LObj: TJSObject;
    s   : string;
   begin
    LObj := TJSObject(Response.Result);
    _Memo.Lines.Clear;
    if length(trim(string(LObj['Titel']))) > 0 then _Memo.Lines.add(trim(string(LObj['Titel'])));
    if length(trim(string(LObj['Vorname']))) > 0 then s := trim(string(LObj['Vorname']));
    if length(trim(string(LObj['Name']))) > 0 then s := s + GLC_1Blank + trim(string(LObj['Name']));
    if length(trim(s)) > 0 then _Memo.Lines.add(trim(s));
    if length(trim(string(LObj['Institution']))) > 0 then _Memo.Lines.add(string(LObj['Institution']));
    if length(trim(string(LObj['Strasse']))) > 0 then _Memo.Lines.add(trim(string(LObj['Strasse'])));
    s := '';
    if length(trim(string(LObj['PLZ']))) > 0 then s := s + (trim(string(LObj['PLZ']))) + GLC_1Blank;
    if length(trim(string(LObj['Stadt']))) > 0 then s := s + trim(string(LObj['Stadt']));
    if length(trim(s)) > 0 then _Memo.Lines.add(trim(s));
    if length(trim(string(LObj['Land']))) > 0 then _Memo.Lines.add(trim(string(LObj['Land'])));
   end
    );
end;
{------------------------------------------------------------------------------}
procedure TUserForm.SaveBtnClick(Sender: TObject);
begin
 case _Modus of
  0: WriteNewUser;
  1: WriteChangedUser;
 end;
end;
{------------------------------------------------------------------------------}
function TUserForm.CheckTab1:boolean;
var
 txt                   : string;
begin
 result := false;
 // Username Länge
 if (length(trim(UsernameWebEdit.Text)) < Min_Username_length) then begin
  txt := SP.load(38);//'Der eingegebene Benutzername ist ungültig (mind. @@@ZEICHEN Zeichen)! Bitte erneut eingeben!';
  ReplacePlatzhalter(txt,'@@@ZEICHEN',inttostr(Min_Username_length));
  showmessage(txt);
  WebPageControl1.TabIndex := 0;
  UsernameWebEdit.setfocus;
  exit;
 end;

 // Username Länge
 if (length(trim(UsernameWebEdit.Text)) > Max_Username_length) then begin
  txt := SP.load(39);//'Der eingegebene Benutzername ist ungültig (max. @@@ZEICHEN Zeichen)! Bitte erneut eingeben!';
  ReplacePlatzhalter(txt,'@@@ZEICHEN',inttostr(Max_Username_length));
  showmessage(txt);
  WebPageControl1.TabIndex := 0;
  UsernameWebEdit.setfocus;
  exit;
 end;

 // Leerzeichen im User?
 if pos(GLC_1Blank,trim(UsernameWebEdit.Text)) > 0 then begin
  txt := SP.Load(330);//'Der eingegebene Benutzername ist ungültig (Leerzeichen enthalten)! Bitte erneut eingeben! ...';
  showmessage(txt);
  WebPageControl1.TabIndex := 0;
  UsernameWebEdit.setfocus;
  exit;
 end;

 // Passwort Länge
 if (length(trim(PasswortWebEdit.Text)) < Min_Passwort_length) then begin
  txt := SP.load(40);//'Das eingegebene Passwort ist ungültig (mind. @@@ZEICHEN Zeichen)! Bitte erneut eingeben!';
  ReplacePlatzhalter(txt,'@@@ZEICHEN',inttostr(Min_Passwort_length));
  showmessage(txt);
  WebPageControl1.TabIndex := 0;
  PasswortWebEdit.setfocus;
  exit;
 end;

 // Passwort Länge
 if (length(trim(PasswortWebEdit.Text)) > Max_Passwort_length) then begin
  txt := SP.load(41);//'Das eingegebene Passwort ist ungültig (max. @@@ZEICHEN Zeichen)! Bitte erneut eingeben!';
  ReplacePlatzhalter(txt,'@@@ZEICHEN',inttostr(Max_Passwort_length));
  showmessage(txt);
  WebPageControl1.TabIndex := 0;
  PasswortWebEdit.setfocus;
  exit;
 end;

 // Leerzeichen im Passwort?
 if pos(GLC_1Blank,trim(PasswortWebEdit.Text)) > 0 then begin
  txt := SP.Load(331);//'Das eingegebene Passwort ist ungültig (Leerzeichen enthalten)! Bitte erneut eingeben! ...';
  showmessage(txt);
  WebPageControl1.TabIndex := 0;
  PasswortWebEdit.setfocus;
  exit;
 end;

 // Passwort Wiederholung o.k.
  if trim(PasswortWebEdit.Text) <> trim(Passwort2WebEdit.Text) then begin
  Showmessage(SP.load(42));//'Die Wiederholung des Passworts ist ungleich! Bitte erneut eingeben!');
  WebPageControl1.TabIndex := 0;
  Passwort2WebEdit.setfocus;
  exit;
 end;

 // Gültige E-Mail Adresse
 if not IsValidEmail(trim(EMailWebEdit.Text)) then begin
  Showmessage(SP.load(43));//'Die eingegebene E-Mail Adresse ist ungültig! Bitte erneut eingeben!');
  WebPageControl1.TabIndex := 0;
  EMailWebEdit.setfocus;
  exit;
 end;

 // EMail Wiederholung o.k.
  if trim(EMailWebEdit.Text) <> trim(EMail2WebEdit.Text) then begin
  Showmessage(SP.load(44));//'Die Wiederholung der E-Mail Adresse ist ungleich! Bitte erneut eingeben!');
  WebPageControl1.TabIndex := 0;
  EMail2WebEdit.setfocus;
  exit;
 end;
 result := true;
end;
{------------------------------------------------------------------------------}
function TUserForm.CheckTab2:boolean;
begin
 result := false;

 if length(trim(NameWebEdit.Text)) = 0 then begin
  Showmessage(SP.load(45));//'Bitte Namen eingeben!');
  NameWebEdit.Text := '';
  WebPageControl1.TabIndex := 1;
  NameWebEdit.setfocus;
  exit;
 end;

 if length(trim(VornameWebEdit.Text)) = 0 then begin
  Showmessage(SP.load(46));//'Bitte Vornamen eingeben!');
  VornameWebEdit.Text := '';
  WebPageControl1.TabIndex := 1;
  VornameWebEdit.setfocus;
  exit;
 end;

 if GebWebDateTimePicker.Date <= trunc(now() - 100*365) then begin
  Showmessage(SP.load(47));//'Bitte geben Sie ein gültiges Geburtsdatum ein!');
  WebPageControl1.TabIndex := 1;
  GebWebDateTimePicker.SetFocus;
  exit;
 end;

 if SexWebComboBox.ItemIndex = -1 then begin
  Showmessage(SP.load(48));//'Bitte Geschlecht eingeben!');
  WebPageControl1.TabIndex := 1;
  SexWebComboBox.setfocus;
  exit;
 end;

 if (HeightWebSpinEdit.Value < 100) or (HeightWebSpinEdit.Value > 250) then begin
  Showmessage(SP.load(49));//'Bitte eine gültige Körpergröße (zwischen 100 und 250 cm) eingeben!');
  WebPageControl1.TabIndex := 1;
  HeightWebSpinEdit.setfocus;
  exit;
 end;

 result := true;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.WriteChangedUser;
var
 UTC_Hour,UTC_Minute:integer;
// 2 Optionen:
  //  -  TXDataClient
  //  -  TWebHTTPRequest

  // Wenn wir TUserData verwenden, erhalten wir eine andere JSON-Darstellung
  // als es von XData vorgenommen wird. In a nutshell, XData serialisiert die
  // Eigenschaften einer Klasse und JavaScript (und somit WEB Core) die Felder
  // einer Klasse. Der Grund ist simpel: JavaScript kennt das Konzept der
  // 'Eigenschaft' nicht.
  //
  // Somit bauen wir ein eigenes JavaScript-Objekt (Seite 75 in meinem Buch zu
  // TMS WEB Core). Beachten Sie, dass Gross- und Kleinschreibung unterschieden
  // wird.
 procedure OnResponse(Response:TXDataClientResponse);
  begin
    case byte(TJSObject(Response.Result)['value']) of
    0: begin
        GLV_AppSprache := GLV_AktUser.Sprache;
        showmessage(SP.load(50));//'Der Benutzer wurde erfolgreich gespeichert!');
        close;
       end;
    1: begin
        showmessage(SP.load(51));//'Der Benutzer konnte nicht gespeichert werden! Bitte versuchen Sie es noch einmal.');
        UsernameWebEdit.SetFocus;
       end;
   end;
  end;
var
  WebUser    : TJSObject;
begin

 if not CheckTab1 then exit;
 if not CheckTab2 then exit;

 GLV_AktUser.Username          := UsernameWebEdit.Text;
 GLV_AktUser.Password          := PasswortWebEdit.Text;
 GLV_AktUser.Email             := EMailWebEdit.Text;
 GLV_AktUser.Anrede            := AnredeWebComboBox.ItemIndex;
 GLV_AktUser.Titel             := TitelWebEdit.Text;
 GLV_AktUser.Name              := NameWebEdit.Text;
 GLV_AktUser.Vorname           := VornameWebEdit.Text;
 GLV_AktUser.Geboren           := formatdatetime('ddmmyyyy',GebWebDateTimePicker.Date);
 GLV_AktUser.Geschlecht        := SexWebComboBox.ItemIndex;
 GLV_AktUser.Groesse           := HeightWebSpinEdit.Value;
 GLV_AktUser.Sprache           := SpracheWebComboBox.ItemIndex;
 GLV_AktUser.SetAutoTimezone   := AutoTimeZoneCB.Checked;
 Get_UTC_Itemindex(ComboBox_UTC.ItemIndex,UTC_Hour,UTC_Minute);
 GLV_AktUser.UTC_Hour   := UTC_Hour;
 GLV_AktUser.UTC_Minute := UTC_Minute;
 GLV_AktUser.Newsletter := NewsletterWebCheckBox.Checked;
 GLV_AktUser.QiuSerial1 := QiuSerial1WebEdit.Text;
 GLV_AktUser.QiuSerial2 := QiuSerial2WebEdit.Text;
 GLV_AktUser.QiuSerial3 := QiuSerial3WebEdit.Text;
 GLV_AktUser.LoginMode  := LoginModeCombobox.ItemIndex;


 WebUser := TJSObject.new;
 try
  GLV_AktUser.Set_TWebUserJSObject_From_TWebUser(WebUser);

  MainForm.XDataWebClient.RawInvoke('IDatenService.WriteChangedUser',
         [GLV_AktUser.Token,WebUser],@OnResponse);
 finally
  //
 end;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.WriteNewUser;
var
 UTC_Hour,UTC_Minute:integer;

 procedure OnResponse(Response:TXDataClientResponse);
  begin
   case byte(TJSObject(Response.Result)['value']) of
    0: begin
        showmessage(SP.load(52));//'Der neue Benutzer wurde erfolgreich gespeichert! Die Aktivierungs-Mail für Ihren Account wurde an die angegebene E-Mail Adresse versendet!');
        close;
       end;
    1: begin
        showmessage(SP.load(53));//'Der eingegebene Benutzername wird schon verwendet! Bitte geben Sie einen anderen Benutzernamen ein.');
        UsernameWebEdit.SetFocus;
       end;
    2: begin
        showmessage(SP.load(54));//'Die eingegebene E-Mail Adresse wird schon verwendet! Bitte geben Sie eine andere E-Mail Adresse ein.');
        EMailWebEdit.SetFocus;
       end;
    3: begin
        showmessage(SP.load(55));//'Der eingegebene Benutzername und die eingegebene E-Mail Adresse werden schon verwendet! Bitte geben Sie einen anderen Benutzernamen und eine andere E-Mail Adresse ein.');
        UsernameWebEdit.SetFocus;
       end;
    4: begin
        showmessage(SP.load(56));//'Der neue Benutzer konnte nicht gespeichert werden! Bitte versuchen Sie es noch einmal.');
        UsernameWebEdit.SetFocus;
       end;
   end;
  end;
var
  WebUser    : TJSObject;
begin
 if not CheckTab1 then exit;
 if not CheckTab2 then exit;


 GLV_AktUser.Init;
 GLV_AktUser.Username          := UsernameWebEdit.Text;
 GLV_AktUser.Password          := PasswortWebEdit.Text;
 GLV_AktUser.Email             := EMailWebEdit.Text;
 GLV_AktUser.Anrede            := AnredeWebComboBox.ItemIndex;
 GLV_AktUser.Titel             := TitelWebEdit.Text;
 GLV_AktUser.Name              := NameWebEdit.Text;
 GLV_AktUser.Vorname           := VornameWebEdit.Text;
 GLV_AktUser.Geboren           := formatdatetime('ddmmyyyy',GebWebDateTimePicker.Date);
 GLV_AktUser.Geschlecht        := SexWebComboBox.ItemIndex;
 GLV_AktUser.Groesse           := HeightWebSpinEdit.Value;
 GLV_AktUser.Sprache           := SpracheWebComboBox.ItemIndex;
 GLV_AktUser.SetAutoTimezone   := AutoTimeZoneCB.Checked;
 Get_UTC_Itemindex(ComboBox_UTC.ItemIndex,UTC_Hour,UTC_Minute);
 GLV_AktUser.UTC_Hour   := UTC_Hour;
 GLV_AktUser.UTC_Minute := UTC_Minute;
 GLV_AktUser.Newsletter := NewsletterWebCheckBox.Checked;


 WebUser := TJSObject.new;
 try
  GLV_AktUser.Set_TWebUserJSObject_From_TWebUser(WebUser);
  MainForm.XDataWebClient.RawInvoke('ILoginService.WriteNewUser',
         [WebUser],@OnResponse);
 finally
  //
 end;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.CodeWebEditChange(Sender: TObject);
begin
 NewCodeWebBitBtn.Enabled := (length(trim(CodeWebEdit.Text)) = 12);
end;
{------------------------------------------------------------------------------}
procedure TUserForm.NewCodeWebBitBtnClick(Sender: TObject);
 procedure OnResponse(Response:TXDataClientResponse);
 var
    LObj: TJSObject;
  begin
    LObj := TJSObject(Response.Result);
    case integer(LObj['ReqResult']) of
    0: begin
        showmessage(SP.load(57));//'Der Lizenz-Code wurde erfolgreich aktiviert!');
        GLV_AktUser.ValidUntil := string(LObj['ValidUntil']);
        GLV_AktUser.LastCode := integer(LObj['LastCode']);
        ValidUntilWebEdit.Text := formatDateTime(GLV_DatumMaske,MyDateFormatToTDate(GLV_AktUser.ValidUntil));
        if MyDateFormatToTDate(GLV_AktUser.ValidUntil) < now then ValidUntilWebEdit.Font.Color := clred else ValidUntilWebEdit.Font.Color := clblack;
       end;
    1: begin
        showmessage(SP.load(58));//'Der Lizenz-Code ist ungültig (Decodieren)!');
       end;
    2: begin
        showmessage(SP.load(59));//'Der Benutzer konnte anhand des Tokens nicht gefunden werden');
       end;
    3: begin
        showmessage(SP.load(60));//'Der Lizenz-Code wurde schon von diesem Benutzer verwendet');
       end;
    4: begin
        showmessage(SP.load(61));//'Keine Verbindung zur Code-Datenbank möglich.');
       end;
    5: begin
        showmessage(SP.load(62));//'Der Lizenz-Code ist ungültig (Datenbank)!');
       end;
    6: begin
        showmessage(SP.load(63));//'Keine Verbindung zur Datenbank möglich!');
       end;
    7: begin
        showmessage(SP.load(64));//'Server Down!');
       end;
   end;
  end;
begin
 try
  MainForm.XDataWebClient.RawInvoke('IDatenService.ActivateNewLizenzCode',
         [GLV_AktUser.Token,trim(CodeWebEdit.Text)],@OnResponse);
 finally
  //
 end;

end;
{------------------------------------------------------------------------------}
procedure TUserForm.AGBWebButtonClick(Sender: TObject);
begin
 Application.Navigate(GetAGBLink, ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TUserForm.DatenschutzWebButtonClick(Sender: TObject);
begin
 Application.Navigate(GetDatenschutzerklaerungLink, ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TUserForm.DeleteAccount;
procedure OnResponse(Response:TXDataClientResponse);
  begin
    case byte(TJSObject(Response.Result)['value']) of
    0: begin
        showmessage(SP.load(65));//'Der Account wurde erfolgreich gelöscht!');
        GLV_UserLogin := false;
        MainForm.MainMenuFrame1.UpdateFrame;
        Close;
       end;
    1: begin
        showmessage(SP.load(66));//'Der Account konnte nicht gelöscht werden!');
       end;
   end;
  end;
begin
 try
  MainForm.XDataWebClient.RawInvoke('IDatenService.DeleteUserByID',
         [GLV_AktUser.Token,GLV_AktUser.ID],@OnResponse);
 finally
  //
 end;

end;
{------------------------------------------------------------------------------}
procedure TUserForm.DeleteAccountWebBitBtnClick(Sender: TObject);
var
 MyMessageDlgForm : TMyMessageDlgForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   case MyMessageDlgForm._Result of
    drYes : DeleteAccount;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;

begin
 MyMessageDlgForm := TMyMessageDlgForm.CreateNew(@AfterCreate);
 MyMessageDlgForm.Border := fbsingle;
 MyMessageDlgForm.Popup := true;
 MyMessageDlgForm.Titel := SP.load(20);//'Bestätigen';
 MyMessageDlgForm.MessageText := SP.load(67);//'Wollen Sie Ihren myQiu-Zugang wirklich löschen?';
 MyMessageDlgForm.DialogType := dtYesNo;
 MyMessageDlgForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TUserForm.DeleteQiuSerial1WebBitBtnClick(Sender: TObject);
begin
 QiuSerial1WebEdit.Text := '';
end;
{------------------------------------------------------------------------------}
procedure TUserForm.DeleteQiuSerial2WebBitBtnClick(Sender: TObject);
begin
 QiuSerial2WebEdit.Text := '';
end;
{------------------------------------------------------------------------------}
procedure TUserForm.DeleteQiuSerial3WebBitBtnClick(Sender: TObject);
begin
 QiuSerial3WebEdit.Text := '';
end;
{------------------------------------------------------------------------------}
procedure TUserForm.SelectTherapeut1WebBitBtnClick(Sender: TObject);
var
 SelectTherapeutForm : TSelectTherapeutForm;
 procedure AfterShowModal(AValue: TModalResult);
  begin
   GLV_AktUser.Therapeut1_UUID := SelectTherapeutForm.SelectedUUID;
   if trim(GLV_AktUser.Therapeut1_UUID) <> '' then GetTherapeutRecByUUID(trim(GLV_AktUser.Therapeut1_UUID),Therapeut1WebMemo);
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;
begin
 SelectTherapeutForm := TSelectTherapeutForm.CreateNew(@AfterCreate);
 SelectTherapeutForm.Border := fbDialog;
 SelectTherapeutForm.Caption := SP.load(68);//'Therapeut auswählen';
 SelectTherapeutForm.Popup := true;
 SelectTherapeutForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TUserForm.SelectTherapeut2WebBitBtnClick(Sender: TObject);
var
 SelectTherapeutForm : TSelectTherapeutForm;
 procedure AfterShowModal(AValue: TModalResult);
  begin
   GLV_AktUser.Therapeut2_UUID := SelectTherapeutForm.SelectedUUID;
   if trim(GLV_AktUser.Therapeut2_UUID) <> '' then GetTherapeutRecByUUID(trim(GLV_AktUser.Therapeut2_UUID),Therapeut2WebMemo);
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;
begin
 SelectTherapeutForm := TSelectTherapeutForm.CreateNew(@AfterCreate);
 SelectTherapeutForm.Border := fbDialog;
 SelectTherapeutForm.Caption := SP.load(68);//'Therapeut auswählen';
 SelectTherapeutForm.Popup := true;
 SelectTherapeutForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TUserForm.RemoveTherapeut1WebBitBtnClick(Sender: TObject);
begin
 Therapeut1WebMemo.Lines.Clear;
 GLV_AktUser.Therapeut1_UUID := '';
end;
{------------------------------------------------------------------------------}
procedure TUserForm.RemoveTherapeut2WebBitBtnClick(Sender: TObject);
begin
 Therapeut2WebMemo.Lines.Clear;
 GLV_AktUser.Therapeut2_UUID := '';
end;
{------------------------------------------------------------------------------}
function TUserForm.Set_UTC_Itemindex(_UTC_Hour,_UTC_Minute:integer):integer;
begin
 result := 0;
 case _UTC_Hour of
  -12 : result := 0;
  -11 : begin
         case _UTC_Minute of
          -45: result := 1;
          -30: result := 2;
          -15: result := 3;
          0 : result := 4;
         end;
        end;
  -10 : begin
         case _UTC_Minute of
          -45: result := 5;
          -30: result := 6;
          -15: result := 7;
          0 : result := 8;
         end;
        end;
  -9  : begin
         case _UTC_Minute of
          -45: result := 9;
          -30: result := 10;
          -15: result := 11;
          0 : result := 12;
         end;
        end;
  -8  : begin
         case _UTC_Minute of
          -45: result := 13;
          -30: result := 14;
          -15: result := 15;
          0 : result := 16;
         end;
        end;
  -7  : begin
         case _UTC_Minute of
          -45: result := 17;
          -30: result := 18;
          -15: result := 19;
          0 : result := 20;
         end;
        end;
  -6  : begin
         case _UTC_Minute of
          -45: result := 21;
          -30: result := 22;
          -15: result := 23;
          0 : result := 24;
         end;
        end;
  -5  : begin
         case _UTC_Minute of
          -45: result := 25;
          -30: result := 26;
          -15: result := 27;
          0 : result := 28;
         end;
        end;
  -4  : begin
         case _UTC_Minute of
          -45: result := 29;
          -30: result := 30;
          -15: result := 31;
          0 : result := 32;
         end;
        end;
  -3  : begin
         case _UTC_Minute of
          -45: result := 33;
          -30: result := 34;
          -15: result := 35;
          0 : result := 36
         end;
        end;
  -2  : begin
         case _UTC_Minute of
          -45: result := 37;
          -30: result := 38;
          -15: result := 39;
          0 : result := 40;
         end;
        end;
  -1  : begin
         case _UTC_Minute of
          -45: result := 41;
          -30: result := 42;
          -15: result := 43;
          0 : result := 44;
         end;
        end;
  0  : begin
         case _UTC_Minute of
          -45: result := 45;
          -30: result := 46;
          -15: result := 47;
          0 : result := 48;
          15:  result := 49;
          30:  result := 50;
          45 : result := 51;
         end;
        end;
  1  : begin
         case _UTC_Minute of
          0: result   := 52;
          15: result  := 53;
          30: result  := 54;
          45 : result := 55;
         end;
        end;
  2  : begin
         case _UTC_Minute of
          0: result   := 56;
          15: result  := 57;
          30: result  := 58;
          45 : result := 59;
         end;
        end;
  3  : begin
         case _UTC_Minute of
          0: result   := 60;
          15: result  := 61;
          30: result  := 62;
          45 : result := 63;
         end;
        end;
  4  : begin
         case _UTC_Minute of
          0: result   := 64;
          15: result  := 65;
          30: result  := 66;
          45 : result := 67;
         end;
        end;
  5  : begin
         case _UTC_Minute of
          0: result   := 68;
          15: result  := 69;
          30: result  := 70;
          45 : result := 71;
         end;
        end;
  6  : begin
         case _UTC_Minute of
          0: result   := 72;
          15: result  := 73;
          30: result  := 74;
          45 : result := 75;
         end;
        end;
  7  : begin
         case _UTC_Minute of
          0: result   := 76;
          15: result  := 77;
          30: result  := 78;
          45 : result := 79;
         end;
        end;
  8  : begin
         case _UTC_Minute of
          0: result   := 80;
          15: result  := 81;
          30: result  := 82;
          45 : result := 83;
         end;
        end;
  9  : begin
         case _UTC_Minute of
          0: result   := 84;
          15: result  := 85;
          30: result  := 86;
          45 : result := 87;
         end;
        end;
  10 : begin
         case _UTC_Minute of
          0: result   := 88;
          15: result  := 89;
          30: result  := 90;
          45 : result := 91;
         end;
        end;
  11 : begin
         case _UTC_Minute of
          0: result   := 92;
          15: result  := 93;
          30: result  := 94;
          45 : result := 95;
         end;
        end;
  12  : begin
         case _UTC_Minute of
          0: result   := 96;
         end;
        end;
 end;
end;
{------------------------------------------------------------------------------}
procedure TUserForm.Get_UTC_Itemindex(_CBItemIndex:integer;var UTC_Hour:integer; var UTC_Minute:integer);
begin
 case _CBItemIndex of
  0 : begin UTC_Hour := -12; UTC_Minute := 0; end;
  1 : begin UTC_Hour := -11; UTC_Minute := -45; end;
  2 : begin UTC_Hour := -11; UTC_Minute := -30; end;
  3 : begin UTC_Hour := -11; UTC_Minute := -15; end;
  4 : begin UTC_Hour := -11; UTC_Minute := 0; end;
  5 : begin UTC_Hour := -10; UTC_Minute := -45; end;
  6 : begin UTC_Hour := -10; UTC_Minute := -30; end;
  7 : begin UTC_Hour := -10; UTC_Minute := -15; end;
  8 : begin UTC_Hour := -10; UTC_Minute := 0; end;
  9 : begin UTC_Hour := -9; UTC_Minute := -45; end;
  10: begin UTC_Hour := -9; UTC_Minute := -30; end;
  11: begin UTC_Hour := -9; UTC_Minute := -15; end;
  12: begin UTC_Hour := -9; UTC_Minute := 0; end;
  13 : begin UTC_Hour := -8; UTC_Minute := -45; end;
  14: begin UTC_Hour := -8; UTC_Minute := -30; end;
  15: begin UTC_Hour := -8; UTC_Minute := -15; end;
  16: begin UTC_Hour := -8; UTC_Minute := 0; end;
  17 : begin UTC_Hour := -7; UTC_Minute := -45; end;
  18: begin UTC_Hour := -7; UTC_Minute := -30; end;
  19: begin UTC_Hour := -7; UTC_Minute := -15; end;
  20: begin UTC_Hour := -7; UTC_Minute := 0; end;
  21 : begin UTC_Hour := -6; UTC_Minute := -45; end;
  22: begin UTC_Hour := -6; UTC_Minute := -30; end;
  23: begin UTC_Hour := -6; UTC_Minute := -15; end;
  24: begin UTC_Hour := -6; UTC_Minute := 0; end;
  25 : begin UTC_Hour := -5; UTC_Minute := -45; end;
  26: begin UTC_Hour := -5; UTC_Minute := -30; end;
  27: begin UTC_Hour := -5; UTC_Minute := -15; end;
  28: begin UTC_Hour := -5; UTC_Minute := 0; end;
  29 : begin UTC_Hour := -4; UTC_Minute := -45; end;
  30: begin UTC_Hour := -4; UTC_Minute := -30; end;
  31: begin UTC_Hour := -4; UTC_Minute := -15; end;
  32: begin UTC_Hour := -4; UTC_Minute := 0; end;
  33 : begin UTC_Hour := -3; UTC_Minute := -45; end;
  34: begin UTC_Hour := -3; UTC_Minute := -30; end;
  35: begin UTC_Hour := -3; UTC_Minute := -15; end;
  36: begin UTC_Hour := -3; UTC_Minute := 0; end;
  37 : begin UTC_Hour := -2; UTC_Minute := -45; end;
  38: begin UTC_Hour := -2; UTC_Minute := -30; end;
  39: begin UTC_Hour := -2; UTC_Minute := -15; end;
  40: begin UTC_Hour := -2; UTC_Minute := 0; end;
  41 : begin UTC_Hour := -1; UTC_Minute := -45; end;
  42: begin UTC_Hour := -1; UTC_Minute := -30; end;
  43: begin UTC_Hour := -1; UTC_Minute := -15; end;
  44: begin UTC_Hour := -1; UTC_Minute := 0; end;
  45 : begin UTC_Hour := 0; UTC_Minute := -45; end;
  46: begin UTC_Hour := 0; UTC_Minute := -30; end;
  47: begin UTC_Hour := 0; UTC_Minute := -15; end;
  48: begin UTC_Hour := 0; UTC_Minute := 0; end;
  49: begin UTC_Hour := 0; UTC_Minute := 15; end;
  50: begin UTC_Hour := 0; UTC_Minute := 30; end;
  51: begin UTC_Hour := 0; UTC_Minute := 45; end;
  52: begin UTC_Hour := 1; UTC_Minute := 0; end;
  53: begin UTC_Hour := 1; UTC_Minute := 15; end;
  54: begin UTC_Hour := 1; UTC_Minute := 30; end;
  55: begin UTC_Hour := 1; UTC_Minute := 45; end;
  56: begin UTC_Hour := 2; UTC_Minute := 0; end;
  57: begin UTC_Hour := 2; UTC_Minute := 15; end;
  58: begin UTC_Hour := 2; UTC_Minute := 30; end;
  59: begin UTC_Hour := 2; UTC_Minute := 45; end;
  60: begin UTC_Hour := 3; UTC_Minute := 0; end;
  61: begin UTC_Hour := 3; UTC_Minute := 15; end;
  62: begin UTC_Hour := 3; UTC_Minute := 30; end;
  63: begin UTC_Hour := 3; UTC_Minute := 45; end;
  64: begin UTC_Hour := 4; UTC_Minute := 0; end;
  65: begin UTC_Hour := 4; UTC_Minute := 15; end;
  66: begin UTC_Hour := 4; UTC_Minute := 30; end;
  67: begin UTC_Hour := 4; UTC_Minute := 45; end;
  68: begin UTC_Hour := 5; UTC_Minute := 0; end;
  69: begin UTC_Hour := 5; UTC_Minute := 15; end;
  70: begin UTC_Hour := 5; UTC_Minute := 30; end;
  71: begin UTC_Hour := 5; UTC_Minute := 45; end;
  72: begin UTC_Hour := 6; UTC_Minute := 0; end;
  73: begin UTC_Hour := 6; UTC_Minute := 15; end;
  74: begin UTC_Hour := 6; UTC_Minute := 30; end;
  75: begin UTC_Hour := 6; UTC_Minute := 45; end;
  76: begin UTC_Hour := 7; UTC_Minute := 0; end;
  77: begin UTC_Hour := 7; UTC_Minute := 15; end;
  78: begin UTC_Hour := 7; UTC_Minute := 30; end;
  79: begin UTC_Hour := 7; UTC_Minute := 45; end;
  80: begin UTC_Hour := 8; UTC_Minute := 0; end;
  81: begin UTC_Hour := 8; UTC_Minute := 15; end;
  82: begin UTC_Hour := 8; UTC_Minute := 30; end;
  83: begin UTC_Hour := 8; UTC_Minute := 45; end;
  84: begin UTC_Hour := 9; UTC_Minute := 0; end;
  85: begin UTC_Hour := 9; UTC_Minute := 15; end;
  86: begin UTC_Hour := 9; UTC_Minute := 30; end;
  87: begin UTC_Hour := 9; UTC_Minute := 45; end;
  88: begin UTC_Hour := 10; UTC_Minute := 0; end;
  89: begin UTC_Hour := 10; UTC_Minute := 15; end;
  90: begin UTC_Hour := 10; UTC_Minute := 30; end;
  91: begin UTC_Hour := 10; UTC_Minute := 45; end;
  92: begin UTC_Hour := 11; UTC_Minute := 0; end;
  93: begin UTC_Hour := 11; UTC_Minute := 15; end;
  94: begin UTC_Hour := 11; UTC_Minute := 30; end;
  95: begin UTC_Hour := 11; UTC_Minute := 45; end;
  96: begin UTC_Hour := 12; UTC_Minute := 0; end;
 end;
end;
procedure TUserForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPageControl1 := TPageControl.Create(Self);
  WebPageControl1Sheet1 := TTabSheet.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel13 := TLabel.Create(Self);
  UsernameWebEdit := TEdit.Create(Self);
  PasswortWebEdit := TEdit.Create(Self);
  EMailWebEdit := TEdit.Create(Self);
  EMail2WebEdit := TEdit.Create(Self);
  Passwort2WebEdit := TEdit.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  AGBWebButton := TButton.Create(Self);
  DatenschutzWebButton := TButton.Create(Self);
  WebMemo2 := TMemo.Create(Self);
  WebPageControl1Sheet2 := TTabSheet.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebLabel12 := TLabel.Create(Self);
  LoginModeLabel := TLabel.Create(Self);
  TitelWebEdit := TEdit.Create(Self);
  AnredeWebComboBox := TComboBox.Create(Self);
  NameWebEdit := TEdit.Create(Self);
  VornameWebEdit := TEdit.Create(Self);
  GebWebDateTimePicker := TDateTimePicker.Create(Self);
  SexWebComboBox := TComboBox.Create(Self);
  SpracheWebComboBox := TComboBox.Create(Self);
  NewsletterWebCheckBox := TCheckBox.Create(Self);
  HeightWebSpinEdit := TSpinEdit.Create(Self);
  AutoTimeZoneCB := TCheckBox.Create(Self);
  ComboBox_UTC := TComboBox.Create(Self);
  LoginModeCombobox := TComboBox.Create(Self);
  WebPageControl1Sheet3 := TTabSheet.Create(Self);
  WebLabel14 := TLabel.Create(Self);
  WebLabel15 := TLabel.Create(Self);
  WebLabel16 := TLabel.Create(Self);
  ValidUntilWebEdit := TEdit.Create(Self);
  NewCodeWebBitBtn := TBitBtn.Create(Self);
  AboTypWebComboBox := TComboBox.Create(Self);
  CodeWebEdit := TEdit.Create(Self);
  WebPageControl1Sheet4 := TTabSheet.Create(Self);
  WebLabel17 := TLabel.Create(Self);
  WebLabel18 := TLabel.Create(Self);
  Therapeut1WebMemo := TMemo.Create(Self);
  SelectTherapeut1WebBitBtn := TBitBtn.Create(Self);
  RemoveTherapeut1WebBitBtn := TBitBtn.Create(Self);
  Therapeut2WebMemo := TMemo.Create(Self);
  SelectTherapeut2WebBitBtn := TBitBtn.Create(Self);
  RemoveTherapeut2WebBitBtn := TBitBtn.Create(Self);
  WebPageControl1Sheet5 := TTabSheet.Create(Self);
  DeleteAccountWebBitBtn := TBitBtn.Create(Self);
  WebPageControl1Sheet6 := TTabSheet.Create(Self);
  WebLabel19 := TLabel.Create(Self);
  WebLabel20 := TLabel.Create(Self);
  WebLabel21 := TLabel.Create(Self);
  QiuSerial1WebEdit := TEdit.Create(Self);
  QiuSerial2WebEdit := TEdit.Create(Self);
  QiuSerial3WebEdit := TEdit.Create(Self);
  DeleteQiuSerial1WebBitBtn := TBitBtn.Create(Self);
  DeleteQiuSerial2WebBitBtn := TBitBtn.Create(Self);
  DeleteQiuSerial3WebBitBtn := TBitBtn.Create(Self);
  SaveBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);

  WebPageControl1.BeforeLoadDFMValues;
  WebPageControl1Sheet1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel13.BeforeLoadDFMValues;
  UsernameWebEdit.BeforeLoadDFMValues;
  PasswortWebEdit.BeforeLoadDFMValues;
  EMailWebEdit.BeforeLoadDFMValues;
  EMail2WebEdit.BeforeLoadDFMValues;
  Passwort2WebEdit.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  AGBWebButton.BeforeLoadDFMValues;
  DatenschutzWebButton.BeforeLoadDFMValues;
  WebMemo2.BeforeLoadDFMValues;
  WebPageControl1Sheet2.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebLabel12.BeforeLoadDFMValues;
  LoginModeLabel.BeforeLoadDFMValues;
  TitelWebEdit.BeforeLoadDFMValues;
  AnredeWebComboBox.BeforeLoadDFMValues;
  NameWebEdit.BeforeLoadDFMValues;
  VornameWebEdit.BeforeLoadDFMValues;
  GebWebDateTimePicker.BeforeLoadDFMValues;
  SexWebComboBox.BeforeLoadDFMValues;
  SpracheWebComboBox.BeforeLoadDFMValues;
  NewsletterWebCheckBox.BeforeLoadDFMValues;
  HeightWebSpinEdit.BeforeLoadDFMValues;
  AutoTimeZoneCB.BeforeLoadDFMValues;
  ComboBox_UTC.BeforeLoadDFMValues;
  LoginModeCombobox.BeforeLoadDFMValues;
  WebPageControl1Sheet3.BeforeLoadDFMValues;
  WebLabel14.BeforeLoadDFMValues;
  WebLabel15.BeforeLoadDFMValues;
  WebLabel16.BeforeLoadDFMValues;
  ValidUntilWebEdit.BeforeLoadDFMValues;
  NewCodeWebBitBtn.BeforeLoadDFMValues;
  AboTypWebComboBox.BeforeLoadDFMValues;
  CodeWebEdit.BeforeLoadDFMValues;
  WebPageControl1Sheet4.BeforeLoadDFMValues;
  WebLabel17.BeforeLoadDFMValues;
  WebLabel18.BeforeLoadDFMValues;
  Therapeut1WebMemo.BeforeLoadDFMValues;
  SelectTherapeut1WebBitBtn.BeforeLoadDFMValues;
  RemoveTherapeut1WebBitBtn.BeforeLoadDFMValues;
  Therapeut2WebMemo.BeforeLoadDFMValues;
  SelectTherapeut2WebBitBtn.BeforeLoadDFMValues;
  RemoveTherapeut2WebBitBtn.BeforeLoadDFMValues;
  WebPageControl1Sheet5.BeforeLoadDFMValues;
  DeleteAccountWebBitBtn.BeforeLoadDFMValues;
  WebPageControl1Sheet6.BeforeLoadDFMValues;
  WebLabel19.BeforeLoadDFMValues;
  WebLabel20.BeforeLoadDFMValues;
  WebLabel21.BeforeLoadDFMValues;
  QiuSerial1WebEdit.BeforeLoadDFMValues;
  QiuSerial2WebEdit.BeforeLoadDFMValues;
  QiuSerial3WebEdit.BeforeLoadDFMValues;
  DeleteQiuSerial1WebBitBtn.BeforeLoadDFMValues;
  DeleteQiuSerial2WebBitBtn.BeforeLoadDFMValues;
  DeleteQiuSerial3WebBitBtn.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  try
    Name := 'UserForm';
    Width := 421;
    Height := 657;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebPageControl1.SetParentComponent(Self);
    WebPageControl1.Name := 'WebPageControl1';
    WebPageControl1.Left := 8;
    WebPageControl1.Top := 25;
    WebPageControl1.Width := 393;
    WebPageControl1.Height := 544;
    WebPageControl1.ChildOrder := 6;
    WebPageControl1.TabIndex := 0;
    WebPageControl1.TabOrder := 0;
    WebPageControl1Sheet1.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet1.Name := 'WebPageControl1Sheet1';
    WebPageControl1Sheet1.Left := 0;
    WebPageControl1Sheet1.Top := 20;
    WebPageControl1Sheet1.Width := 393;
    WebPageControl1Sheet1.Height := 524;
    WebPageControl1Sheet1.Caption := 'Benutzername';
    WebPageControl1Sheet1.ChildOrder := 1;
    WebLabel1.SetParentComponent(WebPageControl1Sheet1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 17;
    WebLabel1.Top := 8;
    WebLabel1.Width := 82;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Benutzername';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(WebPageControl1Sheet1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 17;
    WebLabel2.Top := 56;
    WebLabel2.Width := 52;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Passwort';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(WebPageControl1Sheet1);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 17;
    WebLabel3.Top := 159;
    WebLabel3.Width := 85;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'E-Mail Adresse';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(WebPageControl1Sheet1);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 17;
    WebLabel7.Top := 208;
    WebLabel7.Width := 159;
    WebLabel7.Height := 16;
    WebLabel7.Caption := 'E-Mail Adresse wiederholen';
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel13.SetParentComponent(WebPageControl1Sheet1);
    WebLabel13.Name := 'WebLabel13';
    WebLabel13.Left := 17;
    WebLabel13.Top := 104;
    WebLabel13.Width := 126;
    WebLabel13.Height := 16;
    WebLabel13.Caption := 'Passwort wiederholen';
    WebLabel13.HeightPercent := 100.000000000000000000;
    WebLabel13.WidthPercent := 100.000000000000000000;
    UsernameWebEdit.SetParentComponent(WebPageControl1Sheet1);
    UsernameWebEdit.Name := 'UsernameWebEdit';
    UsernameWebEdit.Left := 17;
    UsernameWebEdit.Top := 27;
    UsernameWebEdit.Width := 185;
    UsernameWebEdit.Height := 22;
    UsernameWebEdit.ChildOrder := 1;
    UsernameWebEdit.HeightPercent := 100.000000000000000000;
    UsernameWebEdit.Text := 'UsernameWebEdit';
    UsernameWebEdit.WidthPercent := 100.000000000000000000;
    PasswortWebEdit.SetParentComponent(WebPageControl1Sheet1);
    PasswortWebEdit.Name := 'PasswortWebEdit';
    PasswortWebEdit.Left := 17;
    PasswortWebEdit.Top := 75;
    PasswortWebEdit.Width := 185;
    PasswortWebEdit.Height := 22;
    PasswortWebEdit.ChildOrder := 1;
    PasswortWebEdit.HeightPercent := 100.000000000000000000;
    PasswortWebEdit.TabOrder := 1;
    PasswortWebEdit.Text := 'WebEdit1';
    PasswortWebEdit.WidthPercent := 100.000000000000000000;
    EMailWebEdit.SetParentComponent(WebPageControl1Sheet1);
    EMailWebEdit.Name := 'EMailWebEdit';
    EMailWebEdit.Left := 17;
    EMailWebEdit.Top := 178;
    EMailWebEdit.Width := 185;
    EMailWebEdit.Height := 22;
    EMailWebEdit.ChildOrder := 1;
    EMailWebEdit.HeightPercent := 100.000000000000000000;
    EMailWebEdit.TabOrder := 3;
    EMailWebEdit.Text := 'WebEdit1';
    EMailWebEdit.WidthPercent := 100.000000000000000000;
    EMail2WebEdit.SetParentComponent(WebPageControl1Sheet1);
    EMail2WebEdit.Name := 'EMail2WebEdit';
    EMail2WebEdit.Left := 17;
    EMail2WebEdit.Top := 227;
    EMail2WebEdit.Width := 185;
    EMail2WebEdit.Height := 22;
    EMail2WebEdit.ChildOrder := 1;
    EMail2WebEdit.HeightPercent := 100.000000000000000000;
    EMail2WebEdit.TabOrder := 4;
    EMail2WebEdit.Text := 'WebEdit1';
    EMail2WebEdit.WidthPercent := 100.000000000000000000;
    Passwort2WebEdit.SetParentComponent(WebPageControl1Sheet1);
    Passwort2WebEdit.Name := 'Passwort2WebEdit';
    Passwort2WebEdit.Left := 17;
    Passwort2WebEdit.Top := 123;
    Passwort2WebEdit.Width := 185;
    Passwort2WebEdit.Height := 22;
    Passwort2WebEdit.ChildOrder := 1;
    Passwort2WebEdit.HeightPercent := 100.000000000000000000;
    Passwort2WebEdit.TabOrder := 2;
    Passwort2WebEdit.Text := 'WebEdit1';
    Passwort2WebEdit.WidthPercent := 100.000000000000000000;
    WebMemo1.SetParentComponent(WebPageControl1Sheet1);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 16;
    WebMemo1.Top := 262;
    WebMemo1.Width := 353;
    WebMemo1.Height := 37;
    WebMemo1.AutoSize := False;
    WebMemo1.BorderStyle := bsNone;
    WebMemo1.Font.Charset := DEFAULT_CHARSET;
    WebMemo1.Font.Color := clWindowText;
    WebMemo1.Font.Height := -11;
    WebMemo1.Font.Name := 'Tahoma';
    WebMemo1.Font.Style := [];
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('Die AGBs und die Datenschutzerkl'#228'rung m'#252'ssen beim ersten Login und ');
      WebMemo1.Lines.Add('danach auch bei Aktualisierungen akzeptiert werden.');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ParentFont := False;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 120;
    WebMemo1.WidthPercent := 100.000000000000000000;
    AGBWebButton.SetParentComponent(WebPageControl1Sheet1);
    AGBWebButton.Name := 'AGBWebButton';
    AGBWebButton.Left := 80;
    AGBWebButton.Top := 303;
    AGBWebButton.Width := 210;
    AGBWebButton.Height := 24;
    AGBWebButton.Caption := 'AGBs '#246'ffnen';
    AGBWebButton.ChildOrder := 11;
    AGBWebButton.HeightPercent := 100.000000000000000000;
    AGBWebButton.TabOrder := 5;
    AGBWebButton.WidthPercent := 100.000000000000000000;
    SetEvent(AGBWebButton, Self, 'OnClick', 'AGBWebButtonClick');
    DatenschutzWebButton.SetParentComponent(WebPageControl1Sheet1);
    DatenschutzWebButton.Name := 'DatenschutzWebButton';
    DatenschutzWebButton.Left := 80;
    DatenschutzWebButton.Top := 334;
    DatenschutzWebButton.Width := 210;
    DatenschutzWebButton.Height := 25;
    DatenschutzWebButton.Caption := 'Datenschutzerkl'#228'rung '#246'ffnen';
    DatenschutzWebButton.ChildOrder := 11;
    DatenschutzWebButton.HeightPercent := 100.000000000000000000;
    DatenschutzWebButton.TabOrder := 6;
    DatenschutzWebButton.WidthPercent := 100.000000000000000000;
    SetEvent(DatenschutzWebButton, Self, 'OnClick', 'DatenschutzWebButtonClick');
    WebMemo2.SetParentComponent(WebPageControl1Sheet1);
    WebMemo2.Name := 'WebMemo2';
    WebMemo2.Left := 16;
    WebMemo2.Top := 370;
    WebMemo2.Width := 353;
    WebMemo2.Height := 127;
    WebMemo2.AutoSize := False;
    WebMemo2.BorderStyle := bsNone;
    WebMemo2.Font.Charset := DEFAULT_CHARSET;
    WebMemo2.Font.Color := clWindowText;
    WebMemo2.Font.Height := -11;
    WebMemo2.Font.Name := 'Tahoma';
    WebMemo2.Font.Style := [];
    WebMemo2.HeightPercent := 100.000000000000000000;
    WebMemo2.Lines.BeginUpdate;
    try
      WebMemo2.Lines.Clear;
      WebMemo2.Lines.Add('*Hinweise zum Datenschutz: Der Schutz Ihrer Daten ist uns wichtig. ');
      WebMemo2.Lines.Add('Deswegen werden Ihre Daten verschl'#252'sselt und getrennt von ');
      WebMemo2.Lines.Add('medizinischen Daten gespeichert. Medizinische Daten werden ');
      WebMemo2.Lines.Add('anonymisiert und enthalten keinerlei pers'#246'nliche Informationen wie ');
      WebMemo2.Lines.Add('Name, Geburtsdatum oder Adresse.');
    finally
      WebMemo2.Lines.EndUpdate;
    end;
    WebMemo2.ParentFont := False;
    WebMemo2.ReadOnly := True;
    WebMemo2.SelLength := 0;
    WebMemo2.SelStart := 285;
    WebMemo2.WidthPercent := 100.000000000000000000;
    WebPageControl1Sheet2.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet2.Name := 'WebPageControl1Sheet2';
    WebPageControl1Sheet2.Left := 0;
    WebPageControl1Sheet2.Top := 20;
    WebPageControl1Sheet2.Width := 393;
    WebPageControl1Sheet2.Height := 524;
    WebPageControl1Sheet2.Align := alTop;
    WebPageControl1Sheet2.Caption := 'pers'#246'nliche Daten';
    WebPageControl1Sheet2.ChildOrder := 2;
    WebPageControl1Sheet2.ElementFont := efCSS;
    WebLabel4.SetParentComponent(WebPageControl1Sheet2);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 128;
    WebLabel4.Top := 16;
    WebLabel4.Width := 25;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Titel';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(WebPageControl1Sheet2);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 16;
    WebLabel5.Top := 16;
    WebLabel5.Width := 41;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Anrede';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(WebPageControl1Sheet2);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 16;
    WebLabel6.Top := 118;
    WebLabel6.Width := 33;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'Name';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(WebPageControl1Sheet2);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 17;
    WebLabel8.Top := 68;
    WebLabel8.Width := 52;
    WebLabel8.Height := 16;
    WebLabel8.Caption := 'Vorname';
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(WebPageControl1Sheet2);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 16;
    WebLabel9.Top := 170;
    WebLabel9.Width := 48;
    WebLabel9.Height := 16;
    WebLabel9.Caption := 'Geboren';
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebLabel10.SetParentComponent(WebPageControl1Sheet2);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.Left := 16;
    WebLabel10.Top := 222;
    WebLabel10.Width := 61;
    WebLabel10.Height := 16;
    WebLabel10.Caption := 'Geschlecht';
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.WidthPercent := 100.000000000000000000;
    WebLabel11.SetParentComponent(WebPageControl1Sheet2);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.Left := 16;
    WebLabel11.Top := 275;
    WebLabel11.Width := 47;
    WebLabel11.Height := 16;
    WebLabel11.Caption := 'Sprache';
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.WidthPercent := 100.000000000000000000;
    WebLabel12.SetParentComponent(WebPageControl1Sheet2);
    WebLabel12.Name := 'WebLabel12';
    WebLabel12.Left := 128;
    WebLabel12.Top := 222;
    WebLabel12.Width := 102;
    WebLabel12.Height := 16;
    WebLabel12.Caption := 'K'#246'rpergr'#246#223'e [cm]';
    WebLabel12.HeightPercent := 100.000000000000000000;
    WebLabel12.WidthPercent := 100.000000000000000000;
    LoginModeLabel.SetParentComponent(WebPageControl1Sheet2);
    LoginModeLabel.Name := 'LoginModeLabel';
    LoginModeLabel.Left := 16;
    LoginModeLabel.Top := 355;
    LoginModeLabel.Width := 92;
    LoginModeLabel.Height := 16;
    LoginModeLabel.Caption := 'Login-Verfahren';
    LoginModeLabel.HeightPercent := 100.000000000000000000;
    LoginModeLabel.WidthPercent := 100.000000000000000000;
    TitelWebEdit.SetParentComponent(WebPageControl1Sheet2);
    TitelWebEdit.Name := 'TitelWebEdit';
    TitelWebEdit.Left := 128;
    TitelWebEdit.Top := 35;
    TitelWebEdit.Width := 137;
    TitelWebEdit.Height := 22;
    TitelWebEdit.ChildOrder := 1;
    TitelWebEdit.HeightPercent := 100.000000000000000000;
    TitelWebEdit.TabOrder := 8;
    TitelWebEdit.Text := 'WebEdit1';
    TitelWebEdit.WidthPercent := 100.000000000000000000;
    AnredeWebComboBox.SetParentComponent(WebPageControl1Sheet2);
    AnredeWebComboBox.Name := 'AnredeWebComboBox';
    AnredeWebComboBox.Left := 16;
    AnredeWebComboBox.Top := 35;
    AnredeWebComboBox.Width := 89;
    AnredeWebComboBox.Height := 24;
    AnredeWebComboBox.HeightPercent := 100.000000000000000000;
    AnredeWebComboBox.TabOrder := 7;
    AnredeWebComboBox.Text := 'Firma';
    AnredeWebComboBox.WidthPercent := 100.000000000000000000;
    AnredeWebComboBox.ItemIndex := -1;
    AnredeWebComboBox.Items.BeginUpdate;
    try
      AnredeWebComboBox.Items.Clear;
      AnredeWebComboBox.Items.Add('');
    finally
      AnredeWebComboBox.Items.EndUpdate;
    end;
    NameWebEdit.SetParentComponent(WebPageControl1Sheet2);
    NameWebEdit.Name := 'NameWebEdit';
    NameWebEdit.Left := 17;
    NameWebEdit.Top := 137;
    NameWebEdit.Width := 249;
    NameWebEdit.Height := 22;
    NameWebEdit.ChildOrder := 1;
    NameWebEdit.HeightPercent := 100.000000000000000000;
    NameWebEdit.TabOrder := 9;
    NameWebEdit.Text := 'WebEdit1';
    NameWebEdit.WidthPercent := 100.000000000000000000;
    VornameWebEdit.SetParentComponent(WebPageControl1Sheet2);
    VornameWebEdit.Name := 'VornameWebEdit';
    VornameWebEdit.Left := 17;
    VornameWebEdit.Top := 87;
    VornameWebEdit.Width := 249;
    VornameWebEdit.Height := 22;
    VornameWebEdit.ChildOrder := 1;
    VornameWebEdit.HeightPercent := 100.000000000000000000;
    VornameWebEdit.TabOrder := 10;
    VornameWebEdit.Text := 'WebEdit1';
    VornameWebEdit.WidthPercent := 100.000000000000000000;
    GebWebDateTimePicker.SetParentComponent(WebPageControl1Sheet2);
    GebWebDateTimePicker.Name := 'GebWebDateTimePicker';
    GebWebDateTimePicker.Left := 16;
    GebWebDateTimePicker.Top := 189;
    GebWebDateTimePicker.Width := 153;
    GebWebDateTimePicker.Height := 22;
    GebWebDateTimePicker.BorderStyle := bsSingle;
    GebWebDateTimePicker.ChildOrder := 9;
    GebWebDateTimePicker.Color := clWhite;
    GebWebDateTimePicker.Date := 44218.359056435180000000;
    GebWebDateTimePicker.Role := '';
    GebWebDateTimePicker.TabOrder := 11;
    GebWebDateTimePicker.Text := '';
    SexWebComboBox.SetParentComponent(WebPageControl1Sheet2);
    SexWebComboBox.Name := 'SexWebComboBox';
    SexWebComboBox.Left := 16;
    SexWebComboBox.Top := 241;
    SexWebComboBox.Width := 89;
    SexWebComboBox.Height := 24;
    SexWebComboBox.HeightPercent := 100.000000000000000000;
    SexWebComboBox.TabOrder := 12;
    SexWebComboBox.Text := 'm'#228'nnlich';
    SexWebComboBox.WidthPercent := 100.000000000000000000;
    SexWebComboBox.ItemIndex := -1;
    SexWebComboBox.Items.BeginUpdate;
    try
      SexWebComboBox.Items.Clear;
      SexWebComboBox.Items.Add('');
    finally
      SexWebComboBox.Items.EndUpdate;
    end;
    SpracheWebComboBox.SetParentComponent(WebPageControl1Sheet2);
    SpracheWebComboBox.Name := 'SpracheWebComboBox';
    SpracheWebComboBox.Left := 16;
    SpracheWebComboBox.Top := 294;
    SpracheWebComboBox.Width := 89;
    SpracheWebComboBox.Height := 24;
    SpracheWebComboBox.HeightPercent := 100.000000000000000000;
    SpracheWebComboBox.TabOrder := 14;
    SpracheWebComboBox.Text := 'Deutsch';
    SpracheWebComboBox.WidthPercent := 100.000000000000000000;
    SpracheWebComboBox.ItemIndex := -1;
    NewsletterWebCheckBox.SetParentComponent(WebPageControl1Sheet2);
    NewsletterWebCheckBox.Name := 'NewsletterWebCheckBox';
    NewsletterWebCheckBox.Left := 128;
    NewsletterWebCheckBox.Top := 294;
    NewsletterWebCheckBox.Width := 145;
    NewsletterWebCheckBox.Height := 22;
    NewsletterWebCheckBox.Caption := 'Newsletter abonnieren';
    NewsletterWebCheckBox.ChildOrder := 14;
    NewsletterWebCheckBox.HeightPercent := 100.000000000000000000;
    NewsletterWebCheckBox.TabOrder := 15;
    NewsletterWebCheckBox.WidthPercent := 100.000000000000000000;
    HeightWebSpinEdit.SetParentComponent(WebPageControl1Sheet2);
    HeightWebSpinEdit.Name := 'HeightWebSpinEdit';
    HeightWebSpinEdit.Left := 128;
    HeightWebSpinEdit.Top := 241;
    HeightWebSpinEdit.Width := 84;
    HeightWebSpinEdit.Height := 22;
    HeightWebSpinEdit.AutoSize := False;
    HeightWebSpinEdit.BorderStyle := bsSingle;
    HeightWebSpinEdit.ChildOrder := 17;
    HeightWebSpinEdit.Color := clWhite;
    HeightWebSpinEdit.Increment := 1;
    HeightWebSpinEdit.MaxValue := 250;
    HeightWebSpinEdit.MinValue := 50;
    HeightWebSpinEdit.Role := '';
    HeightWebSpinEdit.TabOrder := 13;
    HeightWebSpinEdit.Value := 0;
    AutoTimeZoneCB.SetParentComponent(WebPageControl1Sheet2);
    AutoTimeZoneCB.Name := 'AutoTimeZoneCB';
    AutoTimeZoneCB.Left := 16;
    AutoTimeZoneCB.Top := 328;
    AutoTimeZoneCB.Width := 145;
    AutoTimeZoneCB.Height := 22;
    AutoTimeZoneCB.Caption := 'Zeitzone automatisch';
    AutoTimeZoneCB.ChildOrder := 14;
    AutoTimeZoneCB.HeightPercent := 100.000000000000000000;
    AutoTimeZoneCB.TabOrder := 16;
    AutoTimeZoneCB.WidthPercent := 100.000000000000000000;
    SetEvent(AutoTimeZoneCB, Self, 'OnClick', 'AutoTimeZoneCBClick');
    ComboBox_UTC.SetParentComponent(WebPageControl1Sheet2);
    ComboBox_UTC.Name := 'ComboBox_UTC';
    ComboBox_UTC.Left := 182;
    ComboBox_UTC.Top := 328;
    ComboBox_UTC.Width := 120;
    ComboBox_UTC.Height := 24;
    ComboBox_UTC.HeightPercent := 100.000000000000000000;
    ComboBox_UTC.TabOrder := 17;
    ComboBox_UTC.Text := 'Deutsch';
    ComboBox_UTC.WidthPercent := 100.000000000000000000;
    ComboBox_UTC.ItemIndex := -1;
    LoginModeCombobox.SetParentComponent(WebPageControl1Sheet2);
    LoginModeCombobox.Name := 'LoginModeCombobox';
    LoginModeCombobox.Left := 16;
    LoginModeCombobox.Top := 374;
    LoginModeCombobox.Width := 325;
    LoginModeCombobox.Height := 24;
    LoginModeCombobox.HeightPercent := 100.000000000000000000;
    LoginModeCombobox.TabOrder := 14;
    LoginModeCombobox.Text := 'Deutsch';
    LoginModeCombobox.WidthPercent := 100.000000000000000000;
    LoginModeCombobox.ItemIndex := -1;
    WebPageControl1Sheet3.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet3.Name := 'WebPageControl1Sheet3';
    WebPageControl1Sheet3.Left := 0;
    WebPageControl1Sheet3.Top := 20;
    WebPageControl1Sheet3.Width := 393;
    WebPageControl1Sheet3.Height := 524;
    WebPageControl1Sheet3.Caption := 'Lizenzmodell';
    WebPageControl1Sheet3.ChildOrder := 2;
    WebLabel14.SetParentComponent(WebPageControl1Sheet3);
    WebLabel14.Name := 'WebLabel14';
    WebLabel14.Left := 88;
    WebLabel14.Top := 90;
    WebLabel14.Width := 144;
    WebLabel14.Height := 16;
    WebLabel14.Caption := 'Aktuelle Lizenz g'#252'ltig bis:';
    WebLabel14.HeightPercent := 100.000000000000000000;
    WebLabel14.WidthPercent := 100.000000000000000000;
    WebLabel15.SetParentComponent(WebPageControl1Sheet3);
    WebLabel15.Name := 'WebLabel15';
    WebLabel15.Left := 88;
    WebLabel15.Top := 29;
    WebLabel15.Width := 104;
    WebLabel15.Height := 16;
    WebLabel15.Caption := 'Gew'#228'hlter Zugang';
    WebLabel15.HeightPercent := 100.000000000000000000;
    WebLabel15.WidthPercent := 100.000000000000000000;
    WebLabel16.SetParentComponent(WebPageControl1Sheet3);
    WebLabel16.Name := 'WebLabel16';
    WebLabel16.Left := 88;
    WebLabel16.Top := 151;
    WebLabel16.Width := 107;
    WebLabel16.Height := 16;
    WebLabel16.Caption := 'Neuer Lizenz-Code';
    WebLabel16.HeightPercent := 100.000000000000000000;
    WebLabel16.WidthPercent := 100.000000000000000000;
    ValidUntilWebEdit.SetParentComponent(WebPageControl1Sheet3);
    ValidUntilWebEdit.Name := 'ValidUntilWebEdit';
    ValidUntilWebEdit.Left := 88;
    ValidUntilWebEdit.Top := 109;
    ValidUntilWebEdit.Width := 206;
    ValidUntilWebEdit.Height := 22;
    ValidUntilWebEdit.ChildOrder := 1;
    ValidUntilWebEdit.HeightPercent := 100.000000000000000000;
    ValidUntilWebEdit.ReadOnly := True;
    ValidUntilWebEdit.TabOrder := 19;
    ValidUntilWebEdit.Text := 'ValidUntilWebEdit';
    ValidUntilWebEdit.WidthPercent := 100.000000000000000000;
    NewCodeWebBitBtn.SetParentComponent(WebPageControl1Sheet3);
    NewCodeWebBitBtn.Name := 'NewCodeWebBitBtn';
    NewCodeWebBitBtn.Left := 103;
    NewCodeWebBitBtn.Top := 221;
    NewCodeWebBitBtn.Width := 162;
    NewCodeWebBitBtn.Height := 30;
    NewCodeWebBitBtn.Caption := 'Lizenz-Code einl'#246'sen';
    NewCodeWebBitBtn.Color := clNone;
    NewCodeWebBitBtn.HeightPercent := 100.000000000000000000;
    NewCodeWebBitBtn.MaterialGlyph := 'edit';
    NewCodeWebBitBtn.TabOrder := 1;
    NewCodeWebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(NewCodeWebBitBtn, Self, 'OnClick', 'NewCodeWebBitBtnClick');
    AboTypWebComboBox.SetParentComponent(WebPageControl1Sheet3);
    AboTypWebComboBox.Name := 'AboTypWebComboBox';
    AboTypWebComboBox.Left := 88;
    AboTypWebComboBox.Top := 48;
    AboTypWebComboBox.Width := 206;
    AboTypWebComboBox.Height := 24;
    AboTypWebComboBox.HeightPercent := 100.000000000000000000;
    AboTypWebComboBox.TabOrder := 18;
    AboTypWebComboBox.Text := 'Lizenz-Code';
    AboTypWebComboBox.WidthPercent := 100.000000000000000000;
    AboTypWebComboBox.ItemIndex := 0;
    AboTypWebComboBox.Items.BeginUpdate;
    try
      AboTypWebComboBox.Items.Clear;
      AboTypWebComboBox.Items.Add('Lizenz-Code');
    finally
      AboTypWebComboBox.Items.EndUpdate;
    end;
    CodeWebEdit.SetParentComponent(WebPageControl1Sheet3);
    CodeWebEdit.Name := 'CodeWebEdit';
    CodeWebEdit.Left := 88;
    CodeWebEdit.Top := 170;
    CodeWebEdit.Width := 206;
    CodeWebEdit.Height := 22;
    CodeWebEdit.ChildOrder := 1;
    CodeWebEdit.HeightPercent := 100.000000000000000000;
    CodeWebEdit.TabOrder := 20;
    CodeWebEdit.Text := 'WebEdit1';
    CodeWebEdit.WidthPercent := 100.000000000000000000;
    SetEvent(CodeWebEdit, Self, 'OnChange', 'CodeWebEditChange');
    WebPageControl1Sheet4.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet4.Name := 'WebPageControl1Sheet4';
    WebPageControl1Sheet4.Left := 0;
    WebPageControl1Sheet4.Top := 20;
    WebPageControl1Sheet4.Width := 393;
    WebPageControl1Sheet4.Height := 524;
    WebPageControl1Sheet4.Caption := 'Therapeut';
    WebPageControl1Sheet4.ChildOrder := 2;
    WebLabel17.SetParentComponent(WebPageControl1Sheet4);
    WebLabel17.Name := 'WebLabel17';
    WebLabel17.Left := 25;
    WebLabel17.Top := 38;
    WebLabel17.Width := 70;
    WebLabel17.Height := 16;
    WebLabel17.Caption := 'Therapeut 1';
    WebLabel17.HeightPercent := 100.000000000000000000;
    WebLabel17.WidthPercent := 100.000000000000000000;
    WebLabel18.SetParentComponent(WebPageControl1Sheet4);
    WebLabel18.Name := 'WebLabel18';
    WebLabel18.Left := 24;
    WebLabel18.Top := 248;
    WebLabel18.Width := 70;
    WebLabel18.Height := 16;
    WebLabel18.Caption := 'Therapeut 2';
    WebLabel18.HeightPercent := 100.000000000000000000;
    WebLabel18.WidthPercent := 100.000000000000000000;
    Therapeut1WebMemo.SetParentComponent(WebPageControl1Sheet4);
    Therapeut1WebMemo.Name := 'Therapeut1WebMemo';
    Therapeut1WebMemo.Left := 26;
    Therapeut1WebMemo.Top := 61;
    Therapeut1WebMemo.Width := 327;
    Therapeut1WebMemo.Height := 125;
    Therapeut1WebMemo.AutoSize := False;
    Therapeut1WebMemo.HeightPercent := 100.000000000000000000;
    Therapeut1WebMemo.Lines.BeginUpdate;
    try
      Therapeut1WebMemo.Lines.Clear;
      Therapeut1WebMemo.Lines.Add('Therapeut1WebMemo');
    finally
      Therapeut1WebMemo.Lines.EndUpdate;
    end;
    Therapeut1WebMemo.ReadOnly := True;
    Therapeut1WebMemo.SelLength := 0;
    Therapeut1WebMemo.SelStart := 0;
    Therapeut1WebMemo.WidthPercent := 100.000000000000000000;
    SelectTherapeut1WebBitBtn.SetParentComponent(WebPageControl1Sheet4);
    SelectTherapeut1WebBitBtn.Name := 'SelectTherapeut1WebBitBtn';
    SelectTherapeut1WebBitBtn.Left := 33;
    SelectTherapeut1WebBitBtn.Top := 201;
    SelectTherapeut1WebBitBtn.Width := 140;
    SelectTherapeut1WebBitBtn.Height := 25;
    SelectTherapeut1WebBitBtn.Caption := 'Therapeut w'#228'hlen';
    SelectTherapeut1WebBitBtn.Color := clNone;
    SelectTherapeut1WebBitBtn.HeightPercent := 100.000000000000000000;
    SelectTherapeut1WebBitBtn.TabOrder := 1;
    SelectTherapeut1WebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SelectTherapeut1WebBitBtn, Self, 'OnClick', 'SelectTherapeut1WebBitBtnClick');
    RemoveTherapeut1WebBitBtn.SetParentComponent(WebPageControl1Sheet4);
    RemoveTherapeut1WebBitBtn.Name := 'RemoveTherapeut1WebBitBtn';
    RemoveTherapeut1WebBitBtn.Left := 183;
    RemoveTherapeut1WebBitBtn.Top := 201;
    RemoveTherapeut1WebBitBtn.Width := 160;
    RemoveTherapeut1WebBitBtn.Height := 25;
    RemoveTherapeut1WebBitBtn.Caption := 'Therapeut entfernen';
    RemoveTherapeut1WebBitBtn.Color := clNone;
    RemoveTherapeut1WebBitBtn.HeightPercent := 100.000000000000000000;
    RemoveTherapeut1WebBitBtn.TabOrder := 2;
    RemoveTherapeut1WebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(RemoveTherapeut1WebBitBtn, Self, 'OnClick', 'RemoveTherapeut1WebBitBtnClick');
    Therapeut2WebMemo.SetParentComponent(WebPageControl1Sheet4);
    Therapeut2WebMemo.Name := 'Therapeut2WebMemo';
    Therapeut2WebMemo.Left := 25;
    Therapeut2WebMemo.Top := 271;
    Therapeut2WebMemo.Width := 328;
    Therapeut2WebMemo.Height := 125;
    Therapeut2WebMemo.AutoSize := False;
    Therapeut2WebMemo.HeightPercent := 100.000000000000000000;
    Therapeut2WebMemo.Lines.BeginUpdate;
    try
      Therapeut2WebMemo.Lines.Clear;
      Therapeut2WebMemo.Lines.Add('WebMemo3');
    finally
      Therapeut2WebMemo.Lines.EndUpdate;
    end;
    Therapeut2WebMemo.ReadOnly := True;
    Therapeut2WebMemo.SelLength := 0;
    Therapeut2WebMemo.SelStart := 0;
    Therapeut2WebMemo.WidthPercent := 100.000000000000000000;
    SelectTherapeut2WebBitBtn.SetParentComponent(WebPageControl1Sheet4);
    SelectTherapeut2WebBitBtn.Name := 'SelectTherapeut2WebBitBtn';
    SelectTherapeut2WebBitBtn.Left := 33;
    SelectTherapeut2WebBitBtn.Top := 407;
    SelectTherapeut2WebBitBtn.Width := 136;
    SelectTherapeut2WebBitBtn.Height := 25;
    SelectTherapeut2WebBitBtn.Caption := 'Therapeut w'#228'hlen';
    SelectTherapeut2WebBitBtn.Color := clNone;
    SelectTherapeut2WebBitBtn.HeightPercent := 100.000000000000000000;
    SelectTherapeut2WebBitBtn.TabOrder := 4;
    SelectTherapeut2WebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SelectTherapeut2WebBitBtn, Self, 'OnClick', 'SelectTherapeut2WebBitBtnClick');
    RemoveTherapeut2WebBitBtn.SetParentComponent(WebPageControl1Sheet4);
    RemoveTherapeut2WebBitBtn.Name := 'RemoveTherapeut2WebBitBtn';
    RemoveTherapeut2WebBitBtn.Left := 182;
    RemoveTherapeut2WebBitBtn.Top := 407;
    RemoveTherapeut2WebBitBtn.Width := 160;
    RemoveTherapeut2WebBitBtn.Height := 25;
    RemoveTherapeut2WebBitBtn.Caption := 'Therapeut entfernen';
    RemoveTherapeut2WebBitBtn.Color := clNone;
    RemoveTherapeut2WebBitBtn.HeightPercent := 100.000000000000000000;
    RemoveTherapeut2WebBitBtn.TabOrder := 5;
    RemoveTherapeut2WebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(RemoveTherapeut2WebBitBtn, Self, 'OnClick', 'RemoveTherapeut2WebBitBtnClick');
    WebPageControl1Sheet5.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet5.Name := 'WebPageControl1Sheet5';
    WebPageControl1Sheet5.Left := 0;
    WebPageControl1Sheet5.Top := 20;
    WebPageControl1Sheet5.Width := 393;
    WebPageControl1Sheet5.Height := 524;
    WebPageControl1Sheet5.Caption := 'myQiu-Zugang l'#246'schen';
    WebPageControl1Sheet5.ChildOrder := 4;
    DeleteAccountWebBitBtn.SetParentComponent(WebPageControl1Sheet5);
    DeleteAccountWebBitBtn.Name := 'DeleteAccountWebBitBtn';
    DeleteAccountWebBitBtn.Left := 111;
    DeleteAccountWebBitBtn.Top := 206;
    DeleteAccountWebBitBtn.Width := 162;
    DeleteAccountWebBitBtn.Height := 75;
    DeleteAccountWebBitBtn.Caption := 'Account l'#246'schen';
    DeleteAccountWebBitBtn.Color := clNone;
    DeleteAccountWebBitBtn.HeightPercent := 100.000000000000000000;
    DeleteAccountWebBitBtn.MaterialGlyph := 'delete_forever';
    DeleteAccountWebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteAccountWebBitBtn, Self, 'OnClick', 'DeleteAccountWebBitBtnClick');
    WebPageControl1Sheet6.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet6.Name := 'WebPageControl1Sheet6';
    WebPageControl1Sheet6.Left := 0;
    WebPageControl1Sheet6.Top := 20;
    WebPageControl1Sheet6.Width := 393;
    WebPageControl1Sheet6.Height := 524;
    WebPageControl1Sheet6.Caption := 'Qiu-Seriennummern';
    WebPageControl1Sheet6.ChildOrder := 5;
    WebLabel19.SetParentComponent(WebPageControl1Sheet6);
    WebLabel19.Name := 'WebLabel19';
    WebLabel19.Left := 53;
    WebLabel19.Top := 46;
    WebLabel19.Width := 68;
    WebLabel19.Height := 16;
    WebLabel19.Caption := 'Qiu-Serial 1';
    WebLabel19.HeightPercent := 100.000000000000000000;
    WebLabel19.WidthPercent := 100.000000000000000000;
    WebLabel20.SetParentComponent(WebPageControl1Sheet6);
    WebLabel20.Name := 'WebLabel20';
    WebLabel20.Left := 53;
    WebLabel20.Top := 96;
    WebLabel20.Width := 68;
    WebLabel20.Height := 16;
    WebLabel20.Caption := 'Qiu-Serial 2';
    WebLabel20.HeightPercent := 100.000000000000000000;
    WebLabel20.WidthPercent := 100.000000000000000000;
    WebLabel21.SetParentComponent(WebPageControl1Sheet6);
    WebLabel21.Name := 'WebLabel21';
    WebLabel21.Left := 53;
    WebLabel21.Top := 146;
    WebLabel21.Width := 68;
    WebLabel21.Height := 16;
    WebLabel21.Caption := 'Qiu-Serial 3';
    WebLabel21.HeightPercent := 100.000000000000000000;
    WebLabel21.WidthPercent := 100.000000000000000000;
    QiuSerial1WebEdit.SetParentComponent(WebPageControl1Sheet6);
    QiuSerial1WebEdit.Name := 'QiuSerial1WebEdit';
    QiuSerial1WebEdit.Left := 53;
    QiuSerial1WebEdit.Top := 65;
    QiuSerial1WebEdit.Width := 206;
    QiuSerial1WebEdit.Height := 22;
    QiuSerial1WebEdit.ChildOrder := 1;
    QiuSerial1WebEdit.HeightPercent := 100.000000000000000000;
    QiuSerial1WebEdit.ReadOnly := True;
    QiuSerial1WebEdit.TabOrder := 19;
    QiuSerial1WebEdit.WidthPercent := 100.000000000000000000;
    QiuSerial2WebEdit.SetParentComponent(WebPageControl1Sheet6);
    QiuSerial2WebEdit.Name := 'QiuSerial2WebEdit';
    QiuSerial2WebEdit.Left := 53;
    QiuSerial2WebEdit.Top := 115;
    QiuSerial2WebEdit.Width := 206;
    QiuSerial2WebEdit.Height := 22;
    QiuSerial2WebEdit.ChildOrder := 1;
    QiuSerial2WebEdit.HeightPercent := 100.000000000000000000;
    QiuSerial2WebEdit.ReadOnly := True;
    QiuSerial2WebEdit.TabOrder := 19;
    QiuSerial2WebEdit.WidthPercent := 100.000000000000000000;
    QiuSerial3WebEdit.SetParentComponent(WebPageControl1Sheet6);
    QiuSerial3WebEdit.Name := 'QiuSerial3WebEdit';
    QiuSerial3WebEdit.Left := 53;
    QiuSerial3WebEdit.Top := 165;
    QiuSerial3WebEdit.Width := 206;
    QiuSerial3WebEdit.Height := 22;
    QiuSerial3WebEdit.ChildOrder := 1;
    QiuSerial3WebEdit.HeightPercent := 100.000000000000000000;
    QiuSerial3WebEdit.ReadOnly := True;
    QiuSerial3WebEdit.TabOrder := 19;
    QiuSerial3WebEdit.WidthPercent := 100.000000000000000000;
    DeleteQiuSerial1WebBitBtn.SetParentComponent(WebPageControl1Sheet6);
    DeleteQiuSerial1WebBitBtn.Name := 'DeleteQiuSerial1WebBitBtn';
    DeleteQiuSerial1WebBitBtn.Left := 272;
    DeleteQiuSerial1WebBitBtn.Top := 64;
    DeleteQiuSerial1WebBitBtn.Width := 22;
    DeleteQiuSerial1WebBitBtn.Height := 25;
    DeleteQiuSerial1WebBitBtn.Color := clNone;
    DeleteQiuSerial1WebBitBtn.HeightPercent := 100.000000000000000000;
    DeleteQiuSerial1WebBitBtn.MaterialGlyph := 'delete';
    DeleteQiuSerial1WebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteQiuSerial1WebBitBtn, Self, 'OnClick', 'DeleteQiuSerial1WebBitBtnClick');
    DeleteQiuSerial2WebBitBtn.SetParentComponent(WebPageControl1Sheet6);
    DeleteQiuSerial2WebBitBtn.Name := 'DeleteQiuSerial2WebBitBtn';
    DeleteQiuSerial2WebBitBtn.Left := 272;
    DeleteQiuSerial2WebBitBtn.Top := 112;
    DeleteQiuSerial2WebBitBtn.Width := 22;
    DeleteQiuSerial2WebBitBtn.Height := 25;
    DeleteQiuSerial2WebBitBtn.Color := clNone;
    DeleteQiuSerial2WebBitBtn.HeightPercent := 100.000000000000000000;
    DeleteQiuSerial2WebBitBtn.MaterialGlyph := 'delete';
    DeleteQiuSerial2WebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteQiuSerial2WebBitBtn, Self, 'OnClick', 'DeleteQiuSerial2WebBitBtnClick');
    DeleteQiuSerial3WebBitBtn.SetParentComponent(WebPageControl1Sheet6);
    DeleteQiuSerial3WebBitBtn.Name := 'DeleteQiuSerial3WebBitBtn';
    DeleteQiuSerial3WebBitBtn.Left := 272;
    DeleteQiuSerial3WebBitBtn.Top := 162;
    DeleteQiuSerial3WebBitBtn.Width := 22;
    DeleteQiuSerial3WebBitBtn.Height := 25;
    DeleteQiuSerial3WebBitBtn.Color := clNone;
    DeleteQiuSerial3WebBitBtn.HeightPercent := 100.000000000000000000;
    DeleteQiuSerial3WebBitBtn.MaterialGlyph := 'delete';
    DeleteQiuSerial3WebBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteQiuSerial3WebBitBtn, Self, 'OnClick', 'DeleteQiuSerial3WebBitBtnClick');
    SaveBtn.SetParentComponent(Self);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 61;
    SaveBtn.Top := 587;
    SaveBtn.Width := 100;
    SaveBtn.Height := 30;
    SaveBtn.Caption := 'Speichern';
    SaveBtn.Color := clNone;
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.MaterialGlyph := 'save';
    SaveBtn.TabOrder := 1;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 224;
    CancelBtn.Top := 587;
    CancelBtn.Width := 100;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 2;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
  finally
    WebPageControl1.AfterLoadDFMValues;
    WebPageControl1Sheet1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel13.AfterLoadDFMValues;
    UsernameWebEdit.AfterLoadDFMValues;
    PasswortWebEdit.AfterLoadDFMValues;
    EMailWebEdit.AfterLoadDFMValues;
    EMail2WebEdit.AfterLoadDFMValues;
    Passwort2WebEdit.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    AGBWebButton.AfterLoadDFMValues;
    DatenschutzWebButton.AfterLoadDFMValues;
    WebMemo2.AfterLoadDFMValues;
    WebPageControl1Sheet2.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebLabel12.AfterLoadDFMValues;
    LoginModeLabel.AfterLoadDFMValues;
    TitelWebEdit.AfterLoadDFMValues;
    AnredeWebComboBox.AfterLoadDFMValues;
    NameWebEdit.AfterLoadDFMValues;
    VornameWebEdit.AfterLoadDFMValues;
    GebWebDateTimePicker.AfterLoadDFMValues;
    SexWebComboBox.AfterLoadDFMValues;
    SpracheWebComboBox.AfterLoadDFMValues;
    NewsletterWebCheckBox.AfterLoadDFMValues;
    HeightWebSpinEdit.AfterLoadDFMValues;
    AutoTimeZoneCB.AfterLoadDFMValues;
    ComboBox_UTC.AfterLoadDFMValues;
    LoginModeCombobox.AfterLoadDFMValues;
    WebPageControl1Sheet3.AfterLoadDFMValues;
    WebLabel14.AfterLoadDFMValues;
    WebLabel15.AfterLoadDFMValues;
    WebLabel16.AfterLoadDFMValues;
    ValidUntilWebEdit.AfterLoadDFMValues;
    NewCodeWebBitBtn.AfterLoadDFMValues;
    AboTypWebComboBox.AfterLoadDFMValues;
    CodeWebEdit.AfterLoadDFMValues;
    WebPageControl1Sheet4.AfterLoadDFMValues;
    WebLabel17.AfterLoadDFMValues;
    WebLabel18.AfterLoadDFMValues;
    Therapeut1WebMemo.AfterLoadDFMValues;
    SelectTherapeut1WebBitBtn.AfterLoadDFMValues;
    RemoveTherapeut1WebBitBtn.AfterLoadDFMValues;
    Therapeut2WebMemo.AfterLoadDFMValues;
    SelectTherapeut2WebBitBtn.AfterLoadDFMValues;
    RemoveTherapeut2WebBitBtn.AfterLoadDFMValues;
    WebPageControl1Sheet5.AfterLoadDFMValues;
    DeleteAccountWebBitBtn.AfterLoadDFMValues;
    WebPageControl1Sheet6.AfterLoadDFMValues;
    WebLabel19.AfterLoadDFMValues;
    WebLabel20.AfterLoadDFMValues;
    WebLabel21.AfterLoadDFMValues;
    QiuSerial1WebEdit.AfterLoadDFMValues;
    QiuSerial2WebEdit.AfterLoadDFMValues;
    QiuSerial3WebEdit.AfterLoadDFMValues;
    DeleteQiuSerial1WebBitBtn.AfterLoadDFMValues;
    DeleteQiuSerial2WebBitBtn.AfterLoadDFMValues;
    DeleteQiuSerial3WebBitBtn.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
  end;
end;

end.