
unit myQiuTestFragenAntwortenMC;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  XData.Web.Client, Vcl.Imaging.pngimage, WEBLib.Buttons, Vcl.Graphics,
  DatenServiceClasses, WEBLib.ExtCtrls;

type
  TmyQiuTestFragenAntwortenMCForm = class(TForm)
    WebLabel1: TLabel;
    WebMemo1: TMemo;
    CancelBtn: TBitBtn;
    SaveBtn: TBitBtn;
    WebPanel1: TPanel;
    WebRadioButton1: TRadioButton;
    WebMemo2: TMemo;
    WebPanel2: TPanel;
    WebRadioButton2: TRadioButton;
    WebMemo3: TMemo;
    WebPanel3: TPanel;
    WebRadioButton3: TRadioButton;
    WebMemo4: TMemo;
    WebPanel4: TPanel;
    WebRadioButton4: TRadioButton;
    WebMemo5: TMemo;
    WebPanel5: TPanel;
    WebRadioButton5: TRadioButton;
    WebMemo6: TMemo;
    WebLabel2: TLabel;
    PreviousBtn: TBitBtn;
    NextBtn: TBitBtn;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure SaveChangedAntwort;
    procedure SaveBtnClick(Sender: TObject);
    procedure WebMemo2Click(Sender: TObject);
    procedure WebMemo3Click(Sender: TObject);
    procedure WebMemo4Click(Sender: TObject);
    procedure WebMemo5Click(Sender: TObject);
    procedure WebMemo6Click(Sender: TObject);
    procedure NextBtnClick(Sender: TObject);
    procedure PreviousBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
    FormClosed : boolean;
  public
    { Public-Deklarationen }
    Rec         : TFragenAntwortenItemClass;
    MessungsID  : string;
    ChangesDone : boolean;
    EditMode    : boolean;  // AUfruf kommt cvon Fragen bearbeiten
    Next        : boolean;
    FrageIDX    : integer;
  protected procedure LoadDFMValues; override; end;

var
  myQiuTestFragenAntwortenMCForm: TmyQiuTestFragenAntwortenMCForm;

implementation

uses Main, KVT, myQiuTestFragenAntworten;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.CancelBtnClick(Sender: TObject);
begin
 FormClosed := true;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
 ChangesDone:= false;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebFormResize(Sender: TObject);
begin
 if EditMode then begin
  SaveBtn.Left   := self.ClientWidth div 2 + GLV_Dist;
  CancelBtn.Left := self.ClientWidth div 2 - CancelBtn.Width - GLV_Dist;
 end else begin
  if FrageIDX <= 0 then PreviousBtn.Enabled := false;
  PreviousBtn.Left := self.ClientWidth div 2 - PreviousBtn.Width - GLV_Dist;
  NextBtn.Left   := self.ClientWidth div 2 + GLV_Dist;
  CancelBtn.Left := self.ClientWidth div 2 - CancelBtn.Width div 2;
 end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebFormShow(Sender: TObject);
var
 s : string;
begin
 CancelBtn.Caption := SP.Load(27);
 SaveBtn.Caption := SP.Load(74);
 WebLabel1.Caption := SP.Load(304);

 NextBtn.Caption := SP.Load(320);
 PreviousBtn.Caption := SP.Load(321);

 if EditMode then begin
  NextBtn.Visible := false;
  PreviousBtn.Visible := false;
  SaveBtn.Visible := true;
 end else begin
  NextBtn.Visible := true;
  PreviousBtn.Visible := true;
  SaveBtn.Visible := false;
 end;


 WebMemo1.Lines.Clear;
 WebMemo1.Lines.Add(Rec.Text);

 WebMemo2.Lines.Clear;
 WebMemo3.Lines.Clear;
 WebMemo4.Lines.Clear;
 WebMemo5.Lines.Clear;
 WebMemo6.Lines.Clear;

 WebRadioButton1.GroupName := '0';
 WebRadioButton2.GroupName := '0';
 WebRadioButton3.GroupName := '0';
 WebRadioButton4.GroupName := '0';
 WebRadioButton5.GroupName := '0';

 if Rec.Typ = 1 then WebLabel2.Caption := SP.Load(318);
 if Rec.Typ = 2 then begin
  WebLabel2.Caption := SP.Load(319);
  WebRadioButton1.GroupName := '1';
  WebRadioButton2.GroupName := '2';
  WebRadioButton3.GroupName := '3';
  WebRadioButton4.GroupName := '4';
  WebRadioButton5.GroupName := '5';
 end;

 if trim(Rec.MC1_Text) <> ''  then begin
  WebMemo2.Lines.Add(Rec.MC1_Text);
  WebPanel1.Visible := true;
 end else WebPanel1.Visible := false;

 if trim(Rec.MC2_Text) <> ''  then begin
  WebMemo3.Lines.Add(Rec.MC2_Text);
  WebPanel2.Visible := true;
 end else WebPanel2.Visible := false;

 if trim(Rec.MC3_Text) <> ''  then begin
  WebMemo4.Lines.Add(Rec.MC3_Text);
  WebPanel3.Visible := true;
 end else WebPanel3.Visible := false;

 if trim(Rec.MC4_Text) <> ''  then begin
  WebMemo5.Lines.Add(Rec.MC4_Text);
  WebPanel4.Visible := true;
 end else WebPanel4.Visible := false;

 if trim(Rec.MC5_Text) <> ''  then begin
  WebMemo6.Lines.Add(Rec.MC5_Text);
  WebPanel5.Visible := true;
 end else WebPanel5.Visible := false;
 s := inttostr(Rec.Wert);
 if copy(s,2,1) = '1' then WebRadioButton1.Checked := true else WebRadioButton1.Checked := false;
 if copy(s,3,1) = '1' then WebRadioButton2.Checked := true else WebRadioButton2.Checked := false;
 if copy(s,4,1) = '1' then WebRadioButton3.Checked := true else WebRadioButton3.Checked := false;
 if copy(s,5,1) = '1' then WebRadioButton4.Checked := true else WebRadioButton4.Checked := false;
 if copy(s,6,1) = '1' then WebRadioButton5.Checked := true else WebRadioButton5.Checked := false;

 WebFormResize(Self);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebMemo2Click(Sender: TObject);
begin
 WebRadioButton1.Checked := not WebRadioButton1.Checked;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebMemo3Click(Sender: TObject);
begin
 WebRadioButton2.Checked := not WebRadioButton2.Checked;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebMemo4Click(Sender: TObject);
begin
 WebRadioButton3.Checked := not WebRadioButton3.Checked;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebMemo5Click(Sender: TObject);
begin
 WebRadioButton4.Checked := not WebRadioButton4.Checked;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.WebMemo6Click(Sender: TObject);
begin
 WebRadioButton5.Checked := not WebRadioButton5.Checked;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.NextBtnClick(Sender: TObject);
begin
 SaveChangedAntwort;
 Next := true;
 FormClosed := true;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.PreviousBtnClick(Sender: TObject);
begin
 SaveChangedAntwort;
 Next := false;
 FormClosed := true;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.SaveBtnClick(Sender: TObject);
begin
 SaveChangedAntwort;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenMCForm.SaveChangedAntwort;
var
 s           : string;
begin
   ChangesDone := true;

   s := '9';
   if WebRadioButton1.Checked then s := s + '1' else s := s + '0';
   if WebRadioButton2.Checked then s := s + '1' else s := s + '0';
   if WebRadioButton3.Checked then s := s + '1' else s := s + '0';
   if WebRadioButton4.Checked then s := s + '1' else s := s + '0';
   if WebRadioButton5.Checked then s := s + '1' else s := s + '0';

   Rec.Wert := strtoint(s);

   if EditMode then begin
     MainForm.XDataWebClient.RawInvoke('IDatenService.EditAntwortValue',[GLV_AktUser.Token,GLV_AktUser.UUID,MessungsID,Rec.ID,Rec.Wert],
     procedure (Response: TXDataClientResponse)
     begin
      if FormClosed then exit;
      case byte(TJSObject(Response.Result)['value']) of
       1: begin
           FormClosed := true;
           Close;
          end;
       0: showmessage(SP.load(204));
      end;
     end);
   end;
end;

procedure TmyQiuTestFragenAntwortenMCForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  SaveBtn := TBitBtn.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebRadioButton1 := TRadioButton.Create(Self);
  WebMemo2 := TMemo.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebRadioButton2 := TRadioButton.Create(Self);
  WebMemo3 := TMemo.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebRadioButton3 := TRadioButton.Create(Self);
  WebMemo4 := TMemo.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  WebRadioButton4 := TRadioButton.Create(Self);
  WebMemo5 := TMemo.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  WebRadioButton5 := TRadioButton.Create(Self);
  WebMemo6 := TMemo.Create(Self);
  PreviousBtn := TBitBtn.Create(Self);
  NextBtn := TBitBtn.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebRadioButton1.BeforeLoadDFMValues;
  WebMemo2.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebRadioButton2.BeforeLoadDFMValues;
  WebMemo3.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebRadioButton3.BeforeLoadDFMValues;
  WebMemo4.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  WebRadioButton4.BeforeLoadDFMValues;
  WebMemo5.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  WebRadioButton5.BeforeLoadDFMValues;
  WebMemo6.BeforeLoadDFMValues;
  PreviousBtn.BeforeLoadDFMValues;
  NextBtn.BeforeLoadDFMValues;
  try
    Name := 'myQiuTestFragenAntwortenMCForm';
    Width := 365;
    Height := 522;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 12;
    WebLabel1.Top := 16;
    WebLabel1.Width := 54;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Fragetext';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 12;
    WebLabel2.Top := 115;
    WebLabel2.Width := 54;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Fragetext';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 12;
    WebMemo1.Top := 38;
    WebMemo1.Width := 340;
    WebMemo1.Height := 73;
    WebMemo1.AutoSize := False;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 17;
    CancelBtn.Top := 445;
    CancelBtn.Width := 153;
    CancelBtn.Height := 33;
    CancelBtn.Caption := 'CancelBtn';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 1;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    SaveBtn.SetParentComponent(Self);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 190;
    SaveBtn.Top := 445;
    SaveBtn.Width := 153;
    SaveBtn.Height := 33;
    SaveBtn.Caption := 'SaveBtn';
    SaveBtn.Color := clNone;
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.MaterialGlyph := 'save';
    SaveBtn.TabOrder := 2;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 12;
    WebPanel1.Top := 133;
    WebPanel1.Width := 340;
    WebPanel1.Height := 50;
    WebPanel1.ChildOrder := 4;
    WebRadioButton1.SetParentComponent(WebPanel1);
    WebRadioButton1.Name := 'WebRadioButton1';
    WebRadioButton1.Left := 10;
    WebRadioButton1.Top := 16;
    WebRadioButton1.Width := 25;
    WebRadioButton1.Height := 22;
    WebRadioButton1.Margins.Left := 10;
    WebRadioButton1.Checked := False;
    WebRadioButton1.Color := clNone;
    WebRadioButton1.HeightPercent := 100.000000000000000000;
    WebRadioButton1.WidthPercent := 100.000000000000000000;
    WebMemo2.SetParentComponent(WebPanel1);
    WebMemo2.Name := 'WebMemo2';
    WebMemo2.Left := 31;
    WebMemo2.Top := 6;
    WebMemo2.Width := 300;
    WebMemo2.Height := 40;
    WebMemo2.AutoSize := False;
    WebMemo2.HeightPercent := 100.000000000000000000;
    WebMemo2.Lines.BeginUpdate;
    try
      WebMemo2.Lines.Clear;
      WebMemo2.Lines.Add('WebMemo2');
    finally
      WebMemo2.Lines.EndUpdate;
    end;
    WebMemo2.ReadOnly := True;
    WebMemo2.SelLength := 0;
    WebMemo2.SelStart := 0;
    WebMemo2.WidthPercent := 100.000000000000000000;
    SetEvent(WebMemo2, Self, 'OnClick', 'WebMemo2Click');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 12;
    WebPanel2.Top := 185;
    WebPanel2.Width := 340;
    WebPanel2.Height := 50;
    WebPanel2.ChildOrder := 4;
    WebRadioButton2.SetParentComponent(WebPanel2);
    WebRadioButton2.Name := 'WebRadioButton2';
    WebRadioButton2.Left := 10;
    WebRadioButton2.Top := 16;
    WebRadioButton2.Width := 25;
    WebRadioButton2.Height := 22;
    WebRadioButton2.Margins.Left := 10;
    WebRadioButton2.Checked := False;
    WebRadioButton2.Color := clNone;
    WebRadioButton2.HeightPercent := 100.000000000000000000;
    WebRadioButton2.WidthPercent := 100.000000000000000000;
    WebMemo3.SetParentComponent(WebPanel2);
    WebMemo3.Name := 'WebMemo3';
    WebMemo3.Left := 31;
    WebMemo3.Top := 6;
    WebMemo3.Width := 300;
    WebMemo3.Height := 40;
    WebMemo3.AutoSize := False;
    WebMemo3.HeightPercent := 100.000000000000000000;
    WebMemo3.Lines.BeginUpdate;
    try
      WebMemo3.Lines.Clear;
      WebMemo3.Lines.Add('WebMemo2');
    finally
      WebMemo3.Lines.EndUpdate;
    end;
    WebMemo3.ReadOnly := True;
    WebMemo3.SelLength := 0;
    WebMemo3.SelStart := 0;
    WebMemo3.WidthPercent := 100.000000000000000000;
    SetEvent(WebMemo3, Self, 'OnClick', 'WebMemo3Click');
    WebPanel3.SetParentComponent(Self);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 12;
    WebPanel3.Top := 236;
    WebPanel3.Width := 340;
    WebPanel3.Height := 50;
    WebPanel3.ChildOrder := 4;
    WebRadioButton3.SetParentComponent(WebPanel3);
    WebRadioButton3.Name := 'WebRadioButton3';
    WebRadioButton3.Left := 10;
    WebRadioButton3.Top := 16;
    WebRadioButton3.Width := 25;
    WebRadioButton3.Height := 22;
    WebRadioButton3.Margins.Left := 10;
    WebRadioButton3.Checked := False;
    WebRadioButton3.Color := clNone;
    WebRadioButton3.HeightPercent := 100.000000000000000000;
    WebRadioButton3.WidthPercent := 100.000000000000000000;
    WebMemo4.SetParentComponent(WebPanel3);
    WebMemo4.Name := 'WebMemo4';
    WebMemo4.Left := 31;
    WebMemo4.Top := 6;
    WebMemo4.Width := 300;
    WebMemo4.Height := 40;
    WebMemo4.AutoSize := False;
    WebMemo4.HeightPercent := 100.000000000000000000;
    WebMemo4.Lines.BeginUpdate;
    try
      WebMemo4.Lines.Clear;
      WebMemo4.Lines.Add('WebMemo2');
    finally
      WebMemo4.Lines.EndUpdate;
    end;
    WebMemo4.ReadOnly := True;
    WebMemo4.SelLength := 0;
    WebMemo4.SelStart := 0;
    WebMemo4.WidthPercent := 100.000000000000000000;
    SetEvent(WebMemo4, Self, 'OnClick', 'WebMemo4Click');
    WebPanel4.SetParentComponent(Self);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 12;
    WebPanel4.Top := 288;
    WebPanel4.Width := 340;
    WebPanel4.Height := 50;
    WebPanel4.ChildOrder := 4;
    WebRadioButton4.SetParentComponent(WebPanel4);
    WebRadioButton4.Name := 'WebRadioButton4';
    WebRadioButton4.Left := 10;
    WebRadioButton4.Top := 16;
    WebRadioButton4.Width := 25;
    WebRadioButton4.Height := 22;
    WebRadioButton4.Margins.Left := 10;
    WebRadioButton4.Checked := False;
    WebRadioButton4.Color := clNone;
    WebRadioButton4.HeightPercent := 100.000000000000000000;
    WebRadioButton4.WidthPercent := 100.000000000000000000;
    WebMemo5.SetParentComponent(WebPanel4);
    WebMemo5.Name := 'WebMemo5';
    WebMemo5.Left := 31;
    WebMemo5.Top := 6;
    WebMemo5.Width := 300;
    WebMemo5.Height := 40;
    WebMemo5.AutoSize := False;
    WebMemo5.HeightPercent := 100.000000000000000000;
    WebMemo5.Lines.BeginUpdate;
    try
      WebMemo5.Lines.Clear;
      WebMemo5.Lines.Add('WebMemo2');
    finally
      WebMemo5.Lines.EndUpdate;
    end;
    WebMemo5.ReadOnly := True;
    WebMemo5.SelLength := 0;
    WebMemo5.SelStart := 0;
    WebMemo5.WidthPercent := 100.000000000000000000;
    SetEvent(WebMemo5, Self, 'OnClick', 'WebMemo5Click');
    WebPanel5.SetParentComponent(Self);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 12;
    WebPanel5.Top := 341;
    WebPanel5.Width := 340;
    WebPanel5.Height := 50;
    WebPanel5.ChildOrder := 4;
    WebRadioButton5.SetParentComponent(WebPanel5);
    WebRadioButton5.Name := 'WebRadioButton5';
    WebRadioButton5.Left := 10;
    WebRadioButton5.Top := 16;
    WebRadioButton5.Width := 25;
    WebRadioButton5.Height := 22;
    WebRadioButton5.Margins.Left := 10;
    WebRadioButton5.Checked := False;
    WebRadioButton5.Color := clNone;
    WebRadioButton5.HeightPercent := 100.000000000000000000;
    WebRadioButton5.WidthPercent := 100.000000000000000000;
    WebMemo6.SetParentComponent(WebPanel5);
    WebMemo6.Name := 'WebMemo6';
    WebMemo6.Left := 31;
    WebMemo6.Top := 6;
    WebMemo6.Width := 300;
    WebMemo6.Height := 40;
    WebMemo6.AutoSize := False;
    WebMemo6.HeightPercent := 100.000000000000000000;
    WebMemo6.Lines.BeginUpdate;
    try
      WebMemo6.Lines.Clear;
      WebMemo6.Lines.Add('WebMemo2');
    finally
      WebMemo6.Lines.EndUpdate;
    end;
    WebMemo6.ReadOnly := True;
    WebMemo6.SelLength := 0;
    WebMemo6.SelStart := 0;
    WebMemo6.WidthPercent := 100.000000000000000000;
    SetEvent(WebMemo6, Self, 'OnClick', 'WebMemo6Click');
    PreviousBtn.SetParentComponent(Self);
    PreviousBtn.Name := 'PreviousBtn';
    PreviousBtn.Left := 17;
    PreviousBtn.Top := 406;
    PreviousBtn.Width := 153;
    PreviousBtn.Height := 33;
    PreviousBtn.Caption := 'PreviousBtn';
    PreviousBtn.Color := clNone;
    PreviousBtn.HeightPercent := 100.000000000000000000;
    PreviousBtn.MaterialGlyph := 'navigate_before';
    PreviousBtn.TabOrder := 8;
    PreviousBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PreviousBtn, Self, 'OnClick', 'PreviousBtnClick');
    NextBtn.SetParentComponent(Self);
    NextBtn.Name := 'NextBtn';
    NextBtn.Left := 190;
    NextBtn.Top := 406;
    NextBtn.Width := 153;
    NextBtn.Height := 33;
    NextBtn.Caption := 'NextBtn';
    NextBtn.Color := clNone;
    NextBtn.HeightPercent := 100.000000000000000000;
    NextBtn.MaterialGlyph := 'navigate_next';
    NextBtn.TabOrder := 9;
    NextBtn.WidthPercent := 100.000000000000000000;
    SetEvent(NextBtn, Self, 'OnClick', 'NextBtnClick');
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebRadioButton1.AfterLoadDFMValues;
    WebMemo2.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebRadioButton2.AfterLoadDFMValues;
    WebMemo3.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebRadioButton3.AfterLoadDFMValues;
    WebMemo4.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    WebRadioButton4.AfterLoadDFMValues;
    WebMemo5.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    WebRadioButton5.AfterLoadDFMValues;
    WebMemo6.AfterLoadDFMValues;
    PreviousBtn.AfterLoadDFMValues;
    NextBtn.AfterLoadDFMValues;
  end;
end;

end.