unit KVT;

interface

uses DatenServiceClasses, Texte, VCL.Graphics;

var

 GLV_UserLogin           : boolean = false;
 GLV_AccountOK           : boolean = false;
 GLV_HideMainMenuFrame   : boolean = false;
 GLV_AktUser             : TUser;
 GLV_DatumMaske          : string = 'dd.mm.yyyy';
 GLV_ZeitMaske           : string = 'hh:nn:ss';
 GLV_ZeitMaskeShort      : string = 'hh:nn';
 GLV_AppSprache          : integer = 0;
 SP                      : TSprachManagement;

 GLV_MinSmallWidth       : integer = 550;      // Minimale Breite, ab da Forms SW (small width)
 GLV_MinSmallHeight      : integer = 550;      // Minimale Höhe, ab da Forms SH (small height)

 GLV_FormSH_Height       : integer = 800;     // Höhe aller FormPanels in den SH Forms


 // Farben
 CaptionPanelColor        : TColor = clGray;
 CaptionPanelFontColor    : TColor = clWhite;
 CaptionPanelBorderColor  : TColor = clSilver;

 GLV_AutoLogin            : boolean = false;
 GLV_User                 : string = '';
 GLV_Password             : string = '';

 GLV_ServerHostName       : string;
 GLV_ServerPort           : integer;
 GLV_ServerBaseURL        : string = 'http://localhost:8000/BioSign/myQiu';

 GLV_NavigateHintAlreadyShown : boolean = false;

 GLV_PictureCreateSizeFactor : integer = 1;

 GLV_ShowReportMode          : integer = 0;

const
 GLC_Version              = '1.00.035';
 GLC_NumericVersion        = 100035;

 GLC_LocalHost      : boolean = false;

 GLC_1Blank    : string = ' ';
 GLV_Dist      : integer = 4;


 GLC_LokalHelpDir = './HtmlHelp/';

implementation


end.
