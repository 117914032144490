unit myQiuComplicance;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.Graphics,
  WEBLib.Buttons, Vcl.Forms, MainMenuFrame,KVT;

type
  TMyQiuComplicanceForm = class(TForm)
    TopPanel: TPanel;
    MoveRightBtn: TSpeedButton;
    MoveFRBtn: TSpeedButton;
    MoveLeftBtn: TSpeedButton;
    MoveFLBtn: TSpeedButton;
    TodayBtn: TSpeedButton;
    gotoBtn: TSpeedButton;
    MoveFFLBtn: TSpeedButton;
    MoveFFRBtn: TSpeedButton;
    BottomPanel: TPanel;
    CancelBtn: TSpeedButton;
    WebImageControl1: TImageControl;
    MainMenuFrame1: TMainMenuFrame1;
    RepaintTimer: TTimer;
    CaptionPanel: TPanel;
    HelpBtn: TSpeedButton;
    WaitPanel: TPanel;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure gotoBtnClick(Sender: TObject);
    procedure TodayBtnClick(Sender: TObject);
    procedure ShowCompliance;
    procedure MoveFFLBtnClick(Sender: TObject);
    procedure MoveFFRBtnClick(Sender: TObject);
    procedure MoveFLBtnClick(Sender: TObject);
    procedure MoveFRBtnClick(Sender: TObject);
    procedure MoveLeftBtnClick(Sender: TObject);
    procedure MoveRightBtnClick(Sender: TObject);
    procedure CancelBtnClick(Sender: TObject);
    procedure RepaintTimerTimer(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure HelpBtnClick(Sender: TObject);

  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    AktMonth        : word;
    AktYear         : word;
    Targetdate      : TDateTime;
    FormClosed      : boolean;
  protected procedure LoadDFMValues; override; end;

var
  MyQiuComplicanceForm: TMyQiuComplicanceForm;

implementation

uses DatePicker,XData.Web.Client,Main,DatenServiceClasses,System.DateUtils,CommonFunctions;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.gotoBtnClick(Sender: TObject);
var
 tmpdate        : TDate;
 Rest           : double;
 DatePickerForm : TDatePickerForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    if not DatePickerForm.Canceled then begin
     tmpdate := DatePickerForm.DatePickerDate;
     tmpdate := 1.0*(trunc(tmpdate)) + Rest;
     TargetDate := tmpDate;
     ShowCompliance;
    end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   DatePickerForm.WebDateTimePicker1.Date := tmpdate;
   DatePickerForm.ShowModal(@AfterShowModal);
  end;

begin
 tmpdate := trunc(now());
 Rest    := now() - tmpdate;
 DatePickerForm := TDatePickerForm.CreateNew(@AfterCreate);
 DatePickerForm.caption := SP.Load(127);
 DatePickerForm.Border := fbDialog;
 DatePickerForm.Popup := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.HelpBtnClick(Sender: TObject);
begin
 Application.Navigate(GetHelpDir + 'MyQiu-Compliance.html', ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.TodayBtnClick(Sender: TObject);
begin
 Targetdate := trunc(now());
 ShowCompliance;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 FormClosed := true;
 RepaintTimer.Enabled := false;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.WebFormResize(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 HelpBtn.left       := BottomPanel.ClientWidth - round(1.5*CancelBtn.width);
 CancelBtn.left     := HelpBtn.Left - CancelBtn.width - GLV_Dist;

 MoveRightBtn.left := TopPanel.ClientWidth div 2 + GLV_Dist div 2;
 MoveFRBtn.left    := MoveRightBtn.left + MoveRightBtn.width + GLV_Dist;
 MoveFFRBtn.left   := MoveFRBtn.left + MoveFRBtn.width + GLV_Dist;

 TodayBtn.left     := MoveFFRBtn.left + MoveFFRBtn.width + round(3*GLV_Dist);
 gotoBtn.left      := TodayBtn.left + TodayBtn.width + GLV_Dist;

 MoveLeftBtn.left  := TopPanel.ClientWidth div 2 - GLV_Dist div 2 - MoveLeftBtn.width;
 MoveFLBtn.left    := MoveLeftBtn.left - GLV_Dist - MoveFLBtn.width;
 MoveFFLBtn.left   := MoveFLBtn.left - GLV_Dist - MoveFFLBtn.width;
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.WebFormShow(Sender: TObject);
var
_Month,_Year : word;
begin
 CaptionPanel.Color := CaptionPanelColor;
 CaptionPanel.Font.Color := CaptionPanelFontColor;
 CaptionPanel.BorderColor :=  CaptionPanelBorderColor;
 CaptionPanel.Caption := SP.load(130) + GLC_1Blank + GetMyQiuUserString;
 MainMenuFrame1.UpdateFrame;
 MoveFLBtn.Hint    := SP.load(217);
 MoveLeftBtn.Hint  := SP.load(218);
 MoveRightBtn.Hint := SP.load(219);
 MoveFRBtn.Hint    := SP.load(220);
 TodayBtn.Hint     := SP.load(221);
 gotobtn.hint      := SP.load(222);
 CancelBtn.Hint    := SP.load(223);
 MoveFFLBtn.Hint   := SP.load(234);
 MoveFFRBtn.Hint   := SP.load(235);
 HelpBtn.Hint      := SP.Load(257);

 // TargetDate inital berechnen
  _month := monthof(now());_Year := yearof(now());
  if (_month=Aktmonth) and (_year=aktyear) then begin
     TargetDate := trunc(now());  // aktueller Monat, also heutigen Tag verwenden
  end else begin
     // Target auf den letzen Tag im gewählten Monat setzen
     TargetDate := encodeDate(aktyear,aktmonth,25);
     while monthof(TargetDate)=Aktmonth do Targetdate := TargetDate+1;
     Targetdate := TargetDate-1;
  end;
  ShowCompliance;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.ShowCompliance;
var
 _Height1,_Width1 : integer;
 sTargetdate      : string;
begin

 _Height1    := WebImageControl1.Height * GLV_PictureCreateSizeFactor;
 _Width1     := WebImageControl1.Width * GLV_PictureCreateSizeFactor;
 sTargetdate := MyTDateTimeFormatToString(Targetdate);

 WaitPanel.Top := self.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= self.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetComplianceDownloadLink',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                                             sTargetdate,_Height1,_Width1],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    WebImageControl1.URL := string(TJSObject(Response.Result)['value']);
    WaitPanel.Visible := false;
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.MoveFFLBtnClick(Sender: TObject);
var
  m1   : word;
  m2   : word;
  count: word;
begin
  count := 0;m1 := monthof(targetDate);
  while count < 3 do begin
        TargetDate := TargetDate-1;
        m2 := monthof(targetDate);
        if m2<>m1 then  begin inc(count);M1:=m2; end;
  end;
  ShowCompliance;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.MoveFFRBtnClick(Sender: TObject);
var
  m1   : word;
  m2   : word;
  count: word;
begin
  if targetDate>=trunc(now()) then exit;
  count := 0;m1 := monthof(targetDate);
  while count < 4 do begin
        TargetDate := TargetDate+1;
        m2 := monthof(targetDate);
        if m2<>m1 then  begin inc(count);M1:=m2; end;
  end;
  TargetDate := TargetDate-1;
  ShowCompliance;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.MoveFLBtnClick(Sender: TObject);
var
  m1   : word;
  m2   : word;
  count: word;
begin
  count := 0;m1 := monthof(targetDate);
  while count < 1 do begin
        TargetDate := TargetDate-1;
        m2 := monthof(targetDate);
        if m2<>m1 then begin inc(count);M1:=m2; end;
  end;
  ShowCompliance;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.MoveFRBtnClick(Sender: TObject);
var
  m1   : word;
  m2   : word;
  count: word;
begin
  if targetDate>=trunc(now()) then exit;
  count := 0;m1 := monthof(targetDate);
  while count < 2 do begin
        TargetDate := TargetDate+1;
        m2 := monthof(targetDate);
        if m2<>m1 then  begin inc(count);M1:=m2; end;
  end;
  TargetDate := TargetDate-1;
  ShowCompliance;
end;

{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.MoveLeftBtnClick(Sender: TObject);
begin
  Targetdate := targetDate-7;
  ShowCompliance;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.MoveRightBtnClick(Sender: TObject);
begin
  Targetdate := targetDate+7;
  if targetDate>now() then Targetdate := trunc(now());
  ShowCompliance;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuComplicanceForm.RepaintTimerTimer(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 ShowCompliance;
end;

procedure TMyQiuComplicanceForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  TopPanel := TPanel.Create(Self);
  MoveRightBtn := TSpeedButton.Create(Self);
  MoveFRBtn := TSpeedButton.Create(Self);
  MoveLeftBtn := TSpeedButton.Create(Self);
  MoveFLBtn := TSpeedButton.Create(Self);
  TodayBtn := TSpeedButton.Create(Self);
  gotoBtn := TSpeedButton.Create(Self);
  MoveFFLBtn := TSpeedButton.Create(Self);
  MoveFFRBtn := TSpeedButton.Create(Self);
  BottomPanel := TPanel.Create(Self);
  CancelBtn := TSpeedButton.Create(Self);
  HelpBtn := TSpeedButton.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  WaitPanel := TPanel.Create(Self);
  RepaintTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  WebImageControl1.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  MoveRightBtn.BeforeLoadDFMValues;
  MoveFRBtn.BeforeLoadDFMValues;
  MoveLeftBtn.BeforeLoadDFMValues;
  MoveFLBtn.BeforeLoadDFMValues;
  TodayBtn.BeforeLoadDFMValues;
  gotoBtn.BeforeLoadDFMValues;
  MoveFFLBtn.BeforeLoadDFMValues;
  MoveFFRBtn.BeforeLoadDFMValues;
  BottomPanel.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  HelpBtn.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  WaitPanel.BeforeLoadDFMValues;
  RepaintTimer.BeforeLoadDFMValues;
  try
    Name := 'MyQiuComplicanceForm';
    Width := 927;
    Height := 651;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 162;
    WebImageControl1.Width := 927;
    WebImageControl1.Height := 439;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Align := alClient;
    WebImageControl1.ChildOrder := 3;
    TopPanel.SetParentComponent(Self);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 112;
    TopPanel.Width := 927;
    TopPanel.Height := 50;
    TopPanel.HeightPercent := 100.000000000000000000;
    TopPanel.WidthPercent := 100.000000000000000000;
    TopPanel.Align := alTop;
    TopPanel.BorderColor := clSilver;
    TopPanel.BorderStyle := bsNone;
    TopPanel.ChildOrder := 5;
    TopPanel.Color := clWhite;
    MoveRightBtn.SetParentComponent(TopPanel);
    MoveRightBtn.Name := 'MoveRightBtn';
    MoveRightBtn.Left := 410;
    MoveRightBtn.Top := 3;
    MoveRightBtn.Width := 41;
    MoveRightBtn.Height := 41;
    MoveRightBtn.Color := clNone;
    MoveRightBtn.Glyph.LoadFromFile('myQiuComplicance.TopPanel.MoveRightBtn.Glyph.bmp');
    MoveRightBtn.HeightPercent := 100.000000000000000000;
    MoveRightBtn.ShowHint := True;
    MoveRightBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveRightBtn, Self, 'OnClick', 'MoveRightBtnClick');
    MoveFRBtn.SetParentComponent(TopPanel);
    MoveFRBtn.Name := 'MoveFRBtn';
    MoveFRBtn.Left := 457;
    MoveFRBtn.Top := 3;
    MoveFRBtn.Width := 41;
    MoveFRBtn.Height := 41;
    MoveFRBtn.Color := clNone;
    MoveFRBtn.Glyph.LoadFromFile('myQiuComplicance.TopPanel.MoveFRBtn.Glyph.bmp');
    MoveFRBtn.HeightPercent := 100.000000000000000000;
    MoveFRBtn.ShowHint := True;
    MoveFRBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFRBtn, Self, 'OnClick', 'MoveFRBtnClick');
    MoveLeftBtn.SetParentComponent(TopPanel);
    MoveLeftBtn.Name := 'MoveLeftBtn';
    MoveLeftBtn.Left := 354;
    MoveLeftBtn.Top := 3;
    MoveLeftBtn.Width := 41;
    MoveLeftBtn.Height := 41;
    MoveLeftBtn.Color := clNone;
    MoveLeftBtn.Glyph.LoadFromFile('myQiuComplicance.TopPanel.MoveLeftBtn.Glyph.bmp');
    MoveLeftBtn.HeightPercent := 100.000000000000000000;
    MoveLeftBtn.ShowHint := True;
    MoveLeftBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveLeftBtn, Self, 'OnClick', 'MoveLeftBtnClick');
    MoveFLBtn.SetParentComponent(TopPanel);
    MoveFLBtn.Name := 'MoveFLBtn';
    MoveFLBtn.Left := 307;
    MoveFLBtn.Top := 3;
    MoveFLBtn.Width := 41;
    MoveFLBtn.Height := 41;
    MoveFLBtn.Color := clNone;
    MoveFLBtn.Glyph.LoadFromFile('myQiuComplicance.TopPanel.MoveFLBtn.Glyph.bmp');
    MoveFLBtn.HeightPercent := 100.000000000000000000;
    MoveFLBtn.ShowHint := True;
    MoveFLBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFLBtn, Self, 'OnClick', 'MoveFLBtnClick');
    TodayBtn.SetParentComponent(TopPanel);
    TodayBtn.Name := 'TodayBtn';
    TodayBtn.Left := 584;
    TodayBtn.Top := 3;
    TodayBtn.Width := 41;
    TodayBtn.Height := 41;
    TodayBtn.Color := clNone;
    TodayBtn.Glyph.LoadFromFile('myQiuComplicance.TopPanel.TodayBtn.Glyph.bmp');
    TodayBtn.HeightPercent := 100.000000000000000000;
    TodayBtn.ShowHint := True;
    TodayBtn.WidthPercent := 100.000000000000000000;
    SetEvent(TodayBtn, Self, 'OnClick', 'TodayBtnClick');
    gotoBtn.SetParentComponent(TopPanel);
    gotoBtn.Name := 'gotoBtn';
    gotoBtn.Left := 631;
    gotoBtn.Top := 3;
    gotoBtn.Width := 41;
    gotoBtn.Height := 41;
    gotoBtn.Color := clNone;
    gotoBtn.Glyph.LoadFromFile('myQiuComplicance.TopPanel.gotoBtn.Glyph.bmp');
    gotoBtn.HeightPercent := 100.000000000000000000;
    gotoBtn.ShowHint := True;
    gotoBtn.WidthPercent := 100.000000000000000000;
    SetEvent(gotoBtn, Self, 'OnClick', 'gotoBtnClick');
    MoveFFLBtn.SetParentComponent(TopPanel);
    MoveFFLBtn.Name := 'MoveFFLBtn';
    MoveFFLBtn.Left := 243;
    MoveFFLBtn.Top := 3;
    MoveFFLBtn.Width := 58;
    MoveFFLBtn.Height := 41;
    MoveFFLBtn.Color := clNone;
    MoveFFLBtn.Glyph.LoadFromFile('myQiuComplicance.TopPanel.MoveFFLBtn.Glyph.bmp');
    MoveFFLBtn.HeightPercent := 100.000000000000000000;
    MoveFFLBtn.ShowHint := True;
    MoveFFLBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFFLBtn, Self, 'OnClick', 'MoveFFLBtnClick');
    MoveFFRBtn.SetParentComponent(TopPanel);
    MoveFFRBtn.Name := 'MoveFFRBtn';
    MoveFFRBtn.Left := 504;
    MoveFFRBtn.Top := 3;
    MoveFFRBtn.Width := 57;
    MoveFFRBtn.Height := 41;
    MoveFFRBtn.Color := clNone;
    MoveFFRBtn.Glyph.LoadFromFile('myQiuComplicance.TopPanel.MoveFFRBtn.Glyph.bmp');
    MoveFFRBtn.HeightPercent := 100.000000000000000000;
    MoveFFRBtn.ShowHint := True;
    MoveFFRBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFFRBtn, Self, 'OnClick', 'MoveFFRBtnClick');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 601;
    BottomPanel.Width := 927;
    BottomPanel.Height := 50;
    BottomPanel.HeightPercent := 100.000000000000000000;
    BottomPanel.WidthPercent := 100.000000000000000000;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderColor := clSilver;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.ChildOrder := 2;
    BottomPanel.Color := clWhite;
    CancelBtn.SetParentComponent(BottomPanel);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 811;
    CancelBtn.Top := 3;
    CancelBtn.Width := 41;
    CancelBtn.Height := 41;
    CancelBtn.Color := clNone;
    CancelBtn.Glyph.LoadFromFile('myQiuComplicance.BottomPanel.CancelBtn.Glyph.bmp');
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.ShowHint := True;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    HelpBtn.SetParentComponent(BottomPanel);
    HelpBtn.Name := 'HelpBtn';
    HelpBtn.Left := 858;
    HelpBtn.Top := 3;
    HelpBtn.Width := 41;
    HelpBtn.Height := 41;
    HelpBtn.Color := clNone;
    HelpBtn.Glyph.LoadFromFile('myQiuComplicance.BottomPanel.HelpBtn.Glyph.bmp');
    HelpBtn.HeightPercent := 100.000000000000000000;
    HelpBtn.ShowHint := True;
    HelpBtn.WidthPercent := 100.000000000000000000;
    SetEvent(HelpBtn, Self, 'OnClick', 'HelpBtnClick');
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 927;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 2;
    CaptionPanel.SetParentComponent(Self);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 86;
    CaptionPanel.Width := 927;
    CaptionPanel.Height := 26;
    CaptionPanel.HeightPercent := 100.000000000000000000;
    CaptionPanel.WidthPercent := 100.000000000000000000;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := clBtnFace;
    CaptionPanel.BorderStyle := bsSingle;
    CaptionPanel.Caption := 'CaptionPanel';
    CaptionPanel.ChildOrder := 4;
    CaptionPanel.Color := clWhite;
    WaitPanel.SetParentComponent(Self);
    WaitPanel.Name := 'WaitPanel';
    WaitPanel.Left := 248;
    WaitPanel.Top := 296;
    WaitPanel.Width := 361;
    WaitPanel.Height := 41;
    WaitPanel.HeightPercent := 100.000000000000000000;
    WaitPanel.WidthPercent := 100.000000000000000000;
    WaitPanel.BorderColor := clBtnFace;
    WaitPanel.BorderStyle := bsSingle;
    WaitPanel.Caption := 'Bitte warten';
    WaitPanel.ChildOrder := 5;
    WaitPanel.Color := clWhite;
    WaitPanel.Visible := False;
    RepaintTimer.SetParentComponent(Self);
    RepaintTimer.Name := 'RepaintTimer';
    RepaintTimer.Enabled := False;
    RepaintTimer.Interval := 500;
    SetEvent(RepaintTimer, Self, 'OnTimer', 'RepaintTimerTimer');
    RepaintTimer.Left := 720;
    RepaintTimer.Top := 350;
  finally
    WebImageControl1.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    MoveRightBtn.AfterLoadDFMValues;
    MoveFRBtn.AfterLoadDFMValues;
    MoveLeftBtn.AfterLoadDFMValues;
    MoveFLBtn.AfterLoadDFMValues;
    TodayBtn.AfterLoadDFMValues;
    gotoBtn.AfterLoadDFMValues;
    MoveFFLBtn.AfterLoadDFMValues;
    MoveFFRBtn.AfterLoadDFMValues;
    BottomPanel.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    HelpBtn.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    WaitPanel.AfterLoadDFMValues;
    RepaintTimer.AfterLoadDFMValues;
  end;
end;

end.