unit MainMenuFrame;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.Menus, WEBLib.Menus,
  WEBLib.Controls, WEBLib.ExtCtrls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.Buttons, Vcl.Imaging.pngimage;

type
  TMainMenuFrame1 = class(TFrame)
    myQiuWebImageControl: TImageControl;
    LoginBtn: TBitBtn;
    WebLabel1: TLabel;
    KontoBtn: TBitBtn;
    RegistBtn: TBitBtn;
    German_WebImageControl: TImageControl;
    English_WebImageControl: TImageControl;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebCheckBox1: TCheckBox;
    procedure InitForm;
    procedure UpdateFrame;
    procedure ShowMainForm;
    procedure LoginBtnClick(Sender: TObject);
    procedure KontoBtnClick(Sender: TObject);
    procedure RegistBtnClick(Sender: TObject);
    procedure German_WebImageControlClick(Sender: TObject);
    procedure English_WebImageControlClick(Sender: TObject);
    procedure WebCheckBox1Click(Sender: TObject);
    procedure ShowLogin2Faktor;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

implementation

uses Login, KVT, Main, User,CommonFunctions, Texte, XData.Web.Client, JS, DatenServiceClasses,
     Login2Faktor;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.InitForm;
begin
 UpdateFrame;
end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.UpdateFrame;
var
 s   : string;
 Tage: integer;
begin
 if (self.Parent = MainForm) then self.Visible := true;
 RegistBtn.Caption := SP.load(4);//'Konto erstellen';
 KontoBtn.Caption := SP.load(5);//'Mein Konto';
 if GLV_UserLogin then begin
  German_WebImageControl.Visible := false;
  English_WebImageControl.Visible := false;
  LoginBtn.Caption := SP.load(1);//'Abmelden';
  WebLabel1.Caption := GLV_AktUser.Username;
  KontoBtn.Visible := true;
  RegistBtn.Visible := false;
  WebCheckBox1.Caption := GLC_1Blank + SP.Load(249);
  if (self.Parent <> MainForm) then begin
   if not GLV_HideMainMenuFrame then self.Visible := true else self.Visible := false;
  end;
  if not GLV_AktUser.Verifiziert then begin
   WebLabel2.Caption := SP.load(206);
  end else begin
   Tage := trunc(MyDateFormatToTDate(GLV_AktUser.ValidUntil) - now);
   if Tage < 0 then Tage := 0;
   case GLV_AktUser.Abostatus of
     0: begin
         s := SP.Load(210);
         ReplacePlatzhalter(s,'@@@ANZAHL',inttostr(Tage));
         WebLabel2.Caption := SP.load(208) + GLC_1Blank + s;
        end;
     1: begin
         s := SP.Load(210);
         ReplacePlatzhalter(s,'@@@ANZAHL',inttostr(Tage));
         WebLabel2.Caption := SP.load(209)+ GLC_1Blank + s;
        end;
     2: WebLabel2.Caption := SP.load(207);       //gesperrrt
   end;
  end;
 end else begin
  German_WebImageControl.Visible := true;
  English_WebImageControl.Visible := true;
  WebCheckBox1.Visible := false;
  LoginBtn.Caption := SP.load(2);//'Anmelden';
  WebLabel1.Caption := SP.load(3);//'Bitte anmelden';
  WebLabel2.Caption := '';
  KontoBtn.Visible := false;
  RegistBtn.Visible := true;
 end;
 WebLabel3.Visible := GLC_LocalHost;

end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.WebCheckBox1Click(Sender: TObject);
begin
 GLV_HideMainMenuFrame := WebCheckBox1.checked;
 self.Visible := not GLV_HideMainMenuFrame;
end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.German_WebImageControlClick(Sender: TObject);
begin
 GLV_AppSprache := 0;
 SP.ChangeLanguage(GLV_AppSprache);
 German_WebImageControl.Visible := false;
 English_WebImageControl.Visible := true;
end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.English_WebImageControlClick(Sender: TObject);
begin
 GLV_AppSprache := 1;
 SP.ChangeLanguage(GLV_AppSprache);
 German_WebImageControl.Visible := true;
 English_WebImageControl.Visible := false;
end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.ShowMainForm;
var
 MainForm: TMainForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   MainForm.ShowModal(@AfterShowModal);
  end;

begin
 MainForm:= TMainForm.CreateNew(@AfterCreate);
 MainForm.Popup := false;
end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.LoginBtnClick(Sender: TObject);
procedure OnResponse(Response:TXDataClientResponse);
  begin
   case byte(TJSObject(Response.Result)['value']) of
     0: begin
         GLV_UserLogin := false;
         UpdateFrame;
         ShowMainForm;
        end;
     1: showmessage(SP.load(6));//'Token ungültig');
     2: showmessage(SP.load(7));//'User nicht gefunden');
   end;
  end;
var
 LoginForm : TLoginForm;
 procedure AfterShowModal(AValue: TModalResult);
  begin
   case GLV_AktUser.LoginMode of
    0: UpdateFrame;
    1: self.ShowLogin2Faktor;
   end;
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   LoginForm.ShowModal(@AfterShowModal);
  end;

begin
 if GLV_UserLogin then begin
  MainForm.XDataWebClient.RawInvoke('IDatenService.Logout',
        [GLV_AktUser.Token],@OnResponse);
 end else begin
  LoginForm := TLoginForm.CreateNew(@AfterCreate);
  LoginForm.caption := SP.load(2);
  LoginForm.Border := fbDialog;
  LoginForm.Popup := true;
 end;
end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.ShowLogin2Faktor;
var
 Login2FaktorForm : TLogin2FaktorForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    UpdateFrame;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   Login2FaktorForm.ShowModal(@AfterShowModal);
  end;


begin
 Login2FaktorForm          := TLogin2FaktorForm.CreateNew(@AfterCreate);
 Login2FaktorForm.caption  := SP.load(339);
 Login2FaktorForm.Border   := fbDialog;
 Login2FaktorForm.Popup    := true;
end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.KontoBtnClick(Sender: TObject);
var
 UserForm : TUserForm;
 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
  // will be called after form has been created
  procedure AfterCreate(AForm: TObject);
  begin
   UserForm._Modus := 1;
   UserForm.ShowModal(@AfterShowModal);
  end;
begin
 UserForm := TUserForm.CreateNew(@AfterCreate);
 UserForm.Caption := SP.Load(105);
 UserForm.Popup := true;
 UserForm.Border := fbDialog;
end;
{------------------------------------------------------------------------------}
procedure TMainMenuFrame1.RegistBtnClick(Sender: TObject);
var
var
 UserForm : TUserForm;
 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
  // will be called after form has been created
  procedure AfterCreate(AForm: TObject);
  begin
   UserForm._Modus := 0;
   UserForm.ShowModal(@AfterShowModal);
  end;
begin
 UserForm := TUserForm.CreateNew(@AfterCreate);
 UserForm.Caption := SP.Load(104);
 UserForm.Popup := true;
 UserForm.Border := fbDialog;
end;

procedure TMainMenuFrame1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  myQiuWebImageControl := TImageControl.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  German_WebImageControl := TImageControl.Create(Self);
  English_WebImageControl := TImageControl.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  LoginBtn := TBitBtn.Create(Self);
  KontoBtn := TBitBtn.Create(Self);
  RegistBtn := TBitBtn.Create(Self);
  WebCheckBox1 := TCheckBox.Create(Self);

  myQiuWebImageControl.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  German_WebImageControl.BeforeLoadDFMValues;
  English_WebImageControl.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  LoginBtn.BeforeLoadDFMValues;
  KontoBtn.BeforeLoadDFMValues;
  RegistBtn.BeforeLoadDFMValues;
  WebCheckBox1.BeforeLoadDFMValues;
  try
    Name := 'MainMenuFrame1';
    Left := 0;
    Top := 0;
    Width := 684;
    Height := 86;
    Align := alTop;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    TabOrder := 0;
    myQiuWebImageControl.SetParentComponent(Self);
    myQiuWebImageControl.Name := 'myQiuWebImageControl';
    myQiuWebImageControl.Left := 10;
    myQiuWebImageControl.Top := 4;
    myQiuWebImageControl.Width := 89;
    myQiuWebImageControl.Height := 48;
    myQiuWebImageControl.HeightPercent := 100.000000000000000000;
    myQiuWebImageControl.WidthPercent := 100.000000000000000000;
    myQiuWebImageControl.AutoSize := True;
    myQiuWebImageControl.Picture.LoadFromFile('MainMenuFrame.myQiuWebImageControl.Picture.bmp');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 105;
    WebLabel1.Top := 5;
    WebLabel1.Width := 85;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Bitte anmelden';
    WebLabel1.Color := clBtnFace;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    German_WebImageControl.SetParentComponent(Self);
    German_WebImageControl.Name := 'German_WebImageControl';
    German_WebImageControl.Tag := 1;
    German_WebImageControl.Left := 322;
    German_WebImageControl.Top := 10;
    German_WebImageControl.Width := 40;
    German_WebImageControl.Height := 40;
    German_WebImageControl.Cursor := crHandPoint;
    German_WebImageControl.HeightStyle := ssAuto;
    German_WebImageControl.WidthStyle := ssAuto;
    German_WebImageControl.HeightPercent := 100.000000000000000000;
    German_WebImageControl.WidthPercent := 100.000000000000000000;
    German_WebImageControl.AutoSize := True;
    German_WebImageControl.ChildOrder := 2;
    SetEvent(German_WebImageControl, Self, 'OnClick', 'German_WebImageControlClick');
    German_WebImageControl.Picture.LoadFromFile('MainMenuFrame.German_WebImageControl.Picture.png');
    English_WebImageControl.SetParentComponent(Self);
    English_WebImageControl.Name := 'English_WebImageControl';
    English_WebImageControl.Left := 322;
    English_WebImageControl.Top := 10;
    English_WebImageControl.Width := 40;
    English_WebImageControl.Height := 41;
    English_WebImageControl.Cursor := crHandPoint;
    English_WebImageControl.HeightStyle := ssAuto;
    English_WebImageControl.WidthStyle := ssAuto;
    English_WebImageControl.HeightPercent := 100.000000000000000000;
    English_WebImageControl.WidthPercent := 100.000000000000000000;
    English_WebImageControl.AutoSize := True;
    English_WebImageControl.ChildOrder := 6;
    SetEvent(English_WebImageControl, Self, 'OnClick', 'English_WebImageControlClick');
    English_WebImageControl.Picture.LoadFromFile('MainMenuFrame.English_WebImageControl.Picture.png');
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 10;
    WebLabel2.Top := 56;
    WebLabel2.Width := 85;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Bitte anmelden';
    WebLabel2.Color := clBtnFace;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 15;
    WebLabel3.Top := 34;
    WebLabel3.Width := 74;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'LOCALHOST';
    WebLabel3.Color := clBtnFace;
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := clRed;
    WebLabel3.Font.Height := -13;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [fsBold];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    LoginBtn.SetParentComponent(Self);
    LoginBtn.Name := 'LoginBtn';
    LoginBtn.Left := 105;
    LoginBtn.Top := 26;
    LoginBtn.Width := 96;
    LoginBtn.Height := 25;
    LoginBtn.Caption := 'Anmelden';
    LoginBtn.Color := clNone;
    LoginBtn.HeightPercent := 100.000000000000000000;
    LoginBtn.WidthPercent := 100.000000000000000000;
    SetEvent(LoginBtn, Self, 'OnClick', 'LoginBtnClick');
    KontoBtn.SetParentComponent(Self);
    KontoBtn.Name := 'KontoBtn';
    KontoBtn.Left := 207;
    KontoBtn.Top := 26;
    KontoBtn.Width := 96;
    KontoBtn.Height := 25;
    KontoBtn.Caption := 'Mein Konto';
    KontoBtn.Color := clNone;
    KontoBtn.HeightPercent := 100.000000000000000000;
    KontoBtn.TabOrder := 1;
    KontoBtn.WidthPercent := 100.000000000000000000;
    SetEvent(KontoBtn, Self, 'OnClick', 'KontoBtnClick');
    RegistBtn.SetParentComponent(Self);
    RegistBtn.Name := 'RegistBtn';
    RegistBtn.Left := 207;
    RegistBtn.Top := 26;
    RegistBtn.Width := 107;
    RegistBtn.Height := 25;
    RegistBtn.Caption := 'Konto erstellen';
    RegistBtn.Color := clNone;
    RegistBtn.HeightPercent := 100.000000000000000000;
    RegistBtn.TabOrder := 2;
    RegistBtn.WidthPercent := 100.000000000000000000;
    SetEvent(RegistBtn, Self, 'OnClick', 'RegistBtnClick');
    WebCheckBox1.SetParentComponent(Self);
    WebCheckBox1.Name := 'WebCheckBox1';
    WebCheckBox1.Left := 285;
    WebCheckBox1.Top := 56;
    WebCheckBox1.Width := 97;
    WebCheckBox1.Height := 22;
    WebCheckBox1.Caption := 'ausblenden';
    WebCheckBox1.ChildOrder := 9;
    WebCheckBox1.HeightPercent := 100.000000000000000000;
    WebCheckBox1.WidthPercent := 100.000000000000000000;
    SetEvent(WebCheckBox1, Self, 'OnClick', 'WebCheckBox1Click');
  finally
    myQiuWebImageControl.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    German_WebImageControl.AfterLoadDFMValues;
    English_WebImageControl.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    LoginBtn.AfterLoadDFMValues;
    KontoBtn.AfterLoadDFMValues;
    RegistBtn.AfterLoadDFMValues;
    WebCheckBox1.AfterLoadDFMValues;
  end;
end;

end.
