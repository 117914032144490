
//http://localhost:8000/BioSign


unit Main;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  XData.Web.Connection, XData.Web.Client,Vcl.Forms,
  KVT, MainMenuFrame, WEBLib.ExtCtrls,DatenServiceClasses,
  System.Generics.Collections, WEBLib.WebCtrls, WEBLib.Buttons,
  Vcl.Imaging.pngimage, Vcl.Graphics, Vcl.Grids, WEBLib.Grids;

type
  TMainForm = class(TForm)
    XDataWebConnection: TXDataWebConnection;
    XDataWebClient: TXDataWebClient;
    MainMenuFrame1: TMainMenuFrame1;
    ConnectTimer: TTimer;
    FormControlTimer: TTimer;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    OverviewBtn: TSpeedButton;
    BPTableBtn: TSpeedButton;
    WebImageControl1: TImageControl;
    WebImageControl2: TImageControl;
    AutoLoginTimer: TTimer;
    WebButton1: TButton;
    BottomPanel: TPanel;
    VersionLabel: TLabel;
    CopyrightLabel: TLabel;
    WebLinkLabel_Impressum: TLinkLabel;
    WebLinkLabel_Datenschutz: TLinkLabel;
    WebLinkLabel_Homepage: TLinkLabel;
    WebLinkLabel_Shop: TLinkLabel;
    WebLinkLabel_AGB: TLinkLabel;
    WebLabel3: TLabel;
    FrageBogenBtn: TSpeedButton;
    DownloadAPK_Btn: TButton;
    AppAPKDownloadLabel: TLabel;
    HilfeBtn: TSpeedButton;
    WebLabel4: TLabel;
    procedure XDataWebConnectionConnect(Sender: TObject);
    procedure XDataWebConnectionError(Error: TXDataWebConnectionError);
    procedure PingBtnClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure InitForm;
    procedure Connect;
    procedure XDataWebConnectionResponse(Args: TXDataWebConnectionResponse);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure ConnectTimerTimer(Sender: TObject);
    procedure FormControlTimerTimer(Sender: TObject);
    procedure FormControlListAddTask(_Task:string);
    procedure WebFormResize(Sender: TObject);

    procedure BPTableBtnClick(Sender: TObject);
    procedure ShowBPTableForm;
    procedure ShowBPTableFormVerteiler;
    procedure ShowBPTableFormSmallWidth;
    procedure ShowBPTableFormSmallHeight;

    procedure OverviewBtnClick(Sender: TObject);
    procedure ShowOverviewForm;
    procedure ShowOverviewFormVerteiler;
    procedure ShowOverviewFormSmallWidth;
    procedure ShowOverviewFormSmallHeight;

    procedure AutoLogin;
    procedure MainShowLogin2Faktor;
    procedure AutoLoginTimerTimer(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);

    procedure ShowNavigateHint;
    procedure FrageBogenBtnClick(Sender: TObject);

    procedure ShowFragebogenForm;
    procedure DownloadAPK_BtnClick(Sender: TObject);
    procedure AppAPKDownloadLabelClick(Sender: TObject);
    procedure MainMenuFrame1KontoBtnClick(Sender: TObject);
    procedure HilfeBtnClick(Sender: TObject);

  private
    { Private-Deklarationen }
    FConnected : boolean;
    MaxLinkLabelWidth : integer;
    procedure SetConnected(const Value:boolean);
  public
   { Public-Deklarationen }
   FormControlList : TStringList;
   property Connected : Boolean read FConnected write SetConnected;
  protected procedure LoadDFMValues; override; end;

var
  MainForm: TMainForm;


implementation

uses Login,User,CommonFunctions,Texte,MyQiuOverview,
     MyQiuOverview_SW,MyQiuOverview_SH, MyQiuBPTable,MyQiuBPTable_SW,
     MyQiuBPTable_SH,SelectServerBaseURL,NavigateHint,myQiuFragebogen,
     MyMsgDialog,Login2Faktor;

{$R *.dfm}

{------------------------------------------------------------------------------}
procedure TMainForm.WebButton1Click(Sender: TObject);
var
 SelectServerBaseURLForm : TSelectServerBaseURLForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   MainMenuFrame1.UpdateFrame;
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   SelectServerBaseURLForm.ShowModal(@AfterShowModal);
  end;

begin
 SelectServerBaseURLForm          := TSelectServerBaseURLForm.CreateNew(@AfterCreate);
 SelectServerBaseURLForm.caption  := 'BuildBaseURL';
 SelectServerBaseURLForm.Border   := fbDialog;
 SelectServerBaseURLForm.Popup    := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 ConnectTimer.Enabled := false;
 FormControlTimer.Enabled := false;
 FormControlList.Clear;
 FormControlList.Free;
 SP.Close;
 SP.Free;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.WebFormCreate(Sender: TObject);
begin
 //Application.RemoveCSS('themecss');
 //Application.InsertCSS('themecss','theme.css');
 GLV_AktUser   := TUser.Create;
 SP            := TSprachManagement.Create;
 SP.Init(GLV_AppSprache);
 FormControlList := TStringList.Create;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 self.WebFormResize(self);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.WebFormResize(Sender: TObject);
var
 tmp      : integer;
begin
 WebImageControl1.Visible := false;
 WebImageControl2.Visible := false;
 if (self.Width <= GLV_MinSmallWidth) or (self.Height <= GLV_MinSmallHeight) then
  WebImageControl2.Visible := true
 else
  WebImageControl1.Visible := true;
 WebLabel1.Left := OverviewBtn.Left + OverviewBtn.Width div 2 - WebLabel1.Width div 2;
 WebLabel2.Left := BPTableBtn.Left + BPTableBtn.Width div 2 - WebLabel2.Width div 2;
 WebLabel3.Left := FrageBogenBtn.Left + FrageBogenBtn.Width div 2 - WebLabel3.Width div 2;
 WebLabel4.Left := HilfeBtn.Left + HilfeBtn.Width div 2 - WebLabel4.Width div 2;
 WebImageControl1.Top := self.ClientHeight div 2 - WebImageControl1.Height div 2;
 WebImageControl1.Left :=  self.ClientWidth div 2 - WebImageControl1.Width div 2;
 WebImageControl2.Top := self.ClientHeight div 2 - WebImageControl2.Height div 2;
 WebImageControl2.Left :=  self.ClientWidth div 2 - WebImageControl2.Width div 2;
 tmp := BPTableBtn.Top + BPTableBtn.Height + 10;

 if WebImageControl1.Visible then begin
  if WebImageControl1.Top <= tmp then WebImageControl1.Top := tmp;
  if WebImageControl1.Top + WebImageControl1.Height > self.ClientHeight then begin
   WebImageControl1.Top  := self.ClientHeight div 2 - WebImageControl1.Height div 2;
  end;
 end;

 if WebImageControl2.Visible then begin
  if WebImageControl2.Top <= tmp then WebImageControl2.Top := tmp;
  if WebImageControl2.Top + WebImageControl1.Height > self.ClientHeight then begin
   WebImageControl2.Top  := self.ClientHeight div 2 - WebImageControl2.Height div 2;
  end;
 end;

 if (self.Height <= GLV_MinSmallHeight) then begin
  WebLinkLabel_Shop.Left := trunc(self.ClientWidth - 1.1*MaxLinkLabelWidth);
  WebLinkLabel_Homepage.Left := WebLinkLabel_Shop.Left;
  WebLinkLabel_Datenschutz.Left := WebLinkLabel_Shop.Left;
  WebLinkLabel_Impressum.Left := WebLinkLabel_Shop.Left;
  WebLinkLabel_AGB.Left := WebLinkLabel_Shop.Left;
  AppAPKDownloadLabel.Left := WebLinkLabel_Shop.Left;

  AppAPKDownloadLabel.Top     := OverviewBtn.Top;
  WebLinkLabel_AGB.Top        := trunc(AppAPKDownloadLabel.Top + 1.1*WebLinkLabel_AGB.Height);
  WebLinkLabel_Impressum.Top  := trunc(WebLinkLabel_AGB.Top + 1.1*WebLinkLabel_Impressum.Height);
  WebLinkLabel_Datenschutz.Top:= trunc(WebLinkLabel_Impressum.Top + 1.1*WebLinkLabel_Impressum.Height);
  WebLinkLabel_Homepage.Top   := trunc(WebLinkLabel_Datenschutz.Top + 1.1*WebLinkLabel_Datenschutz.Height);
  WebLinkLabel_Shop.Top       := trunc(WebLinkLabel_Homepage.Top + 1.1*WebLinkLabel_Homepage.Height);

 end else begin
  WebLinkLabel_Shop.Left := OverviewBtn.Left;
  WebLinkLabel_Homepage.Left := OverviewBtn.Left;
  WebLinkLabel_Datenschutz.Left := OverviewBtn.Left;
  WebLinkLabel_Impressum.Left := OverviewBtn.Left;
  WebLinkLabel_AGB.Left := OverviewBtn.Left;
  AppAPKDownloadLabel.Left := OverviewBtn.Left;

  WebLinkLabel_Shop.Top := trunc(MainForm.ClientHeight - BottomPanel.Height - 1.1*WebLinkLabel_Shop.Height);
  WebLinkLabel_Homepage.Top := trunc(WebLinkLabel_Shop.Top - 1.1*WebLinkLabel_Homepage.Height);
  WebLinkLabel_Datenschutz.Top := trunc(WebLinkLabel_Homepage.Top - 1.1*WebLinkLabel_Datenschutz.Height);
  WebLinkLabel_Impressum.Top := trunc(WebLinkLabel_Datenschutz.Top - 1.1*WebLinkLabel_Impressum.Height);
  WebLinkLabel_AGB.Top := trunc(WebLinkLabel_Impressum.Top - 1.1*WebLinkLabel_AGB.Height);
  AppAPKDownloadLabel.Top := trunc(WebLinkLabel_AGB.Top - 1.1*AppAPKDownloadLabel.Height);
 end;

 if (self.Width <= 600) or (self.Height <= 800) then
  GLV_PictureCreateSizeFactor := 2 else GLV_PictureCreateSizeFactor := 1;

end;
{------------------------------------------------------------------------------}
procedure TMainForm.InitForm;
begin
 OverviewBtn.Hint := SP.Load(242);
 BPTableBtn.Hint := SP.Load(243);
 WebLabel1.Caption := SP.Load(242);
 WebLabel2.Caption := SP.Load(243);
 WebLabel3.Caption := SP.Load(305);
 WebLabel4.Caption := SP.Load(350);
 MainMenuFrame1.UpdateFrame;

 AppAPKDownloadLabel.Caption :=SP.Load(323);
 WebLinkLabel_AGB.Caption := '<a href="'+ GetAGBLink +'" target="_blank">' + SP.Load(278) + '</a>';
 WebLinkLabel_Impressum.Caption := '<a href="https://site.biosign.de/impressum" target="_blank">' + SP.Load(274) + '</a>';
 WebLinkLabel_Datenschutz.Caption := '<a href="'+ GetDatenschutzerklaerungLink +'" target="_blank">' + SP.Load(275) + '</a>';
 WebLinkLabel_Homepage.Caption := '<a href="https://www.biosign.de/" target="_blank">' + SP.Load(276) + '</a>';
 WebLinkLabel_Shop.Caption := '<a href="https://site.biosign.de/shop/" target="_blank">' + SP.Load(277) + '</a>';

 MaxLinkLabelWidth := 1;
 if WebLinkLabel_Shop.width > MaxLinkLabelWidth then MaxLinkLabelWidth := WebLinkLabel_Shop.width;
 if WebLinkLabel_Homepage.width > MaxLinkLabelWidth then MaxLinkLabelWidth := WebLinkLabel_Homepage.width;
 if WebLinkLabel_Datenschutz.width > MaxLinkLabelWidth then MaxLinkLabelWidth := WebLinkLabel_Datenschutz.width;
 if WebLinkLabel_Impressum.width > MaxLinkLabelWidth then MaxLinkLabelWidth := WebLinkLabel_Impressum.width;
 if WebLinkLabel_AGB.width > MaxLinkLabelWidth then MaxLinkLabelWidth := WebLinkLabel_AGB.width;


 VersionLabel.Caption := 'myQiu' + GLC_1Blank + 'V' + GLC_Version;
 CopyrightLabel.Caption := 'copyright 2021-' + formatdatetime('yyyy',now) + GLC_1Blank + 'by BioSign GmbH - www.biosign.de';
 end;
{------------------------------------------------------------------------------}
procedure TMainForm.WebFormShow(Sender: TObject);
begin
 InitForm;
 WebFormResize(self);
 if GLC_LocalHost then
  GLV_ServerBaseURL := 'http://localhost:8000/BioSign/myQiu'
 else
  GLV_ServerBaseURL := 'https://myqiu-server.de:8000/BioSign/myQiu';   //'https://87.106.22.110:8000/BioSign/myQiu'
 Connect;
 if GLV_AutoLogin then begin
  AutoLoginTimer.Enabled := true;
 end else begin
  if not GLV_NavigateHintAlreadyShown then ShowNavigateHint;
 end;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowNavigateHint;
var
 NavigateHintForm : TNavigateHintForm;
 procedure AfterShowModal(AValue: TModalResult);
  begin
   // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   NavigateHintForm.ShowModal(@AfterShowModal);
  end;

begin
  NavigateHintForm := TNavigateHintForm.CreateNew(@AfterCreate);
  NavigateHintForm.caption := SP.load(279);
  NavigateHintForm.Border := fbDialog;
  NavigateHintForm.Popup := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.AutoLoginTimerTimer(Sender: TObject);
begin
  AutoLoginTimer.Enabled := false;
  if Connected then AutoLogin else AutoLoginTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.SetConnected(const Value:boolean);
begin
 FConnected := Value;
 MainMenuFrame1.LoginBtn.enabled := FConnected;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.AutoLogin;
var
 LoginForm : TLoginForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   case GLV_AktUser.LoginMode of
    0: MainMenuFrame1.UpdateFrame;
    1: self.MainShowLogin2Faktor;
   end;
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   LoginForm.ShowModal(@AfterShowModal);
  end;

begin
 LoginForm          := TLoginForm.CreateNew(@AfterCreate);
 LoginForm.caption  := SP.load(2);
 LoginForm.Border   := fbDialog;
 LoginForm.Popup    := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.MainMenuFrame1KontoBtnClick(Sender: TObject);
begin
  MainMenuFrame1.KontoBtnClick(Sender);

end;

procedure TMainForm.MainShowLogin2Faktor;
var
 Login2FaktorForm : TLogin2FaktorForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    MainMenuFrame1.UpdateFrame;;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   Login2FaktorForm.ShowModal(@AfterShowModal);
  end;


begin
 Login2FaktorForm          := TLogin2FaktorForm.CreateNew(@AfterCreate);
 Login2FaktorForm.caption  := SP.load(339);
 Login2FaktorForm.Border   := fbDialog;
 Login2FaktorForm.Popup    := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.XDataWebConnectionConnect(Sender: TObject);
begin
 ConnectTimer.Enabled := false;
 Connected := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.XDataWebConnectionError(Error: TXDataWebConnectionError);
begin
 Connected := false;
 Showmessage('Cannot connect to web service. Try to reconnect.');
 ConnectTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.XDataWebConnectionResponse(
  Args: TXDataWebConnectionResponse);
var
 s : string;
begin
 s := inttostr(Args.Response.StatusCode) + ' ' ;
 case Args.Response.StatusCode of
  400 : Showmessage(s + 'Bad Request'); //Bad Request
  401 : begin
         showmessage(SP.Load(271)); //Showmessage(s + 'Unauthorized');// Unauthorized
         GLV_UserLogin := false;
         MainMenuFrame1.UpdateFrame;
         MainMenuFrame1.ShowMainForm;
        end;
  403 : Showmessage(s + 'Forbidden'); //Forbidden
  404 : Showmessage(s + 'NotFound'); //NotFound
  503 : Showmessage(s + 'Service unavailable'); //Service unavailable
 end;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.PingBtnClick(Sender: TObject);
 procedure OnResponse(Response:TXDataClientResponse);
 begin
  if (byte(TJSObject(Response.Result)['value'])) = 0 then showmessage('Ping erfolgreich');
 end;

begin
 XDataWebClient.RawInvoke('ILoginService.Ping',
        [],@OnResponse);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.Connect;
begin
 if XDataWebConnection.Connected then XDataWebConnection.Connected := false;
 XDataWebConnection.URL := GLV_ServerBaseURL;
 XDataWebConnection.Connected := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ConnectTimerTimer(Sender: TObject);
begin
 ConnectTimer.Enabled := false;
 Connect;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.DownloadAPK_BtnClick(Sender: TObject);
 procedure OnResponse(Response:TXDataClientResponse);
 begin
  Application.Navigate(string(TJSObject(Response.Result)['value']));
 end;

begin
 XDataWebClient.RawInvoke('ILoginService.GetCurrentAndroidAppAPKLink',
        [],@OnResponse);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.AppAPKDownloadLabelClick(Sender: TObject);
procedure OnResponse(Response:TXDataClientResponse);
 var
  MyMessageDlgForm : TMyMessageDlgForm;
  _Link            : string;
  s : string;
  procedure AfterShowModal(AValue: TModalResult);
  begin
   case MyMessageDlgForm._Result of
    drYes : Application.Navigate(_Link);
    drNo  : exit;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;
 begin
  _Link := string(TJSObject(Response.Result)['value']);
  MyMessageDlgForm := TMyMessageDlgForm.CreateNew(@AfterCreate);
  MyMessageDlgForm.Border := fbsingle;
  MyMessageDlgForm.Popup := true;
  MyMessageDlgForm.Titel := SP.load(20);
  s := SP.Load(324);
  ReplacePlatzhalter(s,'@@@LINK',_Link);
  MyMessageDlgForm.MessageText := s;
  MyMessageDlgForm.DialogType := dtYesNo;
  MyMessageDlgForm.ShowModal(@AfterShowModal);
 end;

begin
 XDataWebClient.RawInvoke('ILoginService.GetCurrentAndroidAppAPKLink',
        [],@OnResponse);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.FormControlTimerTimer(Sender: TObject);
var
 Task : string;
begin
 FormControlTimer.Enabled := false;
 FormControlTimer.Interval := 1000;
 while FormControlList.Count > 0 do begin
  Task := FormControlList[FormControlList.Count-1];
  FormControlList.Delete(FormControlList.Count-1);
  if Task = 'SHOWOVERVIEW' then ShowOverviewFormVerteiler;
  if Task = 'SHOWBPTABLE' then ShowBPTableFormVerteiler;
 end;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.FormControlListAddTask(_Task:string);
begin
 FormControlList.Add(_Task);
 FormControlTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.OverviewBtnClick(Sender: TObject);
begin
 if not GLV_UserLogin then begin
  showmessage(SP.Load(248));
  self.MainMenuFrame1.LoginBtnClick(self);
  exit;
 end;
 if not GLV_AccountOK then exit;
 ShowOverviewFormVerteiler;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.HilfeBtnClick(Sender: TObject);
begin
 Application.Navigate(GetManualLink,ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowOverviewFormVerteiler;
begin
 if self.Width <= GLV_MinSmallWidth then begin
  ShowOverviewFormSmallWidth;
  exit;
 end;
 if self.Height <= GLV_MinSmallHeight then begin
  ShowOverviewFormSmallHeight;
  exit;
 end;
 ShowOverviewForm;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowOverviewForm;
var
  myQiuOverviewForm : TmyQiuOverviewForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;
begin
 myQiuOverviewForm := TmyQiuOverviewForm.CreateNew(@AfterCreate);
 myQiuOverviewForm.Popup := false;
 myQiuOverviewForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowOverviewFormSmallWidth;
var
  myQiuOverviewSWForm : TmyQiuOverviewSWForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;
begin
 myQiuOverviewSWForm := TmyQiuOverviewSWForm.CreateNew(@AfterCreate);
 myQiuOverviewSWForm.Popup := false;
 myQiuOverviewSWForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowOverviewFormSmallHeight;
var
  myQiuOverviewSHForm : TmyQiuOverviewSHForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;
begin
 myQiuOverviewSHForm := TmyQiuOverviewSHForm.CreateNew(@AfterCreate);
 myQiuOverviewSHForm.Popup  := false;
 myQiuOverviewSHForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.BPTableBtnClick(Sender: TObject);
begin
 if not GLV_UserLogin then begin
  showmessage(SP.Load(248));
  self.MainMenuFrame1.LoginBtnClick(self);
  exit;
 end;
 if not GLV_AccountOK then exit;
 ShowBPTableFormVerteiler;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowBPTableFormVerteiler;
begin
 if self.Width <= GLV_MinSmallWidth then begin
  ShowBPTableFormSmallWidth;
  exit;
 end;
 if self.Height <= GLV_MinSmallHeight then begin
  ShowBPTableFormSmallHeight;
  exit;
 end;
 ShowBPTableForm;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowBPTableForm;
var
  MyQiuBPTableForm : TMyQiuBPTableForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;
begin
 if not GLV_UserLogin then begin
  showmessage(SP.Load(248));
  exit;
 end;

 MyQiuBPTableForm := TMyQiuBPTableForm.CreateNew(@AfterCreate);
 MyQiuBPTableForm.Popup := false;
 MyQiuBPTableForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowBPTableFormSmallWidth;
var
  MyQiuBPTableSWForm : TMyQiuBPTableSWForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;
begin
 if not GLV_UserLogin then begin
  showmessage(SP.Load(248));
  exit;
 end;

 MyQiuBPTableSWForm := TMyQiuBPTableSWForm.CreateNew(@AfterCreate);
 MyQiuBPTableSWForm.Popup := false;
 MyQiuBPTableSWForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowBPTableFormSmallHeight;
var
  MyQiuBPTableSHForm : TMyQiuBPTableSHForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;
begin
 if not GLV_UserLogin then begin
  showmessage(SP.Load(248));
  exit;
 end;

 MyQiuBPTableSHForm := TMyQiuBPTableSHForm.CreateNew(@AfterCreate);
 MyQiuBPTableSHForm.Popup := false;
 MyQiuBPTableSHForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TMainForm.FrageBogenBtnClick(Sender: TObject);
begin
 if not GLV_UserLogin then begin
  showmessage(SP.Load(248));
  self.MainMenuFrame1.LoginBtnClick(self);
  exit;
 end;
 if not GLV_AccountOK then exit;
 ShowFragebogenForm;
end;
{------------------------------------------------------------------------------}
procedure TMainForm.ShowFragebogenForm;
var
  myQiuFragebogenForm : TmyQiuFragebogenForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;
begin
 if not GLV_UserLogin then begin
  showmessage(SP.Load(248));
  exit;
 end;

 myQiuFragebogenForm := TmyQiuFragebogenForm.CreateNew(@AfterCreate);
 myQiuFragebogenForm.Popup := false;
 myQiuFragebogenForm.ShowModal(@AfterShowModal);
end;
procedure TMainForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebImageControl2 := TImageControl.Create(Self);
  WebLinkLabel_Impressum := TLinkLabel.Create(Self);
  WebLinkLabel_Datenschutz := TLinkLabel.Create(Self);
  WebLinkLabel_Homepage := TLinkLabel.Create(Self);
  WebLinkLabel_Shop := TLinkLabel.Create(Self);
  WebLinkLabel_AGB := TLinkLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  AppAPKDownloadLabel := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  OverviewBtn := TSpeedButton.Create(Self);
  BPTableBtn := TSpeedButton.Create(Self);
  WebButton1 := TButton.Create(Self);
  BottomPanel := TPanel.Create(Self);
  VersionLabel := TLabel.Create(Self);
  CopyrightLabel := TLabel.Create(Self);
  FrageBogenBtn := TSpeedButton.Create(Self);
  DownloadAPK_Btn := TButton.Create(Self);
  HilfeBtn := TSpeedButton.Create(Self);
  XDataWebConnection := TXDataWebConnection.Create(Self);
  XDataWebClient := TXDataWebClient.Create(Self);
  ConnectTimer := TTimer.Create(Self);
  FormControlTimer := TTimer.Create(Self);
  AutoLoginTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebImageControl2.BeforeLoadDFMValues;
  WebLinkLabel_Impressum.BeforeLoadDFMValues;
  WebLinkLabel_Datenschutz.BeforeLoadDFMValues;
  WebLinkLabel_Homepage.BeforeLoadDFMValues;
  WebLinkLabel_Shop.BeforeLoadDFMValues;
  WebLinkLabel_AGB.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  AppAPKDownloadLabel.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  MainMenuFrame1.KontoBtn.BeforeLoadDFMValues;
  MainMenuFrame1.RegistBtn.BeforeLoadDFMValues;
  OverviewBtn.BeforeLoadDFMValues;
  BPTableBtn.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  BottomPanel.BeforeLoadDFMValues;
  VersionLabel.BeforeLoadDFMValues;
  CopyrightLabel.BeforeLoadDFMValues;
  FrageBogenBtn.BeforeLoadDFMValues;
  DownloadAPK_Btn.BeforeLoadDFMValues;
  HilfeBtn.BeforeLoadDFMValues;
  XDataWebConnection.BeforeLoadDFMValues;
  XDataWebClient.BeforeLoadDFMValues;
  ConnectTimer.BeforeLoadDFMValues;
  FormControlTimer.BeforeLoadDFMValues;
  AutoLoginTimer.BeforeLoadDFMValues;
  try
    Name := 'MainForm';
    Width := 694;
    Height := 512;
    Align := alClient;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 35;
    WebLabel1.Top := 94;
    WebLabel1.Width := 63;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 35;
    WebLabel2.Top := 188;
    WebLabel2.Width := 63;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'WebLabel2';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 249;
    WebImageControl1.Top := 149;
    WebImageControl1.Width := 390;
    WebImageControl1.Height := 110;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.AutoSize := True;
    WebImageControl1.ChildOrder := 7;
    WebImageControl1.Picture.LoadFromFile('Main.WebImageControl1.Picture.png');
    WebImageControl2.SetParentComponent(Self);
    WebImageControl2.Name := 'WebImageControl2';
    WebImageControl2.Left := 161;
    WebImageControl2.Top := 293;
    WebImageControl2.Width := 195;
    WebImageControl2.Height := 55;
    WebImageControl2.HeightPercent := 100.000000000000000000;
    WebImageControl2.WidthPercent := 100.000000000000000000;
    WebImageControl2.AutoSize := True;
    WebImageControl2.ChildOrder := 7;
    WebImageControl2.Picture.LoadFromFile('Main.WebImageControl2.Picture.png');
    WebLinkLabel_Impressum.SetParentComponent(Self);
    WebLinkLabel_Impressum.Name := 'WebLinkLabel_Impressum';
    WebLinkLabel_Impressum.Left := 35;
    WebLinkLabel_Impressum.Top := 337;
    WebLinkLabel_Impressum.Width := 64;
    WebLinkLabel_Impressum.Height := 16;
    WebLinkLabel_Impressum.HeightPercent := 100.000000000000000000;
    WebLinkLabel_Impressum.WidthPercent := 100.000000000000000000;
    WebLinkLabel_Impressum.Caption := 'Impressum';
    WebLinkLabel_Datenschutz.SetParentComponent(Self);
    WebLinkLabel_Datenschutz.Name := 'WebLinkLabel_Datenschutz';
    WebLinkLabel_Datenschutz.Left := 35;
    WebLinkLabel_Datenschutz.Top := 353;
    WebLinkLabel_Datenschutz.Width := 123;
    WebLinkLabel_Datenschutz.Height := 16;
    WebLinkLabel_Datenschutz.HeightPercent := 100.000000000000000000;
    WebLinkLabel_Datenschutz.WidthPercent := 100.000000000000000000;
    WebLinkLabel_Datenschutz.Caption := 'Datenschutzerkl'#228'rung';
    WebLinkLabel_Homepage.SetParentComponent(Self);
    WebLinkLabel_Homepage.Name := 'WebLinkLabel_Homepage';
    WebLinkLabel_Homepage.Left := 35;
    WebLinkLabel_Homepage.Top := 369;
    WebLinkLabel_Homepage.Width := 99;
    WebLinkLabel_Homepage.Height := 16;
    WebLinkLabel_Homepage.HeightPercent := 100.000000000000000000;
    WebLinkLabel_Homepage.WidthPercent := 100.000000000000000000;
    WebLinkLabel_Homepage.Caption := 'BioSign Webseite';
    WebLinkLabel_Shop.SetParentComponent(Self);
    WebLinkLabel_Shop.Name := 'WebLinkLabel_Shop';
    WebLinkLabel_Shop.Left := 35;
    WebLinkLabel_Shop.Top := 385;
    WebLinkLabel_Shop.Width := 116;
    WebLinkLabel_Shop.Height := 16;
    WebLinkLabel_Shop.HeightPercent := 100.000000000000000000;
    WebLinkLabel_Shop.WidthPercent := 100.000000000000000000;
    WebLinkLabel_Shop.Caption := 'BioSign Online-Shop';
    WebLinkLabel_AGB.SetParentComponent(Self);
    WebLinkLabel_AGB.Name := 'WebLinkLabel_AGB';
    WebLinkLabel_AGB.Left := 36;
    WebLinkLabel_AGB.Top := 321;
    WebLinkLabel_AGB.Width := 23;
    WebLinkLabel_AGB.Height := 16;
    WebLinkLabel_AGB.HeightPercent := 100.000000000000000000;
    WebLinkLabel_AGB.WidthPercent := 100.000000000000000000;
    WebLinkLabel_AGB.Caption := 'AGB';
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 130;
    WebLabel3.Top := 188;
    WebLabel3.Width := 63;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'WebLabel3';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    AppAPKDownloadLabel.SetParentComponent(Self);
    AppAPKDownloadLabel.Name := 'AppAPKDownloadLabel';
    AppAPKDownloadLabel.Left := 36;
    AppAPKDownloadLabel.Top := 304;
    AppAPKDownloadLabel.Width := 130;
    AppAPKDownloadLabel.Height := 16;
    AppAPKDownloadLabel.Caption := 'AppAPKDownloadLabel';
    AppAPKDownloadLabel.Font.Charset := DEFAULT_CHARSET;
    AppAPKDownloadLabel.Font.Color := clHighlight;
    AppAPKDownloadLabel.Font.Height := -13;
    AppAPKDownloadLabel.Font.Name := 'Tahoma';
    AppAPKDownloadLabel.Font.Style := [];
    AppAPKDownloadLabel.HeightPercent := 100.000000000000000000;
    AppAPKDownloadLabel.ParentFont := False;
    AppAPKDownloadLabel.WidthPercent := 100.000000000000000000;
    SetEvent(AppAPKDownloadLabel, Self, 'OnClick', 'AppAPKDownloadLabelClick');
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 130;
    WebLabel4.Top := 94;
    WebLabel4.Width := 63;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'WebLabel4';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 694;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 0;
    SetEvent(MainMenuFrame1.KontoBtn, Self, 'OnClick', 'MainMenuFrame1KontoBtnClick');
    MainMenuFrame1.RegistBtn.Left := 206;
    OverviewBtn.SetParentComponent(Self);
    OverviewBtn.Name := 'OverviewBtn';
    OverviewBtn.Left := 35;
    OverviewBtn.Top := 114;
    OverviewBtn.Width := 70;
    OverviewBtn.Height := 68;
    OverviewBtn.Color := clNone;
    OverviewBtn.Glyph.LoadFromFile('Main.OverviewBtn.Glyph.bmp');
    OverviewBtn.HeightPercent := 100.000000000000000000;
    OverviewBtn.TabOrder := 1;
    OverviewBtn.WidthPercent := 100.000000000000000000;
    SetEvent(OverviewBtn, Self, 'OnClick', 'OverviewBtnClick');
    BPTableBtn.SetParentComponent(Self);
    BPTableBtn.Name := 'BPTableBtn';
    BPTableBtn.Left := 35;
    BPTableBtn.Top := 210;
    BPTableBtn.Width := 70;
    BPTableBtn.Height := 68;
    BPTableBtn.Color := clNone;
    BPTableBtn.Glyph.LoadFromFile('Main.BPTableBtn.Glyph.bmp');
    BPTableBtn.HeightPercent := 100.000000000000000000;
    BPTableBtn.TabOrder := 2;
    BPTableBtn.WidthPercent := 100.000000000000000000;
    SetEvent(BPTableBtn, Self, 'OnClick', 'BPTableBtnClick');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 371;
    WebButton1.Top := 418;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'BuildBaseURL';
    WebButton1.ChildOrder := 9;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.Visible := False;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 478;
    BottomPanel.Width := 694;
    BottomPanel.Height := 34;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.ChildOrder := 10;
    BottomPanel.Color := clWhite;
    VersionLabel.SetParentComponent(BottomPanel);
    VersionLabel.Name := 'VersionLabel';
    VersionLabel.Left := 0;
    VersionLabel.Top := 0;
    VersionLabel.Width := 73;
    VersionLabel.Height := 16;
    VersionLabel.Align := alTop;
    VersionLabel.Alignment := taCenter;
    VersionLabel.Caption := 'VersionLabel';
    VersionLabel.Font.Charset := DEFAULT_CHARSET;
    VersionLabel.Font.Color := clWindowText;
    VersionLabel.Font.Height := -13;
    VersionLabel.Font.Name := 'Tahoma';
    VersionLabel.Font.Style := [];
    VersionLabel.HeightPercent := 100.000000000000000000;
    VersionLabel.ParentFont := False;
    VersionLabel.WidthPercent := 100.000000000000000000;
    CopyrightLabel.SetParentComponent(BottomPanel);
    CopyrightLabel.Name := 'CopyrightLabel';
    CopyrightLabel.Left := 0;
    CopyrightLabel.Top := 18;
    CopyrightLabel.Width := 84;
    CopyrightLabel.Height := 16;
    CopyrightLabel.Align := alBottom;
    CopyrightLabel.Alignment := taCenter;
    CopyrightLabel.Caption := 'CopyrightLabel';
    CopyrightLabel.Font.Charset := DEFAULT_CHARSET;
    CopyrightLabel.Font.Color := clWindowText;
    CopyrightLabel.Font.Height := -13;
    CopyrightLabel.Font.Name := 'Tahoma';
    CopyrightLabel.Font.Style := [];
    CopyrightLabel.HeightPercent := 100.000000000000000000;
    CopyrightLabel.ParentFont := False;
    CopyrightLabel.WidthPercent := 100.000000000000000000;
    FrageBogenBtn.SetParentComponent(Self);
    FrageBogenBtn.Name := 'FrageBogenBtn';
    FrageBogenBtn.Left := 131;
    FrageBogenBtn.Top := 210;
    FrageBogenBtn.Width := 70;
    FrageBogenBtn.Height := 68;
    FrageBogenBtn.Color := clNone;
    FrageBogenBtn.Glyph.LoadFromFile('Main.FrageBogenBtn.Glyph.png');
    FrageBogenBtn.HeightPercent := 100.000000000000000000;
    FrageBogenBtn.TabOrder := 5;
    FrageBogenBtn.WidthPercent := 100.000000000000000000;
    SetEvent(FrageBogenBtn, Self, 'OnClick', 'FrageBogenBtnClick');
    DownloadAPK_Btn.SetParentComponent(Self);
    DownloadAPK_Btn.Name := 'DownloadAPK_Btn';
    DownloadAPK_Btn.Left := 483;
    DownloadAPK_Btn.Top := 418;
    DownloadAPK_Btn.Width := 96;
    DownloadAPK_Btn.Height := 25;
    DownloadAPK_Btn.Caption := 'DownloadAPK';
    DownloadAPK_Btn.ChildOrder := 9;
    DownloadAPK_Btn.HeightPercent := 100.000000000000000000;
    DownloadAPK_Btn.Visible := False;
    DownloadAPK_Btn.WidthPercent := 100.000000000000000000;
    SetEvent(DownloadAPK_Btn, Self, 'OnClick', 'DownloadAPK_BtnClick');
    HilfeBtn.SetParentComponent(Self);
    HilfeBtn.Name := 'HilfeBtn';
    HilfeBtn.Left := 130;
    HilfeBtn.Top := 114;
    HilfeBtn.Width := 70;
    HilfeBtn.Height := 68;
    HilfeBtn.Color := clNone;
    HilfeBtn.Glyph.LoadFromFile('Main.HilfeBtn.Glyph.bmp');
    HilfeBtn.HeightPercent := 100.000000000000000000;
    HilfeBtn.TabOrder := 7;
    HilfeBtn.WidthPercent := 100.000000000000000000;
    SetEvent(HilfeBtn, Self, 'OnClick', 'HilfeBtnClick');
    XDataWebConnection.SetParentComponent(Self);
    XDataWebConnection.Name := 'XDataWebConnection';
    XDataWebConnection.URL := 'http://biosign-server.de:8000/BioSign/myQiu';
    SetEvent(XDataWebConnection, Self, 'OnConnect', 'XDataWebConnectionConnect');
    SetEvent(XDataWebConnection, Self, 'OnError', 'XDataWebConnectionError');
    SetEvent(XDataWebConnection, Self, 'OnResponse', 'XDataWebConnectionResponse');
    XDataWebConnection.Left := 424;
    XDataWebConnection.Top := 80;
    XDataWebClient.SetParentComponent(Self);
    XDataWebClient.Name := 'XDataWebClient';
    XDataWebClient.Connection := XDataWebConnection;
    XDataWebClient.Left := 544;
    XDataWebClient.Top := 80;
    ConnectTimer.SetParentComponent(Self);
    ConnectTimer.Name := 'ConnectTimer';
    ConnectTimer.Enabled := False;
    SetEvent(ConnectTimer, Self, 'OnTimer', 'ConnectTimerTimer');
    ConnectTimer.Left := 632;
    ConnectTimer.Top := 240;
    FormControlTimer.SetParentComponent(Self);
    FormControlTimer.Name := 'FormControlTimer';
    FormControlTimer.Enabled := False;
    SetEvent(FormControlTimer, Self, 'OnTimer', 'FormControlTimerTimer');
    FormControlTimer.Left := 640;
    FormControlTimer.Top := 88;
    AutoLoginTimer.SetParentComponent(Self);
    AutoLoginTimer.Name := 'AutoLoginTimer';
    AutoLoginTimer.Enabled := False;
    AutoLoginTimer.Interval := 500;
    SetEvent(AutoLoginTimer, Self, 'OnTimer', 'AutoLoginTimerTimer');
    AutoLoginTimer.Left := 632;
    AutoLoginTimer.Top := 168;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebImageControl2.AfterLoadDFMValues;
    WebLinkLabel_Impressum.AfterLoadDFMValues;
    WebLinkLabel_Datenschutz.AfterLoadDFMValues;
    WebLinkLabel_Homepage.AfterLoadDFMValues;
    WebLinkLabel_Shop.AfterLoadDFMValues;
    WebLinkLabel_AGB.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    AppAPKDownloadLabel.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    MainMenuFrame1.KontoBtn.AfterLoadDFMValues;
    MainMenuFrame1.RegistBtn.AfterLoadDFMValues;
    OverviewBtn.AfterLoadDFMValues;
    BPTableBtn.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    BottomPanel.AfterLoadDFMValues;
    VersionLabel.AfterLoadDFMValues;
    CopyrightLabel.AfterLoadDFMValues;
    FrageBogenBtn.AfterLoadDFMValues;
    DownloadAPK_Btn.AfterLoadDFMValues;
    HilfeBtn.AfterLoadDFMValues;
    XDataWebConnection.AfterLoadDFMValues;
    XDataWebClient.AfterLoadDFMValues;
    ConnectTimer.AfterLoadDFMValues;
    FormControlTimer.AfterLoadDFMValues;
    AutoLoginTimer.AfterLoadDFMValues;
  end;
end;

end.
