unit myQiuTestFragenAntworten;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,DatenServiceClasses, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Buttons, Vcl.Grids, WEBLib.Grids, WEBLib.ExtCtrls,
  Vcl.Forms, MainMenuFrame, Vcl.Graphics, Vcl.Imaging.pngimage, Types;

type
  TmyQiuTestFragenAntwortenForm = class(TForm)
    BottomPanel: TPanel;
    CancelBtn: TSpeedButton;
    RepaintTimer: TTimer;
    MainMenuFrame1: TMainMenuFrame1;
    CaptionPanel: TPanel;
    MiddlePanel: TPanel;
    AntwortenStringGrid: TStringGrid;
    WaitPanel: TPanel;
    ListPanel: TPanel;
    SelectTimer: TTimer;
    EditBtn: TBitBtn;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure RepaintTimerTimer(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure GetAntwortenListe;
    procedure AntwortenStringGridClick(Sender: TObject);
    procedure SelectTimerTimer(Sender: TObject);
    procedure AntwortenStringGridKeyUp(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure AntwortenStringGridSelectCell(Sender: TObject; ACol,
      ARow: Integer; var CanSelect: Boolean);
    procedure ShowAntwortenListe;
    procedure AntwortenStringGridDblClick(Sender: TObject);
    procedure EditBtnClick(Sender: TObject);
    procedure Show_myQiuTestFragenAntwortenTrackbarForm(_Rec:TFragenAntwortenItemClass);
    procedure Show_myQiuTestFragenAntwortenMCForm(_Rec:TFragenAntwortenItemClass);
  private
    { Private-Deklarationen }
    AktRec             : TFragenAntwortenItemClass;
  public
    { Public-Deklarationen }
    FormClosed         : boolean;
    AntwortenListe     : TArray<TFragenAntwortenItemClass>;
    SelectedTopRow     : integer;
    SelectedBottomRow  : integer;
    MessungsID         : string;
    _Caption           : string;
  protected procedure LoadDFMValues; override; end;

var
  myQiuTestFragenAntwortenForm: TmyQiuTestFragenAntwortenForm;

implementation

uses Main,XData.Web.Client,KVT,MyMsgDialog,CommonFunctions,System.Generics.Collections,
     myQiuTestFragenAntwortenTrackbar,myQiuTestFragenAntwortenMC;

{$R *.dfm}

{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 SetLength(AntwortenListe,0);
 FormClosed := true;
 RepaintTimer.Enabled := false;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.WebFormResize(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 CancelBtn.left       := BottomPanel.ClientWidth - CancelBtn.width - GLV_Dist;
 EditBtn.Left         := MiddlePanel.ClientWidth div 2 - EditBtn.width div 2;
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.WebFormShow(Sender: TObject);
begin
 CaptionPanel.Color := CaptionPanelColor;
 CaptionPanel.Font.Color := CaptionPanelFontColor;
 CaptionPanel.BorderColor :=  CaptionPanelBorderColor;
 CaptionPanel.Caption := _Caption + GLC_1Blank + GetMyQiuUserString;
 MainMenuFrame1.UpdateFrame;
 CancelBtn.Hint := SP.Load(223);
 CancelBtn.ShowHint := true;
 EditBtn.caption := SP.Load(310);
 MainMenuFrame1.UpdateFrame;
 CancelBtn.Caption := SP.Load(27);

 AntwortenStringGrid.ColCount := 4;
 AntwortenStringGrid.RowCount := 1;
 AntwortenStringGrid.FixedCols := 0;
 AntwortenStringGrid.FixedRows := 1;
 AntwortenStringGrid.ColWidths[0] := 200;
 AntwortenStringGrid.ColWidths[1] := 220;
 AntwortenStringGrid.ColWidths[2] := 60;
 AntwortenStringGrid.ColWidths[3] := 800;
 AntwortenStringGrid.Cells[0,0] := SP.Load(301);
 AntwortenStringGrid.Cells[1,0] := SP.Load(302);
 AntwortenStringGrid.Cells[2,0] := SP.Load(303);
 AntwortenStringGrid.Cells[3,0] := SP.Load(317);

 GetAntwortenListe;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.EditBtnClick(Sender: TObject);
begin
 if SelectedTopRow < 0 then begin showmessage(SP.load(124)); exit; end;
 case AktRec.Typ of
  0: Show_myQiuTestFragenAntwortenTrackbarForm(AktRec);
  1: Show_myQiuTestFragenAntwortenMCForm(AktRec);
  2: Show_myQiuTestFragenAntwortenMCForm(AktRec);
 end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.Show_myQiuTestFragenAntwortenTrackbarForm(_Rec:TFragenAntwortenItemClass);
var
 myQiuTestFragenAntwortenTrackbarForm : TmyQiuTestFragenAntwortenTrackbarForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   // evaluate AValue and get values from form ...
   if myQiuTestFragenAntwortenTrackbarForm.ChangesDone then begin
    AntwortenListe[SelectedTopRow] := myQiuTestFragenAntwortenMCForm.Rec;
    ShowAntwortenListe;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuTestFragenAntwortenTrackbarForm.ShowModal(@AfterShowModal);
  end;
begin
 myQiuTestFragenAntwortenTrackbarForm := TmyQiuTestFragenAntwortenTrackbarForm.CreateNew(@AfterCreate);
 myQiuTestFragenAntwortenTrackbarForm.EditMode := true;
 myQiuTestFragenAntwortenTrackbarForm.caption := SP.Load(311);
 myQiuTestFragenAntwortenTrackbarForm.Border := fbDialog;
 myQiuTestFragenAntwortenTrackbarForm.Popup := true;
 myQiuTestFragenAntwortenTrackbarForm.Rec := _Rec;
 myQiuTestFragenAntwortenTrackbarForm.MessungsID := MessungsID;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.Show_myQiuTestFragenAntwortenMCForm(_Rec:TFragenAntwortenItemClass);
var
 myQiuTestFragenAntwortenMCForm : TmyQiuTestFragenAntwortenMCForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   // evaluate AValue and get values from form ...
   if myQiuTestFragenAntwortenMCForm.ChangesDone then begin
    AntwortenListe[SelectedTopRow] := myQiuTestFragenAntwortenMCForm.Rec;
    ShowAntwortenListe;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuTestFragenAntwortenMCForm.ShowModal(@AfterShowModal);
  end;
begin
  myQiuTestFragenAntwortenMCForm := TmyQiuTestFragenAntwortenMCForm.CreateNew(@AfterCreate);
  myQiuTestFragenAntwortenMCForm.EditMode := true;
  myQiuTestFragenAntwortenMCForm.caption := SP.Load(311);
  myQiuTestFragenAntwortenMCForm.Border := fbDialog;
  myQiuTestFragenAntwortenMCForm.Popup := true;
  myQiuTestFragenAntwortenMCForm.Rec := _Rec;
  myQiuTestFragenAntwortenMCForm.MessungsID := MessungsID;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.AntwortenStringGridDblClick(
  Sender: TObject);
begin
 EditBtnClick(Self);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.RepaintTimerTimer(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.GetAntwortenListe;
var
 tmpWert : integer;
 s       : string;
begin
 SelectedTopRow := -1;
 SelectedBottomRow := -1;


 WaitPanel.Top := ListPanel.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= ListPanel.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetAntwortenListe',[GLV_AktUser.Token,GLV_AktUser.UUID,MessungsID],
   procedure (Response: TXDataClientResponse)
   var
    LArray : TJSArray;
    LObj   : TJSObject;
    i      : integer;
    Rec    : TFragenAntwortenItemClass;
   begin
    if FormClosed then exit;
    LArray := TJSArray(TJSObject(Response.Result)['value']);
    SetLength(AntwortenListe,LArray.Length);
    AntwortenStringGrid.RowCount := LArray.Length+1;
    AntwortenStringGrid.BeginUpdate;
    for i := 0 to LArray.Length-1 do begin
     LObj := TJSObject(LArray[i]);
     Rec  := TFragenAntwortenItemClass.Create;
     Rec.ID     := string(LObj['ID']);
     Rec.Zeit   := byte(LObj['Zeit']);
     Rec.Text   := string(LObj['Text']);
     Rec.Typ    := integer(LObj['Typ']);
     Rec.Wert   := integer(LObj['Wert']);
     Rec.Min    := integer(LObj['Min']);
     Rec.Max    := integer(LObj['Max']);
     Rec.Steps  := integer(LObj['Steps']);
     Rec.BezMin := string(LObj['BezMin']);
     Rec.BezMax := string(LObj['BezMax']);
     Rec.MC1_Text  := string(LObj['MC1_Text']);
     Rec.MC1_Value := integer(LObj['MC1_Value']);
     Rec.MC2_Text  := string(LObj['MC2_Text']);
     Rec.MC2_Value := integer(LObj['MC2_Value']);
     Rec.MC3_Text  := string(LObj['MC3_Text']);
     Rec.MC3_Value := integer(LObj['MC3_Value']);
     Rec.MC4_Text  := string(LObj['MC4_Text']);
     Rec.MC4_Value := integer(LObj['MC4_Value']);
     Rec.MC5_Text  := string(LObj['MC5_Text']);
     Rec.MC5_Value := integer(LObj['MC5_Value']);

     AntwortenListe[i] := Rec;
     if Rec.Zeit = 0 then AntwortenStringGrid.Cells[0,i+1] := SP.Load(306);
     if Rec.Zeit = 1 then AntwortenStringGrid.Cells[0,i+1] := SP.Load(307);
     if Rec.Zeit = 2 then AntwortenStringGrid.Cells[0,i+1] := SP.Load(305);

     if Rec.Typ = 0 then AntwortenStringGrid.Cells[1,i+1] := SP.Load(312);
     if Rec.Typ = 1 then AntwortenStringGrid.Cells[1,i+1] := SP.Load(313);
     if Rec.Typ = 2 then AntwortenStringGrid.Cells[1,i+1] := SP.Load(314);

     tmpWert := 0;
     if Rec.Typ = 0 then tmpWert := Rec.Wert;
     if (Rec.Typ = 1) or (Rec.Typ = 2) then begin
      s := inttostr(Rec.Wert);
      tmpWert:= 0;
      if copy(s,2,1) = '1' then tmpWert := tmpWert + Rec.MC1_Value;
      if copy(s,3,1) = '1' then tmpWert := tmpWert + Rec.MC2_Value;
      if copy(s,4,1) = '1' then tmpWert := tmpWert + Rec.MC3_Value;
      if copy(s,5,1) = '1' then tmpWert := tmpWert + Rec.MC4_Value;
      if copy(s,6,1) = '1' then tmpWert := tmpWert + Rec.MC5_Value;
     end;
     AntwortenStringGrid.Cells[2,i+1] := inttostr(tmpWert);
     AntwortenStringGrid.Cells[3,i+1] := Rec.Text;
    end;
    AntwortenStringGrid.EndUpdate;
    WaitPanel.Visible := false;
    SelectTimer.Enabled := true;
   end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.ShowAntwortenListe;
var
 i    : integer;
 Rec  : TFragenAntwortenItemClass;
 tmpWert : integer;
 s : string;
begin
 AntwortenStringGrid.RowCount := Length(AntwortenListe)+1;
 AntwortenStringGrid.BeginUpdate;
 for i := 0 to Length(AntwortenListe)-1 do begin
  Rec := AntwortenListe[i];
  if Rec.Zeit = 0 then AntwortenStringGrid.Cells[0,i+1] := SP.Load(306);
  if Rec.Zeit = 1 then AntwortenStringGrid.Cells[0,i+1] := SP.Load(307);
  if Rec.Zeit = 2 then AntwortenStringGrid.Cells[0,i+1] := SP.Load(305);

  if Rec.Typ = 0 then AntwortenStringGrid.Cells[1,i+1] := SP.Load(312);
  if Rec.Typ = 1 then AntwortenStringGrid.Cells[1,i+1] := SP.Load(313);
  if Rec.Typ = 2 then AntwortenStringGrid.Cells[1,i+1] := SP.Load(314);

  tmpWert := 0;
  if Rec.Typ = 0 then tmpWert := Rec.Wert;
  if (Rec.Typ = 1) or (Rec.Typ = 2) then begin
   s := inttostr(Rec.Wert);
   tmpWert:= 0;
   if copy(s,2,1) = '1' then tmpWert := tmpWert + Rec.MC1_Value;
   if copy(s,3,1) = '1' then tmpWert := tmpWert + Rec.MC2_Value;
   if copy(s,4,1) = '1' then tmpWert := tmpWert + Rec.MC3_Value;
   if copy(s,5,1) = '1' then tmpWert := tmpWert + Rec.MC4_Value;
   if copy(s,6,1) = '1' then tmpWert := tmpWert + Rec.MC5_Value;
  end;
  AntwortenStringGrid.Cells[2,i+1] := inttostr(tmpWert);
  AntwortenStringGrid.Cells[3,i+1] := Rec.Text;
 end;
 AntwortenStringGrid.EndUpdate;
 AntwortenStringGrid.Selection.Top    := SelectedTopRow;
 AntwortenStringGrid.Selection.Bottom := SelectedBottomRow;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.AntwortenStringGridClick(
  Sender: TObject);
var
 tmp : integer;
begin
 SelectedTopRow    := AntwortenStringGrid.Selection.Top-1;
 SelectedBottomRow := AntwortenStringGrid.Selection.Bottom-1;
 if SelectedBottomRow < SelectedTopRow then begin
  tmp := SelectedTopRow;
  SelectedTopRow := SelectedBottomRow;
  SelectedBottomRow := tmp;
 end;
 AktRec := AntwortenListe[SelectedTopRow];
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.AntwortenStringGridKeyUp(
  Sender: TObject; var Key: Word; Shift: TShiftState);
begin
 AntwortenStringGridClick(Self);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.AntwortenStringGridSelectCell(
  Sender: TObject; ACol, ARow: Integer; var CanSelect: Boolean);
begin
 //
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.SelectTimerTimer(Sender: TObject);
begin
 if length(AntwortenListe) > 0 then begin
  SelectTimer.Enabled := false;
  SelectedTopRow := 0;
  SelectedBottomRow := 0;
  EditBtn.Enabled   := true;
 end else begin
  EditBtn.Enabled   := false;
 end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BottomPanel := TPanel.Create(Self);
  CancelBtn := TSpeedButton.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  ListPanel := TPanel.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  MiddlePanel := TPanel.Create(Self);
  EditBtn := TBitBtn.Create(Self);
  AntwortenStringGrid := TStringGrid.Create(Self);
  WaitPanel := TPanel.Create(Self);
  RepaintTimer := TTimer.Create(Self);
  SelectTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  BottomPanel.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  MainMenuFrame1.LoginBtn.BeforeLoadDFMValues;
  MainMenuFrame1.KontoBtn.BeforeLoadDFMValues;
  MainMenuFrame1.RegistBtn.BeforeLoadDFMValues;
  ListPanel.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  MiddlePanel.BeforeLoadDFMValues;
  EditBtn.BeforeLoadDFMValues;
  AntwortenStringGrid.BeforeLoadDFMValues;
  WaitPanel.BeforeLoadDFMValues;
  RepaintTimer.BeforeLoadDFMValues;
  SelectTimer.BeforeLoadDFMValues;
  try
    Name := 'myQiuTestFragenAntwortenForm';
    Width := 817;
    Height := 607;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 557;
    BottomPanel.Width := 817;
    BottomPanel.Height := 50;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.ChildOrder := 8;
    BottomPanel.Color := clWhite;
    CancelBtn.SetParentComponent(BottomPanel);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 745;
    CancelBtn.Top := 4;
    CancelBtn.Width := 41;
    CancelBtn.Height := 41;
    CancelBtn.Color := clNone;
    CancelBtn.Glyph.LoadFromFile('myQiuTestFragenAntworten.BottomPanel.CancelBtn.Glyph.bmp');
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.ShowHint := True;
    CancelBtn.TabOrder := 0;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 817;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 1;
    MainMenuFrame1.LoginBtn.TabOrder := 3;
    MainMenuFrame1.KontoBtn.TabOrder := 0;
    MainMenuFrame1.RegistBtn.TabOrder := 1;
    ListPanel.SetParentComponent(Self);
    ListPanel.Name := 'ListPanel';
    ListPanel.Left := 0;
    ListPanel.Top := 86;
    ListPanel.Width := 817;
    ListPanel.Height := 471;
    ListPanel.Align := alClient;
    ListPanel.ChildOrder := 6;
    CaptionPanel.SetParentComponent(ListPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 817;
    CaptionPanel.Height := 26;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := clBtnFace;
    CaptionPanel.Caption := 'CaptionPanel';
    CaptionPanel.ChildOrder := 4;
    CaptionPanel.Color := clWhite;
    MiddlePanel.SetParentComponent(ListPanel);
    MiddlePanel.Name := 'MiddlePanel';
    MiddlePanel.Left := 0;
    MiddlePanel.Top := 395;
    MiddlePanel.Width := 817;
    MiddlePanel.Height := 76;
    MiddlePanel.Align := alBottom;
    MiddlePanel.BorderStyle := bsNone;
    MiddlePanel.ChildOrder := 1;
    MiddlePanel.Color := clWhite;
    EditBtn.SetParentComponent(MiddlePanel);
    EditBtn.Name := 'EditBtn';
    EditBtn.Left := 324;
    EditBtn.Top := 16;
    EditBtn.Width := 198;
    EditBtn.Height := 41;
    EditBtn.Caption := 'Antwort bearbeiten';
    EditBtn.Color := clNone;
    EditBtn.HeightPercent := 100.000000000000000000;
    EditBtn.WidthPercent := 100.000000000000000000;
    SetEvent(EditBtn, Self, 'OnClick', 'EditBtnClick');
    AntwortenStringGrid.SetParentComponent(ListPanel);
    AntwortenStringGrid.Name := 'AntwortenStringGrid';
    AntwortenStringGrid.Left := 0;
    AntwortenStringGrid.Top := 26;
    AntwortenStringGrid.Width := 817;
    AntwortenStringGrid.Height := 369;
    AntwortenStringGrid.Align := alClient;
    AntwortenStringGrid.ColCount := 3;
    AntwortenStringGrid.DefaultColWidth := 100;
    AntwortenStringGrid.FixedColor := clGray;
    AntwortenStringGrid.FixedCols := 0;
    AntwortenStringGrid.RowCount := 2;
    AntwortenStringGrid.FixedRows := 0;
    AntwortenStringGrid.GradientEndColor := clWhite;
    AntwortenStringGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    AntwortenStringGrid.TabOrder := 2;
    SetEvent(AntwortenStringGrid, Self, 'OnClick', 'AntwortenStringGridClick');
    SetEvent(AntwortenStringGrid, Self, 'OnDblClick', 'AntwortenStringGridDblClick');
    SetEvent(AntwortenStringGrid, Self, 'OnKeyUp', 'AntwortenStringGridKeyUp');
    SetEvent(AntwortenStringGrid, Self, 'OnSelectCell', 'AntwortenStringGridSelectCell');
    AntwortenStringGrid.FixedTextColor := clWhite;
    AntwortenStringGrid.RangeEdit.Max := 100.000000000000000000;
    AntwortenStringGrid.RangeEdit.Step := 1.000000000000000000;
    AntwortenStringGrid.HeightPercent := 100.000000000000000000;
    AntwortenStringGrid.WidthPercent := 100.000000000000000000;
    WaitPanel.SetParentComponent(ListPanel);
    WaitPanel.Name := 'WaitPanel';
    WaitPanel.Left := 207;
    WaitPanel.Top := 296;
    WaitPanel.Width := 361;
    WaitPanel.Height := 41;
    WaitPanel.BorderColor := clBtnFace;
    WaitPanel.Caption := 'Bitte warten';
    WaitPanel.ChildOrder := 3;
    WaitPanel.Color := clWhite;
    WaitPanel.Visible := False;
    RepaintTimer.SetParentComponent(Self);
    RepaintTimer.Name := 'RepaintTimer';
    RepaintTimer.Enabled := False;
    RepaintTimer.Interval := 500;
    SetEvent(RepaintTimer, Self, 'OnTimer', 'RepaintTimerTimer');
    RepaintTimer.Left := 456;
    RepaintTimer.Top := 142;
    SelectTimer.SetParentComponent(Self);
    SelectTimer.Name := 'SelectTimer';
    SelectTimer.Enabled := False;
    SelectTimer.Interval := 500;
    SetEvent(SelectTimer, Self, 'OnTimer', 'SelectTimerTimer');
    SelectTimer.Left := 344;
    SelectTimer.Top := 136;
  finally
    BottomPanel.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    MainMenuFrame1.LoginBtn.AfterLoadDFMValues;
    MainMenuFrame1.KontoBtn.AfterLoadDFMValues;
    MainMenuFrame1.RegistBtn.AfterLoadDFMValues;
    ListPanel.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    MiddlePanel.AfterLoadDFMValues;
    EditBtn.AfterLoadDFMValues;
    AntwortenStringGrid.AfterLoadDFMValues;
    WaitPanel.AfterLoadDFMValues;
    RepaintTimer.AfterLoadDFMValues;
    SelectTimer.AfterLoadDFMValues;
  end;
end;

end.