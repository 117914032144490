
unit myQiuTestFragenAntwortenTrackbar;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  XData.Web.Client, Vcl.Imaging.pngimage, WEBLib.Buttons, Vcl.Graphics,
  DatenServiceClasses, WEBLib.ExtCtrls;

type
  TmyQiuTestFragenAntwortenTrackbarForm = class(TForm)
    WebLabel1: TLabel;
    WebMemo1: TMemo;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebTrackBar1: TTrackBar;
    WebLabel4: TLabel;
    PreviousBtn: TBitBtn;
    NextBtn: TBitBtn;
    CancelBtn: TBitBtn;
    SaveBtn: TBitBtn;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure SaveChangedAntwort;
    procedure SaveBtnClick(Sender: TObject);
    procedure WebTrackBar1Change(Sender: TObject);
    procedure NextBtnClick(Sender: TObject);
    procedure PreviousBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
    FormClosed : boolean;
  public
    { Public-Deklarationen }
    Rec         : TFragenAntwortenItemClass;
    MessungsID  : string;
    ChangesDone : boolean;
    EditMode    : boolean;  // AUfruf kommt cvon Fragen bearbeiten
    Next        : boolean;
    FrageIDX    : integer;
  protected procedure LoadDFMValues; override; end;

var
  myQiuTestFragenAntwortenTrackbarForm: TmyQiuTestFragenAntwortenTrackbarForm;

implementation

uses Main, KVT, myQiuTestFragenAntworten;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.CancelBtnClick(Sender: TObject);
begin
 FormClosed := true;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
 ChangesDone:= false;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.WebFormResize(Sender: TObject);
begin
 if EditMode then begin
  SaveBtn.Left   := self.ClientWidth div 2 + GLV_Dist;
  CancelBtn.Left := self.ClientWidth div 2 - CancelBtn.Width - GLV_Dist;
 end else begin
  PreviousBtn.Left := self.ClientWidth div 2 - PreviousBtn.Width - GLV_Dist;
  NextBtn.Left   := self.ClientWidth div 2 + GLV_Dist;
  CancelBtn.Left := self.ClientWidth div 2 - CancelBtn.Width div 2;
  if FrageIDX <= 0 then PreviousBtn.Enabled := false;
 end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.WebFormShow(Sender: TObject);
begin
 CancelBtn.Caption := SP.Load(27);
 SaveBtn.Caption := SP.Load(74);
 WebLabel1.Caption := SP.Load(304);

 NextBtn.Caption := SP.Load(320);
 PreviousBtn.Caption := SP.Load(321);

 if EditMode then begin
  NextBtn.Visible := false;
  PreviousBtn.Visible := false;
  SaveBtn.Visible := true;
 end else begin
  NextBtn.Visible := true;
  PreviousBtn.Visible := true;
  SaveBtn.Visible := false;
 end;

 WebLabel2.Caption := Rec.BezMin +  '=' + inttostr(Rec.Min);
 WebLabel3.Caption := Rec.BezMax +  '=' + inttostr(Rec.Max);

 WebMemo1.Lines.Clear;
 WebMemo1.Lines.Add(Rec.Text);


 WebTrackBar1.Min := Rec.Min;
 WebTrackBar1.Max := Rec.Max;

 WebTrackBar1.Position := Rec.Wert;
 WebLabel4.Caption := inttostr(WebTrackBar1.Position);

 WebFormResize(Self);

end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.WebTrackBar1Change(
  Sender: TObject);
begin
 WebLabel4.Caption := inttostr(WebTrackBar1.Position);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.NextBtnClick(Sender: TObject);
begin
 SaveChangedAntwort;
 Next := true;
 FormClosed := true;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.PreviousBtnClick(
  Sender: TObject);
begin
  SaveChangedAntwort;
 Next := false;
 FormClosed := true;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.SaveBtnClick(Sender: TObject);
begin
 SaveChangedAntwort;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestFragenAntwortenTrackbarForm.SaveChangedAntwort;
begin
   ChangesDone := true;
   Rec.Wert    := WebTrackBar1.Position;

   if EditMode then begin
    MainForm.XDataWebClient.RawInvoke('IDatenService.EditAntwortValue',[GLV_AktUser.Token,GLV_AktUser.UUID,MessungsID,Rec.ID,Rec.Wert],
     procedure (Response: TXDataClientResponse)
     begin
      if FormClosed then exit;
      case byte(TJSObject(Response.Result)['value']) of
       1: begin
           FormClosed := true;
           Close;
          end;
       0: showmessage(SP.load(204));
      end;
     end);
   end;
end;

procedure TmyQiuTestFragenAntwortenTrackbarForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebTrackBar1 := TTrackBar.Create(Self);
  PreviousBtn := TBitBtn.Create(Self);
  NextBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  SaveBtn := TBitBtn.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebTrackBar1.BeforeLoadDFMValues;
  PreviousBtn.BeforeLoadDFMValues;
  NextBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  try
    Name := 'myQiuTestFragenAntwortenTrackbarForm';
    Width := 365;
    Height := 347;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 4;
    WebLabel1.Top := 5;
    WebLabel1.Width := 54;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Fragetext';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 4;
    WebLabel2.Top := 118;
    WebLabel2.Width := 63;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'WebLabel2';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 129;
    WebLabel3.Top := 118;
    WebLabel3.Width := 225;
    WebLabel3.Height := 16;
    WebLabel3.Alignment := taRightJustify;
    WebLabel3.AutoSize := False;
    WebLabel3.Caption := 'WebLabel2';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 4;
    WebLabel4.Top := 144;
    WebLabel4.Width := 350;
    WebLabel4.Height := 16;
    WebLabel4.Alignment := taCenter;
    WebLabel4.AutoSize := False;
    WebLabel4.Caption := '0';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 4;
    WebMemo1.Top := 30;
    WebMemo1.Width := 350;
    WebMemo1.Height := 73;
    WebMemo1.AutoSize := False;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
    WebTrackBar1.SetParentComponent(Self);
    WebTrackBar1.Name := 'WebTrackBar1';
    WebTrackBar1.Left := 4;
    WebTrackBar1.Top := 166;
    WebTrackBar1.Width := 350;
    WebTrackBar1.Height := 41;
    WebTrackBar1.ChildOrder := 5;
    WebTrackBar1.Max := 10;
    WebTrackBar1.Min := 0;
    WebTrackBar1.Position := 0;
    WebTrackBar1.Role := '';
    SetEvent(WebTrackBar1, Self, 'OnChange', 'WebTrackBar1Change');
    PreviousBtn.SetParentComponent(Self);
    PreviousBtn.Name := 'PreviousBtn';
    PreviousBtn.Left := 17;
    PreviousBtn.Top := 230;
    PreviousBtn.Width := 153;
    PreviousBtn.Height := 33;
    PreviousBtn.Caption := 'PreviousBtn';
    PreviousBtn.Color := clNone;
    PreviousBtn.HeightPercent := 100.000000000000000000;
    PreviousBtn.MaterialGlyph := 'navigate_before';
    PreviousBtn.TabOrder := 2;
    PreviousBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PreviousBtn, Self, 'OnClick', 'PreviousBtnClick');
    NextBtn.SetParentComponent(Self);
    NextBtn.Name := 'NextBtn';
    NextBtn.Left := 190;
    NextBtn.Top := 230;
    NextBtn.Width := 153;
    NextBtn.Height := 33;
    NextBtn.Caption := 'NextBtn';
    NextBtn.Color := clNone;
    NextBtn.HeightPercent := 100.000000000000000000;
    NextBtn.MaterialGlyph := 'navigate_next';
    NextBtn.TabOrder := 3;
    NextBtn.WidthPercent := 100.000000000000000000;
    SetEvent(NextBtn, Self, 'OnClick', 'NextBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 17;
    CancelBtn.Top := 269;
    CancelBtn.Width := 153;
    CancelBtn.Height := 33;
    CancelBtn.Caption := 'CancelBtn';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 4;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    SaveBtn.SetParentComponent(Self);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 190;
    SaveBtn.Top := 269;
    SaveBtn.Width := 153;
    SaveBtn.Height := 33;
    SaveBtn.Caption := 'SaveBtn';
    SaveBtn.Color := clNone;
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.MaterialGlyph := 'save';
    SaveBtn.TabOrder := 5;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebTrackBar1.AfterLoadDFMValues;
    PreviousBtn.AfterLoadDFMValues;
    NextBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
  end;
end;

end.