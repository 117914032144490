{

siehe auch How to build a login page with TMS WEB Core

https://www.youtube.com/watch?v=QfUCb3kRUYo

Eigenschaft     AutoCompletion setzen um ausfüllen von Browser für Username und Passwort zu enablen

Login Button

Google Material Icons pack

 }
unit Login2Faktor;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  XData.Web.Client, Vcl.Imaging.pngimage, WEBLib.Buttons, WEBLib.ExtCtrls;

type
  TLogin2FaktorForm = class(TForm)
    CodeLabel: TLabel;
    CodeEdit: TEdit;
    OKBtn: TBitBtn;
    CancelBtn: TBitBtn;
    SendAgainBtn: TBitBtn;
    WebMemo1: TMemo;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    function  CheckCode(s:string):boolean;
    procedure OKBtnClick(Sender: TObject);
    procedure OnCheckSecurityCodeResponse(Response:TXDataClientResponse);
    procedure OnSendSecurityCodeResponse(Response:TXDataClientResponse);
    procedure WebFormCreate(Sender: TObject);
    procedure SendAgainBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
    FormClosed : boolean;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  Login2FaktorForm: TLogin2FaktorForm;

implementation

uses Main, KVT, DatenServiceClasses, CommonFunctions,MyMsgDialog,Accept_AGB, MyQiuOverview,
     myQiuTestverwaltung,PasswortReset;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TLogin2FaktorForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;

{------------------------------------------------------------------------------}
procedure TLogin2FaktorForm.OnSendSecurityCodeResponse(Response:TXDataClientResponse);
  begin
   if FormClosed then exit;

   case byte(TJSObject(Response.Result)['value']) of
    0: begin
        showmessage(SP.Load(344));
       end;
    1: begin
        showmessage(SP.Load(343) + GLC_1Blank + inttostr(byte(TJSObject(Response.Result)['value'])));
       end;
    2: begin
        showmessage(SP.Load(343) + GLC_1Blank + inttostr(byte(TJSObject(Response.Result)['value'])));
       end;
    3: begin
        showmessage(SP.Load(343) + GLC_1Blank + inttostr(byte(TJSObject(Response.Result)['value'])));
       end;
   end;
 end;
 {------------------------------------------------------------------------------}
procedure TLogin2FaktorForm.OnCheckSecurityCodeResponse(Response:TXDataClientResponse);
 begin
   if FormClosed then exit;

   case byte(TJSObject(Response.Result)['value']) of
    0: begin
        GLV_AccountOK := true;
        GLV_UserLogin := true;
        MainForm.InitForm;
        close;
       end;
    1: begin
        showmessage('Invalid User');
        GLV_AccountOK := false;
        GLV_UserLogin := false;
       end;
    2: begin
        showmessage(SP.Load(345));
        GLV_AccountOK := false;
        GLV_UserLogin := false;
       end;
    3: begin
        showmessage(SP.Load(346));
        GLV_AccountOK := false;
        GLV_UserLogin := false;
       end;
   end;
  end;
{------------------------------------------------------------------------------}
procedure TLogin2FaktorForm.SendAgainBtnClick(Sender: TObject);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.SendSecurityCode',
        [GLV_AktUser.Token,GLV_AktUser.ID],@OnSendSecurityCodeResponse);
end;
{------------------------------------------------------------------------------}
function TLogin2FaktorForm.CheckCode(s:string):boolean;
var
 i: integer;
begin
 Result := false;
 if length(trim(s)) < 6 then exit;
 for i:= 1 to Length(s) do begin
  // illegal char - no valid address
  if not (s[i] in ['0'..'9']) then exit;
 end;
 Result:= True;
end;
{------------------------------------------------------------------------------}
procedure TLogin2FaktorForm.OKBtnClick(Sender: TObject);
begin
 if not CheckCode(trim(CodeEdit.Text)) then begin
  showmessage(SP.Load(342));
  exit;
 end;

 MainForm.XDataWebClient.RawInvoke('IDatenService.CheckSecurityCode',
        [GLV_AktUser.Token,GLV_AktUser.ID,trim(CodeEdit.Text)],@OnCheckSecurityCodeResponse);
end;
{------------------------------------------------------------------------------}
procedure TLogin2FaktorForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 FormClosed := true;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TLogin2FaktorForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TLogin2FaktorForm.WebFormShow(Sender: TObject);
var
 s,tmps : string;
begin
 OKBtn.Caption := SP.Load(24);
 CancelBtn.Caption := SP.Load(27);
 CodeLabel.Caption := SP.Load(339);
 case GLV_AktUser.LoginMode of
  1: begin
      s := SP.Load(341);
      tmps := EMailUnkenntlichmachen(trim(GLV_AktUser.Email));
      ReplacePlatzhalter(s,'@@@MAIL',tmps);
     end;
 end;
 WebMemo1.Lines.Clear;
 WebMemo1.Lines.Add(s);
 SendAgainBtn.Caption := SP.Load(340);
end;

procedure TLogin2FaktorForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  CodeLabel := TLabel.Create(Self);
  CodeEdit := TEdit.Create(Self);
  OKBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  SendAgainBtn := TBitBtn.Create(Self);
  WebMemo1 := TMemo.Create(Self);

  CodeLabel.BeforeLoadDFMValues;
  CodeEdit.BeforeLoadDFMValues;
  OKBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  SendAgainBtn.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  try
    Name := 'Login2FaktorForm';
    Width := 324;
    Height := 268;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    CodeLabel.SetParentComponent(Self);
    CodeLabel.Name := 'CodeLabel';
    CodeLabel.Left := 67;
    CodeLabel.Top := 21;
    CodeLabel.Width := 158;
    CodeLabel.Height := 16;
    CodeLabel.Caption := 'Sicherheits-Code eingeben:';
    CodeLabel.HeightPercent := 100.000000000000000000;
    CodeLabel.WidthPercent := 100.000000000000000000;
    CodeEdit.SetParentComponent(Self);
    CodeEdit.Name := 'CodeEdit';
    CodeEdit.Left := 59;
    CodeEdit.Top := 43;
    CodeEdit.Width := 174;
    CodeEdit.Height := 22;
    CodeEdit.AutoCompletion := acUserName;
    CodeEdit.ChildOrder := 1;
    CodeEdit.ElementClassName := '.form-control-static';
    CodeEdit.HeightPercent := 100.000000000000000000;
    CodeEdit.WidthPercent := 100.000000000000000000;
    OKBtn.SetParentComponent(Self);
    OKBtn.Name := 'OKBtn';
    OKBtn.Left := 48;
    OKBtn.Top := 161;
    OKBtn.Width := 96;
    OKBtn.Height := 30;
    OKBtn.Caption := 'Login';
    OKBtn.Color := clNone;
    OKBtn.HeightPercent := 100.000000000000000000;
    OKBtn.MaterialGlyph := 'lock';
    OKBtn.TabOrder := 2;
    OKBtn.WidthPercent := 100.000000000000000000;
    SetEvent(OKBtn, Self, 'OnClick', 'OKBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 150;
    CancelBtn.Top := 161;
    CancelBtn.Width := 96;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 3;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    SendAgainBtn.SetParentComponent(Self);
    SendAgainBtn.Name := 'SendAgainBtn';
    SendAgainBtn.Left := 48;
    SendAgainBtn.Top := 197;
    SendAgainBtn.Width := 198;
    SendAgainBtn.Height := 30;
    SendAgainBtn.Caption := 'Code erneut zusenden';
    SendAgainBtn.Color := clNone;
    SendAgainBtn.HeightPercent := 100.000000000000000000;
    SendAgainBtn.MaterialGlyph := 'mail';
    SendAgainBtn.TabOrder := 3;
    SendAgainBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SendAgainBtn, Self, 'OnClick', 'SendAgainBtnClick');
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 8;
    WebMemo1.Top := 72;
    WebMemo1.Width := 305;
    WebMemo1.Height := 73;
    WebMemo1.AutoSize := False;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.WidthPercent := 100.000000000000000000;
  finally
    CodeLabel.AfterLoadDFMValues;
    CodeEdit.AfterLoadDFMValues;
    OKBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    SendAgainBtn.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
  end;
end;

end.