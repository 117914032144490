{

siehe auch How to build a login page with TMS WEB Core

https://www.youtube.com/watch?v=QfUCb3kRUYo

Eigenschaft     AutoCompletion setzen um ausfüllen von Browser für Username und Passwort zu enablen

Login Button

Google Material Icons pack

 }
unit Login;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  XData.Web.Client, Vcl.Imaging.pngimage, WEBLib.Buttons, WEBLib.ExtCtrls;

type
  TLoginForm = class(TForm)
    UsernameLabel: TLabel;
    UsernameEdit: TEdit;
    PasswortLabel: TLabel;
    PasswortEdit: TEdit;
    OKBtn: TBitBtn;
    CancelBtn: TBitBtn;
    WebLabel1: TLabel;
    PasswortResetBtn: TBitBtn;
    CloseTimer: TTimer;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    procedure OKBtnClick(Sender: TObject);
    procedure OnLoginResponse(Response:TXDataClientResponse);
    procedure AskForSendVerificationEMailAgain;
    procedure SendVerificationEMail;
    function  ShowDGSVO:boolean;
    procedure PasswortLabelDblClick(Sender: TObject);
    procedure WebLabel1DblClick(Sender: TObject);
    procedure AutoLogin(_User,_Password:string);
    procedure WebFormCreate(Sender: TObject);
    procedure PasswortResetBtnClick(Sender: TObject);
    procedure CloseTimerTimer(Sender: TObject);
  private
    { Private-Deklarationen }
    FormClosed : boolean;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  LoginForm: TLoginForm;

implementation

uses Main, KVT, DatenServiceClasses, CommonFunctions,MyMsgDialog,Accept_AGB, MyQiuOverview,
     myQiuTestverwaltung,PasswortReset,Login2Faktor;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TLoginForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.CloseTimerTimer(Sender: TObject);
begin
 CloseTimer.Enabled := false;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.OnLoginResponse(Response:TXDataClientResponse);
 var
   LObj    : TJSObject;
   s       : string;

  begin
   if FormClosed then exit;
   GLV_AccountOK := true;
   LObj := TJSObject(Response.Result);

   if string(LObj['Token']) = '1' then begin
    s := SP.load(14);//'Benutzer ' + trim(UsernameEdit.Text) + '  nicht gefunden'
    ReplacePlatzhalter(s,'@@@USER',trim(UsernameEdit.Text));
    showmessage(s);
    GLV_AccountOK := false;
    GLV_UserLogin := false;
    exit;
   end;
   if string(LObj['Token']) = '2' then begin
    showmessage(SP.load(15));//'Passwort falsch');
    GLV_AccountOK := false;
    GLV_UserLogin := false;
    exit;
   end;

   GLV_AktUser.Set_TWebUser_From_WebUserJSObject(LObj);

   // Zugang noch nicht verifiziert anhand EMail mit LInk
   if (not GLV_AktUser.Verifiziert) then begin
    showmessage(SP.load(16));//'Ihr myQiu Zugang wurde noch nicht aktiviert! Bitte klicken Sie auf den Link in der E-Mail, die an die von Ihnen bei der Anmeldung angegebene E-Mail Adresse gesendet wurde.');
    AskForSendVerificationEMailAgain;
    GLV_AccountOK := false;
    exit;
   end;
   // Zugang gesperrt
   if (GLV_AktUser.Abostatus = 2) then begin
    showmessage(SP.load(17));//'Ihr myQiu Zugang wurde gesperrt! Bitte wenden Sie sich an BioSign (www.biosign.de).');
    GLV_AccountOK := false;
    exit;
   end;
   // Abo abgelaufen
   if (GLV_AktUser.Abostatus = 1) and (MyDateFormatToTDate(GLV_AktUser.ValidUntil) < now) then begin
    showmessage(SP.load(18));//'Der abonnierte Zeitraum für myQiu ist abgelaufen! Bitte verlängern Sie Ihren Zugang  in "myQiu Account verwalten".');
    GLV_AccountOK := false;
   end;
   // Demo abgelaufen
   if (GLV_AktUser.Abostatus = 0) and (MyDateFormatToTDate(GLV_AktUser.ValidUntil) < now) then begin
    showmessage(SP.load(19));//'Der Demozeitraum für myQiu ist abgelaufen! Bitte verlängern Sie Ihren Zugang in "myQiu Account verwalten".');
    GLV_AccountOK := false;
   end;
   // Datenschutzerklärung generell oder aktualisiert zustimmen
   if (not GLV_AktUser.DGSVO_OK) then begin
    GLV_AccountOK := false;
    ShowDGSVO;
    exit;
   end;

   if string(LObj['Token']) = '3' then begin
    showmessage('Code konnte nicht verschickt werden'); //@@
    GLV_AccountOK := false;
    GLV_UserLogin := false;
    exit;
   end;


   case GLV_AktUser.LoginMode of
    0: begin   // Login Username Passwort
        GLV_UserLogin  := true;
        GLV_AppSprache := GLV_AktUser.Sprache;
        MainForm.InitForm;
        close;
       end;
    1: begin //Zwei Faktor Login
        GLV_UserLogin  := false;
        GLV_AppSprache := GLV_AktUser.Sprache;
        MainForm.InitForm;
        close;
       end;
   end;

  end;
{------------------------------------------------------------------------------}
procedure TLoginForm.OKBtnClick(Sender: TObject);
begin
 MainForm.XDataWebClient.RawInvoke('ILoginService.UserLogin',
        [trim(UsernameEdit.Text),trim(PasswortEdit.Text)],@OnLoginResponse);
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.AutoLogin(_User,_Password:string);
begin
 MainForm.XDataWebClient.RawInvoke('ILoginService.UserLogin',
        [trim(_User),trim(_Password)],@OnLoginResponse);
end;
{------------------------------------------------------------------------------}
function TLoginForm.ShowDGSVO:boolean;
var
 Accept_AGB_Form : TAccept_AGB_Form;
 procedure AfterShowModal(AValue: TModalResult);
  begin
   if Accept_AGB_Form.All_Accepted then begin
    GLV_UserLogin  := true;
    GLV_AppSprache := GLV_AktUser.Sprache;
    close;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;
begin
 result := true;
 Accept_AGB_Form := TAccept_AGB_Form.CreateNew(@AfterCreate);
 Accept_AGB_Form.Border := fbDialog;
 Accept_AGB_Form.Popup := true;
 Accept_AGB_Form.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.AskForSendVerificationEMailAgain;
var
 MyMessageDlgForm : TMyMessageDlgForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   case MyMessageDlgForm._Result of
    drYes : SendVerificationEMail;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;

begin
 MyMessageDlgForm := TMyMessageDlgForm.CreateNew(@AfterCreate);
 MyMessageDlgForm.Border := fbSingle;
 MyMessageDlgForm.Popup := true;
 MyMessageDlgForm.Titel := SP.load(20);//'Bestätigen';
 MyMessageDlgForm.MessageText := SP.load(21);//'Verifizierungs-Mail erneut zusenden?';
 MyMessageDlgForm.DialogType := dtYesNo;
 MyMessageDlgForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.SendVerificationEMail;
 procedure OnResponse(Response:TXDataClientResponse);
  begin
   case byte(TJSObject(Response.Result)['value']) of
    0 : showmessage(SP.load(22));//'Die Aktivierungs-Mail für Ihren Account wurde an die angegebene E-Mail Adresse versendet!');
    1 : showmessage(SP.load(23));//'Beim Versenden der Akitiverungsmail ist ein Fehler aufgetreten.');
   end;
  end;

begin
  MainForm.XDataWebClient.RawInvoke('IDatenService.SendVerificationEMail',
        [GLV_AktUser.Token,GLV_AktUser.ID],@OnResponse);
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.PasswortLabelDblClick(Sender: TObject);
begin
  //
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.PasswortResetBtnClick(Sender: TObject);
var
 PasswortResetForm : TPasswortResetForm;
begin
 PasswortResetForm          := TPasswortResetForm.CreateNew;
 PasswortResetForm.caption  := SP.load(294);
 PasswortResetForm.Border   := fbDialog;
 PasswortResetForm.Popup    := true;
 PasswortResetForm.ShowModal;
 CloseTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 FormClosed := true;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TLoginForm.WebFormShow(Sender: TObject);
begin
 OKBtn.Caption := SP.Load(24);
 CancelBtn.Caption := SP.Load(27);
 UsernameLabel.Caption := SP.Load(28);
 PasswortLabel.Caption := SP.Load(29);
 PasswortResetBtn.Caption := SP.Load(294);//'Passwort vergessen';

 if GLV_AutoLogin then begin
  GLV_AutoLogin := false;
  AutoLogin(GLV_User,GLV_Password);
 end else begin
  if GLC_LocalHost then begin
   UsernameEdit.Text := 'hofix2004';
   PasswortEdit.Text := 'Landwind001';
   //OKBtnClick(Self);
  end;
 end;

end;
{------------------------------------------------------------------------------}
procedure TLoginForm.WebLabel1DblClick(Sender: TObject);
begin
 UsernameEdit.Text := 'hofix2004';
 PasswortEdit.Text := 'Landwind001';
 //UsernameEdit.Text := 'Demo-User';
 //PasswortEdit.Text := 'InternalUse001';
 //UsernameEdit.Text := 'Reinhard';
 //PasswortEdit.Text := '12345678';
 OKBtnClick(Self);
end;

procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  UsernameLabel := TLabel.Create(Self);
  PasswortLabel := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  UsernameEdit := TEdit.Create(Self);
  PasswortEdit := TEdit.Create(Self);
  OKBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  PasswortResetBtn := TBitBtn.Create(Self);
  CloseTimer := TTimer.Create(Self);

  UsernameLabel.BeforeLoadDFMValues;
  PasswortLabel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  UsernameEdit.BeforeLoadDFMValues;
  PasswortEdit.BeforeLoadDFMValues;
  OKBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  PasswortResetBtn.BeforeLoadDFMValues;
  CloseTimer.BeforeLoadDFMValues;
  try
    Name := 'LoginForm';
    Width := 228;
    Height := 241;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    UsernameLabel.SetParentComponent(Self);
    UsernameLabel.Name := 'UsernameLabel';
    UsernameLabel.Left := 27;
    UsernameLabel.Top := 29;
    UsernameLabel.Width := 82;
    UsernameLabel.Height := 16;
    UsernameLabel.Caption := 'Benutzername';
    UsernameLabel.HeightPercent := 100.000000000000000000;
    UsernameLabel.WidthPercent := 100.000000000000000000;
    PasswortLabel.SetParentComponent(Self);
    PasswortLabel.Name := 'PasswortLabel';
    PasswortLabel.Left := 27;
    PasswortLabel.Top := 76;
    PasswortLabel.Width := 52;
    PasswortLabel.Height := 16;
    PasswortLabel.Caption := 'Passwort';
    PasswortLabel.HeightPercent := 100.000000000000000000;
    PasswortLabel.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 167;
    WebLabel1.Top := 8;
    WebLabel1.Width := 53;
    WebLabel1.Height := 25;
    WebLabel1.AutoSize := False;
    WebLabel1.Caption := 'Test';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindow;
    WebLabel1.Font.Height := -13;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel1, Self, 'OnDblClick', 'WebLabel1DblClick');
    UsernameEdit.SetParentComponent(Self);
    UsernameEdit.Name := 'UsernameEdit';
    UsernameEdit.Left := 27;
    UsernameEdit.Top := 48;
    UsernameEdit.Width := 153;
    UsernameEdit.Height := 22;
    UsernameEdit.AutoCompletion := acUserName;
    UsernameEdit.ChildOrder := 1;
    UsernameEdit.ElementClassName := '.form-control-static';
    UsernameEdit.HeightPercent := 100.000000000000000000;
    UsernameEdit.WidthPercent := 100.000000000000000000;
    PasswortEdit.SetParentComponent(Self);
    PasswortEdit.Name := 'PasswortEdit';
    PasswortEdit.Left := 27;
    PasswortEdit.Top := 101;
    PasswortEdit.Width := 153;
    PasswortEdit.Height := 22;
    PasswortEdit.AutoCompletion := acCurrentPassword;
    PasswortEdit.ChildOrder := 4;
    PasswortEdit.ElementClassName := '.form-control-static';
    PasswortEdit.ElementFont := efCSS;
    PasswortEdit.HeightPercent := 100.000000000000000000;
    PasswortEdit.PasswordChar := '*';
    PasswortEdit.WidthPercent := 100.000000000000000000;
    OKBtn.SetParentComponent(Self);
    OKBtn.Name := 'OKBtn';
    OKBtn.Left := 8;
    OKBtn.Top := 137;
    OKBtn.Width := 96;
    OKBtn.Height := 30;
    OKBtn.Caption := 'Login';
    OKBtn.Color := clNone;
    OKBtn.HeightPercent := 100.000000000000000000;
    OKBtn.MaterialGlyph := 'lock';
    OKBtn.TabOrder := 2;
    OKBtn.WidthPercent := 100.000000000000000000;
    SetEvent(OKBtn, Self, 'OnClick', 'OKBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 110;
    CancelBtn.Top := 137;
    CancelBtn.Width := 96;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 3;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    PasswortResetBtn.SetParentComponent(Self);
    PasswortResetBtn.Name := 'PasswortResetBtn';
    PasswortResetBtn.Left := 8;
    PasswortResetBtn.Top := 173;
    PasswortResetBtn.Width := 198;
    PasswortResetBtn.Height := 30;
    PasswortResetBtn.Caption := 'Passwort vergessen';
    PasswortResetBtn.Color := clNone;
    PasswortResetBtn.HeightPercent := 100.000000000000000000;
    PasswortResetBtn.TabOrder := 4;
    PasswortResetBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PasswortResetBtn, Self, 'OnClick', 'PasswortResetBtnClick');
    CloseTimer.SetParentComponent(Self);
    CloseTimer.Name := 'CloseTimer';
    CloseTimer.Enabled := False;
    SetEvent(CloseTimer, Self, 'OnTimer', 'CloseTimerTimer');
    CloseTimer.Left := 192;
    CloseTimer.Top := 72;
  finally
    UsernameLabel.AfterLoadDFMValues;
    PasswortLabel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    UsernameEdit.AfterLoadDFMValues;
    PasswortEdit.AfterLoadDFMValues;
    OKBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    PasswortResetBtn.AfterLoadDFMValues;
    CloseTimer.AfterLoadDFMValues;
  end;
end;

end.