unit DatenServiceClasses;

interface
uses DBFunctions,System.SysUtils,JS;

const
 MyDateFormat = 'ddmmyyyy';
 MyDateTimeFormat = 'ddmmyyyyhhnnsszzz';

type
 {Groß- Kleinschreibung für JSON: Erster Buchstabe klein, dann wird klein groß berücksichtigt
  FEMail -> in JSON [eMail)}

  TUser = class
  public
   Token             : string;
   ID                : integer;
   UUID              : string;
   Username          : string;
   Password          : string;
   Email             : string;
   Anrede            : byte;
   Titel             : string;
   Name              : string;
   Vorname           : string;
   Geboren           : string;
   Geschlecht        : integer;
   Groesse           : integer;
   Sprache           : byte;
   Therapeut1_UUID   : string;
   Therapeut2_UUID   : string;
   SetAutoTimezone   : boolean;
   UTC_Hour          : integer;
   UTC_Minute        : integer;
   Newsletter        : boolean;
   ValidUntil        : string;
   LastCode          : integer;
   Abostatus         : integer;
   Abotyp            : integer;
   Verifiziert       : boolean;
   BfB_3D            : boolean;
   OnlineSpektrum    : boolean;
   Rhythmisierung    : boolean;
   RSA_Test          : boolean;
   KurzzeitHRV       : boolean;
   DGSVO_OK          : boolean;
   QiuSerial1        : string;
   QiuSerial2        : string;
   QiuSerial3        : string;
   ShowReportMode    : integer;
   LoginMode         : integer;
   constructor Create;
   procedure Init;
   procedure Set_TWebUser_From_TMyQiuUserRec(_MyQiuUserRec: TMyQiuUserRec);
   procedure Set_TMyQiuUserRec_From_TWebUser(var _MyQiuUserRec: TMyQiuUserRec);
   procedure Set_TWebUser_From_WebUserJSObject(WebUserJSObject:TJSObject);
   procedure Set_TWebUserJSObject_From_TWebUser(var WebUserJSObject:TJSObject);
  end;

  TTherapeut = class
   public
    ID                : integer;
    UUID              : string;
    Anrede            : string;
    Titel             : string;
    Name              : string;
    Vorname           : string;
    Institution       : string;
    Strasse           : string;
    PLZ               : string;
    Stadt             : string;
    Land              : string;
    constructor Create(_TherapeutRec: TMyQiuTherapeutRec);
  end;

  TMessungID = class
  public
   TestID   : string;
  end;

  TMessung = class
  public
   TestID    : string;
   Typ       : string;
   Datum     : string;
   Dauer     : integer;
   Kommentar : string;
   TypFragebogen : byte;
   BezeichnungFragebogen  : string;
   constructor Create;
   procedure Init;
  end;

  TLizenzCodeActivateRec = class
   public
    ReqResult    : byte;
    ValidUntil   : string;
    LastCode     : integer;
  end;

  TSchedulerItemClass = class
  public
   TYP       : string;       // BFB,QIU,PCBFB,RSA,KURZHRV,BP,GLU,WEIGHT,SONST
   MODE      : string;       // DAILY,WEEKLY,MONTHLY
   Zeit      : string;
   WochenTag : string;       // MO,DI,MI...
   MonatsTag : string;       // 1,2,3,4,5...29!! nicht 30,31
   TextFeld  : string;
   ID        : integer;
   Remember  : boolean;
  end;

  TSchedulerClass = class
   public
   isBasis   : boolean;
   ValidDate : string;
   Bez       : string;
   ID        : integer;
  end;

  TBiomarkerItemClass = class
   public
   TYP          : String;       // BP,WEIGHT;BZ
   Zeit         : String;
   Sys          : single;
   Dia          : single;
   HF           : single;
   BZmmol       : single;
   BZmg         : single;
   Gewicht      : single;
   BMI          : single;
   Bauch        : single;
   Hip          : single;
   WHR          : single;
   ID           : integer;
   Comment      : string;
   constructor Create;
   procedure Init;
  end;

  TmyQiuAppUserClass = class   //aktuell mit Fragebogen                //@@kann irgendwann weg
  public
   Token             : string;
   ID                : integer;
   UUID              : string;
   Username          : string;
   Password          : string;
   Email             : string;
   Anrede            : byte;
   Titel             : string;
   Name              : string;
   Vorname           : string;
   Geboren           : string;
   Geschlecht        : integer;
   Groesse           : integer;
   Sprache           : byte;
   Therapeut1_UUID   : string;
   Therapeut2_UUID   : string;
   SetAutoTimezone   : boolean;
   UTC_Hour          : integer;
   UTC_Minute        : integer;
   Newsletter        : boolean;
   ValidUntil        : string;
   LastCode          : integer;
   Abostatus         : integer;
   Abotyp            : integer;
   Verifiziert       : boolean;
   BfB_3D            : boolean;
   OnlineSpektrum    : boolean;
   Rhythmisierung    : boolean;
   RSA_Test          : boolean;
   KurzzeitHRV       : boolean;
   DGSVO_OK          : boolean;
   Protokoll         : integer;
   QiuSerial1        : string;
   QiuSerial2        : string;
   QiuSerial3        : string;
   LoginMode         : integer;
   Q_StandAlone      : byte;
   Q_VorRSA          : byte;
   Q_NachRSA         : byte;
   Q_VorKHRV         : byte;
   Q_NachKHRV        : byte;
   Q_VorBFB          : byte;
   Q_NachBFB         : byte;
   constructor Create;
   procedure Init;
  end;

  TmyQiuAppUserClass2 = class           //@@kann irgendwann weg
  public
   Token             : string;
   ID                : integer;
   UUID              : string;
   Username          : string;
   Password          : string;
   Email             : string;
   Anrede            : byte;
   Titel             : string;
   Name              : string;
   Vorname           : string;
   Geboren           : string;
   Geschlecht        : integer;
   Groesse           : integer;
   Sprache           : byte;
   Therapeut1_UUID   : string;
   Therapeut2_UUID   : string;
   SetAutoTimezone   : boolean;
   UTC_Hour          : integer;
   UTC_Minute        : integer;
   Newsletter        : boolean;
   ValidUntil        : string;
   LastCode          : integer;
   Abostatus         : integer;
   Abotyp            : integer;
   Verifiziert       : boolean;
   BfB_3D            : boolean;
   OnlineSpektrum    : boolean;
   Rhythmisierung    : boolean;
   RSA_Test          : boolean;
   KurzzeitHRV       : boolean;
   DGSVO_OK          : boolean;
   Protokoll         : integer;
   QiuSerial1        : string;
   QiuSerial2        : string;
   QiuSerial3        : string;
   ShowReportMode    : integer;
   LoginMode         : integer;

   constructor Create;
   procedure Init;
  end;

  TmyQiuAppUserClass3 = class
  public
   Token             : string;
   ID                : integer;
   UUID              : string;
   Username          : string;
   Password          : string;
   Email             : string;
   Name              : string;
   Vorname           : string;
   Geboren           : string;
   Geschlecht        : integer;
   Groesse           : integer;
   Sprache           : byte;
   Therapeut1_UUID   : string;
   Therapeut2_UUID   : string;
   SetAutoTimezone   : boolean;
   UTC_Hour          : integer;
   UTC_Minute        : integer;
   Newsletter        : boolean;
   ValidUntil        : string;
   LastCode          : integer;
   Abostatus         : integer;
   Abotyp            : integer;
   Verifiziert       : boolean;
   BfB_3D            : boolean;
   OnlineSpektrum    : boolean;
   Rhythmisierung    : boolean;
   RSA_Test          : boolean;
   KurzzeitHRV       : boolean;
   DGSVO_OK          : boolean;
   Protokoll         : integer;
   QiuSerial1        : string;
   QiuSerial2        : string;
   QiuSerial3        : string;
   ShowReportMode    : integer;
   LoginMode         : integer;
   Q_StandAlone      : byte;
   Q_VorRSA          : byte;
   Q_NachRSA         : byte;
   Q_VorKHRV         : byte;
   Q_NachKHRV        : byte;
   Q_VorBFB          : byte;
   Q_NachBFB         : byte;
   LastBfB           : string;
   LastRSA           : string;
   LastKHRV          : string;
   LastQiu           : string;
   NoOfBfB           : integer;
   NoOfRSA           : integer;
   NoOfKHRV          : integer;
   NoOfQiu           : integer;
   constructor Create;
   procedure Init;
  end;



  TmyQiuFileUploadedResultClass = class
  public
   UploadResult    : integer;     // 0= alles o.k. 1 = Datei existiert nicht 2 = Dateigröße passt nicht  3 = Integration gescheitert
   AuswertenResult : integer;     // -1 = noch keine Auswertung gestartet 0= alles o.k.
   PDFLink         : string;
   constructor Create;
   procedure Init;
  end;

  TmyQiuAuswertungResultClass = class
  public
   Result     : integer;
   PDFLink    : string;
   constructor Create;
   procedure Init;
  end;

  TmyQiuFileUploadedOnlyResult = class
  public
   UploadResult    : integer;
   MessungID       : string;
   constructor Create;
   procedure Init;
  end;

  TGlobalTestDescription = class
  public
   Result          : integer;
   OS              : string;
   OSVersion       : string;
   Device          : string;
   Hardware        : string;
   constructor Create;
   procedure Init;
  end;

  TGetBfBVorgabeWertClass = class
  public
   Result                 : integer; //-1 Error
   VorgabeLow             : integer;
   VorgabeHigh            : integer;
   AnzahlRSA              : integer;
   constructor Create;
   procedure Init;
  end;

  TGetCurrentAppInfo = class
  public
   Version                 : integer;
   Link                    : string;
   VersioniOS              : integer;
   LinkiOS                 : string;
   VersionFirmware         : integer;
   LinkFirmware            : string;
   constructor Create;
   procedure Init;
  end;

  TFragenAntwortenItemClass = class
   public
   ID       : string;
   Zeit     : byte;        // 0 = Vor Test, 1 = NachTest, 2 := Standalone
   Text     : string;
   Typ      : byte;        //0 = bft_TrackBarValue  1=fbft_MultipleChoiceSingle  2=fbft_MultipleChoiceMultiple
   Wert     : integer;
   Min      : integer;
   Max      : integer;
   Steps    : integer;
   BezMin   : string;
   BezMax   : string;
   MC1_Text : string;
   MC1_Value: integer;
   MC2_Text : string;
   MC2_Value: integer;
   MC3_Text : string;
   MC3_Value: integer;
   MC4_Text : string;
   MC4_Value: integer;
   MC5_Text : string;
   MC5_Value: integer;
   constructor Create;
   procedure Init;
  end;

  TFragebogenClass = class
   public
    ID          : string;
    NoOfItems   : integer;
    Bezeichnung : string;
    Aktiv       : boolean;
   constructor Create;
   procedure Init;
  end;

  TFragebogenItemClass = class
   public
    ID        : string;
    Rang      : integer;
    Text      : string;
    Typ       : integer;
    Min       : integer;
    Max       : integer;
    Steps     : integer;
    BezMin    : string;
    BezMax    : string;
    MC_Text1  : string;   //multiple Choice Antwort 1
    MC_Value1 : integer;
    MC_Text2  : string;   //multiple Choice Antwort 2
    MC_Value2 : integer;
    MC_Text3  : string;   //multiple Choice Antwort 3
    MC_Value3 : integer;
    MC_Text4  : string;   //multiple Choice Antwort 4
    MC_Value4 : integer;
    MC_Text5  : string;   //multiple Choice Antwort 5
    MC_Value5 : integer;
   constructor Create;
   procedure Init;
  end;

  TBfBSettingsClass = class
   Aktiv                    : integer;    // Einstellungen aktiv
   Sitzungsdauer            : integer;
   Atem_Freq                : integer;
   Atem_In                  : integer;
   Atem_Out                 : integer;
   Atem_Profi               : integer;
   Atem_Profi_Ein           : integer;
   Atem_Profi_Ein_Pause     : integer;
   Atem_Profi_Aus           : integer;
   Atem_Profi_Aus_Pause     : integer;
   Vorgabewert              : integer;
   constructor Create;
   procedure Init;
  end;

  TQiuSettingClass = class
   Aktiv                    : integer;
   CohLevel                 : integer;
   Helligkeit               : integer;
   Dauer                    : integer;
   Atem_Typ                 : integer;
   Atem_Ein                 : integer;
   Atem_Ein_Pause           : integer;
   Atem_Aus                 : integer;
   Atem_Aus_Pause           : integer;
   LastChange               : string;
   constructor Create;
   procedure Init;
  end;


  TFirmwareFileClass = class
   public
    Version     : integer;
    Dateiname   : string;
    Datum       : string;
   constructor Create;
   procedure Init;
  end;


  TAppRSAAuswertungResultClass = class
   Typ                    : string;
   Datum                  : string;
   AktR_Tonus             : string;   // Rang
   AktR_Flexibilitaet     : string;   // Rang
   AktR_Dynamik           : string;   // Rang
   VorwertR_Tonus         : string;   // Rang
   VorwertR_Flexibilitaet : string;   // Rang
   VorwertR_Dynamik       : string;   // Rang
   Akt_RMSSD              : string;   // Wert
   Akt_MEAN               : string;   // Wert
   Akt_RSADIFF2           : string;   // Wert
   Vorwert_RMSSD          : string;   // Wert
   Vorwert_MEAN           : string;   // Wert
   Vorwert_RSADIFF2       : string;   // Wert
   NoOfVorwerte           : string;
   Messqualitaet_Text     : string;
   Messqualitaet_Value    : string;
   Interpretation1        : string;
   Interpretation2        : string;
   HFList                 : string;
   Result                 : integer;
   UploadResult           : integer;
   constructor Create;
   procedure Init;
  end;

  TAppKURZHRVAuswertungResultClass = class
   Typ                    : string;
   Datum                  : string;
   AktR_Tonus              : string; // Rang
   AktR_Flexibilitaet      : string; // Rang
   AktR_Dynamik1           : string; // Rang
   AktR_Dynamik2           : string; // Rang
   AktR_Stressindex        : string; // Rang
   VorwertR_Tonus          : string; // Rang
   VorwertR_Flexibilitaet  : string; // Rang
   VorwertR_Dynamik1       : string; // Rang
   VorwertR_Dynamik2       : string; // Rang
   VorwertR_Stressindex    : string; // Rang
   Akt_MEAN                : string;
   Akt_VKRR                : string;
   Akt_SD1                 : string;
   Akt_SD2                 : string;
   Akt_STRESSINDEX         : string;
   Vorwert_MEAN            : string;
   Vorwert_VKRR            : string;
   Vorwert_SD1             : string;
   Vorwert_SD2             : string;
   Vorwert_STRESSINDEX     : string;
   NoOfVorwerte            : string;
   PatAge                  : string;
   Messqualitaet_Text      : string;
   Messqualitaet_Value     : string;
   Interpretation1         : string;
   HFList                  : string;
   Result                  : integer;
   UploadResult            : integer;
   constructor Create;
   procedure Init;
  end;

  TAppBfBAuswertungResultClass = class
   Typ                     : string;
   Datum                   : string;
   Vorgabewert             : string;
   Dauer                   : string;
   CohRel                  : string;
   COH_LOW                 : string;
   COH_MED                 : string;
   COH_HIGH                : string;
   COH_MAX                 : string;
   COH_MEAN                : string;
   BFBERFOLG               : string;
   Messqualitaet_Text      : string;
   Messqualitaet_Value     : string;
   BfbResultText           : string;
   HFCOHList               : string;
   Result                  : integer;
   UploadResult            : integer;
   constructor Create;
   procedure Init;
  end;

  TAppGetMessungsListeResultClass = class
   Result                  : integer;
   Count                   : integer;
   Liste                   : string;
   constructor Create;
   procedure Init;
  end;

  TMessungComment = class
  public
   Comment   : string;
   UserUUID  : string;
   MessungID : string ;
   constructor Create;
   procedure Init;
  end;


  function MyDateFormatToTDate(_MyDateFormat:string):TDate;
  function MyTDateFormatToString(_Date:TDateTime):string;
  function MyDateTimeFormatToTDateTime(_MyDateTimeFormat:string):TDateTime;
  function MyTDateTimeFormatToString(_DateTIme:TDateTime):string;


implementation

uses KVT;
{------------------------------------------------------------------------------}
function MyDateFormatToTDate(_MyDateFormat:string):TDate;
var
 dd,mm,yyyy : word;
begin
 dd := strtoint(copy(_MyDateFormat,1,2));
 mm := strtoint(copy(_MyDateFormat,3,2));
 yyyy := strtoint(copy(_MyDateFormat,5,4));
 result := encodedate(yyyy,mm,dd);
end;
{------------------------------------------------------------------------------}
function MyTDateFormatToString(_Date:TDateTime):string;
begin
 result := formatDateTime(MyDateFormat,_Date);
end;
{------------------------------------------------------------------------------}
function MyDateTimeFormatToTDateTime(_MyDateTimeFormat:string):TDateTime;
var
 dd,mm,yyyy : word;
 hh,nn,ss,zzz:word;
begin
 dd := strtoint(copy(_MyDateTimeFormat,1,2));
 mm := strtoint(copy(_MyDateTimeFormat,3,2));
 yyyy := strtoint(copy(_MyDateTimeFormat,5,4));

 hh := strtoint(copy(_MyDateTimeFormat,9,2));
 nn := strtoint(copy(_MyDateTimeFormat,11,2));
 ss := strtoint(copy(_MyDateTimeFormat,13,2));
 zzz := strtoint(copy(_MyDateTimeFormat,15,3));

 result := encodedate(yyyy,mm,dd) + encodetime(hh,nn,ss,zzz);
end;
{------------------------------------------------------------------------------}
function MyTDateTimeFormatToString(_DateTime:TDateTime):string;
begin
 result := formatDateTime(MyDateTimeFormat,_DateTime);
end;
{------------------------------------------------------------------------------}

{ TWebUser }
{------------------------------------------------------------------------------}
constructor TUser.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TUser.Init;
begin
  Token           := '';
  ID              := 0;
  UUID            := '';
  Username        := '';
  Password        := '';
  Email           := '';
  Anrede          := 0;
  Titel           := '';
  Name            := '';
  Vorname         := '';
  Geboren         := '01011900';
  Geschlecht      := 0;
  Groesse         := 0;
  Sprache         := 0;
  Therapeut1_UUID := '';
  Therapeut2_UUID := '';
  Setautotimezone := true;
  UTC_Hour        := 0;
  UTC_Minute      := 0;
  Newsletter      := true;
  ValidUntil      := '01011900';
  LastCode        := 0;
  Abostatus       := 0;
  Abotyp          := 0;
  Verifiziert     := false;
  BfB_3D          := true;
  OnlineSpektrum  := true;
  Rhythmisierung  := true;
  RSA_Test        := true;
  KurzzeitHRV     := true;
  DGSVO_OK        := false;
  QiuSerial1      := '';
  QiuSerial2      := '';
  QiuSerial3      := '';
  ShowReportMode  := 0;
  LoginMode       := 0;
end;
{------------------------------------------------------------------------------}
procedure TUser.Set_TWebUser_From_TMyQiuUserRec(_MyQiuUserRec: TMyQiuUserRec);
begin
 Token            := '';
 ID               := _MyQiuUserRec.ID;
 UUID             := _MyQiuUserRec.UUID;
 Username         := _MyQiuUserRec.Username;
 Password         := _MyQiuUserRec.Password;
 Email            := _MyQiuUserRec.eMail;
 Anrede           := ord(_MyQiuUserRec.Anrede);
 Titel            := _MyQiuUserRec.Titel;
 Name             := _MyQiuUserRec.Name;
 Vorname          := _MyQiuUserRec.Vorname;
 Geboren          := MyTDateFormatToString(_MyQiuUserRec.Geboren);
 Geschlecht       := _MyQiuUserRec.Geschlecht;
 Groesse          := _MyQiuUserRec.Groesse;
 Sprache          := ord(_MyQiuUserRec.Sprache);
 Therapeut1_uuid  := _MyQiuUserRec.Therapeut1_UUID;
 Therapeut2_uuid  := _MyQiuUserRec.Therapeut2_UUID;
 Setautotimezone  := _MyQiuUserRec.SetAutoTimezone;
 UTC_Hour         := _MyQiuUserRec.UTC_Hour;
 UTC_Minute       := _MyQiuUserRec.UTC_Minute;
 Newsletter       := _MyQiuUserRec.Newsletter;
 ValidUntil       := MyTDateFormatToString(_MyQiuUserRec.ValidUntil);
 LastCode         := _MyQiuUserRec.LastCode;
 Abostatus        := ord(_MyQiuUserRec.Abostatus);
 Abotyp           := ord(_MyQiuUserRec.Abotyp);
 Verifiziert      := _MyQiuUserRec.Verifiziert;
 BfB_3D           := _MyQiuUserRec.BfB_3D;
 OnlineSpektrum   := _MyQiuUserRec.OnlineSpektrum;
 Rhythmisierung   := _MyQiuUserRec.Rhythmisierung;
 RSA_Test         := _MyQiuUserRec.RSA_Test;
 KurzzeitHRV      := _MyQiuUserRec.KurzzeitHRV;
 DGSVO_OK         := false;
 QiuSerial1       := _MyQiuUserRec.QiuSerial1;
 QiuSerial2       := _MyQiuUserRec.QiuSerial2;
 QiuSerial3       := _MyQiuUserRec.QiuSerial3;
 ShowReportMode   := _MyQiuUserRec.ShowReportMode;
 LoginMode        := _MyQiuUserRec.LoginMode;
end;
{------------------------------------------------------------------------------}
procedure TUser.Set_TMyQiuUserRec_From_TWebUser(var _MyQiuUserRec: TMyQiuUserRec);
begin
 _MyQiuUserRec.ID   := ID;
 _MyQiuUserRec.UUID := UUID;
 _MyQiuUserRec.Username := Username;
 _MyQiuUserRec.Password := Password;
 _MyQiuUserRec.EMail := Email;
 case Anrede of
  0 : _MyQiuUserRec.Anrede := ida_herr;
  1 : _MyQiuUserRec.Anrede := ida_frau;
  2 : _MyQiuUserRec.Anrede := ida_firma;
  3 : _MyQiuUserRec.Anrede := ida_none;
 end;
 _MyQiuUserRec.Titel              := Titel;
 _MyQiuUserRec.Name               := Name;
 _MyQiuUserRec.Vorname            := Vorname;
 _MyQiuUserRec.Geboren            := MyDateFormatToTDate(Geboren);
 _MyQiuUserRec.Geschlecht         := Geschlecht;
 _MyQiuUserRec.Groesse            := Groesse;
 case Sprache of
  0:  _MyQiuUserRec.Sprache := idmq_deutsch;
  1:  _MyQiuUserRec.Sprache :=idmq_englisch;
 end;
 _MyQiuUserRec.Therapeut1_UUID    := Therapeut1_uuid;
 _MyQiuUserRec.Therapeut2_UUID    := Therapeut2_uuid;
 _MyQiuUserRec.SetAutoTimezone    := Setautotimezone;
 _MyQiuUserRec.UTC_Hour           := _MyQiuUserRec.UTC_Hour;
 _MyQiuUserRec.UTC_Minute         := _MyQiuUserRec.UTC_Minute;
 _MyQiuUserRec.Newsletter         := Newsletter;
 _MyQiuUserRec.ValidUntil         := MyDateFormatToTDate(ValidUntil);
 _MyQiuUserRec.LastCode           := LastCode;
 case Abostatus of
  0:  _MyQiuUserRec.Abostatus := ids_demo;
  1 : _MyQiuUserRec.Abostatus := ids_normal;
  2 : _MyQiuUserRec.Abostatus := ids_gesperrt;
 end;
 case Abotyp of
  0: _MyQiuUserRec.Abotyp := idt_prepaid;
  1: _MyQiuUserRec.Abotyp := idt_monatlich;
  2: _MyQiuUserRec.Abotyp := idt_quartal;
  3: _MyQiuUserRec.Abotyp := idt_halbjahr;
  4: _MyQiuUserRec.Abotyp := idt_jahr;
 end;
 _MyQiuUserRec.Verifiziert        := Verifiziert;
 _MyQiuUserRec.BfB_3D             := BfB_3D;
 _MyQiuUserRec.OnlineSpektrum     := OnlineSpektrum;
 _MyQiuUserRec.Rhythmisierung     := Rhythmisierung;
 _MyQiuUserRec.RSA_Test           := RSA_Test;
 _MyQiuUserRec.KurzzeitHRV        := KurzzeitHRV;
 _MyQiuUserRec.QiuSerial1         := QiuSerial1;
 _MyQiuUserRec.QiuSerial2         := QiuSerial2;
 _MyQiuUserRec.QiuSerial3         := QiuSerial3;
 _MyQiuUserRec.ShowReportMode     := ShowReportMode;
 _MyQiuUserRec.LoginMode          := LoginMode;
end;
{------------------------------------------------------------------------------}
procedure TUser.Set_TWebUser_From_WebUserJSObject(WebUserJSObject:TJSObject);
begin
 Token             := string(WebUserJSObject['Token']);
 ID                := integer(WebUserJSObject['ID']);
 UUID              := string(WebUserJSObject['UUID']);
 Username          := string(WebUserJSObject['Username']);
 Password          := string(WebUserJSObject['Password']);
 EMail             := string(WebUserJSObject['Email']);
 Anrede            := byte(WebUserJSObject['Anrede']);
 Titel             := string(WebUserJSObject['Titel']);
 Name              := string(WebUserJSObject['Name']);
 Vorname           := string(WebUserJSObject['Vorname']);
 Geboren           := string(WebUserJSObject['Geboren']);
 Geschlecht        := integer(WebUserJSObject['Geschlecht']);
 Groesse           := integer(WebUserJSObject['Groesse']);
 Sprache           := byte(WebUserJSObject['Sprache']);
 Therapeut1_UUID   := string(WebUserJSObject['Therapeut1_UUID']);
 Therapeut2_UUID   := string(WebUserJSObject['Therapeut2_UUID']);
 SetAutoTimezone   := boolean(WebUserJSObject['SetAutoTimezone']);
 UTC_Hour          := integer(WebUserJSObject['UTC_Hour']);
 UTC_Minute        := integer(WebUserJSObject['UTC_Minute']);
 Newsletter        := boolean(WebUserJSObject['Newsletter']);
 ValidUntil        := string(WebUserJSObject['ValidUntil']);
 LastCode          := integer(WebUserJSObject['LastCode']);
 Abostatus         := integer(WebUserJSObject['Abostatus']);
 Abotyp            := integer(WebUserJSObject['Abotyp']);
 Verifiziert       := boolean(WebUserJSObject['Verifiziert']);
 BfB_3D            := boolean(WebUserJSObject['BfB_3D']);
 OnlineSpektrum    := boolean(WebUserJSObject['OnlineSpektrum']);
 Rhythmisierung    := boolean(WebUserJSObject['Rhythmisierung']);
 RSA_Test          := boolean(WebUserJSObject['RSA_Test']);
 KurzzeitHRV       := boolean(WebUserJSObject['KurzzeitHRV']);
 DGSVO_OK          := boolean(WebUserJSObject['DGSVO_OK']);
 QiuSerial1        := string(WebUserJSObject['QiuSerial1']);
 QiuSerial2        := string(WebUserJSObject['QiuSerial2']);
 QiuSerial3        := string(WebUserJSObject['QiuSerial3']);
 ShowReportMode    := integer(WebUserJSObject['ShowReportMode']);
 LoginMode         := integer(WebUserJSObject['LoginMode']);
end;
{------------------------------------------------------------------------------}
procedure TUser.Set_TWebUserJSObject_From_TWebUser(var WebUserJSObject:TJSObject);
begin
 WebUserJSObject['ID']            := ID;
 WebUserJSObject['UUID']          := UUID;
 WebUserJSObject['Username']      := Username;
 WebUserJSObject['Password']      := Password;
 WebUserJSObject['Anrede']        := Anrede;
 WebUserJSObject['Titel']         := Titel;
 WebUserJSObject['Name']          := Name;
 WebUserJSObject['Vorname']       := Vorname;
 WebUserJSObject['Geboren']       := Geboren;
 WebUserJSObject['Geschlecht']    := Geschlecht;
 WebUserJSObject['Groesse']       := Groesse;
 WebUserJSObject['Sprache']       := Sprache;
 WebUserJSObject['Email']         := Email;
 WebUserJSObject['Therapeut1_UUID'] := Therapeut1_UUID;
 WebUserJSObject['Therapeut2_UUID'] := Therapeut2_UUID;
 WebUserJSObject['SetAutoTimezone'] := SetAutoTimezone;
 WebUserJSObject['UTC_Hour']        := UTC_Hour;
 WebUserJSObject['UTC_Minute']      := UTC_Minute;
 WebUserJSObject['Newsletter']      := Newsletter;
 WebUserJSObject['ValidUntil']     := ValidUntil;
 WebUserJSObject['LastCode']       := LastCode;
 WebUserJSObject['Abostatus']      := Abostatus;
 WebUserJSObject['Abotyp']         := Abotyp;
 WebUserJSObject['Verifiziert']    := Verifiziert;
 WebUserJSObject['BfB_3D']         := BfB_3D;
 WebUserJSObject['OnlineSpektrum'] := OnlineSpektrum;
 WebUserJSObject['Rhythmisierung'] := Rhythmisierung;
 WebUserJSObject['RSA_Test']       := RSA_Test;
 WebUserJSObject['KurzzeitHRV']    := KurzzeitHRV;
 WebUserJSObject['DGSVO_OK']       := DGSVO_OK;
 WebUserJSObject['QiuSerial1']     := QiuSerial1;
 WebUserJSObject['QiuSerial2']     := QiuSerial2;
 WebUserJSObject['QiuSerial3']     := QiuSerial3;
 WebUserJSObject['ShowReportMode'] := ShowReportMode;
 WebUserJSObject['LoginMode']      := LoginMode;
end;


{ TWebTherapeut }
{------------------------------------------------------------------------------}
constructor TTherapeut.Create(_TherapeutRec: TMyQiuTherapeutRec);
begin
 ID                := _TherapeutRec.ID;
 UUID              := _TherapeutRec.UUID;
 case _TherapeutRec.Anrede of
  ida_herr : Anrede := 'Herr';
  ida_frau : Anrede := 'Frau';
  ida_firma: Anrede := 'Firma';
  ida_none: Anrede  := '';
 end;
 Titel             := _TherapeutRec.Titel;
 Name              := _TherapeutRec.Name;
 Vorname           := _TherapeutRec.Vorname;
 Institution       := _TherapeutRec.Institution;
 Strasse           := _TherapeutRec.Strasse;
 PLZ               := _TherapeutRec.PLZ;
 Stadt             := _TherapeutRec.Stadt;
 case
  _TherapeutRec.Land of
   idl_germany : Land := 'Deutschland';
   idl_austria : Land := 'Österreich';
   idl_schweiz : Land := 'Schweiz';
 end;
end;

{TMessung}
{------------------------------------------------------------------------------}
constructor TMessung.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TMessung.Init;
begin
 TestID    := '';
 Typ       := '';
 Datum     := '';
 Dauer     := 0;
 Kommentar := '';
 TypFragebogen := 0;      // 1 = StandAloneFragebogen wegen Bezeichnung in der Messungsliste
 BezeichnungFragebogen := '';
end;
{ TBiomarkerItemClass }
{------------------------------------------------------------------------------}
constructor TBiomarkerItemClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TBiomarkerItemClass.Init;
begin
  TYP          := 'BP';       // BP,WEIGHT;BZ
  Zeit         := MyTDateTimeFormatToString(now());    //now() unkritisch
  Sys          := 0;
  Dia          := 0;
  HF           := 0;
  BZmmol       := 0;
  BZmg         := 0;
  Gewicht      := 0;
  BMI          := 0;
  Bauch        := 0;
  Hip          := 0;
  WHR          := 0;
  ID           := 0;
  Comment      := '';
end;
{ TmyQiuAppUserClass }
{------------------------------------------------------------------------------}
constructor TmyQiuAppUserClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuAppUserClass.Init;
begin
  Token           := '';
  ID              := 0;
  UUID            := '';
  Username        := '';
  Password        := '';
  Email           := '';
  Anrede          := 0;
  Titel           := '';
  Name            := '';
  Vorname         := '';
  Geboren         := '01011900';
  Geschlecht      := 0;
  Groesse         := 0;
  Sprache         := 0;
  Therapeut1_UUID := '';
  Therapeut2_UUID := '';
  Setautotimezone := true;
  UTC_Hour        := 0;
  UTC_Minute      := 0;
  Newsletter      := true;
  ValidUntil      := '01011900';
  LastCode        := 0;
  Abostatus       := 0;
  Abotyp          := 0;
  Verifiziert     := false;
  BfB_3D          := true;
  OnlineSpektrum  := true;
  Rhythmisierung  := true;
  RSA_Test        := true;
  KurzzeitHRV     := true;
  DGSVO_OK        := false;
  Protokoll       := 0;
  QiuSerial1      := '';
  QiuSerial2      := '';
  QiuSerial3      := '';
  LoginMode       := 0;
  Q_StandAlone    := 0;
  Q_VorRSA        := 0;
  Q_NachRSA       := 0;
  Q_VorKHRV       := 0;
  Q_NachKHRV      := 0;
  Q_VorBFB        := 0;
  Q_NachBFB       := 0;
end;

{ TmyQiuAppUserClass2 }
{------------------------------------------------------------------------------}
constructor TmyQiuAppUserClass2.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuAppUserClass2.Init;
begin
  Token           := '';
  ID              := 0;
  UUID            := '';
  Username        := '';
  Password        := '';
  Email           := '';
  Anrede          := 0;
  Titel           := '';
  Name            := '';
  Vorname         := '';
  Geboren         := '01011900';
  Geschlecht      := 0;
  Groesse         := 0;
  Sprache         := 0;
  Therapeut1_UUID := '';
  Therapeut2_UUID := '';
  Setautotimezone := true;
  UTC_Hour        := 0;
  UTC_Minute      := 0;
  Newsletter      := true;
  ValidUntil      := '01011900';
  LastCode        := 0;
  Abostatus       := 0;
  Abotyp          := 0;
  Verifiziert     := false;
  BfB_3D          := true;
  OnlineSpektrum  := true;
  Rhythmisierung  := true;
  RSA_Test        := true;
  KurzzeitHRV     := true;
  DGSVO_OK        := false;
  Protokoll       := 0;
  QiuSerial1      := '';
  QiuSerial2      := '';
  QiuSerial3      := '';
  ShowReportMode  := 0;
  LoginMode       := 0;
end;
{ TmyQiuAppUserClass3 }
{------------------------------------------------------------------------------}
constructor TmyQiuAppUserClass3.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuAppUserClass3.Init;
begin
  Token           := '';
  ID              := 0;
  UUID            := '';
  Username        := '';
  Password        := '';
  Email           := '';
  Name            := '';
  Vorname         := '';
  Geboren         := '01011900';
  Geschlecht      := 0;
  Groesse         := 0;
  Sprache         := 0;
  Therapeut1_UUID := '';
  Therapeut2_UUID := '';
  Setautotimezone := true;
  UTC_Hour        := 0;
  UTC_Minute      := 0;
  Newsletter      := true;
  ValidUntil      := '01011900';
  LastCode        := 0;
  Abostatus       := 0;
  Abotyp          := 0;
  Verifiziert     := false;
  BfB_3D          := true;
  OnlineSpektrum  := true;
  Rhythmisierung  := true;
  RSA_Test        := true;
  KurzzeitHRV     := true;
  DGSVO_OK        := false;
  Protokoll       := 0;
  QiuSerial1      := '';
  QiuSerial2      := '';
  QiuSerial3      := '';
  ShowReportMode  := 0;
  LoginMode       := 0;
  Q_StandAlone    := 0;
  Q_VorRSA        := 0;
  Q_NachRSA       := 0;
  Q_VorKHRV       := 0;
  Q_NachKHRV      := 0;
  Q_VorBFB        := 0;
  Q_NachBFB       := 0;
  // MessungsStatistik
  LastBfB         := '01011900';
  LastRSA         := '01011900';
  LastKHRV        := '01011900';
  LastQiu         := '01011900';
  NoOfBfB         := 0;
  NoOfRSA         := 0;
  NoOfKHRV        := 0;
  NoOfQiu         := 0;
end;

{ TmyQiuFileUploadedResultClass }
{------------------------------------------------------------------------------}
constructor TmyQiuFileUploadedResultClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFileUploadedResultClass.Init;
begin
 UploadResult    := -1;
 AuswertenResult := -1;
 PDFLink         := '';
end;

{TmyQiuAuswertungResultClass}
{------------------------------------------------------------------------------}
constructor TmyQiuAuswertungResultClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuAuswertungResultClass.Init;
begin
 Result     := -1;
 PDFLink    := '';
end;

{TmyQiuFileUploadedOnlyResult}
{------------------------------------------------------------------------------}
constructor TmyQiuFileUploadedOnlyResult.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFileUploadedOnlyResult.Init;
begin
 UploadResult     := -1;
 MessungID    := '';
end;

{TGlobalTestDescription}
{------------------------------------------------------------------------------}
constructor TGlobalTestDescription.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TGlobalTestDescription.Init;
begin
 Result          := 0;
 OS              := 'unknown';
 OSVersion       := 'unknown';
 Device          := 'unknown';
 Hardware        := 'unknown';
end;

{TGetBfBVorgabeWertClass}
{------------------------------------------------------------------------------}
constructor TGetBfBVorgabeWertClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TGetBfBVorgabeWertClass.Init;
begin
 Result              := -1;
 VorgabeLow          := 0;
 VorgabeHigh         := 0;
 AnzahlRSA           := 0;
end;
{TGetCurrentAppInfo}
{------------------------------------------------------------------------------}
constructor TGetCurrentAppInfo.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TGetCurrentAppInfo.Init;
begin
 Version              := -1;
 Link                 := '';
 VersioniOS           := -1;
 LinkiOS              := '';
 VersionFirmware      := -1;
 LinkFirmware         := '';
end;

{TFragenAntwortenItemClass}
{------------------------------------------------------------------------------}
constructor TFragenAntwortenItemClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TFragenAntwortenItemClass.Init;
begin
 ID     := '';
 Zeit   := 0;
 Text   := '';
 Typ    := 0;
 Wert   := 0;
 Min    := 0;
 Max    := 0;
 Steps  := 0;;
 BezMin := '';
 BezMax := '';
 MC1_Text := '';
 MC1_Value:= 0;
 MC2_Text := '';
 MC2_Value:= 0;
 MC3_Text := '';
 MC3_Value:= 0;
 MC4_Text := '';
 MC4_Value:= 0;
 MC5_Text := '';
 MC5_Value:= 0;
end;

{TFragebogenClass}
{------------------------------------------------------------------------------}
constructor TFragebogenClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TFragebogenClass.Init;
begin
 ID               := '';
 NoOfItems        := 0;
 Bezeichnung      := '';
 Aktiv            := false;
end;
{TFragebogenItemClass }
{------------------------------------------------------------------------------}
constructor TFragebogenItemClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TFragebogenItemClass.Init;
begin
 ID          := '';
 Rang        := 0;
 Text        := '';
 Typ         := 0;
 Min         := 0;
 Max         := 0;
 Steps       := 0;
 BezMin      := '';
 BezMax      := '';
 MC_Text1    := '';   //multiple Choice Antwort 1
 MC_Value1   := 0;
 MC_Text2    := '';   //multiple Choice Antwort 2
 MC_Value2   := 0;
 MC_Text3    := '';  //multiple Choice Antwort 3
 MC_Value3   := 0;
 MC_Text4    := '';   //multiple Choice Antwort 4
 MC_Value4   := 0;
 MC_Text5    := '';   //multiple Choice Antwort 5
 MC_Value5   := 0;
end;

{TBfBSettingsClass}
{------------------------------------------------------------------------------}
constructor TBfBSettingsClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TBfBSettingsClass.Init;
begin
 Aktiv                    := 0;
 Sitzungsdauer            := 180;
 Atem_Freq                := 6;
 Atem_In                  := 50;
 Atem_Out                 := 50;
 Atem_Profi               := 0;
 Atem_Profi_Ein           := 3000;
 Atem_Profi_Ein_Pause     := 0;
 Atem_Profi_Aus           := 3000;
 Atem_Profi_Aus_Pause     := 0;
 Vorgabewert              := 20;
end;
{TQiuSettingClass}
{------------------------------------------------------------------------------}
constructor TQiuSettingClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TQiuSettingClass.Init;
begin
 Aktiv                    := 0;
 CohLevel                 := 20;
 Helligkeit               := 6;
 Dauer                    := 3;
 Atem_Typ                 := 6;
 Atem_Ein                 := 5;
 Atem_Ein_Pause           := 0;
 Atem_Aus                 := 5;
 Atem_Aus_Pause           := 0;
 LastChange               := '01.01.2000';
end;
{TFirmwareFileClass}
{------------------------------------------------------------------------------}
constructor TFirmwareFileClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TFirmwareFileClass.Init;
begin
 Version     := 0;
 Dateiname   := '';
 Datum       := '01.01.2000';
end;
{TAppRSAAuswertungResultClass}
{------------------------------------------------------------------------------}
constructor TAppRSAAuswertungResultClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TAppRSAAuswertungResultClass.Init;
begin
 Typ                    := '';
 Datum                  := '';
 AktR_Tonus             := '-1';
 AktR_Flexibilitaet     := '-1';
 AktR_Dynamik           := '-1';
 VorwertR_Tonus         := '-1';
 VorwertR_Flexibilitaet := '-1';
 VorwertR_Dynamik       := '-1';
 Akt_RMSSD              := '0';   // Wert
 Akt_MEAN               := '0';   // Wert
 Akt_RSADIFF2           := '0';   // Wert
 Vorwert_RMSSD          := '0';   // Wert
 Vorwert_MEAN           := '0';   // Wert
 Vorwert_RSADIFF2       := '0';   // Wert
 NoOfVorwerte           := '0';
 Messqualitaet_Text     := '0';
 Messqualitaet_Value    := '-1';
 Interpretation1        := '';
 Interpretation2        := '';
 HFList                 := '';
 Result                 := 0;
 UploadResult           := 0;
end;
{TAppKURZHRVAuswertungResultClass}
{------------------------------------------------------------------------------}
constructor TAppKURZHRVAuswertungResultClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TAppKURZHRVAuswertungResultClass.Init;
begin
  Typ                     := '';
  Datum                   := '';
  AktR_Tonus              := '0'; // Rang
  AktR_Flexibilitaet      := '0'; // Rang
  AktR_Dynamik1           := '0'; // Rang
  AktR_Dynamik2           := '0'; // Rang
  AktR_Stressindex        := '0'; // Rang
  VorwertR_Tonus          := '0'; // Rang
  VorwertR_Flexibilitaet  := '0'; // Rang
  VorwertR_Dynamik1       := '0'; // Rang
  VorwertR_Dynamik2       := '0'; // Rang
  VorwertR_Stressindex    := '0'; // Rang
  Akt_MEAN                := '0';
  Akt_VKRR                := '0';
  Akt_SD1                 := '0';
  Akt_SD2                 := '0';
  Akt_STRESSINDEX         := '0';
  Vorwert_MEAN            := '0';
  Vorwert_VKRR            := '0';
  Vorwert_SD1             := '0';
  Vorwert_SD2             := '0';
  Vorwert_STRESSINDEX     := '0';
  NoOfVorwerte            := '0';
  PatAge                  := '0';
  Messqualitaet_Text      := '';
  Messqualitaet_Value     := '-1';
  Interpretation1         := '';
  HFList                  := '';
  Result                  := 0;
  UploadResult            := 0;
end;
{TAppBfBAuswertungResultClass}
{------------------------------------------------------------------------------}
constructor TAppBfBAuswertungResultClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TAppBfBAuswertungResultClass.Init;
begin
   Typ                     := '';
   Datum                   := '';
   Vorgabewert             := '0';
   Dauer                   := '0';
   CohRel                  := '0';
   COH_LOW                 := '0';
   COH_MED                 := '0';
   COH_HIGH                := '0';
   COH_MAX                 := '0';
   COH_MEAN                := '0';
   BFBERFOLG               := '0';
   Messqualitaet_Text      := '';
   Messqualitaet_Value     := '0';
   BfbResultText           := '';
   HFCOHList               := '';
   Result                  := 0;
   UploadResult            := 0;
end;
{------------------------------------------------------------------------------}
constructor TAppGetMessungsListeResultClass.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TAppGetMessungsListeResultClass.Init;
begin
 Result    := 0;
 Count     := 0;
 Liste     := ''
end;
{------------------------------------------------------------------------------}
constructor TMessungComment.Create;
begin
 Init;
end;
{------------------------------------------------------------------------------}
procedure TMessungComment.Init;
begin
 Comment    := '';
 UserUUID   := '';
 MessungID  := '';
end;
end.
