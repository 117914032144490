unit myQiuSchedulerEnterDlg;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, Vcl.Controls, WEBLib.StdCtrls,
  Vcl.StdCtrls;

type
  TmyQiuSchedulerEnterDialog = class(TForm)
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebDateTimePicker1: TDateTimePicker;
    SaveBtn: TBitBtn;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure SaveBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    Modus     : byte;   // Eingabe bezeichnung, 1 = Eingabe Datum
    ValidDate : TdateTime;
  protected procedure LoadDFMValues; override; end;

var
  myQiuSchedulerEnterDialog: TmyQiuSchedulerEnterDialog;

implementation

uses KVT, CommonFunctions;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TmyQiuSchedulerEnterDialog.SaveBtnClick(Sender: TObject);
begin
 if Modus = 1  then ValidDate := WebDateTimePicker1.Date;
 close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuSchedulerEnterDialog.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuSchedulerEnterDialog.WebFormCreate(Sender: TObject);
begin
end;
{------------------------------------------------------------------------------}
procedure TmyQiuSchedulerEnterDialog.WebFormResize(Sender: TObject);
begin
 WebLabel1.Left := self.ClientWidth div 2 - WebLabel1.Width div 2;
 WebEdit1.Left := self.ClientWidth div 2 - WebEdit1.Width div 2;
 WebDateTimePicker1.Left := self.ClientWidth div 2 - WebDateTimePicker1.Width div 2;
 SaveBtn.Left := self.ClientWidth div 2 - SaveBtn.Width div 2;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuSchedulerEnterDialog.WebFormShow(Sender: TObject);
begin
 if Modus = 0 then begin
     weblabel1.Caption := SP.load(166);
     webedit1.Visible  := true;WebDateTimePicker1.Visible := false;
  end else begin
     weblabel1.Caption := SP.load(167);
     webedit1.Visible  := false;WebDateTimePicker1.Visible := true;
  end;
end;

procedure TmyQiuSchedulerEnterDialog.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebDateTimePicker1 := TDateTimePicker.Create(Self);
  SaveBtn := TBitBtn.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebDateTimePicker1.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  try
    Name := 'myQiuSchedulerEnterDialog';
    Width := 413;
    Height := 155;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 83;
    WebLabel1.Top := 29;
    WebLabel1.Width := 63;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 83;
    WebEdit1.Top := 51;
    WebEdit1.Width := 249;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.WidthPercent := 100.000000000000000000;
    WebDateTimePicker1.SetParentComponent(Self);
    WebDateTimePicker1.Name := 'WebDateTimePicker1';
    WebDateTimePicker1.Left := 83;
    WebDateTimePicker1.Top := 51;
    WebDateTimePicker1.Width := 153;
    WebDateTimePicker1.Height := 22;
    WebDateTimePicker1.BorderStyle := bsSingle;
    WebDateTimePicker1.ChildOrder := 2;
    WebDateTimePicker1.Color := clWhite;
    WebDateTimePicker1.Date := 44271.731633611110000000;
    WebDateTimePicker1.Role := '';
    WebDateTimePicker1.Text := '';
    SaveBtn.SetParentComponent(Self);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 149;
    SaveBtn.Top := 85;
    SaveBtn.Width := 100;
    SaveBtn.Height := 30;
    SaveBtn.Caption := 'OK';
    SaveBtn.Color := clNone;
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.MaterialGlyph := 'save';
    SaveBtn.TabOrder := 2;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
  finally
    WebLabel1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebDateTimePicker1.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
  end;
end;

end.