unit DBFunctions;

interface


type

  TMyQiuUser_AnredeID         = (ida_herr,ida_frau,ida_firma,ida_none);
  TMyQiuUser_LandID           = (idl_germany,idl_austria,idl_schweiz);
  TMyQiuUser_AboTypID         = (idt_prepaid,idt_monatlich,idt_quartal,idt_halbjahr,idt_jahr);   //ACHTUNG InterpretAbotyp angleichen
  TMyQiuUser_ZahlartID        = (idz_sepa,id_paypal);
  TMyQiuUser_GeschlechtID     = (idg_male,idg_female);
  TMyQiuUser_AbostatusID      = (ids_demo,ids_normal,ids_gesperrt);    //ACHTUNG InterpretAbostatus angleichen


  { ACHTUNG bei Ergänzung anpassen:
   - TMyQiuDBFunctions.DecodeMyQiuUserRec
  }
  TMyQiuUser_SprachID         = (idmq_deutsch,idmq_englisch);//,idmq_tuerkisch,idmq_chinesisch,idmq_suomi,idmq_spanisch,idmq_franzoesisch

  {------------------------------------------------------------------------------}
  // bildet den uncodierten Datenbankeintrag ab
  {------------------------------------------------------------------------------}
  // bildet den uncodierten Datenbankeintrag ab
  TMyQiuUserRec = packed record
   ID                : integer;
   Timestamp         : TDateTime;
   LastUpdate        : TDateTime;
   UUID              : string;
   Username          : string;
   upper_Username    : string;
   Password          : string;
   Anrede            : TMyQiuUser_AnredeID;
   Titel             : string;
   Name              : string;
   Vorname           : string;
   Institution       : string;
   Geboren           : TDate;
   Geschlecht        : integer;
   Groesse           : integer;
   eMail             : string;
   upper_eMail       : string;
   Telefon1          : string;
   Telefon2          : string;
   Fax               : string;
   Strasse           : string;
   PLZ               : string;
   Stadt             : string;
   Land              : TMyQiuUser_LandID;
   Zahlart           : TMyQiuUser_ZahlartID;
   Bank              : string;
   IBAN              : string;
   BIC               : string;
   PayPal            : string;
   Abostatus         : TMyQiuUser_AbostatusID;
   Abotyp            : TMyQiuUser_AboTypID;
   Verifiziert       : boolean;
   Verifycode        : string;
   Sprache           : TMyQiuUser_SprachID;
   Therapeut1_UUID   : string;
   Therapeut2_UUID   : string;
   SetAutoTimezone   : boolean;
   UTC_Hour          : integer;
   UTC_Minute        : integer;
   ValidUntil        : TDate;
   LastCode          : integer;
   LSDate1           : TDate;
   LSDate2           : TDate;
   Newsletter        : boolean;
   BfB_3D            : boolean;
   OnlineSpektrum    : boolean;
   Rhythmisierung    : boolean;
   RSA_Test          : boolean;
   KurzzeitHRV       : boolean;
   DGSVO_Version     : integer;    //CHANGE:2018-15-05 Datenschutzerklärung
   DGSVO_Accepted    : TDateTime;  //CHANGE:2018-15-05 Datenschutzerklärung
   QiuSerial1        : string;
   QiuSerial2        : string;
   QiuSerial3        : string;
   ShowReportMode    : integer;
   LoginMode         : integer;
  end;

  {------------------------------------------------------------------------------}
  // bildet den uncodierten Datenbankeintrag ab
  TMyQiuTherapeutRec = packed record
   ID                : integer;
   Timestamp         : TDateTime;
   LastUpdate        : TDateTime;
   UUID              : string;
   Username          : string;
   upper_Username    : string;
   Password          : string;
   Anrede            : TMyQiuUser_AnredeID;
   Titel             : string;
   Name              : string;
   Vorname           : string;
   Institution       : string;
   eMail             : string;
   upper_eMail       : string;
   Telefon1          : string;
   Telefon2          : string;
   Fax               : string;
   Strasse           : string;
   PLZ               : string;
   Stadt             : string;
   Land              : TMyQiuUser_LandID;
   Zahlart           : TMyQiuUser_ZahlartID;
   Bank              : string;
   IBAN              : string;
   BIC               : string;
   PayPal            : string;
   Abostatus         : TMyQiuUser_AbostatusID;
   Abotyp            : TMyQiuUser_AboTypID;
   Verifiziert       : byte;
   Verifycode        : string;
   Sprache           : TMyQiuUser_SprachID;
   Newsletter        : byte;
   DGSVO_Version     : integer;    //CHANGE:2018-15-05 Datenschutzerklärung
   DGSVO_Accepted    : TDateTime;  //CHANGE:2018-15-05 Datenschutzerklärung
   DGSVO_Auftrag     : TDateTime;  //CHANGE:2018-15-05 Datenschutzerklärung
  end;

implementation

end.
