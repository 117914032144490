unit Accept_AGB;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Buttons, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls;

type
  TAccept_AGB_Form = class(TForm)
    NewsletterCheckBox: TCheckBox;
    SaveBtn: TBitBtn;
    CancelBtn: TBitBtn;
    AGBCheckBox: TCheckBox;
    AGBsBtn: TButton;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    DGSVOButton: TButton;
    DGSVOCheckBox: TCheckBox;
    procedure AGBsBtnClick(Sender: TObject);
    procedure DGSVOButtonClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure AGBCheckBoxClick(Sender: TObject);
    procedure DGSVOCheckBoxClick(Sender: TObject);
    procedure SaveBtnClick(Sender: TObject);
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure AskFornNewsletter;
    procedure Save_All_Accepted;
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    All_Accepted    : boolean;
    AGBS_seen       : boolean;
    Datenschutz_seen: boolean;
    DGSVO_Needed    : integer;
  protected procedure LoadDFMValues; override; end;

var
  Accept_AGB_Form: TAccept_AGB_Form;

implementation

uses CommonFunctions, KVT, DatenServiceClasses,XData.Web.Client,Main,MyMsgDialog;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.AGBsBtnClick(Sender: TObject);
begin
 Application.Navigate(GetAGBLink, ntBlank);
 AGBS_seen := true;
 AGBCheckBox.OnClick := nil;
 AGBCheckBox.Checked := true;
 AGBCheckBox.OnClick := AGBCheckBoxClick;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.CancelBtnClick(Sender: TObject);
begin
 All_Accepted := false;
 showmessage(SP.load(134));
 Close;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.DGSVOCheckBoxClick(Sender: TObject);
begin
 if not Datenschutz_seen then begin
  showmessage(SP.Load(270));
  DGSVOCheckBox.OnClick := nil;
  DGSVOCheckBox.Checked := false;
  DGSVOCheckBox.OnClick := DGSVOCheckBoxClick;
 end;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.SaveBtnClick(Sender: TObject);
begin
 if not AGBcheckbox.Checked then begin
   showmessage(SP.load(266));
   exit;
 end;
 if not DGSVOcheckbox.Checked then begin
   showmessage(SP.load(268));
   exit;
 end;

 if not NewsletterCheckBox.Checked then begin
  AskFornNewsletter;
  exit;
 end;
 Save_All_Accepted;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.Save_All_Accepted;

 procedure OnResponse(Response:TXDataClientResponse);
  begin
    //
  end;

begin
 All_Accepted := true;
 try
  MainForm.XDataWebClient.RawInvoke('IDatenService.Save_AGB_DGSVO_Accepted',
         [GLV_AktUser.Token,GLV_AktUser.ID],@OnResponse);
 finally
  //
 end;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.AskFornNewsletter;
var
 MyMessageDlgForm : TMyMessageDlgForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   case MyMessageDlgForm._Result of
    drYes : GLV_AktUser.newsletter := true;
    drNo  : GLV_AktUser.newsletter := false;
   end;
   Save_All_Accepted;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;

begin
 MyMessageDlgForm := TMyMessageDlgForm.CreateNew(@AfterCreate);
 MyMessageDlgForm.Border := fbsingle;
 MyMessageDlgForm.Popup := true;
 MyMessageDlgForm.Titel := SP.load(20);
 MyMessageDlgForm.MessageText := SP.Load(251);
 MyMessageDlgForm.DialogType := dtYesNo;
 MyMessageDlgForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.DGSVOButtonClick(Sender: TObject);
begin
 Application.Navigate(GetDatenschutzerklaerungLink, ntBlank);
 Datenschutz_seen:= true;
 DGSVOCheckBox.OnClick := nil;
 DGSVOCheckBox.Checked := true;
 DGSVOCheckBox.OnClick := DGSVOCheckBoxClick;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.AGBCheckBoxClick(Sender: TObject);
begin
 if not AGBS_seen then begin
  showmessage(SP.Load(269));
  AGBCheckBox.OnClick := nil;
  AGBCheckBox.Checked := false;
  AGBCheckBox.OnClick := AGBCheckBoxClick;
 end;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.WebFormCreate(Sender: TObject);
begin
 All_Accepted := false;
 AGBS_seen    := false;
 Datenschutz_seen:= false;
 DGSVO_Needed := 0;
end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.WebFormResize(Sender: TObject);
begin
 AGBsBtn.Left     := self.ClientWidth div 2 - AGBsBtn.Width div 2;
 DGSVOButton.Left := self.ClientWidth div 2 - DGSVOButton.Width div 2;

 SaveBtn.Left     := self.ClientWidth div 2 - SaveBtn.Width - 4;
 CancelBtn.Left   := self.ClientWidth div 2 + 4;

end;
{------------------------------------------------------------------------------}
procedure TAccept_AGB_Form.WebFormShow(Sender: TObject);
begin
 AGBsBtn.caption            :=  SP.Load(90);
 DGSVOButton.caption        :=  SP.Load(91);
 NewsletterCheckBox.caption :=  SP.Load(84);
 SaveBtn.caption            :=  SP.Load(74);
 CancelBtn.caption          :=  SP.Load(27);
 AGBCheckBox.caption        :=  SP.Load(265);
 WebLabel1.caption          :=  SP.Load(266);
 WebLabel2.caption          :=  SP.Load(268);
 DGSVOCheckBox.caption      :=  SP.Load(267);

 NewsletterCheckBox.Checked := GLV_AktUser.Newsletter;
end;

procedure TAccept_AGB_Form.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  NewsletterCheckBox := TCheckBox.Create(Self);
  SaveBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  AGBCheckBox := TCheckBox.Create(Self);
  AGBsBtn := TButton.Create(Self);
  DGSVOButton := TButton.Create(Self);
  DGSVOCheckBox := TCheckBox.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  NewsletterCheckBox.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  AGBCheckBox.BeforeLoadDFMValues;
  AGBsBtn.BeforeLoadDFMValues;
  DGSVOButton.BeforeLoadDFMValues;
  DGSVOCheckBox.BeforeLoadDFMValues;
  try
    Name := 'Accept_AGB_Form';
    Width := 617;
    Height := 386;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 24;
    WebLabel1.Width := 463;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Bitte best'#228'tigen Sie, dass Sie die AGBs zu myQiu gelesen haben und akzeptieren!';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 24;
    WebLabel2.Top := 144;
    WebLabel2.Width := 557;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Bitte best'#228'tigen Sie, dass Sie die Datenschutzerkl'#228'rung zu myQiu gelesen haben und akzeptieren!';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    NewsletterCheckBox.SetParentComponent(Self);
    NewsletterCheckBox.Name := 'NewsletterCheckBox';
    NewsletterCheckBox.Left := 8;
    NewsletterCheckBox.Top := 286;
    NewsletterCheckBox.Width := 529;
    NewsletterCheckBox.Height := 22;
    NewsletterCheckBox.Caption := 'Newsletter abonnieren';
    NewsletterCheckBox.ChildOrder := 2;
    NewsletterCheckBox.HeightPercent := 100.000000000000000000;
    NewsletterCheckBox.WidthPercent := 100.000000000000000000;
    SaveBtn.SetParentComponent(Self);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 210;
    SaveBtn.Top := 331;
    SaveBtn.Width := 100;
    SaveBtn.Height := 30;
    SaveBtn.Caption := 'Speichern';
    SaveBtn.Color := clNone;
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.MaterialGlyph := 'save';
    SaveBtn.TabOrder := 1;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 333;
    CancelBtn.Top := 331;
    CancelBtn.Width := 100;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 2;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    AGBCheckBox.SetParentComponent(Self);
    AGBCheckBox.Name := 'AGBCheckBox';
    AGBCheckBox.Left := 24;
    AGBCheckBox.Top := 93;
    AGBCheckBox.Width := 577;
    AGBCheckBox.Height := 22;
    AGBCheckBox.Caption := 'Ich habe die AGBs von myQiu gelesen und akzeptiere diese hiermit.';
    AGBCheckBox.ChildOrder := 2;
    AGBCheckBox.HeightPercent := 100.000000000000000000;
    AGBCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(AGBCheckBox, Self, 'OnClick', 'AGBCheckBoxClick');
    AGBsBtn.SetParentComponent(Self);
    AGBsBtn.Name := 'AGBsBtn';
    AGBsBtn.Left := 229;
    AGBsBtn.Top := 56;
    AGBsBtn.Width := 137;
    AGBsBtn.Height := 30;
    AGBsBtn.Caption := 'AGBS '#246'ffnen';
    AGBsBtn.ChildOrder := 1;
    AGBsBtn.HeightPercent := 100.000000000000000000;
    AGBsBtn.WidthPercent := 100.000000000000000000;
    SetEvent(AGBsBtn, Self, 'OnClick', 'AGBsBtnClick');
    DGSVOButton.SetParentComponent(Self);
    DGSVOButton.Name := 'DGSVOButton';
    DGSVOButton.Left := 176;
    DGSVOButton.Top := 179;
    DGSVOButton.Width := 222;
    DGSVOButton.Height := 30;
    DGSVOButton.Caption := 'Datenschutzerkl'#228'rung '#246'ffnen';
    DGSVOButton.ChildOrder := 1;
    DGSVOButton.HeightPercent := 100.000000000000000000;
    DGSVOButton.WidthPercent := 100.000000000000000000;
    SetEvent(DGSVOButton, Self, 'OnClick', 'DGSVOButtonClick');
    DGSVOCheckBox.SetParentComponent(Self);
    DGSVOCheckBox.Name := 'DGSVOCheckBox';
    DGSVOCheckBox.Left := 24;
    DGSVOCheckBox.Top := 226;
    DGSVOCheckBox.Width := 585;
    DGSVOCheckBox.Height := 22;
    DGSVOCheckBox.Caption := 'Ich habe die Datenschutzerkl'#228'rung von myQiu gelesen und akzeptiere diese hiermit.';
    DGSVOCheckBox.ChildOrder := 2;
    DGSVOCheckBox.HeightPercent := 100.000000000000000000;
    DGSVOCheckBox.WidthPercent := 100.000000000000000000;
    SetEvent(DGSVOCheckBox, Self, 'OnClick', 'DGSVOCheckBoxClick');
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    NewsletterCheckBox.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    AGBCheckBox.AfterLoadDFMValues;
    AGBsBtn.AfterLoadDFMValues;
    DGSVOButton.AfterLoadDFMValues;
    DGSVOCheckBox.AfterLoadDFMValues;
  end;
end;

end.