unit MyQiuBPTable_SW;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  WEBLib.Buttons, Vcl.Grids, WEBLib.Grids, Vcl.Graphics, WEBLib.ExtCtrls,
  DatenServiceClasses, Vcl.Forms, MainMenuFrame, WEBLib.ComCtrls, Types;

type
  TMyQiuBPTableSWForm = class(TForm)
    BottomPanel: TPanel;
    WebPanel1: TPanel;
    WebPanel3: TPanel;
    WebLabel2: TLabel;
    V1Label: TLabel;
    V2Label: TLabel;
    V3Label: TLabel;
    CommentEdit: TEdit;
    verlaufBtn: TSpeedButton;
    WebLabel1: TLabel;
    CommentLabel: TLabel;
    TypusCB: TComboBox;
    SollGewichtLabel: TLabel;
    SollGewichtBMILabel: TLabel;
    V1Edit: TEdit;
    V1DownSB: TSpeedButton;
    V1UpSB: TSpeedButton;
    V2Edit: TEdit;
    V2DownSB: TSpeedButton;
    V2UpSB: TSpeedButton;
    V3Edit: TEdit;
    V3DownSB: TSpeedButton;
    V3UpSB: TSpeedButton;
    SollGewichtEdit: TEdit;
    SollGewichtDownSB: TSpeedButton;
    SollGewichtUpSB: TSpeedButton;
    BMILabel: TLabel;
    WHRLabel: TLabel;
    BZEinheitCB: TComboBox;
    HHEdit: TEdit;
    HHDownSE: TSpeedButton;
    HHUpSE: TSpeedButton;
    MMEdit: TEdit;
    MMDownSE: TSpeedButton;
    MMUpSE: TSpeedButton;
    SelectTimer: TTimer;
    WebPanel4: TPanel;
    WebPanel5: TPanel;
    ValuesStringGrid: TStringGrid;
    selectDate: TSpeedButton;
    DateEdit: TEdit;
    MainMenuFrame1: TMainMenuFrame1;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    CaptionPanel: TPanel;
    ExitBtn: TSpeedButton;
    SollSaveBtn: TSpeedButton;
    Editbtn: TSpeedButton;
    Addbtn: TSpeedButton;
    Delbtn: TSpeedButton;
    WebPageControl1: TPageControl;
    WebPageControl1Sheet1: TTabSheet;
    WebPageControl1Sheet2: TTabSheet;
    HelpBtn: TSpeedButton;
    WaitPanel: TPanel;
    TopSelectPanel: TPanel;
    WebLabel5: TLabel;
    SelectYearCB: TComboBox;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure ExitBtnClick(Sender: TObject);
    procedure TypusCBChange(Sender: TObject);
    procedure ShowEntry(_Marker:TBiomarkerItemClass);
    procedure ValuesStringGridClick(Sender: TObject);
    procedure ValuesStringGridSelectCell(Sender: TObject; ACol, ARow: Integer;
      var CanSelect: Boolean);
    procedure ValuesStringGridKeyUp(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure GetListe;
    procedure ShowListe;
    procedure InitValuesStringGrid;
    procedure verlaufBtnClick(Sender: TObject);
    procedure V1DownSBClick(Sender: TObject);
    procedure V1UpSBClick(Sender: TObject);
    procedure V2UpSBClick(Sender: TObject);
    procedure V2DownSBClick(Sender: TObject);
    procedure V3UpSBClick(Sender: TObject);
    procedure V3DownSBClick(Sender: TObject);
    function  GLU_mmol_to_mg(_GLUmmol : single):Single;
    function  GLU_mg_to_mmol(_GLUmg : single):Single;
    procedure DisplayBMI_WHR;
    procedure BZEinheitCBChange(Sender: TObject);
    procedure SollSaveBtnClick(Sender: TObject);
    procedure HHDownSEClick(Sender: TObject);
    procedure HHUpSEClick(Sender: TObject);
    procedure MMDownSEClick(Sender: TObject);
    procedure MMUpSEClick(Sender: TObject);
    procedure DelbtnClick(Sender: TObject);
    procedure EditbtnClick(Sender: TObject);
    procedure AddbtnClick(Sender: TObject);
    procedure HHEditChange(Sender: TObject);
    procedure MMEditChange(Sender: TObject);
    function  EntryExists(_AktType:integer;_Zeit:string):boolean;
    procedure OverTakeEntry(_PBM: TBiomarkerItemClass);
    procedure SelectTimerTimer(Sender: TObject);
    procedure SollGewichtUpSBClick(Sender: TObject);
    procedure SollGewichtDownSBClick(Sender: TObject);
    procedure GetAktSollWeight;
    procedure SollGewichtEditChange(Sender: TObject);
    procedure WebDateTimePicker1Change(Sender: TObject);
    procedure selectDateClick(Sender: TObject);
    procedure HelpBtnClick(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure WebPageControl1Change(Sender: TObject);
    procedure SelectYearCBChange(Sender: TObject);
    procedure InitSelectYearCB;
    procedure DeleteEntry;
    procedure DeleteMultipleEntries;
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    DTPDate             : TDateTime;
    BiomarkerList       : TArray<TBiomarkerItemClass>;
    AktBZUnit           : string;
    AktType             : byte;
    AktSollWeight       : integer;
    PossibleTypes       : array[0..2] of string;
    v1ValueOK,v2ValueOK,v3ValueOK : boolean;
    v1max,v1min,v2max,v2min,v3max,v3min : integer;
    V1Inc,v2Inc,v3Inc   : single;
    SelectedTopRow      : integer;
    SelectedBottomRow   : integer;
    HHValueOK,MMValueOK : boolean;
    SollGewichtMinvalue : integer;
    SollGewichtMaxvalue : integer;
    IsTopForm           : boolean;
    _von,_bis           : TDatetime;
    _Von_BisArray       : Array of TDatetime;
    FormClosed          : boolean;
  protected procedure LoadDFMValues; override; end;

const BZUmrechnung = 18.02;

var
  MyQiuBPTableSWForm: TMyQiuBPTableSWForm;

implementation

uses KVT,XData.Web.Client,Main,MyQiuTrends,System.DateUtils, CommonFunctions,DatePicker,
     MyMsgDialog;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.OverTakeEntry(_PBM: TBiomarkerItemClass);
var
   Zeit          : TDateTime;
begin
   Zeit := trunc(DTPDate)+encodeTime(strtoint(hhedit.Text),strtoint(MMedit.Text),0,0);
   case AktType of
    0 : begin
            _PBM.TYP := 'BP';      //##IGNORE_LINE
            _PBM.zeit := MyTDateTimeFormatToString(zeit);
            _PBM.Sys  := strtofloat(v1edit.Text);
            _PBM.Dia  := strtofloat(v2edit.Text);
            _PBM.HF   := strtofloat(v3edit.Text);
            _PBM.Comment := trim(CommentEdit.Text);
        end;
    1 : begin
           _PBM.TYP := 'WEIGHT';   //##IGNORE_LINE
           _PBM.zeit := MyTDateTimeFormatToString(zeit);
           _PBM.Gewicht  := strtofloat(v1edit.Text);
           _PBM.Bauch    := strtofloat(v2edit.Text);
           _PBM.Hip      := strtofloat(v3edit.Text);
           if GLV_AktUser.Groesse<100 then begin
              _PBM.BMI      := -1;
           end else begin
              _PBM.BMI      :=_PBM.Gewicht/(GLV_AktUser.Groesse*GLV_AktUser.Groesse/10000);
           end;
           if _PBM.Hip>50 then begin
               _PBM.WHR      := _PBM.Bauch/_PBM.Hip;
           end else begin
              _PBM.WHR  := 0;
           end;
           _PBM.Comment := trim(CommentEdit.Text);
        end;
    2 : begin
           _PBM.TYP := 'BZ';       //##IGNORE_LINE
           _PBM.zeit := MyTDateTimeFormatToString(zeit);
           if AktBZUnit= 'MMOL' then begin   //##IGNORE_LINE
              _PBM.BZmmol := strtofloat(v1edit.Text);
              _PBM.BZmg   := self.GLU_mmol_to_mg(_PBM.BZmmol);
           end else begin
              _PBM.BZmg   := strtofloat(v1edit.Text);
              _PBM.BZmmol := self.GLU_mg_to_mmol(_PBM.BZmg);
           end;
           _PBM.Comment := trim(CommentEdit.Text);
        end;
    else
      exit;
 end;
end;
{------------------------------------------------------------------------------}
function TMyQiuBPTableSWForm.EntryExists(_AktType:integer;_Zeit:string):boolean;
var
  PBM           : TBiomarkerItemClass;
  i             : integer;
begin
  result := false;
  for I := 0 to length(BiomarkerList)-1 do begin
      PBM := BiomarkerList[i];
      if (PBM.TYP=PossibleTypes[_AktType]) and (PBM.Zeit=_Zeit) then begin
         result := true; exit;
      end;
  end;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.ExitBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.SollGewichtDownSBClick(Sender: TObject);
begin
 if AktSollWeight = SollGewichtMinvalue then exit;
 dec(AktSollWeight);
 SollGewichtEdit.Text := inttostr(AktSollWeight);
 SollGewichtEditChange(Self);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.SollGewichtEditChange(Sender: TObject);
var
 bmi    : single;
 bmitxt : string;
 s      : string;
begin
 if (GLV_AktUser.Groesse>0) then begin
     BMI     := (AktSollWeight)/(GLV_AktUser.Groesse*GLV_AktUser.Groesse/10000);
     s       := SP.load(205);
     ReplacePlatzhalter(s,'@@@VALUE',floattostrf(bmi,fffixed,5,1));
     bmitxt  := 'kg'+ glc_1blank + s;
 end else begin
     bmitxt  := 'kg';
 end;
 SollGewichtBMILabel.caption := bmitxt;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.GetAktSollWeight;
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.GetSollWeight',[GLV_AktUser.Token,GLV_AktUser.ID],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    AktSollWeight :=  integer(TJSObject(Response.Result)['value']);
    SollGewichtEdit.Text := inttostr(AktSollWeight);
    SollGewichtEditChange(Self);
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.SollGewichtUpSBClick(Sender: TObject);
begin
 if AktSollWeight = SollGewichtMaxvalue then exit;
 inc(AktSollWeight);
 SollGewichtEdit.Text := inttostr(AktSollWeight);
 SollGewichtEditChange(Self);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.SollSaveBtnClick(Sender: TObject);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.ChangeSollWeight',[GLV_AktUser.Token,GLV_AktUser.ID,AktSollWeight],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    if integer(TJSObject(Response.Result)['value'])= 0 then begin
     showmessage(SP.load(204));
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.SelectTimerTimer(Sender: TObject);
var
 PBM           : TBiomarkerItemClass;
begin
 if length(BiomarkerList) > 0 then begin
  SelectTimer.Enabled := false;
  SelectedTopRow    := 0;
  SelectedBottomRow := 0;
  PBM := BiomarkerList[SelectedTopRow];
  ShowEntry(PBM);
 end else begin
 end;
end;
procedure TMyQiuBPTableSWForm.SelectYearCBChange(Sender: TObject);
begin
 _bis := _Von_BisArray[SelectYearCB.ItemIndex];
 _von := _Von_BisArray[SelectYearCB.ItemIndex+1];
 GetListe;
end;

{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.ShowEntry(_Marker:TBiomarkerItemClass);
var
   mm,hh,ss,ms : word;
begin
   DTPDate := trunc(MyDateTimeFormatToTDateTime(_Marker.Zeit));
   DateEdit.text := formatdatetime(GLV_Datummaske,DTPDate);
   decodeTime(MyDateTimeFormatToTDateTime(_Marker.Zeit),hh,mm,ss,ms);
   if hh< 10 then
      hhedit.Text := '0'+inttostr(hh)
   else
      hhedit.Text := inttostr(hh);
   if mm< 10 then
      mmedit.Text := '0'+inttostr(mm)
   else
      mmedit.Text := inttostr(mm);
   CommentEdit.Text := _Marker.Comment;
   if _Marker.TYP = 'BP' then begin      //##IGNORE_LINE
      v1edit.Text := floattostrF(_Marker.Sys,fffixed,5,0);
      v2edit.Text := floattostrF(_Marker.Dia,fffixed,5,0);
      V3edit.Text := floattostrF(_Marker.HF,fffixed,5,0);
   end;
   if _Marker.TYP = 'WEIGHT' then begin      //##IGNORE_LINE
      v1edit.Text := floattostrF(_Marker.Gewicht,fffixed,5,1);
      v2edit.Text := floattostrF(_Marker.Bauch,fffixed,5,1);
      V3edit.Text := floattostrF(_Marker.Hip,fffixed,5,1);
      DisplayBMI_WHR;
   end;
   if _Marker.TYP = 'BZ' then begin        //##IGNORE_LINE
           if AktBZUnit= 'MMOL' then begin     //##IGNORE_LINE
              v1edit.Text := floattostrF(_Marker.BZmmol,fffixed,5,2);
           end else begin
              v1edit.Text := floattostrF(_Marker.BZmg,fffixed,5,2);
           end;
   end;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.ShowListe;
var
 Rec : TBiomarkerItemClass;
 i   : integer;
begin

 if length(BioMarkerList) = 0 then begin
  showmessage(SP.Load(254));
 end else begin
  WaitPanel.Top := WebPanel5.ClientHeight div 2 - WaitPanel.Height div 2;
  WaitPanel.Left:= WebPanel5.ClientWidth div 2 - WaitPanel.Width div 2;
  WaitPanel.Visible := true;

  ValuesStringGrid.BeginUpdate;
  for i := 0 to length(BioMarkerList)-1 do begin
      rec := BiomarkerList[i];
      ValuesStringGrid.Cells[0,i+1] := formatdatetime(GLV_Datummaske + ' ' + GLV_ZeitMaskeShort,MyDateTimeFormatToTDateTime(Rec.Zeit));
      case TypusCB.ItemIndex of
       0: begin
           ValuesStringGrid.Cells[1,i+1] := floattostrf(Rec.Sys,fffixed,5,0);
           ValuesStringGrid.Cells[2,i+1] := floattostrf(Rec.Dia,fffixed,5,0);
           ValuesStringGrid.Cells[3,i+1] := floattostrf(Rec.HF,fffixed,5,0);
           ValuesStringGrid.Cells[4,i+1] := Rec.Comment;
          end;
       1: begin
           ValuesStringGrid.Cells[1,i+1] := floattostrf(Rec.Gewicht,fffixed,5,1);
           ValuesStringGrid.Cells[2,i+1] := floattostrf(Rec.Bauch,fffixed,5,1);
           ValuesStringGrid.Cells[3,i+1] := floattostrf(Rec.BMI,fffixed,5,2);
           ValuesStringGrid.Cells[4,i+1] := floattostrf(Rec.WHR,fffixed,35,2);
           ValuesStringGrid.Cells[5,i+1] := Rec.Comment;
          end;
       2: begin
           if AktBZUnit = 'MMOL' then begin
            ValuesStringGrid.Cells[1,i+1] := floattostrf(Rec.BZmmol,fffixed,5,2);
            ValuesStringGrid.Cells[2,i+1] := SP.Load(185);
           end else begin
            ValuesStringGrid.Cells[1,i+1] := floattostrf(Rec.BZmg,fffixed,5,2);
            ValuesStringGrid.Cells[2,i+1] := SP.Load(186);
           end;
           ValuesStringGrid.Cells[3,i+1] := Rec.Comment;
          end;
      end;
  end;
  ValuesStringGrid.EndUpdate;

  ValuesStringGrid.Selection.Top := SelectedTopRow;
  ValuesStringGrid.Selection.Bottom := SelectedBottomRow;

  WaitPanel.Visible  := false;
  SelectTimer.Enabled := true;
 end;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.GetListe;
var
 MessTyp : string;
 s_von   : string;
 s_bis   : string;
begin
 SelectedTopRow := -1;
 SelectedBottomRow := -1;

 case TypusCB.ItemIndex of
  0: MessTyp:='BP';
  1: MessTyp:='WEIGHT';
  2: MessTyp:='BZ';
 end;

 InitValuesStringGrid;

 WaitPanel.Top := WebPanel5.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= WebPanel5.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 if _bis > now-1 then _bis := now+1;

 s_von := MyTDateTimeFormatToString(_von);
 s_bis := MyTDateTimeFormatToString(_bis);

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetBiomarkerList',[GLV_AktUser.Token,GLV_AktUser.ID,MessTyp,s_von,s_bis],
   procedure (Response: TXDataClientResponse)
   var
    LArray : TJSArray;
    LObj   : TJSObject;
    i      : integer;
    Rec    : TBiomarkerItemClass;
   begin
    if FormClosed then exit;
    LArray := TJSArray(TJSObject(Response.Result)['value']);
    SetLength(BiomarkerList,LArray.Length);
    ValuesStringGrid.RowCount := LArray.Length + 1;
    ValuesStringGrid.BeginUpdate;
    for i := 0 to LArray.Length-1 do begin
     LObj := TJSObject(LArray[i]);
     Rec              := TBiomarkerItemClass.Create;
     Rec.TYP          := string(LObj['TYP']);       // BP,WEIGHT;BZ
     Rec.Zeit         := string(LObj['Zeit']);
     Rec.Sys          := single(LObj['Sys']);
     Rec.Dia          := single(LObj['Dia']);
     Rec.HF           := single(LObj['HF']);
     Rec.BZmmol       := single(LObj['BZmmol']);
     Rec.BZmg         := single(LObj['BZmg']);
     Rec.Gewicht      := single(LObj['Gewicht']);
     Rec.BMI          := single(LObj['BMI']);
     Rec.Bauch        := single(LObj['Bauch']);
     Rec.Hip          := single(LObj['Hip']);
     Rec.WHR          := single(LObj['WHR']);
     Rec.ID           := integer(LObj['ID']);
     Rec.Comment      := string(LObj['Comment']);
     BiomarkerList[i] := Rec;
     ValuesStringGrid.Cells[0,i+1] := formatdatetime(GLV_Datummaske + ' ' + GLV_ZeitMaskeShort,MyDateTimeFormatToTDateTime(Rec.Zeit));
     case TypusCB.ItemIndex of
      0: begin
          ValuesStringGrid.Cells[1,i+1] := floattostrf(Rec.Sys,fffixed,5,0);
          ValuesStringGrid.Cells[2,i+1] := floattostrf(Rec.Dia,fffixed,5,0);
          ValuesStringGrid.Cells[3,i+1] := floattostrf(Rec.HF,fffixed,5,0);
          ValuesStringGrid.Cells[4,i+1] := Rec.Comment;
         end;
      1: begin
          ValuesStringGrid.Cells[1,i+1] := floattostrf(Rec.Gewicht,fffixed,5,1);
          ValuesStringGrid.Cells[2,i+1] := floattostrf(Rec.Bauch,fffixed,5,1);
          ValuesStringGrid.Cells[3,i+1] := floattostrf(Rec.BMI,fffixed,5,2);
          ValuesStringGrid.Cells[4,i+1] := floattostrf(Rec.WHR,fffixed,5,2);
          ValuesStringGrid.Cells[5,i+1] := Rec.Comment;
         end;
      2: begin
          if AktBZUnit = 'MMOL' then begin
           ValuesStringGrid.Cells[1,i+1] := floattostrf(Rec.BZmmol,fffixed,5,2);
           ValuesStringGrid.Cells[2,i+1] := SP.Load(185);
          end else begin
           ValuesStringGrid.Cells[1,i+1] := floattostrf(Rec.BZmg,fffixed,5,2);
           ValuesStringGrid.Cells[2,i+1] := SP.Load(186);

          end;
          ValuesStringGrid.Cells[3,i+1] := Rec.Comment;
         end;
     end;
    end;
    ValuesStringGrid.EndUpdate;
    WaitPanel.Visible   := false;
    if LArray.Length = 0 then
     showmessage(SP.Load(254))
    else
     SelectTimer.Enabled := true;
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.InitValuesStringGrid;
begin
 case TypusCB.ItemIndex of
  0: begin     // Blutdruck
      ValuesStringGrid.ColCount := 5;
      ValuesStringGrid.RowCount := 1;
      ValuesStringGrid.FixedCols := 0;
      ValuesStringGrid.FixedRows := 1;
      ValuesStringGrid.ColWidths[0] := 140;
      ValuesStringGrid.ColWidths[1] := 80;
      ValuesStringGrid.ColWidths[2] := 80;
      ValuesStringGrid.ColWidths[3] := 80;
      //ValuesStringGrid.ColWidths[4] := ValuesStringGrid.ClientWidth - ValuesStringGrid.ColWidths[0] -ValuesStringGrid.ColWidths[1]
      //                               -ValuesStringGrid.ColWidths[2] -ValuesStringGrid.ColWidths[3];
      ValuesStringGrid.ColWidths[4] := 200;
      ValuesStringGrid.Cells[0,0] := SP.Load(112);
      ValuesStringGrid.Cells[1,0] := SP.Load(193);
      ValuesStringGrid.Cells[2,0] := SP.Load(194);
      ValuesStringGrid.Cells[3,0] := SP.Load(195);
      ValuesStringGrid.Cells[4,0] := SP.Load(120);
     end;
  1: begin     // Gewicht
      ValuesStringGrid.ColCount := 6;
      ValuesStringGrid.RowCount := 1;
      ValuesStringGrid.FixedCols := 0;
      ValuesStringGrid.FixedRows := 1;
      ValuesStringGrid.ColWidths[0] := 140;
      ValuesStringGrid.ColWidths[1] := 80;
      ValuesStringGrid.ColWidths[2] := 120;
      ValuesStringGrid.ColWidths[3] := 60;
      ValuesStringGrid.ColWidths[4] := 60;
      //ValuesStringGrid.ColWidths[5] := ValuesStringGrid.ClientWidth - ValuesStringGrid.ColWidths[0] -ValuesStringGrid.ColWidths[1]
      //                               -ValuesStringGrid.ColWidths[2] -ValuesStringGrid.ColWidths[3]-ValuesStringGrid.ColWidths[4];
      ValuesStringGrid.ColWidths[5] := 200;
      ValuesStringGrid.Cells[0,0] := SP.Load(112);
      ValuesStringGrid.Cells[1,0] := SP.Load(196);
      ValuesStringGrid.Cells[2,0] := SP.Load(197);
      ValuesStringGrid.Cells[3,0] := SP.Load(199);
      ValuesStringGrid.Cells[4,0] := SP.Load(198);
      ValuesStringGrid.Cells[5,0] := SP.Load(120);
     end;
  2: begin     // Blutzucker
      ValuesStringGrid.ColCount := 4;
      ValuesStringGrid.RowCount := 1;
      ValuesStringGrid.FixedCols := 0;
      ValuesStringGrid.FixedRows := 1;
      ValuesStringGrid.ColWidths[0] := 140;
      ValuesStringGrid.ColWidths[1] := 80;
      ValuesStringGrid.ColWidths[2] := 60;
      //ValuesStringGrid.ColWidths[3] := ValuesStringGrid.ClientWidth - ValuesStringGrid.ColWidths[0] -ValuesStringGrid.ColWidths[1]
      //                               -ValuesStringGrid.ColWidths[2];
      ValuesStringGrid.ColWidths[3] := 200;
      ValuesStringGrid.Cells[0,0] := SP.Load(112);
      ValuesStringGrid.Cells[1,0] := SP.Load(141);
      ValuesStringGrid.Cells[2,0] := SP.Load(200);
      ValuesStringGrid.Cells[3,0] := SP.Load(120);
     end;

 end;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.MMDownSEClick(Sender: TObject);
var
  mm : integer;
  MMS: string;
begin
  mm := strtoint(mmEdit.Text);
  if MM=0 then exit;
  dec(mm);
  mms := inttostr(mm);
  if length(mms)=1 then mms := '0'+mms;
  MMedit.Text := mms;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.MMEditChange(Sender: TObject);
var
  value : single;
begin
  MMValueOK := true;
  MMedit.Font.Color := clblack;  value :=0;
  try value := strtoint(MMedit.Text); except MMValueOK := false; MMedit.Font.Color := clred;end;
  if MMValueOK then begin
     if value<0 then begin MMValueOK := false; MMedit.Font.Color := clred; end;
     if value>59 then begin MMValueOK := false; MMedit.Font.Color := clred; end;
  end;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.MMUpSEClick(Sender: TObject);
var
  mm : integer;
  MMS: string;
begin
  mm := strtoint(mmEdit.Text);
  if MM=59 then exit;
  inc(mm);
  mms := inttostr(mm);
  if length(mms)=1 then mms := '0'+mms;
  MMedit.Text := mms;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.TypusCBChange(Sender: TObject);
begin
 AktType := TypusCB.ItemIndex;
 InitValuesStringGrid;
 case TypusCB.ItemIndex of
      0 : begin
            V1Label.Caption := SP.load(188);
            V2Label.caption := SP.load(189);  V2Label.visible := true;v2edit.Visible := true; V2UpSB.Visible := true; V2DownSB.Visible := true;
            V3Label.caption := SP.load(190);  V3Label.visible := true;v3edit.Visible := true; V3UpSB.Visible := true; V3DownSB.Visible := true;
            BMILabel.Visible := false;WHRLabel.Visible     := false;
            BZEinheitCB.Visible := false;
            v1max:= 300;  v1min :=0;
            v2max:= v1max;v2min :=v1min;
            v3max:= v1max;v3min :=v2min;
            v1Inc:= 1;v2Inc:=1;v3Inc:=1;
            BMIlabel.Visible := false;WHRLabel.Visible := false;
            sollgewichtlabel.visible := false;  sollgewichtBMIlabel.visible := false; SollGewichtUpSB.visible := false;SollGewichtDownSB.Visible := false;
            SollGewichtEdit.visible := false;
            SollSaveBtn.Visible := false;
          end;
      2 : begin
             V1Label.Caption := SP.load(141);
             V2Label.visible := false;v2edit.Visible := false; V2UpSB.Visible := false; V2DownSB.Visible := false;
             V3Label.visible := false;v3edit.Visible := false; V3UpSB.Visible := false; V3DownSB.Visible := false;
             BMILabel.Visible := false;WHRLabel.Visible     := false;
             BZEinheitCB.Visible := true;
             if AktBZUnit='MMOL' then  BZEinheitCB.ItemIndex := 0     //##IGNORE_LINE
             else BZEinheitCB.ItemIndex := 1;
             v1max:= 500;  v1min :=0;
             v1Inc:= 0.1;
             BMIlabel.Visible := false;WHRLabel.Visible := false;
             sollgewichtlabel.visible := false;  sollgewichtBMIlabel.visible := false;SollGewichtUpSB.visible := false;SollGewichtDownSB.Visible := false;
             SollGewichtEdit.visible := false;
             SollSaveBtn.Visible:=false;
          end;
      1 : begin
             V1Label.Caption := SP.load(142);
             V2Label.caption := SP.load(191); V2Label.visible := true; v2edit.Visible := true; V2UpSB.Visible := true; V2DownSB.Visible := true;
             V3Label.caption := SP.load(192);  V3Label.visible := true; v3edit.Visible := true; V3UpSB.Visible := true; V3DownSB.Visible := true;
             BMILabel.Visible := true;WHRLabel.Visible     := true;
             BZEinheitCB.Visible := false;
             v1max:= 300;  v1min :=0;
             v2max:= v1max;v2min :=v1min;
             v3max:= v1max;v3min :=v2min;
             v1Inc:= 0.1;v2Inc:=0.1;v3Inc:=0.1;
             BMIlabel.Visible := true;WHRLabel.Visible := true;
             sollgewichtlabel.visible := true;  sollgewichtBMIlabel.visible := true;SollGewichtUpSB.visible := true;SollGewichtDownSB.Visible := true;
             SollGewichtEdit.visible := true;
             SollSaveBtn.Visible := true;
             SollGewichtEditChange(self);
          end;

 end;
 GetListe;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.V1DownSBClick(Sender: TObject);
var
  value : single;
  nk    :byte;
begin
  if not V1ValueOK then exit;
  value := strtofloat(V1edit.Text);
  value:=value-v1inc;
  if value<v1min then value:= v1min;
  if v1inc<1 then nk:=1 else nk := 0;
  v1edit.Text := floattostrF(value,fffixed,5,nk);
  DisplayBMI_WHR;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.V1UpSBClick(Sender: TObject);
var
  value : single;
  nk    :byte;
begin
  if not V1ValueOK then exit;
  value := strtofloat(V1edit.Text);
  value:=value+v1inc;
  if value>v1max then value:= v1max;
  if v1inc<1 then nk:=1 else nk := 0;
  v1edit.Text := floattostrF(value,fffixed,5,nk);
  DisplayBMI_WHR;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.V2DownSBClick(Sender: TObject);
var
  value : single;
  nk    :byte;
begin
  if not V2ValueOK then exit;
  value := strtofloat(V2edit.Text);
  value:=value-v2inc;
  if value<v2min then value:= v2min;
  if v2inc<1 then nk:=1 else nk := 0;
  v2edit.Text := floattostrF(value,fffixed,5,nk);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.V2UpSBClick(Sender: TObject);
var
  value : single;
  nk    :byte;
begin
  if not V2ValueOK then exit;
  value := strtofloat(V2edit.Text);
  value:=value+v2inc;
  if value>v2max then value:= v2max;
  if v2inc<1 then nk:=1 else nk := 0;
  v2edit.Text := floattostrF(value,fffixed,5,nk);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.V3DownSBClick(Sender: TObject);
var
  value : single;
  nk    :byte;
begin
  if not V3ValueOK then exit;
  value := strtofloat(V3edit.Text);
  value :=value-v3inc;
  if value<v3min then value:= v3min;
  if v3inc<1 then nk:=1 else nk := 0;
  v3edit.Text := floattostrF(value,fffixed,5,nk);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.V3UpSBClick(Sender: TObject);
var
  value : single;
  nk    :byte;
begin
  if not V3ValueOK then exit;
  value := strtofloat(V3edit.Text);
  value:=value+v3inc;
  if value>v3max then value:= v3max;
  if v3inc<1 then nk:=1 else nk := 0;
  v3edit.Text := floattostrF(value,fffixed,5,nk);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.BZEinheitCBChange(Sender: TObject);
var
 newValue : single;
begin
  if (AktBZUnit ='MMOL') and (BZEinheitCB.ItemIndex=1) then begin   //##IGNORE_LINE
     newValue := strtofloat(V1Edit.Text)*BZUmrechnung;
     v1edit.Text := floattostrF(newValue,fffixed,5,2);
  end;
  if (AktBZUnit ='MG') and (BZEinheitCB.ItemIndex=0) then begin   //##IGNORE_LINE
     newValue := strtofloat(V1Edit.Text)/BZUmrechnung;
     v1edit.Text := floattostrF(newValue,fffixed,5,2);
  end;
  if BZEinheitCB.ItemIndex=0 then AktBZUnit :='MMOL' else AktBZUnit :='MG';    //##IGNORE_LINE

  ShowListe;

  MainForm.XDataWebClient.RawInvoke('IDatenService.ChangeGLUUnit',[GLV_AktUser.Token,GLV_AktUser.ID,AktBZUnit],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    if integer(TJSObject(Response.Result)['value'])<> 0 then begin
     showmessage(SP.load(204));
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.AddbtnClick(Sender: TObject);
var
  PBM           : TBiomarkerItemClass;
  Zeit          : string;
  validEntries  : Boolean;
  Count         : integer;
  ID            : integer;
begin
 if (not HHValueOK) or (not MMValueOK)  then begin
    showmessage(SP.load(203)); exit;
 end;
 Zeit := MyTDateTimeFormatToString(trunc(DTPDate)+encodeTime(strtoint(hhedit.Text),strtoint(MMedit.Text),0,0));

 if MyDateTimeFormatToTDateTime(zeit) > now() then begin
    showmessage(SP.load(201)); exit;
 end;
 if EntryExists(AktType,Zeit) then begin
    showmessage(SP.load(202)); exit;
 end;


 PBM := TBiomarkerItemClass.Create;


 case AktType of
    0 : begin
           if V1ValueOK and V2ValueOK and V3ValueOK then validEntries := true else validEntries := false;
           if not validEntries then begin
             showmessage(SP.load(203)); exit;
            end;
            OverTakeEntry(PBM);
        end;
    1 : begin
          if V1ValueOK and V2ValueOK and V3ValueOK then validEntries := true else validEntries := false;
           if not validEntries then begin
             showmessage(SP.load(203)); exit;
            end;
            OverTakeEntry(PBM);
        end;
    2 : begin
          if V1ValueOK then validEntries := true else validEntries := false;
           if not validEntries then begin
             showmessage(SP.load(203)); exit;
            end;
            OverTakeEntry(PBM);
        end;
 end;

 MainForm.XDataWebClient.RawInvoke('IDatenService.NewBiomarker',[GLV_AktUser.Token,GLV_AktUser.ID,PBM],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    ID := integer(TJSObject(Response.Result)['value']);
    if ID = 0 then begin
     PBM.free;
     showmessage(SP.load(204));
    end else begin
     Count := length(BiomarkerList);
     setlength(BiomarkerList,Count+1);
     PBM.ID := ID;
     BiomarkerList[Count] := PBM;
     QuickSortBioMarkerListDatumAbsteigend(BiomarkerList,0,length(BiomarkerList)-1);
     ValuesStringGrid.RowCount := ValuesStringGrid.RowCount + 1;
     ShowListe;
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.DelbtnClick(Sender: TObject);
var
 MyMessageDlgForm : TMyMessageDlgForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   case MyMessageDlgForm._Result of
    drYes : begin
             if SelectedTopRow = SelectedBottomRow then DeleteEntry else DeleteMultipleEntries;
            end;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;
begin
 if SelectedTopRow < 0 then begin showmessage(SP.load(180)); exit; end;
 MyMessageDlgForm := TMyMessageDlgForm.CreateNew(@AfterCreate);
 MyMessageDlgForm.Border := fbsingle;
 MyMessageDlgForm.Popup := true;
 MyMessageDlgForm.Titel := SP.load(20);//'Bestätigen';
 if SelectedTopRow = SelectedBottomRow then
  MyMessageDlgForm.MessageText := SP.load(255)
 else
  MyMessageDlgForm.MessageText := SP.load(256);
 MyMessageDlgForm.DialogType := dtYesNo;
 MyMessageDlgForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.DeleteEntry;
var
 PBM1 : TBiomarkerItemClass;
begin
 PBM1 := BiomarkerList[SelectedTopRow];
 MainForm.XDataWebClient.RawInvoke('IDatenService.DeleteBiomarker',[GLV_AktUser.Token,GLV_AktUser.ID,PBM1.ID],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    if integer(TJSObject(Response.Result)['value']) <> 0 then begin
     showmessage(SP.load(204));
    end else begin
     DeleteEntryFromBioMarkerList(BiomarkerList,SelectedTopRow);
     if length(BiomarkerList) > 0 then begin
      SelectedTopRow := 0;
      SelectedBottomRow := 0;
      ValuesStringGrid.RowCount := ValuesStringGrid.RowCount - 1;
      ShowListe;
      PBM1 := BiomarkerList[SelectedTopRow];
      ShowEntry(PBM1);
     end else begin
      SelectedTopRow := -1;
      SelectedBottomRow := -1;
      ValuesStringGrid.RowCount := 1;
      ShowListe;
     end;
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.DeleteMultipleEntries;
var
 PBM1     : TBiomarkerItemClass;
 IDs      : string;
 i,ii     : integer;
 DelCount : integer;
 DelIDs   : array of integer;
 TMPID    : integer;
begin
 IDs := '';
 DelCount := 0;
 for i := 0 to length(BiomarkerList)-1 do begin
  if (SelectedTopRow <= i) and (SelectedBottomRow >= i) then begin
   PBM1 := BiomarkerList[i];
   if length(IDs) > 0 then IDs := IDs + '@';
   IDs := IDs + inttostr(PBM1.ID);
   inc(DelCount);
   setlength(DelIDs,DelCount);
   DelIDs[DelCount-1] := PBM1.ID;
  end;
 end;

 MainForm.XDataWebClient.RawInvoke('IDatenService.DeleteMultipleBiomarker',[GLV_AktUser.Token,GLV_AktUser.ID,IDs],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    if integer(TJSObject(Response.Result)['value']) <> 0 then begin
     showmessage(SP.load(204));
    end else begin
     for i := 0 to length(DelIDs)-1 do begin
      TMPID := DelIDs[i];
      for ii := 0 to length(BiomarkerList)-1 do begin
       PBM1 := BiomarkerList[ii];
       if PBM1.ID = TMPID then begin
        DeleteEntryFromBioMarkerList(BiomarkerList,ii);
        break;
       end;
      end;
     end;

     if length(BiomarkerList) > 0 then begin
      SelectedTopRow := 0;
      SelectedBottomRow := 0;
      ValuesStringGrid.RowCount := ValuesStringGrid.RowCount - DelCount;
      ShowListe;
      PBM1 := BiomarkerList[SelectedTopRow];
      ShowEntry(PBM1);
     end else begin
      SelectedTopRow := -1;
      SelectedBottomRow := -1;
      ValuesStringGrid.RowCount := 1;
      ShowListe;
     end;
    end;
    setlength(DelIDs,0);
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.EditbtnClick(Sender: TObject);
var
 PBM1 : TBiomarkerItemClass;
begin

 if SelectedTopRow < 0 then begin
    showMessage(SP.Load(180));
    exit;
  end;

 PBM1 := BiomarkerList[SelectedTopRow];

 OverTakeEntry(PBM1);

 MainForm.XDataWebClient.RawInvoke('IDatenService.EditBiomarker',[GLV_AktUser.Token,GLV_AktUser.ID,PBM1],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    if integer(TJSObject(Response.Result)['value'])<> 0 then begin
     showmessage(SP.load(204));
    end else begin
     BiomarkerList[SelectedTopRow] := PBM1;
     ShowListe;
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.DisplayBMI_WHR;
var
 BMItxt, WHRtxt : string;
 BMI,WHR        : single;
begin
  BMItxt := SP.load(199)+glc_1blank;
  WHRtxt := SP.load(198)+glc_1blank;
  if (GLV_AktUser.Groesse>0) and v1ValueOK then begin
     BMI := strtofloat(V1Edit.Text)/(GLV_AktUser.Groesse*GLV_AktUser.Groesse/10000);
     BMILabel.Caption := BMItxt+floattostrF(BMI,fffixed,5,2);
  end else BMILabel.Caption := BMItxt;
  if v1ValueOK and v2ValueOK and (strtofloat(V3Edit.Text)>0)then begin
     WHR := strtofloat(V2Edit.Text)/strtofloat(V3Edit.Text);
     WHRLabel.Caption := WHRTXT+floattostrf(WHR,fffixed,5,2);
  end else WHRLabel.Caption := WHRTXT;
end;
{------------------------------------------------------------------------------}
function TMyQiuBPTableSWForm.GLU_mmol_to_mg(_GLUmmol : single):Single;
begin
   result := BZUmrechnung*_GLUmmol;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.selectDateClick(Sender: TObject);
var
 tmpdate        : TDate;
 DatePickerForm : TDatePickerForm;
 PBM1           : TBiomarkerItemClass;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    if not DatePickerForm.Canceled then begin
     DTPDate := trunc(DatePickerForm.DatePickerDate);
     DateEdit.text := formatdateTime(GLV_Datummaske,DTPDate);
    end;
    IsTopForm := true;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   DatePickerForm.WebDateTimePicker1.Date := tmpdate;
   DatePickerForm.ShowModal(@AfterShowModal);
  end;

begin
 IsTopForm := false;
 if SelectedTopRow >= 0 then begin
  PBM1 := BiomarkerList[SelectedTopRow];
  tmpdate := MyDateTimeFormatToTDateTime(PBM1.Zeit);
 end else tmpdate := now();
 DatePickerForm := TDatePickerForm.CreateNew(@AfterCreate);
 DatePickerForm.caption := SP.Load(127);
 DatePickerForm.Border := fbDialog;
 DatePickerForm.Popup := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.HelpBtnClick(Sender: TObject);
begin
 Application.Navigate(GetManualLink,ntBlank);//Application.Navigate(GetHelpDir + 'MyQiu-Werte.html', ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.HHDownSEClick(Sender: TObject);
var
  hh : integer;
  hhS: string;
begin
  hh := strtoint(HHEdit.Text);
  if hh=0 then exit;
  dec(hh);
  hhs := inttostr(hh);
  if length(hhs)=1 then hhs := '0'+hhs;
  HHedit.Text := hhs;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.HHEditChange(Sender: TObject);
var
  value : single;
begin
  HHValueOK := true;
  HHedit.Font.Color := clblack;  value :=0;
  try value := strtoint(HHedit.Text); except HHValueOK := false; HHedit.Font.Color := clred;end;
  if HHValueOK then begin
     if value<0 then begin HHValueOK := false; HHedit.Font.Color := clred; end;
     if value>23 then begin HHValueOK := false; HHedit.Font.Color := clred; end;
  end;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.HHUpSEClick(Sender: TObject);
var
  hh : integer;
  hhS: string;
begin
  hh := strtoint(HHEdit.Text);
  if hh=23 then exit;
  inc(hh);
  hhs := inttostr(hh);
  if length(hhs)=1 then hhs := '0'+hhs;
  HHedit.Text := hhs;
end;
{------------------------------------------------------------------------------}
function TMyQiuBPTableSWForm.GLU_mg_to_mmol(_GLUmg : single):Single;
begin
  result := _GLUmg/BZUmrechnung;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.ValuesStringGridClick(Sender: TObject);
var
 tmp : integer;
begin
 SelectedTopRow    := ValuesStringGrid.Selection.Top-1;
 SelectedBottomRow := ValuesStringGrid.Selection.Bottom-1;
 if SelectedBottomRow < SelectedTopRow then begin
  tmp := SelectedTopRow;
  SelectedTopRow := SelectedBottomRow;
  SelectedBottomRow := tmp;
 end;
 if SelectedTopRow > -1 then ShowEntry(BiomarkerList[SelectedTopRow]);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.ValuesStringGridKeyUp(Sender: TObject;
  var Key: Word; Shift: TShiftState);
begin
 ValuesStringGridClick(Self);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.ValuesStringGridSelectCell(Sender: TObject; ACol,
  ARow: Integer; var CanSelect: Boolean);
begin
 //
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.verlaufBtnClick(Sender: TObject);
var
  MyQiuTrendsForm : TMyQiuTrendsForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    IsTopForm := true;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   MyQiuTrendsForm.AktMonth  := Monthof(now());
   MyQiuTrendsForm.AktYear   := yearof(now());
   case AktType of
     0: MyQiuTrendsForm.WhatToShow:= 'BP';      //##IGNORE_LINE
     1: MyQiuTrendsForm.WhatToShow:= 'WEIGHT';   //##IGNORE_LINE
     2: MyQiuTrendsForm.WhatToShow:= 'GLU';      //##IGNORE_LINE
   end;
   MyQiuTrendsForm.ShowModal(@AfterShowModal);
  end;
begin
 IsTopForm := false;
 MyQiuTrendsForm := TMyQiuTrendsForm.CreateNew(@AfterCreate);
 MyQiuTrendsForm.Caption := SP.load(129) + GLC_1Blank + GetMyQiuUserString;
 MyQiuTrendsForm.Popup := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.WebDateTimePicker1Change(Sender: TObject);
begin
 //
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 SetLength(BiomarkerList,0);
 SetLength(_Von_BisArray,0);
 SelectTimer.Enabled := false;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.WebFormCreate(Sender: TObject);
begin
 SelectedTopRow     := -1;
 SelectedBottomRow  := -1;
 FormClosed         := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 if not IsTopForm then exit;
 MainForm.FormControlListAddTask('SHOWBPTABLE');
 Close;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.WebFormResize(Sender: TObject);
begin
 WebPanel3.Height := WebPageControl1Sheet2.ClientHeight;
 WebPanel3.Top    := 0;
 WebPanel3.Left   := WebPageControl1Sheet2.ClientWidth div 2 - WebPanel3.Width div 2;
 HelpBtn.left     := BottomPanel.ClientWidth - round(1.5*HelpBtn.width);
 ExitBtn.left     := HelpBtn.Left - ExitBtn.width - GLV_Dist;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.WebPageControl1Change(Sender: TObject);
begin
 WebPanel3.Height := WebPageControl1Sheet2.ClientHeight;
 WebPanel3.Top    := 0;
 WebPanel3.Left   := WebPageControl1Sheet2.ClientWidth div 2 - WebPanel3.Width div 2;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.InitSelectYearCB;
var
 tmpdate1,
 tmpdate2  : TDatetime;
 Count,i   : integer;
 dd,mm,yyyy: word;
begin

 tmpdate1 := (now);
 decodedate(tmpdate1,yyyy,mm,dd);
 tmpdate2 := EndOfAMonth(yyyy-1,mm);;
 Count := 2;
 SetLength(_Von_BisArray,Count);
 _Von_BisArray[0] := tmpdate1;
 _Von_BisArray[1] := tmpdate2;
 _bis := tmpdate1;
 _von := tmpdate2;

 while mygetyear(tmpdate2) > 2012 do begin
  tmpdate1 := tmpdate2;
  decodedate(tmpdate1,yyyy,mm,dd);
  tmpdate2 := EndOfAMonth(yyyy-1,mm);
  inc(Count);
  SetLength(_Von_BisArray,Count);
  _Von_BisArray[Count-1] := tmpdate2;
 end;

 SelectYearCB.Items.Clear;
 for i := 0 to length(_Von_BisArray)-2 do begin
  SelectYearCB.Items.Add(formatdatetime(GLV_Datummaske,_Von_BisArray[i]) + ' - ' + formatdatetime(GLV_Datummaske,_Von_BisArray[i+1]));
 end;
 SelectYearCB.ItemIndex := 0;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuBPTableSWForm.WebFormShow(Sender: TObject);
begin
  CaptionPanel.Color := CaptionPanelColor;
  CaptionPanel.Font.Color := CaptionPanelFontColor;
  CaptionPanel.BorderColor :=  CaptionPanelBorderColor;
  CaptionPanel.Caption := SP.load(181) + GLC_1Blank + GetMyQiuUserString;
  WebPageControl1Sheet1.Caption := SP.Load(245);
  WebPageControl1Sheet2.Caption := SP.Load(244);
  MainMenuFrame1.UpdateFrame;
  DTPDate := trunc(now());
  DateEdit.text := formatdatetime(GLV_Datummaske,DTPDate);

  InitSelectYearCB;

  Addbtn.Hint      := SP.load(258);
  Editbtn.Hint     := SP.load(259);
  Delbtn.Hint      := SP.load(260);
  VerlaufBtn.Hint  := SP.load(261);
  SollGewichtLabel.caption := SP.load(262);
  SollSaveBtn.hint := SP.load(263);
  SelectDate.Hint     := SP.load(264);
  ExitBtn.Hint    := SP.load(223);
  HelpBtn.Hint      := SP.Load(257);

  WebLabel5.Caption := SP.load(252);
  WebLabel1.Caption := SP.load(182);
  TypusCB.Items.Clear;
  TypusCB.Items.add(SP.load(140));
  TypusCB.Items.add(SP.load(142));
  TypusCB.Items.add(SP.load(141));
  TypusCB.Itemindex := 0;
  AktType :=0;
  PossibleTypes[0]  := 'BP';PossibleTypes[1]  := 'WEIGHT';PossibleTypes[2]  := 'BZ';      //##IGNORE_LINE
  WebLabel2.Caption := SP.load(112) + ' ' + SP.load(113);
  WebLabel3.Caption := SP.Load(246);
  WebLabel4.Caption := SP.Load(247);
  BMILabel.Caption := SP.load(183);
  WHRLabel.Caption := SP.load(184);
  CommentLabel.Caption :=SP.load(120);
  TypusCBChange(self);
  BZEinheitCB.Items.Clear;
  BZEinheitCB.Items.Add(SP.load(185));
  BZEinheitCB.Items.Add(SP.load(186));
  BZEinheitCB.ItemIndex := 0;
  BZEinheitCB.Visible := false;
  AktBZUnit        := 'MMOL';     //##IGNORE_LINE
  SollGewichtLabel.caption:= SP.load(187);
  if (GLV_AktUser.Groesse>0) then begin
     SollGewichtMinvalue := round(18.5*GLV_AktUser.Groesse*GLV_AktUser.Groesse/10000);
     SollGewichtMaxvalue := round(29.9*GLV_AktUser.Groesse*GLV_AktUser.Groesse/10000);
  end;
  V1ValueOK        := true;V2ValueOK        := true;V3ValueOK        := true;
  HHValueOK := true; MMValueOK := true;
  AktSollWeight := 1;
  GetAktSollWeight;
  self.WebFormResize(self);
end;

procedure TMyQiuBPTableSWForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BottomPanel := TPanel.Create(Self);
  ExitBtn := TSpeedButton.Create(Self);
  HelpBtn := TSpeedButton.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  WebPageControl1 := TPageControl.Create(Self);
  WebPageControl1Sheet1 := TTabSheet.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  SollGewichtLabel := TLabel.Create(Self);
  SollGewichtBMILabel := TLabel.Create(Self);
  verlaufBtn := TSpeedButton.Create(Self);
  TypusCB := TComboBox.Create(Self);
  SollGewichtEdit := TEdit.Create(Self);
  SollGewichtDownSB := TSpeedButton.Create(Self);
  SollGewichtUpSB := TSpeedButton.Create(Self);
  SollSaveBtn := TSpeedButton.Create(Self);
  ValuesStringGrid := TStringGrid.Create(Self);
  WaitPanel := TPanel.Create(Self);
  WebPageControl1Sheet2 := TTabSheet.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  V1Label := TLabel.Create(Self);
  V2Label := TLabel.Create(Self);
  V3Label := TLabel.Create(Self);
  CommentLabel := TLabel.Create(Self);
  BMILabel := TLabel.Create(Self);
  WHRLabel := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  CommentEdit := TEdit.Create(Self);
  V1Edit := TEdit.Create(Self);
  V1DownSB := TSpeedButton.Create(Self);
  V1UpSB := TSpeedButton.Create(Self);
  V2Edit := TEdit.Create(Self);
  V2DownSB := TSpeedButton.Create(Self);
  V2UpSB := TSpeedButton.Create(Self);
  V3Edit := TEdit.Create(Self);
  V3DownSB := TSpeedButton.Create(Self);
  V3UpSB := TSpeedButton.Create(Self);
  BZEinheitCB := TComboBox.Create(Self);
  HHEdit := TEdit.Create(Self);
  HHDownSE := TSpeedButton.Create(Self);
  HHUpSE := TSpeedButton.Create(Self);
  MMEdit := TEdit.Create(Self);
  MMDownSE := TSpeedButton.Create(Self);
  MMUpSE := TSpeedButton.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  Editbtn := TSpeedButton.Create(Self);
  Addbtn := TSpeedButton.Create(Self);
  Delbtn := TSpeedButton.Create(Self);
  selectDate := TSpeedButton.Create(Self);
  DateEdit := TEdit.Create(Self);
  TopSelectPanel := TPanel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  SelectYearCB := TComboBox.Create(Self);
  SelectTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  BottomPanel.BeforeLoadDFMValues;
  ExitBtn.BeforeLoadDFMValues;
  HelpBtn.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  WebPageControl1.BeforeLoadDFMValues;
  WebPageControl1Sheet1.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  SollGewichtLabel.BeforeLoadDFMValues;
  SollGewichtBMILabel.BeforeLoadDFMValues;
  verlaufBtn.BeforeLoadDFMValues;
  TypusCB.BeforeLoadDFMValues;
  SollGewichtEdit.BeforeLoadDFMValues;
  SollGewichtDownSB.BeforeLoadDFMValues;
  SollGewichtUpSB.BeforeLoadDFMValues;
  SollSaveBtn.BeforeLoadDFMValues;
  ValuesStringGrid.BeforeLoadDFMValues;
  WaitPanel.BeforeLoadDFMValues;
  WebPageControl1Sheet2.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  V1Label.BeforeLoadDFMValues;
  V2Label.BeforeLoadDFMValues;
  V3Label.BeforeLoadDFMValues;
  CommentLabel.BeforeLoadDFMValues;
  BMILabel.BeforeLoadDFMValues;
  WHRLabel.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  CommentEdit.BeforeLoadDFMValues;
  V1Edit.BeforeLoadDFMValues;
  V1DownSB.BeforeLoadDFMValues;
  V1UpSB.BeforeLoadDFMValues;
  V2Edit.BeforeLoadDFMValues;
  V2DownSB.BeforeLoadDFMValues;
  V2UpSB.BeforeLoadDFMValues;
  V3Edit.BeforeLoadDFMValues;
  V3DownSB.BeforeLoadDFMValues;
  V3UpSB.BeforeLoadDFMValues;
  BZEinheitCB.BeforeLoadDFMValues;
  HHEdit.BeforeLoadDFMValues;
  HHDownSE.BeforeLoadDFMValues;
  HHUpSE.BeforeLoadDFMValues;
  MMEdit.BeforeLoadDFMValues;
  MMDownSE.BeforeLoadDFMValues;
  MMUpSE.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  Editbtn.BeforeLoadDFMValues;
  Addbtn.BeforeLoadDFMValues;
  Delbtn.BeforeLoadDFMValues;
  selectDate.BeforeLoadDFMValues;
  DateEdit.BeforeLoadDFMValues;
  TopSelectPanel.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  SelectYearCB.BeforeLoadDFMValues;
  SelectTimer.BeforeLoadDFMValues;
  try
    Name := 'MyQiuBPTableSWForm';
    Width := 979;
    Height := 634;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 584;
    BottomPanel.Width := 979;
    BottomPanel.Height := 50;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderColor := clBtnFace;
    BottomPanel.Color := clWhite;
    ExitBtn.SetParentComponent(BottomPanel);
    ExitBtn.Name := 'ExitBtn';
    ExitBtn.Left := 874;
    ExitBtn.Top := 3;
    ExitBtn.Width := 41;
    ExitBtn.Height := 41;
    ExitBtn.Color := clNone;
    ExitBtn.Glyph.LoadFromFile('MyQiuBPTable_SW.BottomPanel.ExitBtn.Glyph.bmp');
    ExitBtn.HeightPercent := 100.000000000000000000;
    ExitBtn.ShowHint := True;
    ExitBtn.TabOrder := 0;
    ExitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ExitBtn, Self, 'OnClick', 'ExitBtnClick');
    HelpBtn.SetParentComponent(BottomPanel);
    HelpBtn.Name := 'HelpBtn';
    HelpBtn.Left := 935;
    HelpBtn.Top := 3;
    HelpBtn.Width := 41;
    HelpBtn.Height := 41;
    HelpBtn.Color := clNone;
    HelpBtn.Glyph.LoadFromFile('MyQiuBPTable_SW.BottomPanel.HelpBtn.Glyph.bmp');
    HelpBtn.HeightPercent := 100.000000000000000000;
    HelpBtn.ShowHint := True;
    HelpBtn.TabOrder := 1;
    HelpBtn.WidthPercent := 100.000000000000000000;
    SetEvent(HelpBtn, Self, 'OnClick', 'HelpBtnClick');
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 979;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 1;
    CaptionPanel.SetParentComponent(Self);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 86;
    CaptionPanel.Width := 979;
    CaptionPanel.Height := 25;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := clBtnFace;
    CaptionPanel.Caption := 'CaptionPanel';
    CaptionPanel.ChildOrder := 4;
    CaptionPanel.Color := clInfoBk;
    WebPageControl1.SetParentComponent(Self);
    WebPageControl1.Name := 'WebPageControl1';
    WebPageControl1.Left := 0;
    WebPageControl1.Top := 147;
    WebPageControl1.Width := 979;
    WebPageControl1.Height := 437;
    WebPageControl1.Align := alClient;
    WebPageControl1.ChildOrder := 4;
    WebPageControl1.TabIndex := 0;
    WebPageControl1.TabOrder := 3;
    SetEvent(WebPageControl1, Self, 'OnChange', 'WebPageControl1Change');
    WebPageControl1Sheet1.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet1.Name := 'WebPageControl1Sheet1';
    WebPageControl1Sheet1.Left := 0;
    WebPageControl1Sheet1.Top := 20;
    WebPageControl1Sheet1.Width := 979;
    WebPageControl1Sheet1.Height := 417;
    WebPageControl1Sheet1.Caption := 'WebPageControl1Sheet1';
    WebPanel5.SetParentComponent(WebPageControl1Sheet1);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 0;
    WebPanel5.Top := 0;
    WebPanel5.Width := 979;
    WebPanel5.Height := 417;
    WebPanel5.Align := alClient;
    WebPanel5.ChildOrder := 2;
    WebPanel1.SetParentComponent(WebPanel5);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 308;
    WebPanel1.Width := 979;
    WebPanel1.Height := 109;
    WebPanel1.Align := alBottom;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.ChildOrder := 1;
    WebPanel1.Color := clWhite;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 56;
    WebLabel1.Top := 16;
    WebLabel1.Width := 63;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    SollGewichtLabel.SetParentComponent(WebPanel1);
    SollGewichtLabel.Name := 'SollGewichtLabel';
    SollGewichtLabel.Left := 55;
    SollGewichtLabel.Top := 67;
    SollGewichtLabel.Width := 63;
    SollGewichtLabel.Height := 16;
    SollGewichtLabel.Caption := 'WebLabel1';
    SollGewichtLabel.HeightPercent := 100.000000000000000000;
    SollGewichtLabel.WidthPercent := 100.000000000000000000;
    SollGewichtBMILabel.SetParentComponent(WebPanel1);
    SollGewichtBMILabel.Name := 'SollGewichtBMILabel';
    SollGewichtBMILabel.Left := 233;
    SollGewichtBMILabel.Top := 67;
    SollGewichtBMILabel.Width := 63;
    SollGewichtBMILabel.Height := 16;
    SollGewichtBMILabel.Caption := 'WebLabel1';
    SollGewichtBMILabel.HeightPercent := 100.000000000000000000;
    SollGewichtBMILabel.WidthPercent := 100.000000000000000000;
    verlaufBtn.SetParentComponent(WebPanel1);
    verlaufBtn.Name := 'verlaufBtn';
    verlaufBtn.Left := 9;
    verlaufBtn.Top := 8;
    verlaufBtn.Width := 41;
    verlaufBtn.Height := 41;
    verlaufBtn.Color := clNone;
    verlaufBtn.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel1.verlaufBtn.Glyph.bmp');
    verlaufBtn.HeightPercent := 100.000000000000000000;
    verlaufBtn.ShowHint := True;
    verlaufBtn.TabOrder := 0;
    verlaufBtn.WidthPercent := 100.000000000000000000;
    SetEvent(verlaufBtn, Self, 'OnClick', 'verlaufBtnClick');
    TypusCB.SetParentComponent(WebPanel1);
    TypusCB.Name := 'TypusCB';
    TypusCB.Left := 133;
    TypusCB.Top := 13;
    TypusCB.Width := 120;
    TypusCB.Height := 24;
    TypusCB.HeightPercent := 100.000000000000000000;
    TypusCB.Text := 'TypusCB';
    TypusCB.WidthPercent := 100.000000000000000000;
    SetEvent(TypusCB, Self, 'OnChange', 'TypusCBChange');
    TypusCB.ItemIndex := -1;
    SollGewichtEdit.SetParentComponent(WebPanel1);
    SollGewichtEdit.Name := 'SollGewichtEdit';
    SollGewichtEdit.Left := 141;
    SollGewichtEdit.Top := 66;
    SollGewichtEdit.Width := 57;
    SollGewichtEdit.Height := 22;
    SollGewichtEdit.ChildOrder := 12;
    SollGewichtEdit.HeightPercent := 100.000000000000000000;
    SollGewichtEdit.ReadOnly := True;
    SollGewichtEdit.WidthPercent := 100.000000000000000000;
    SetEvent(SollGewichtEdit, Self, 'OnChange', 'SollGewichtEditChange');
    SollGewichtDownSB.SetParentComponent(WebPanel1);
    SollGewichtDownSB.Name := 'SollGewichtDownSB';
    SollGewichtDownSB.Left := 204;
    SollGewichtDownSB.Top := 77;
    SollGewichtDownSB.Width := 24;
    SollGewichtDownSB.Height := 24;
    SollGewichtDownSB.Color := clNone;
    SollGewichtDownSB.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel1.SollGewichtDownSB.Glyph.bmp');
    SollGewichtDownSB.HeightPercent := 100.000000000000000000;
    SollGewichtDownSB.TabOrder := 3;
    SollGewichtDownSB.WidthPercent := 100.000000000000000000;
    SetEvent(SollGewichtDownSB, Self, 'OnClick', 'SollGewichtDownSBClick');
    SollGewichtUpSB.SetParentComponent(WebPanel1);
    SollGewichtUpSB.Name := 'SollGewichtUpSB';
    SollGewichtUpSB.Left := 204;
    SollGewichtUpSB.Top := 52;
    SollGewichtUpSB.Width := 24;
    SollGewichtUpSB.Height := 24;
    SollGewichtUpSB.Color := clNone;
    SollGewichtUpSB.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel1.SollGewichtUpSB.Glyph.bmp');
    SollGewichtUpSB.HeightPercent := 100.000000000000000000;
    SollGewichtUpSB.TabOrder := 4;
    SollGewichtUpSB.WidthPercent := 100.000000000000000000;
    SetEvent(SollGewichtUpSB, Self, 'OnClick', 'SollGewichtUpSBClick');
    SollSaveBtn.SetParentComponent(WebPanel1);
    SollSaveBtn.Name := 'SollSaveBtn';
    SollSaveBtn.Left := 8;
    SollSaveBtn.Top := 55;
    SollSaveBtn.Width := 41;
    SollSaveBtn.Height := 41;
    SollSaveBtn.Color := clNone;
    SollSaveBtn.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel1.SollSaveBtn.Glyph.bmp');
    SollSaveBtn.HeightPercent := 100.000000000000000000;
    SollSaveBtn.ShowHint := True;
    SollSaveBtn.TabOrder := 5;
    SollSaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SollSaveBtn, Self, 'OnClick', 'SollSaveBtnClick');
    ValuesStringGrid.SetParentComponent(WebPanel5);
    ValuesStringGrid.Name := 'ValuesStringGrid';
    ValuesStringGrid.Left := 0;
    ValuesStringGrid.Top := 0;
    ValuesStringGrid.Width := 979;
    ValuesStringGrid.Height := 308;
    ValuesStringGrid.Align := alClient;
    ValuesStringGrid.ColCount := 3;
    ValuesStringGrid.DefaultColWidth := 100;
    ValuesStringGrid.FixedCols := 0;
    ValuesStringGrid.RowCount := 2;
    ValuesStringGrid.FixedRows := 0;
    ValuesStringGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    ValuesStringGrid.TabOrder := 1;
    SetEvent(ValuesStringGrid, Self, 'OnClick', 'ValuesStringGridClick');
    SetEvent(ValuesStringGrid, Self, 'OnKeyUp', 'ValuesStringGridKeyUp');
    SetEvent(ValuesStringGrid, Self, 'OnSelectCell', 'ValuesStringGridSelectCell');
    ValuesStringGrid.RangeEdit.Max := 100.000000000000000000;
    ValuesStringGrid.RangeEdit.Step := 1.000000000000000000;
    ValuesStringGrid.HeightPercent := 100.000000000000000000;
    ValuesStringGrid.WidthPercent := 100.000000000000000000;
    WaitPanel.SetParentComponent(WebPanel5);
    WaitPanel.Name := 'WaitPanel';
    WaitPanel.Left := 207;
    WaitPanel.Top := 136;
    WaitPanel.Width := 361;
    WaitPanel.Height := 41;
    WaitPanel.BorderColor := clBtnFace;
    WaitPanel.Caption := 'Bitte warten';
    WaitPanel.ChildOrder := 5;
    WaitPanel.Color := clWhite;
    WaitPanel.Visible := False;
    WebPageControl1Sheet2.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet2.Name := 'WebPageControl1Sheet2';
    WebPageControl1Sheet2.Left := 0;
    WebPageControl1Sheet2.Top := 20;
    WebPageControl1Sheet2.Width := 979;
    WebPageControl1Sheet2.Height := 417;
    WebPageControl1Sheet2.Caption := 'WebPageControl1Sheet2';
    WebPageControl1Sheet2.ChildOrder := 1;
    WebPanel3.SetParentComponent(WebPageControl1Sheet2);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 0;
    WebPanel3.Width := 227;
    WebPanel3.Height := 453;
    WebPanel3.BorderStyle := bsNone;
    WebPanel3.Color := clWhite;
    WebLabel2.SetParentComponent(WebPanel3);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 11;
    WebLabel2.Top := 16;
    WebLabel2.Width := 37;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Datum';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    V1Label.SetParentComponent(WebPanel3);
    V1Label.Name := 'V1Label';
    V1Label.Left := 11;
    V1Label.Top := 126;
    V1Label.Width := 45;
    V1Label.Height := 16;
    V1Label.Caption := 'Gewicht';
    V1Label.HeightPercent := 100.000000000000000000;
    V1Label.WidthPercent := 100.000000000000000000;
    V2Label.SetParentComponent(WebPanel3);
    V2Label.Name := 'V2Label';
    V2Label.Left := 11;
    V2Label.Top := 193;
    V2Label.Width := 77;
    V2Label.Height := 16;
    V2Label.Caption := 'Bauchumfang';
    V2Label.HeightPercent := 100.000000000000000000;
    V2Label.WidthPercent := 100.000000000000000000;
    V3Label.SetParentComponent(WebPanel3);
    V3Label.Name := 'V3Label';
    V3Label.Left := 11;
    V3Label.Top := 268;
    V3Label.Width := 66;
    V3Label.Height := 16;
    V3Label.Caption := 'H'#252'ftumfang';
    V3Label.HeightPercent := 100.000000000000000000;
    V3Label.WidthPercent := 100.000000000000000000;
    CommentLabel.SetParentComponent(WebPanel3);
    CommentLabel.Name := 'CommentLabel';
    CommentLabel.Left := 11;
    CommentLabel.Top := 341;
    CommentLabel.Width := 66;
    CommentLabel.Height := 16;
    CommentLabel.Caption := 'Kommentar';
    CommentLabel.HeightPercent := 100.000000000000000000;
    CommentLabel.WidthPercent := 100.000000000000000000;
    BMILabel.SetParentComponent(WebPanel3);
    BMILabel.Name := 'BMILabel';
    BMILabel.Left := 131;
    BMILabel.Top := 155;
    BMILabel.Width := 48;
    BMILabel.Height := 14;
    BMILabel.Caption := 'BMILabel';
    BMILabel.Font.Charset := DEFAULT_CHARSET;
    BMILabel.Font.Color := clWindowText;
    BMILabel.Font.Height := 14;
    BMILabel.Font.Name := 'Tahoma';
    BMILabel.Font.Style := [];
    BMILabel.HeightPercent := 100.000000000000000000;
    BMILabel.ParentFont := False;
    BMILabel.WidthPercent := 100.000000000000000000;
    WHRLabel.SetParentComponent(WebPanel3);
    WHRLabel.Name := 'WHRLabel';
    WHRLabel.Left := 133;
    WHRLabel.Top := 302;
    WHRLabel.Width := 48;
    WHRLabel.Height := 14;
    WHRLabel.Caption := 'BMILabel';
    WHRLabel.Font.Charset := DEFAULT_CHARSET;
    WHRLabel.Font.Color := clWindowText;
    WHRLabel.Font.Height := 14;
    WHRLabel.Font.Name := 'Tahoma';
    WHRLabel.Font.Style := [];
    WHRLabel.HeightPercent := 100.000000000000000000;
    WHRLabel.ParentFont := False;
    WHRLabel.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(WebPanel3);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 11;
    WebLabel3.Top := 67;
    WebLabel3.Width := 40;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Uhrzeit';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(WebPanel3);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 104;
    WebLabel4.Top := 68;
    WebLabel4.Width := 40;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Uhrzeit';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    CommentEdit.SetParentComponent(WebPanel3);
    CommentEdit.Name := 'CommentEdit';
    CommentEdit.Left := 11;
    CommentEdit.Top := 360;
    CommentEdit.Width := 196;
    CommentEdit.Height := 22;
    CommentEdit.ChildOrder := 16;
    CommentEdit.HeightPercent := 100.000000000000000000;
    CommentEdit.MaxLength := 50;
    CommentEdit.WidthPercent := 100.000000000000000000;
    V1Edit.SetParentComponent(WebPanel3);
    V1Edit.Name := 'V1Edit';
    V1Edit.Left := 11;
    V1Edit.Top := 153;
    V1Edit.Width := 85;
    V1Edit.Height := 22;
    V1Edit.ChildOrder := 12;
    V1Edit.HeightPercent := 100.000000000000000000;
    V1Edit.WidthPercent := 100.000000000000000000;
    V1DownSB.SetParentComponent(WebPanel3);
    V1DownSB.Name := 'V1DownSB';
    V1DownSB.Left := 100;
    V1DownSB.Top := 166;
    V1DownSB.Width := 24;
    V1DownSB.Height := 24;
    V1DownSB.Color := clNone;
    V1DownSB.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.V1DownSB.Glyph.bmp');
    V1DownSB.HeightPercent := 100.000000000000000000;
    V1DownSB.TabOrder := 2;
    V1DownSB.WidthPercent := 100.000000000000000000;
    SetEvent(V1DownSB, Self, 'OnClick', 'V1DownSBClick');
    V1UpSB.SetParentComponent(WebPanel3);
    V1UpSB.Name := 'V1UpSB';
    V1UpSB.Left := 100;
    V1UpSB.Top := 141;
    V1UpSB.Width := 24;
    V1UpSB.Height := 24;
    V1UpSB.Color := clNone;
    V1UpSB.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.V1UpSB.Glyph.bmp');
    V1UpSB.HeightPercent := 100.000000000000000000;
    V1UpSB.TabOrder := 3;
    V1UpSB.WidthPercent := 100.000000000000000000;
    SetEvent(V1UpSB, Self, 'OnClick', 'V1UpSBClick');
    V2Edit.SetParentComponent(WebPanel3);
    V2Edit.Name := 'V2Edit';
    V2Edit.Left := 11;
    V2Edit.Top := 222;
    V2Edit.Width := 85;
    V2Edit.Height := 22;
    V2Edit.ChildOrder := 12;
    V2Edit.HeightPercent := 100.000000000000000000;
    V2Edit.WidthPercent := 100.000000000000000000;
    V2DownSB.SetParentComponent(WebPanel3);
    V2DownSB.Name := 'V2DownSB';
    V2DownSB.Left := 100;
    V2DownSB.Top := 238;
    V2DownSB.Width := 24;
    V2DownSB.Height := 24;
    V2DownSB.Color := clNone;
    V2DownSB.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.V2DownSB.Glyph.bmp');
    V2DownSB.HeightPercent := 100.000000000000000000;
    V2DownSB.TabOrder := 5;
    V2DownSB.WidthPercent := 100.000000000000000000;
    SetEvent(V2DownSB, Self, 'OnClick', 'V2DownSBClick');
    V2UpSB.SetParentComponent(WebPanel3);
    V2UpSB.Name := 'V2UpSB';
    V2UpSB.Left := 100;
    V2UpSB.Top := 213;
    V2UpSB.Width := 24;
    V2UpSB.Height := 24;
    V2UpSB.Color := clNone;
    V2UpSB.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.V2UpSB.Glyph.bmp');
    V2UpSB.HeightPercent := 100.000000000000000000;
    V2UpSB.TabOrder := 6;
    V2UpSB.WidthPercent := 100.000000000000000000;
    SetEvent(V2UpSB, Self, 'OnClick', 'V2UpSBClick');
    V3Edit.SetParentComponent(WebPanel3);
    V3Edit.Name := 'V3Edit';
    V3Edit.Left := 11;
    V3Edit.Top := 298;
    V3Edit.Width := 85;
    V3Edit.Height := 22;
    V3Edit.ChildOrder := 12;
    V3Edit.HeightPercent := 100.000000000000000000;
    V3Edit.WidthPercent := 100.000000000000000000;
    V3DownSB.SetParentComponent(WebPanel3);
    V3DownSB.Name := 'V3DownSB';
    V3DownSB.Left := 100;
    V3DownSB.Top := 314;
    V3DownSB.Width := 24;
    V3DownSB.Height := 24;
    V3DownSB.Color := clNone;
    V3DownSB.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.V3DownSB.Glyph.bmp');
    V3DownSB.HeightPercent := 100.000000000000000000;
    V3DownSB.TabOrder := 8;
    V3DownSB.WidthPercent := 100.000000000000000000;
    SetEvent(V3DownSB, Self, 'OnClick', 'V3DownSBClick');
    V3UpSB.SetParentComponent(WebPanel3);
    V3UpSB.Name := 'V3UpSB';
    V3UpSB.Left := 100;
    V3UpSB.Top := 289;
    V3UpSB.Width := 24;
    V3UpSB.Height := 24;
    V3UpSB.Color := clNone;
    V3UpSB.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.V3UpSB.Glyph.bmp');
    V3UpSB.HeightPercent := 100.000000000000000000;
    V3UpSB.TabOrder := 9;
    V3UpSB.WidthPercent := 100.000000000000000000;
    SetEvent(V3UpSB, Self, 'OnClick', 'V3UpSBClick');
    BZEinheitCB.SetParentComponent(WebPanel3);
    BZEinheitCB.Name := 'BZEinheitCB';
    BZEinheitCB.Left := 129;
    BZEinheitCB.Top := 151;
    BZEinheitCB.Width := 78;
    BZEinheitCB.Height := 24;
    BZEinheitCB.HeightPercent := 100.000000000000000000;
    BZEinheitCB.Text := 'BZEinheitCB';
    BZEinheitCB.WidthPercent := 100.000000000000000000;
    SetEvent(BZEinheitCB, Self, 'OnChange', 'BZEinheitCBChange');
    BZEinheitCB.ItemIndex := -1;
    HHEdit.SetParentComponent(WebPanel3);
    HHEdit.Name := 'HHEdit';
    HHEdit.Left := 11;
    HHEdit.Top := 88;
    HHEdit.Width := 57;
    HHEdit.Height := 22;
    HHEdit.ChildOrder := 12;
    HHEdit.HeightPercent := 100.000000000000000000;
    HHEdit.WidthPercent := 100.000000000000000000;
    SetEvent(HHEdit, Self, 'OnChange', 'HHEditChange');
    HHDownSE.SetParentComponent(WebPanel3);
    HHDownSE.Name := 'HHDownSE';
    HHDownSE.Left := 74;
    HHDownSE.Top := 96;
    HHDownSE.Width := 24;
    HHDownSE.Height := 24;
    HHDownSE.Color := clNone;
    HHDownSE.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.HHDownSE.Glyph.bmp');
    HHDownSE.HeightPercent := 100.000000000000000000;
    HHDownSE.TabOrder := 12;
    HHDownSE.WidthPercent := 100.000000000000000000;
    SetEvent(HHDownSE, Self, 'OnClick', 'HHDownSEClick');
    HHUpSE.SetParentComponent(WebPanel3);
    HHUpSE.Name := 'HHUpSE';
    HHUpSE.Left := 74;
    HHUpSE.Top := 71;
    HHUpSE.Width := 24;
    HHUpSE.Height := 24;
    HHUpSE.Color := clNone;
    HHUpSE.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.HHUpSE.Glyph.bmp');
    HHUpSE.HeightPercent := 100.000000000000000000;
    HHUpSE.TabOrder := 13;
    HHUpSE.WidthPercent := 100.000000000000000000;
    SetEvent(HHUpSE, Self, 'OnClick', 'HHUpSEClick');
    MMEdit.SetParentComponent(WebPanel3);
    MMEdit.Name := 'MMEdit';
    MMEdit.Left := 104;
    MMEdit.Top := 89;
    MMEdit.Width := 57;
    MMEdit.Height := 22;
    MMEdit.ChildOrder := 12;
    MMEdit.HeightPercent := 100.000000000000000000;
    MMEdit.WidthPercent := 100.000000000000000000;
    SetEvent(MMEdit, Self, 'OnChange', 'MMEditChange');
    MMDownSE.SetParentComponent(WebPanel3);
    MMDownSE.Name := 'MMDownSE';
    MMDownSE.Left := 167;
    MMDownSE.Top := 96;
    MMDownSE.Width := 24;
    MMDownSE.Height := 24;
    MMDownSE.Color := clNone;
    MMDownSE.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.MMDownSE.Glyph.bmp');
    MMDownSE.HeightPercent := 100.000000000000000000;
    MMDownSE.TabOrder := 15;
    MMDownSE.WidthPercent := 100.000000000000000000;
    SetEvent(MMDownSE, Self, 'OnClick', 'MMDownSEClick');
    MMUpSE.SetParentComponent(WebPanel3);
    MMUpSE.Name := 'MMUpSE';
    MMUpSE.Left := 167;
    MMUpSE.Top := 71;
    MMUpSE.Width := 24;
    MMUpSE.Height := 24;
    MMUpSE.Color := clNone;
    MMUpSE.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.MMUpSE.Glyph.bmp');
    MMUpSE.HeightPercent := 100.000000000000000000;
    MMUpSE.TabOrder := 16;
    MMUpSE.WidthPercent := 100.000000000000000000;
    SetEvent(MMUpSE, Self, 'OnClick', 'MMUpSEClick');
    WebPanel4.SetParentComponent(WebPanel3);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 398;
    WebPanel4.Width := 227;
    WebPanel4.Height := 55;
    WebPanel4.Align := alBottom;
    WebPanel4.BorderStyle := bsNone;
    WebPanel4.ChildOrder := 25;
    WebPanel4.Color := clWhite;
    Editbtn.SetParentComponent(WebPanel4);
    Editbtn.Name := 'Editbtn';
    Editbtn.Left := 19;
    Editbtn.Top := 8;
    Editbtn.Width := 41;
    Editbtn.Height := 41;
    Editbtn.Color := clNone;
    Editbtn.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel4.Editbtn.Glyph.bmp');
    Editbtn.HeightPercent := 100.000000000000000000;
    Editbtn.ShowHint := True;
    Editbtn.TabOrder := 0;
    Editbtn.WidthPercent := 100.000000000000000000;
    SetEvent(Editbtn, Self, 'OnClick', 'EditbtnClick');
    Addbtn.SetParentComponent(WebPanel4);
    Addbtn.Name := 'Addbtn';
    Addbtn.Left := 66;
    Addbtn.Top := 8;
    Addbtn.Width := 41;
    Addbtn.Height := 41;
    Addbtn.Color := clNone;
    Addbtn.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel4.Addbtn.Glyph.bmp');
    Addbtn.HeightPercent := 100.000000000000000000;
    Addbtn.ShowHint := True;
    Addbtn.TabOrder := 1;
    Addbtn.WidthPercent := 100.000000000000000000;
    SetEvent(Addbtn, Self, 'OnClick', 'AddbtnClick');
    Delbtn.SetParentComponent(WebPanel4);
    Delbtn.Name := 'Delbtn';
    Delbtn.Left := 113;
    Delbtn.Top := 8;
    Delbtn.Width := 41;
    Delbtn.Height := 41;
    Delbtn.Color := clNone;
    Delbtn.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel4.Delbtn.Glyph.bmp');
    Delbtn.HeightPercent := 100.000000000000000000;
    Delbtn.ShowHint := True;
    Delbtn.TabOrder := 2;
    Delbtn.WidthPercent := 100.000000000000000000;
    SetEvent(Delbtn, Self, 'OnClick', 'DelbtnClick');
    selectDate.SetParentComponent(WebPanel3);
    selectDate.Name := 'selectDate';
    selectDate.Left := 166;
    selectDate.Top := 17;
    selectDate.Width := 41;
    selectDate.Height := 41;
    selectDate.Color := clNone;
    selectDate.Glyph.LoadFromFile('MyQiuBPTable_SW.WebPanel3.selectDate.Glyph.bmp');
    selectDate.HeightPercent := 100.000000000000000000;
    selectDate.ShowHint := True;
    selectDate.TabOrder := 18;
    selectDate.WidthPercent := 100.000000000000000000;
    SetEvent(selectDate, Self, 'OnClick', 'selectDateClick');
    DateEdit.SetParentComponent(WebPanel3);
    DateEdit.Name := 'DateEdit';
    DateEdit.Left := 11;
    DateEdit.Top := 35;
    DateEdit.Width := 149;
    DateEdit.Height := 22;
    DateEdit.ChildOrder := 26;
    DateEdit.HeightPercent := 100.000000000000000000;
    DateEdit.ReadOnly := True;
    DateEdit.WidthPercent := 100.000000000000000000;
    TopSelectPanel.SetParentComponent(Self);
    TopSelectPanel.Name := 'TopSelectPanel';
    TopSelectPanel.Left := 0;
    TopSelectPanel.Top := 111;
    TopSelectPanel.Width := 979;
    TopSelectPanel.Height := 36;
    TopSelectPanel.Align := alTop;
    TopSelectPanel.BorderColor := clBtnFace;
    TopSelectPanel.ChildOrder := 4;
    TopSelectPanel.Color := clWhite;
    WebLabel5.SetParentComponent(TopSelectPanel);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 10;
    WebLabel5.Top := 9;
    WebLabel5.Width := 51;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Zeitraum';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    SelectYearCB.SetParentComponent(TopSelectPanel);
    SelectYearCB.Name := 'SelectYearCB';
    SelectYearCB.Left := 105;
    SelectYearCB.Top := 6;
    SelectYearCB.Width := 175;
    SelectYearCB.Height := 24;
    SelectYearCB.HeightPercent := 100.000000000000000000;
    SelectYearCB.Text := 'SelectYearCB';
    SelectYearCB.WidthPercent := 100.000000000000000000;
    SetEvent(SelectYearCB, Self, 'OnChange', 'SelectYearCBChange');
    SelectYearCB.ItemIndex := -1;
    SelectTimer.SetParentComponent(Self);
    SelectTimer.Name := 'SelectTimer';
    SelectTimer.Enabled := False;
    SetEvent(SelectTimer, Self, 'OnTimer', 'SelectTimerTimer');
    SelectTimer.Left := 440;
    SelectTimer.Top := 72;
  finally
    BottomPanel.AfterLoadDFMValues;
    ExitBtn.AfterLoadDFMValues;
    HelpBtn.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    WebPageControl1.AfterLoadDFMValues;
    WebPageControl1Sheet1.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    SollGewichtLabel.AfterLoadDFMValues;
    SollGewichtBMILabel.AfterLoadDFMValues;
    verlaufBtn.AfterLoadDFMValues;
    TypusCB.AfterLoadDFMValues;
    SollGewichtEdit.AfterLoadDFMValues;
    SollGewichtDownSB.AfterLoadDFMValues;
    SollGewichtUpSB.AfterLoadDFMValues;
    SollSaveBtn.AfterLoadDFMValues;
    ValuesStringGrid.AfterLoadDFMValues;
    WaitPanel.AfterLoadDFMValues;
    WebPageControl1Sheet2.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    V1Label.AfterLoadDFMValues;
    V2Label.AfterLoadDFMValues;
    V3Label.AfterLoadDFMValues;
    CommentLabel.AfterLoadDFMValues;
    BMILabel.AfterLoadDFMValues;
    WHRLabel.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    CommentEdit.AfterLoadDFMValues;
    V1Edit.AfterLoadDFMValues;
    V1DownSB.AfterLoadDFMValues;
    V1UpSB.AfterLoadDFMValues;
    V2Edit.AfterLoadDFMValues;
    V2DownSB.AfterLoadDFMValues;
    V2UpSB.AfterLoadDFMValues;
    V3Edit.AfterLoadDFMValues;
    V3DownSB.AfterLoadDFMValues;
    V3UpSB.AfterLoadDFMValues;
    BZEinheitCB.AfterLoadDFMValues;
    HHEdit.AfterLoadDFMValues;
    HHDownSE.AfterLoadDFMValues;
    HHUpSE.AfterLoadDFMValues;
    MMEdit.AfterLoadDFMValues;
    MMDownSE.AfterLoadDFMValues;
    MMUpSE.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    Editbtn.AfterLoadDFMValues;
    Addbtn.AfterLoadDFMValues;
    Delbtn.AfterLoadDFMValues;
    selectDate.AfterLoadDFMValues;
    DateEdit.AfterLoadDFMValues;
    TopSelectPanel.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    SelectYearCB.AfterLoadDFMValues;
    SelectTimer.AfterLoadDFMValues;
  end;
end;

end.