unit MyQiuSettings;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Graphics, WEBLib.Buttons, WEBLib.ExtCtrls;

type
  TMyQiuSettingsForm = class(TForm)
    MQSE: TSpinEdit;
    WebLabel1: TLabel;
    SaveBtn: TBitBtn;
    CancelBtn: TBitBtn;
    WebMemo1: TMemo;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure SaveBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
    FormClosed : boolean;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  MyQiuSettingsForm: TMyQiuSettingsForm;

implementation

uses KVT,XData.Web.Client,Main,CommonFunctions;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TMyQiuSettingsForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuSettingsForm.SaveBtnClick(Sender: TObject);
var
 Messquality : integer;

 procedure OnResponse(Response:TXDataClientResponse);
  begin
   if FormClosed then exit;
   case byte(TJSObject(Response.Result)['value']) of
     0: begin
         close;
        end;
     1 : begin
         showmessage(SP.load(51));//'Der Benutzer konnte nicht gespeichert werden! Bitte versuchen Sie es noch einmal.');
         close;
        end;
    end;
  end;

begin
 Messquality := MQSE.Value;
 MainForm.XDataWebClient.RawInvoke('IDatenService.SetmyQiuSettings',[GLV_AktUser.Token,GLV_AktUser.ID,Messquality],@OnResponse);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuSettingsForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 Action := caFree;
 FormClosed := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuSettingsForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuSettingsForm.WebFormResize(Sender: TObject);
begin
 WebLabel1.Left := self.ClientWidth div 2 - WebLabel1.Width div 2;
 MQSE.Left := self.ClientWidth div 2 - MQSE.Width div 2;
 WebMemo1.Left := self.ClientWidth div 2 - WebMemo1.Width div 2;
 SaveBtn.left := self.ClientWidth div 2 - SaveBtn.Width - GLV_Dist;
 CancelBtn.left := self.ClientWidth div 2 + GLV_Dist;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuSettingsForm.WebFormShow(Sender: TObject);
procedure OnResponse(Response:TXDataClientResponse);
  begin
   if FormClosed then exit;
   MQSE.Value := integer(TJSObject(Response.Result)['value']);
  end;

begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.GetmyQiuSettings',[GLV_AktUser.Token,GLV_AktUser.ID],@OnResponse);
 Weblabel1.Caption := SP.load(135);
 WebMemo1.Lines.Clear;
 WebMemo1.Lines.add(SP.load(136));
 SaveBtn.Caption := SP.load(74);
 CancelBtn.Caption := SP.load(27);
 MQSE.SetFocus;
 self.WebFormResize(self);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuSettingsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  MQSE := TSpinEdit.Create(Self);
  SaveBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  WebMemo1 := TMemo.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  MQSE.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  try
    Name := 'MyQiuSettingsForm';
    Width := 360;
    Height := 220;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 135;
    WebLabel1.Top := 25;
    WebLabel1.Width := 71;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Messqualit'#228't';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    MQSE.SetParentComponent(Self);
    MQSE.Name := 'MQSE';
    MQSE.Left := 135;
    MQSE.Top := 47;
    MQSE.Width := 65;
    MQSE.Height := 22;
    MQSE.AutoSize := False;
    MQSE.BorderStyle := bsSingle;
    MQSE.ChildOrder := 1;
    MQSE.Color := clWhite;
    MQSE.Increment := 1;
    MQSE.MaxValue := 99;
    MQSE.MinValue := 50;
    MQSE.Role := '';
    MQSE.Value := 0;
    SaveBtn.SetParentComponent(Self);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 69;
    SaveBtn.Top := 137;
    SaveBtn.Width := 100;
    SaveBtn.Height := 30;
    SaveBtn.Caption := 'OK';
    SaveBtn.Color := clNone;
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.MaterialGlyph := 'save';
    SaveBtn.TabOrder := 1;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 192;
    CancelBtn.Top := 137;
    CancelBtn.Width := 100;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 2;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 8;
    WebMemo1.Top := 77;
    WebMemo1.Width := 344;
    WebMemo1.Height := 54;
    WebMemo1.AutoSize := False;
    WebMemo1.BorderStyle := bsNone;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('WebMemo1');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 0;
    WebMemo1.ShowFocus := False;
    WebMemo1.WidthPercent := 100.000000000000000000;
  finally
    WebLabel1.AfterLoadDFMValues;
    MQSE.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
  end;
end;

end.