unit SelectServerBaseURL;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls;

type
  TSelectServerBaseURLForm = class(TForm)
    HostCB: TComboBox;
    PortSE: TSpinEdit;
    BaseURLEdit: TEdit;
    WebButton1: TButton;
    WebButton2: TButton;
    procedure HostCBChange(Sender: TObject);
    procedure PortSEChange(Sender: TObject);
    procedure BuildBaseURL;
    procedure ShowBaseURL;
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  SelectServerBaseURLForm: TSelectServerBaseURLForm;

implementation

uses KVT,Main;



{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TSelectServerBaseURLForm.HostCBChange(Sender: TObject);
begin
 GLV_ServerHostName := HostCB.Text;
 BuildBaseURL;
end;
{------------------------------------------------------------------------------}
procedure TSelectServerBaseURLForm.BuildBaseURL;
begin
 GLV_ServerBaseURL := GLV_ServerHostName + ':' + inttostr(GLV_ServerPort) + '/BioSign/myQiu';
 ShowBaseURL;
end;
{------------------------------------------------------------------------------}
procedure TSelectServerBaseURLForm.ShowBaseURL;
begin
 BaseURLEdit.Text := GLV_ServerBaseURL;
end;
{------------------------------------------------------------------------------}
procedure TSelectServerBaseURLForm.WebButton1Click(Sender: TObject);
begin
 MainForm.Connect;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TSelectServerBaseURLForm.WebButton2Click(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TSelectServerBaseURLForm.WebFormShow(Sender: TObject);
begin
 HostCBChange(Self);
 PortSEChange(Self);
end;
{------------------------------------------------------------------------------}
procedure TSelectServerBaseURLForm.PortSEChange(Sender: TObject);
begin
 GLV_ServerPort := PortSE.Value;
 BuildBaseURL;
end;

procedure TSelectServerBaseURLForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  HostCB := TComboBox.Create(Self);
  PortSE := TSpinEdit.Create(Self);
  BaseURLEdit := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);

  HostCB.BeforeLoadDFMValues;
  PortSE.BeforeLoadDFMValues;
  BaseURLEdit.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  try
    Name := 'SelectServerBaseURLForm';
    Width := 616;
    Height := 244;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    HostCB.SetParentComponent(Self);
    HostCB.Name := 'HostCB';
    HostCB.Left := 32;
    HostCB.Top := 32;
    HostCB.Width := 145;
    HostCB.Height := 23;
    HostCB.HeightPercent := 100.000000000000000000;
    HostCB.Text := 'http://localhost';
    HostCB.WidthPercent := 100.000000000000000000;
    SetEvent(HostCB, Self, 'OnChange', 'HostCBChange');
    HostCB.ItemIndex := 0;
    HostCB.Items.BeginUpdate;
    try
      HostCB.Items.Clear;
      HostCB.Items.Add('http://localhost');
      HostCB.Items.Add('https://localhost');
      HostCB.Items.Add('http://biosign-server.de');
      HostCB.Items.Add('https://biosign-server.de');
    finally
      HostCB.Items.EndUpdate;
    end;
    PortSE.SetParentComponent(Self);
    PortSE.Name := 'PortSE';
    PortSE.Left := 200;
    PortSE.Top := 32;
    PortSE.Width := 89;
    PortSE.Height := 22;
    PortSE.AutoSize := False;
    PortSE.BorderStyle := bsSingle;
    PortSE.ChildOrder := 1;
    PortSE.Color := clWhite;
    PortSE.Increment := 1;
    PortSE.MaxValue := 10000;
    PortSE.MinValue := 0;
    PortSE.Role := '';
    PortSE.Value := 8000;
    SetEvent(PortSE, Self, 'OnChange', 'PortSEChange');
    BaseURLEdit.SetParentComponent(Self);
    BaseURLEdit.Name := 'BaseURLEdit';
    BaseURLEdit.Left := 32;
    BaseURLEdit.Top := 64;
    BaseURLEdit.Width := 257;
    BaseURLEdit.Height := 22;
    BaseURLEdit.ChildOrder := 2;
    BaseURLEdit.HeightPercent := 100.000000000000000000;
    BaseURLEdit.ReadOnly := True;
    BaseURLEdit.Text := 'BaseURLEdit';
    BaseURLEdit.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 224;
    WebButton1.Top := 128;
    WebButton1.Width := 96;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Connect';
    WebButton1.ChildOrder := 3;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 248;
    WebButton2.Top := 192;
    WebButton2.Width := 96;
    WebButton2.Height := 25;
    WebButton2.Caption := 'Close';
    WebButton2.ChildOrder := 4;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
  finally
    HostCB.AfterLoadDFMValues;
    PortSE.AfterLoadDFMValues;
    BaseURLEdit.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
  end;
end;

end.