
unit TestProperties;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  XData.Web.Client, Vcl.Imaging.pngimage, WEBLib.Buttons, Vcl.Graphics;

type
  TTestPropertiesForm = class(TForm)
    CancelBtn: TBitBtn;
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    WebEdit2: TEdit;
    WebLabel2: TLabel;
    WebEdit3: TEdit;
    WebLabel3: TLabel;
    WebEdit4: TEdit;
    WebLabel4: TLabel;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    OS              : string;
    OSVersion       : string;
    Device          : string;
    Hardware        : string;
  protected procedure LoadDFMValues; override; end;

var
  TestPropertiesForm: TTestPropertiesForm;

implementation

uses Main, KVT;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TTestPropertiesForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TTestPropertiesForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TTestPropertiesForm.WebFormCreate(Sender: TObject);
begin
 //
end;
{------------------------------------------------------------------------------}
procedure TTestPropertiesForm.WebFormResize(Sender: TObject);
begin
 CancelBtn.Left := self.ClientWidth div 2 - CancelBtn.Width div 2;
end;
{------------------------------------------------------------------------------}
procedure TTestPropertiesForm.WebFormShow(Sender: TObject);
begin
 CancelBtn.Caption := SP.Load(24);
 WebLabel1.Caption := SP.Load(286);//'Betriebssystem';
 WebEdit1.Text := OS;
 WebLabel2.Caption := SP.Load(287);//'Betriebssystem';'Betriebssystem Version';
 WebEdit2.Text := OSVersion;
 WebLabel3.Caption := SP.Load(288);//'Betriebssystem';'Device';
 WebEdit3.Text := Device;
 WebLabel4.Caption := SP.Load(289);//'Betriebssystem';'Hardware';
 WebEdit4.Text := Hardware;

 end;


procedure TTestPropertiesForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  WebEdit2 := TEdit.Create(Self);
  WebEdit3 := TEdit.Create(Self);
  WebEdit4 := TEdit.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  WebEdit2.BeforeLoadDFMValues;
  WebEdit3.BeforeLoadDFMValues;
  WebEdit4.BeforeLoadDFMValues;
  try
    Name := 'TestPropertiesForm';
    Width := 304;
    Height := 344;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 8;
    WebLabel1.Top := 24;
    WebLabel1.Width := 86;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Betriebssystem';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 80;
    WebLabel2.Width := 133;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Betriebssystem Version';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 136;
    WebLabel3.Width := 37;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Device';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 192;
    WebLabel4.Width := 56;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Hardware';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 94;
    CancelBtn.Top := 269;
    CancelBtn.Width := 96;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 8;
    WebEdit1.Top := 46;
    WebEdit1.Width := 273;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 2;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.ReadOnly := True;
    WebEdit1.Text := 'WebEdit1';
    WebEdit1.WidthPercent := 100.000000000000000000;
    WebEdit2.SetParentComponent(Self);
    WebEdit2.Name := 'WebEdit2';
    WebEdit2.Left := 8;
    WebEdit2.Top := 102;
    WebEdit2.Width := 273;
    WebEdit2.Height := 22;
    WebEdit2.ChildOrder := 2;
    WebEdit2.HeightPercent := 100.000000000000000000;
    WebEdit2.ReadOnly := True;
    WebEdit2.Text := 'WebEdit1';
    WebEdit2.WidthPercent := 100.000000000000000000;
    WebEdit3.SetParentComponent(Self);
    WebEdit3.Name := 'WebEdit3';
    WebEdit3.Left := 8;
    WebEdit3.Top := 158;
    WebEdit3.Width := 273;
    WebEdit3.Height := 22;
    WebEdit3.ChildOrder := 2;
    WebEdit3.HeightPercent := 100.000000000000000000;
    WebEdit3.ReadOnly := True;
    WebEdit3.Text := 'WebEdit1';
    WebEdit3.WidthPercent := 100.000000000000000000;
    WebEdit4.SetParentComponent(Self);
    WebEdit4.Name := 'WebEdit4';
    WebEdit4.Left := 8;
    WebEdit4.Top := 214;
    WebEdit4.Width := 273;
    WebEdit4.Height := 22;
    WebEdit4.ChildOrder := 2;
    WebEdit4.HeightPercent := 100.000000000000000000;
    WebEdit4.ReadOnly := True;
    WebEdit4.Text := 'WebEdit1';
    WebEdit4.WidthPercent := 100.000000000000000000;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    WebEdit2.AfterLoadDFMValues;
    WebEdit3.AfterLoadDFMValues;
    WebEdit4.AfterLoadDFMValues;
  end;
end;

end.