unit DatePicker;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.Buttons, WEBLib.StdCtrls;

type
  TDatePickerForm = class(TForm)
    SaveBtn: TBitBtn;
    CancelBtn: TBitBtn;
    WebDateTimePicker1: TDateTimePicker;
    procedure CancelBtnClick(Sender: TObject);
    procedure SaveBtnClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    Canceled        : boolean;
    DatePickerDate  : TDateTime;
  protected procedure LoadDFMValues; override; end;

var
  DatePickerForm: TDatePickerForm;

implementation

uses CommonFunctions,KVT;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TDatePickerForm.CancelBtnClick(Sender: TObject);
begin
 DatePickerDate := WebDateTimePicker1.Date;
 Canceled := true;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TDatePickerForm.SaveBtnClick(Sender: TObject);
begin
 DatePickerDate := WebDateTimePicker1.Date;
 Canceled := false;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TDatePickerForm.WebFormResize(Sender: TObject);
begin
 WebDateTimePicker1.Left := self.ClientWidth div 2 - WebDateTimePicker1.Width div 2;
 SaveBtn.left := self.ClientWidth div 2 - SaveBtn.Width - GLV_Dist;
 CancelBtn.left := self.ClientWidth div 2 + GLV_Dist;
end;
{------------------------------------------------------------------------------}
procedure TDatePickerForm.WebFormShow(Sender: TObject);
begin
 self.WebFormResize(self);
end;
{------------------------------------------------------------------------------}
procedure TDatePickerForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  SaveBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  WebDateTimePicker1 := TDateTimePicker.Create(Self);

  SaveBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  WebDateTimePicker1.BeforeLoadDFMValues;
  try
    Name := 'DatePickerForm';
    Width := 270;
    Height := 147;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    SaveBtn.SetParentComponent(Self);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 21;
    SaveBtn.Top := 73;
    SaveBtn.Width := 100;
    SaveBtn.Height := 30;
    SaveBtn.Caption := 'OK';
    SaveBtn.Color := clNone;
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.MaterialGlyph := 'save';
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 144;
    CancelBtn.Top := 73;
    CancelBtn.Width := 100;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 1;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    WebDateTimePicker1.SetParentComponent(Self);
    WebDateTimePicker1.Name := 'WebDateTimePicker1';
    WebDateTimePicker1.Left := 40;
    WebDateTimePicker1.Top := 32;
    WebDateTimePicker1.Width := 169;
    WebDateTimePicker1.Height := 22;
    WebDateTimePicker1.BorderStyle := bsSingle;
    WebDateTimePicker1.ChildOrder := 2;
    WebDateTimePicker1.Color := clWhite;
    WebDateTimePicker1.Date := 44270.330106446760000000;
    WebDateTimePicker1.Role := '';
    WebDateTimePicker1.Text := '';
  finally
    SaveBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    WebDateTimePicker1.AfterLoadDFMValues;
  end;
end;

end.
