unit myQiuFragebogen;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,DatenServiceClasses, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Buttons, Vcl.Grids, WEBLib.Grids, WEBLib.ExtCtrls,
  Vcl.Forms, MainMenuFrame, Vcl.Graphics, Vcl.Imaging.pngimage, Types;

type
  TmyQiuFragebogenForm = class(TForm)
    BottomPanel: TPanel;
    CancelBtn: TSpeedButton;
    RepaintTimer: TTimer;
    MainMenuFrame1: TMainMenuFrame1;
    CaptionPanel: TPanel;
    MiddlePanel: TPanel;
    FragebogenStringGrid: TStringGrid;
    WaitPanel: TPanel;
    ListPanel: TPanel;
    SelectTimer: TTimer;
    StartFragebogenBtn: TButton;
    ShowFrageTimer: TTimer;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure RepaintTimerTimer(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure GetFragenListe;
    procedure FragebogenStringGridClick(Sender: TObject);
    procedure SelectTimerTimer(Sender: TObject);
    procedure FragebogenStringGridKeyUp(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure FragebogenStringGridSelectCell(Sender: TObject; ACol,
      ARow: Integer; var CanSelect: Boolean);
    procedure ShowFragebogenListe;
    procedure Show_myQiuTestFragenAntwortenTrackbarForm(_Rec:TFragenAntwortenItemClass);
    procedure Show_myQiuTestFragenAntwortenMCForm(_Rec:TFragenAntwortenItemClass);
    procedure GetFragebogenListe;
    procedure StartFragebogenBtnClick(Sender: TObject);
    procedure ShowFrage;
    procedure ShowFrageTimerTimer(Sender: TObject);
    procedure SaveResultToServer;
    procedure AskForSaveResultToServer;
  private
    { Private-Deklarationen }
    AktFragebogenRec   : TFragebogenClass;
    AnzahlFragen       : integer;
    AktFrage           : TFragenAntwortenItemClass;
    AktFrageIDX        : integer;
  public
    { Public-Deklarationen }
    FormClosed         : boolean;
    FragenListe        : TArray<TFragenAntwortenItemClass>;
    FragebogenList     : TArray<TFragebogenClass>;
    SelectedTopRow     : integer;
    SelectedBottomRow  : integer;
    MessungsID         : string;
    _Caption           : string;
  protected procedure LoadDFMValues; override; end;

var
  myQiuFragebogenForm: TmyQiuFragebogenForm;

implementation

uses Main,XData.Web.Client,KVT,MyMsgDialog,CommonFunctions,System.Generics.Collections,
     myQiuTestFragenAntwortenTrackbar,myQiuTestFragenAntwortenMC;

{$R *.dfm}


{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 SetLength(FragenListe,0);
 SetLength(FragebogenList,0);
 FormClosed := true;
 RepaintTimer.Enabled := false;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.WebFormResize(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 CancelBtn.left       := BottomPanel.ClientWidth - CancelBtn.width - GLV_Dist;
 StartFragebogenBtn.Left := MiddlePanel.ClientWidth div 2 - StartFragebogenBtn.Width div 2;
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.WebFormShow(Sender: TObject);
begin
 CaptionPanel.Color := CaptionPanelColor;
 CaptionPanel.Font.Color := CaptionPanelFontColor;
 CaptionPanel.BorderColor :=  CaptionPanelBorderColor;
 CaptionPanel.Caption := _Caption + GLC_1Blank + GetMyQiuUserString;
 MainMenuFrame1.UpdateFrame;
 CancelBtn.Hint := SP.Load(223);
 CancelBtn.ShowHint := true;
 MainMenuFrame1.UpdateFrame;

 FragebogenStringGrid.ColCount := 2;
 FragebogenStringGrid.RowCount := 1;
 FragebogenStringGrid.FixedCols := 0;
 FragebogenStringGrid.FixedRows := 1;
 FragebogenStringGrid.ColWidths[0] := 300;
 FragebogenStringGrid.ColWidths[1] := 100;
 FragebogenStringGrid.Cells[0,0] := SP.Load(145);
 FragebogenStringGrid.Cells[1,0] := SP.Load(315);

 GetFragebogenListe;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.RepaintTimerTimer(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.GetFragenListe;
begin

 WaitPanel.Top := ListPanel.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= ListPanel.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 SetLength(FragenListe,0);
 AktFrageIDX := -1;
 AnzahlFragen:= 0;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetStandAloneFragebogenItemListe',[GLV_AktUser.Token,GLV_AktUser.UUID,AktFragebogenRec.ID],
   procedure (Response: TXDataClientResponse)
   var
    LArray : TJSArray;
    LObj   : TJSObject;
    i      : integer;
    Rec    : TFragenAntwortenItemClass;
   begin
    if FormClosed then exit;
    LArray := TJSArray(TJSObject(Response.Result)['value']);
    SetLength(FragenListe,LArray.Length);
    for i := 0 to LArray.Length-1 do begin
     LObj := TJSObject(LArray[i]);
     Rec  := TFragenAntwortenItemClass.Create;
     Rec.ID     := string(LObj['ID']);
     Rec.Zeit   := 2;
     Rec.Text   := string(LObj['Text']);
     Rec.Typ    := integer(LObj['Typ']);
     Rec.Min    := integer(LObj['Min']);
     Rec.Max    := integer(LObj['Max']);
     Rec.Steps  := integer(LObj['Steps']);
     Rec.BezMin := string(LObj['BezMin']);
     Rec.BezMax := string(LObj['BezMax']);
     Rec.MC1_Text  := string(LObj['MC_Text1']);
     Rec.MC1_Value := integer(LObj['MC_Value1']);
     Rec.MC2_Text  := string(LObj['MC_Text2']);
     Rec.MC2_Value := integer(LObj['MC_Value2']);
     Rec.MC3_Text  := string(LObj['MC_Text3']);
     Rec.MC3_Value := integer(LObj['MC_Value3']);
     Rec.MC4_Text  := string(LObj['MC_Text4']);
     Rec.MC4_Value := integer(LObj['MC_Value4']);
     Rec.MC5_Text  := string(LObj['MC_Text5']);
     Rec.MC5_Value := integer(LObj['MC_Value5']);
     FragenListe[i] := Rec;
     inc(AnzahlFragen);
    end;
    WaitPanel.Visible := false;
    if AnzahlFragen > 0 then begin
     AktFrageIDX := 0;
     ShowFrage;
    end;

   end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.GetFragebogenListe;
begin
 SelectedTopRow := -1;
 SelectedBottomRow := -1;


 WaitPanel.Top := ListPanel.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= ListPanel.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 SetLength(FragebogenList,0);

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetStandAloneFrageBogenListe',[GLV_AktUser.Token,GLV_AktUser.UUID],
   procedure (Response: TXDataClientResponse)
   var
    LArray : TJSArray;
    LObj   : TJSObject;
    i      : integer;
    Rec    : TFragebogenClass;
   begin
    if FormClosed then exit;
    LArray := TJSArray(TJSObject(Response.Result)['value']);
    SetLength(FragebogenList,LArray.Length);
    FragebogenStringGrid.RowCount := Length(FragebogenList)+1;
    FragebogenStringGrid.BeginUpdate;
    for i := 0 to LArray.Length-1 do begin
     LObj := TJSObject(LArray[i]);
     Rec  := TFragebogenClass.Create;
     Rec.ID     := string(LObj['ID']);
     Rec.NoOfItems   := integer(LObj['NoOfItems']);
     Rec.Bezeichnung   := string(LObj['Bezeichnung']);
     Rec.Aktiv := boolean(LObj['Aktiv']);
     FragebogenStringGrid.Cells[0,i+1] := Rec.Bezeichnung;
     FragebogenStringGrid.Cells[1,i+1] := inttostr(Rec.NoOfItems);
     FragebogenList[i] := Rec;
    end;
    FragebogenStringGrid.EndUpdate;
    WaitPanel.Visible := false;
    SelectTimer.Enabled := true;
   end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.ShowFragebogenListe;
var
 i       : integer;
 Rec     : TFragebogenClass;
begin
 FragebogenStringGrid.RowCount := Length(FragebogenList)+1;
 FragebogenStringGrid.BeginUpdate;
 for i := 0 to Length(FragebogenList)-1 do begin
  Rec := FragebogenList[i];
  FragebogenStringGrid.Cells[0,i+1] := Rec.Bezeichnung;
  FragebogenStringGrid.Cells[1,i+1] := inttostr(Rec.NoOfItems);
 end;
 FragebogenStringGrid.EndUpdate;
 FragebogenStringGrid.Selection.Top    := SelectedTopRow;
 FragebogenStringGrid.Selection.Bottom := SelectedBottomRow;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.FragebogenStringGridClick(
  Sender: TObject);
var
 tmp : integer;
begin
 SelectedTopRow    := FragebogenStringGrid.Selection.Top-1;
 SelectedBottomRow := FragebogenStringGrid.Selection.Bottom-1;
 if SelectedBottomRow < SelectedTopRow then begin
  tmp := SelectedTopRow;
  SelectedTopRow := SelectedBottomRow;
  SelectedBottomRow := tmp;
 end;
 AktFragebogenRec := FragebogenList[SelectedTopRow];
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.FragebogenStringGridKeyUp(
  Sender: TObject; var Key: Word; Shift: TShiftState);
begin
 FragebogenStringGridClick(Self);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.FragebogenStringGridSelectCell(
  Sender: TObject; ACol, ARow: Integer; var CanSelect: Boolean);
begin
 //
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.SelectTimerTimer(Sender: TObject);
begin
 if length(FragebogenList) > 0 then begin
  SelectTimer.Enabled := false;
  SelectedTopRow := 0;
  SelectedBottomRow := 0;
  StartFragebogenBtn.Enabled := true;
  if length(FragebogenList) = 1 then StartFragebogenBtnClick(Self);
 end else begin
  StartFragebogenBtn.Enabled := false;
 end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.StartFragebogenBtnClick(Sender: TObject);
begin
 if SelectedTopRow= -1 then begin
  showmessage(SP.Load(253));
  exit;
 end;

 AktFragebogenRec := FragebogenList[SelectedTopRow];
 GetFragenListe;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.ShowFrageTimerTimer(Sender: TObject);
begin
 ShowFrageTimer.Enabled := false;
 ShowFrage;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.ShowFrage;
begin
 if AktFrageIDX = -1 then exit;
 if AktFrageIDX > AnzahlFragen-1 then begin
  AskForSaveResultToServer;
  exit;
 end;
 AktFrage := FragenListe[AktFrageIDX];
 case AktFrage.Typ of
  0: Show_myQiuTestFragenAntwortenTrackbarForm(AktFrage);
  1: Show_myQiuTestFragenAntwortenMCForm(AktFrage);
  2: Show_myQiuTestFragenAntwortenMCForm(AktFrage);
 end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.SaveResultToServer;
var
 _Items : string;
 i      : integer;
 Rec    : TFragenAntwortenItemClass;
 DatumUhrzeit : string;
begin

 if Length(FragenListe) = 0 then exit;

 _Items := '';

 for i := 0 to Length(FragenListe)-1 do begin
  Rec := FragenListe[i];
  _Items := _Items + Rec.ID + '|';
  _Items := _Items + inttostr(Rec.Wert) + ';';
 end;

 DatumUhrzeit := formatDateTime(MyDateTimeFormat,now());

 MainForm.XDataWebClient.RawInvoke('IDatenService.SaveStandAloneFragebogen',[GLV_AktUser.Token,GLV_AktUser.UUID,AktFragebogenRec.ID,DatumUhrzeit,_Items],
  procedure (Response: TXDataClientResponse)
     begin
      if FormClosed then exit;
      case byte(TJSObject(Response.Result)['value']) of
       1: begin
           FormClosed := true;
           Close;
          end;
       0: showmessage(SP.load(204));
      end;
     end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.AskForSaveResultToServer;
var
 MyMessageDlgForm : TMyMessageDlgForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   case MyMessageDlgForm._Result of
    drYes : begin
             SaveResultToServer;
            end;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;

begin
 if SelectedTopRow < 0 then begin showmessage(SP.load(124)); exit; end;
 MyMessageDlgForm := TMyMessageDlgForm.CreateNew(@AfterCreate);
 MyMessageDlgForm.Border := fbsingle;
 MyMessageDlgForm.Popup := true;
 MyMessageDlgForm.Titel := SP.load(20);
 MyMessageDlgForm.MessageText := SP.load(316);
 MyMessageDlgForm.DialogType := dtYesNo;
 MyMessageDlgForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.Show_myQiuTestFragenAntwortenTrackbarForm(_Rec:TFragenAntwortenItemClass);
var
 myQiuTestFragenAntwortenTrackbarForm : TmyQiuTestFragenAntwortenTrackbarForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   // evaluate AValue and get values from form ...
   if myQiuTestFragenAntwortenTrackbarForm.ChangesDone then begin
    FragenListe[AktFrageIDX] := myQiuTestFragenAntwortenTrackbarForm.Rec;
    if myQiuTestFragenAntwortenTrackbarForm.Next then begin
     inc(AktFrageIDX);
    end else begin
     dec(AktFrageIDX);
    end;
    ShowFrageTimer.Enabled := true;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuTestFragenAntwortenTrackbarForm.ShowModal(@AfterShowModal);
  end;
begin
 myQiuTestFragenAntwortenTrackbarForm := TmyQiuTestFragenAntwortenTrackbarForm.CreateNew(@AfterCreate);
 myQiuTestFragenAntwortenTrackbarForm.caption := AktFragebogenRec.Bezeichnung + ' - ' + SP.load(317) + GLC_1Blank + inttostr(AktFrageIDX+1) + '/' + inttostr(AnzahlFragen);
 myQiuTestFragenAntwortenTrackbarForm.Border := fbDialog;
 myQiuTestFragenAntwortenTrackbarForm.Popup := true;
 myQiuTestFragenAntwortenTrackbarForm.Rec := _Rec;
 myQiuTestFragenAntwortenTrackbarForm.EditMode := false;
 myQiuTestFragenAntwortenTrackbarForm.FrageIDX := AktFrageIDX;

end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.Show_myQiuTestFragenAntwortenMCForm(_Rec:TFragenAntwortenItemClass);
var
 myQiuTestFragenAntwortenMCForm : TmyQiuTestFragenAntwortenMCForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   // evaluate AValue and get values from form ...
   if myQiuTestFragenAntwortenMCForm.ChangesDone then begin
    FragenListe[AktFrageIDX] := myQiuTestFragenAntwortenMCForm.Rec;
    if myQiuTestFragenAntwortenMCForm.Next then begin
     inc(AktFrageIDX);
    end else begin
     dec(AktFrageIDX);
    end;
    ShowFrageTimer.Enabled := true;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuTestFragenAntwortenMCForm.ShowModal(@AfterShowModal);
  end;
begin
  myQiuTestFragenAntwortenMCForm := TmyQiuTestFragenAntwortenMCForm.CreateNew(@AfterCreate);
  myQiuTestFragenAntwortenMCForm.caption := AktFragebogenRec.Bezeichnung + ' - ' + SP.load(317) + GLC_1Blank + inttostr(AktFrageIDX+1) + '/' +  inttostr(AnzahlFragen);
  myQiuTestFragenAntwortenMCForm.Border := fbDialog;
  myQiuTestFragenAntwortenMCForm.Popup := true;
  myQiuTestFragenAntwortenMCForm.Rec := _Rec;
  myQiuTestFragenAntwortenMCForm.EditMode := false;
  myQiuTestFragenAntwortenMCForm.FrageIDX := AktFrageIDX;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuFragebogenForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BottomPanel := TPanel.Create(Self);
  CancelBtn := TSpeedButton.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  ListPanel := TPanel.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  MiddlePanel := TPanel.Create(Self);
  StartFragebogenBtn := TButton.Create(Self);
  FragebogenStringGrid := TStringGrid.Create(Self);
  WaitPanel := TPanel.Create(Self);
  RepaintTimer := TTimer.Create(Self);
  SelectTimer := TTimer.Create(Self);
  ShowFrageTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  BottomPanel.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  MainMenuFrame1.LoginBtn.BeforeLoadDFMValues;
  MainMenuFrame1.KontoBtn.BeforeLoadDFMValues;
  MainMenuFrame1.RegistBtn.BeforeLoadDFMValues;
  ListPanel.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  MiddlePanel.BeforeLoadDFMValues;
  StartFragebogenBtn.BeforeLoadDFMValues;
  FragebogenStringGrid.BeforeLoadDFMValues;
  WaitPanel.BeforeLoadDFMValues;
  RepaintTimer.BeforeLoadDFMValues;
  SelectTimer.BeforeLoadDFMValues;
  ShowFrageTimer.BeforeLoadDFMValues;
  try
    Name := 'myQiuFragebogenForm';
    Width := 817;
    Height := 607;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 557;
    BottomPanel.Width := 817;
    BottomPanel.Height := 50;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.ChildOrder := 8;
    BottomPanel.Color := clWhite;
    CancelBtn.SetParentComponent(BottomPanel);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 745;
    CancelBtn.Top := 4;
    CancelBtn.Width := 41;
    CancelBtn.Height := 41;
    CancelBtn.Color := clNone;
    CancelBtn.Glyph.LoadFromFile('myQiuFragebogen.BottomPanel.CancelBtn.Glyph.bmp');
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.ShowHint := True;
    CancelBtn.TabOrder := 0;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 817;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 1;
    MainMenuFrame1.LoginBtn.TabOrder := 3;
    MainMenuFrame1.KontoBtn.TabOrder := 0;
    MainMenuFrame1.RegistBtn.TabOrder := 1;
    ListPanel.SetParentComponent(Self);
    ListPanel.Name := 'ListPanel';
    ListPanel.Left := 0;
    ListPanel.Top := 86;
    ListPanel.Width := 817;
    ListPanel.Height := 471;
    ListPanel.Align := alClient;
    ListPanel.ChildOrder := 6;
    CaptionPanel.SetParentComponent(ListPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 817;
    CaptionPanel.Height := 26;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := clBtnFace;
    CaptionPanel.Caption := 'CaptionPanel';
    CaptionPanel.ChildOrder := 4;
    CaptionPanel.Color := clWhite;
    MiddlePanel.SetParentComponent(ListPanel);
    MiddlePanel.Name := 'MiddlePanel';
    MiddlePanel.Left := 0;
    MiddlePanel.Top := 409;
    MiddlePanel.Width := 817;
    MiddlePanel.Height := 62;
    MiddlePanel.Align := alBottom;
    MiddlePanel.BorderStyle := bsNone;
    MiddlePanel.ChildOrder := 1;
    MiddlePanel.Color := clWhite;
    StartFragebogenBtn.SetParentComponent(MiddlePanel);
    StartFragebogenBtn.Name := 'StartFragebogenBtn';
    StartFragebogenBtn.Left := 272;
    StartFragebogenBtn.Top := 16;
    StartFragebogenBtn.Width := 249;
    StartFragebogenBtn.Height := 33;
    StartFragebogenBtn.Caption := 'Fragebogen starten';
    StartFragebogenBtn.HeightPercent := 100.000000000000000000;
    StartFragebogenBtn.WidthPercent := 100.000000000000000000;
    SetEvent(StartFragebogenBtn, Self, 'OnClick', 'StartFragebogenBtnClick');
    FragebogenStringGrid.SetParentComponent(ListPanel);
    FragebogenStringGrid.Name := 'FragebogenStringGrid';
    FragebogenStringGrid.Left := 0;
    FragebogenStringGrid.Top := 26;
    FragebogenStringGrid.Width := 817;
    FragebogenStringGrid.Height := 383;
    FragebogenStringGrid.Align := alClient;
    FragebogenStringGrid.ColCount := 3;
    FragebogenStringGrid.DefaultColWidth := 100;
    FragebogenStringGrid.FixedColor := clGray;
    FragebogenStringGrid.FixedCols := 0;
    FragebogenStringGrid.RowCount := 2;
    FragebogenStringGrid.FixedRows := 0;
    FragebogenStringGrid.GradientEndColor := clWhite;
    FragebogenStringGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    FragebogenStringGrid.TabOrder := 2;
    SetEvent(FragebogenStringGrid, Self, 'OnClick', 'FragebogenStringGridClick');
    SetEvent(FragebogenStringGrid, Self, 'OnKeyUp', 'FragebogenStringGridKeyUp');
    SetEvent(FragebogenStringGrid, Self, 'OnSelectCell', 'FragebogenStringGridSelectCell');
    FragebogenStringGrid.FixedTextColor := clWhite;
    FragebogenStringGrid.RangeEdit.Max := 100.000000000000000000;
    FragebogenStringGrid.RangeEdit.Step := 1.000000000000000000;
    FragebogenStringGrid.HeightPercent := 100.000000000000000000;
    FragebogenStringGrid.WidthPercent := 100.000000000000000000;
    WaitPanel.SetParentComponent(ListPanel);
    WaitPanel.Name := 'WaitPanel';
    WaitPanel.Left := 207;
    WaitPanel.Top := 296;
    WaitPanel.Width := 361;
    WaitPanel.Height := 41;
    WaitPanel.BorderColor := clBtnFace;
    WaitPanel.Caption := 'Bitte warten';
    WaitPanel.ChildOrder := 3;
    WaitPanel.Color := clWhite;
    WaitPanel.Visible := False;
    RepaintTimer.SetParentComponent(Self);
    RepaintTimer.Name := 'RepaintTimer';
    RepaintTimer.Enabled := False;
    RepaintTimer.Interval := 500;
    SetEvent(RepaintTimer, Self, 'OnTimer', 'RepaintTimerTimer');
    RepaintTimer.Left := 440;
    RepaintTimer.Top := 134;
    SelectTimer.SetParentComponent(Self);
    SelectTimer.Name := 'SelectTimer';
    SelectTimer.Enabled := False;
    SelectTimer.Interval := 500;
    SetEvent(SelectTimer, Self, 'OnTimer', 'SelectTimerTimer');
    SelectTimer.Left := 344;
    SelectTimer.Top := 136;
    ShowFrageTimer.SetParentComponent(Self);
    ShowFrageTimer.Name := 'ShowFrageTimer';
    ShowFrageTimer.Enabled := False;
    ShowFrageTimer.Interval := 500;
    SetEvent(ShowFrageTimer, Self, 'OnTimer', 'ShowFrageTimerTimer');
    ShowFrageTimer.Left := 544;
    ShowFrageTimer.Top := 136;
  finally
    BottomPanel.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    MainMenuFrame1.LoginBtn.AfterLoadDFMValues;
    MainMenuFrame1.KontoBtn.AfterLoadDFMValues;
    MainMenuFrame1.RegistBtn.AfterLoadDFMValues;
    ListPanel.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    MiddlePanel.AfterLoadDFMValues;
    StartFragebogenBtn.AfterLoadDFMValues;
    FragebogenStringGrid.AfterLoadDFMValues;
    WaitPanel.AfterLoadDFMValues;
    RepaintTimer.AfterLoadDFMValues;
    SelectTimer.AfterLoadDFMValues;
    ShowFrageTimer.AfterLoadDFMValues;
  end;
end;

end.