unit myQiuTrends;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Graphics, WEBLib.Buttons, WEBLib.ExtCtrls, Vcl.Forms, MainMenuFrame,
  System.DateUtils,KVT, Vcl.Imaging.pngimage;

type
  TMyQiuTrendsForm = class(TForm)
    TopPanel: TPanel;
    MoveRightBtn: TSpeedButton;
    MoveFRBtn: TSpeedButton;
    MoveLeftBtn: TSpeedButton;
    MoveFLBtn: TSpeedButton;
    TodayBtn: TSpeedButton;
    gotoBtn: TSpeedButton;
    SourceLabel: TLabel;
    SourceCB: TComboBox;
    MoveFFLBtn: TSpeedButton;
    MoveFFRBtn: TSpeedButton;
    BottomPanel: TPanel;
    CancelBtn: TSpeedButton;
    WebImageControl1: TImageControl;
    RepaintTimer: TTimer;
    CaptionPanel: TPanel;
    MainMenuFrame1: TMainMenuFrame1;
    WaitPanel: TPanel;
    HelpBtn: TSpeedButton;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure MoveFFLBtnClick(Sender: TObject);
    procedure MoveFLBtnClick(Sender: TObject);
    procedure MoveLeftBtnClick(Sender: TObject);
    procedure MoveRightBtnClick(Sender: TObject);
    procedure MoveFRBtnClick(Sender: TObject);
    procedure MoveFFRBtnClick(Sender: TObject);
    procedure TodayBtnClick(Sender: TObject);
    procedure gotoBtnClick(Sender: TObject);
    procedure SourceCBChange(Sender: TObject);
    procedure ShowTrends;
    procedure RepaintTimerTimer(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure HelpBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
    SourceList : TStringList;
  public
    { Public-Deklarationen }
    AktMonth        : word;
    AktYear         : word;
    WhatToShow      : string;
    Targetdate      : TDateTime; // Stichtag. ab dem die Trends rückwärts gerechnet werden
    FormClosed      : boolean;
  protected procedure LoadDFMValues; override; end;

var
  MyQiuTrendsForm: TMyQiuTrendsForm;

implementation

uses DatePicker,XData.Web.Client,Main,DatenServiceClasses,CommonFunctions;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.gotoBtnClick(Sender: TObject);
var
 tmpdate        : TDate;
 Rest           : double;
 DatePickerForm : TDatePickerForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    if not DatePickerForm.Canceled then begin
     tmpdate := DatePickerForm.DatePickerDate;
     tmpdate := 1.0*(trunc(tmpdate)) + Rest;
     TargetDate:= (tmpDate);
     ShowTrends;
    end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   DatePickerForm.WebDateTimePicker1.Date := tmpdate;
   DatePickerForm.ShowModal(@AfterShowModal);
  end;

begin
 tmpdate := trunc(now());
 Rest    := now() - tmpdate;
 DatePickerForm := TDatePickerForm.CreateNew(@AfterCreate);
 DatePickerForm.caption := SP.Load(127);
 DatePickerForm.Border := fbDialog;
 DatePickerForm.Popup := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.HelpBtnClick(Sender: TObject);
begin
 Application.Navigate(GetManualLink,ntBlank);//Application.Navigate(GetManualLink,ntBlank);//Application.Navigate(GetHelpDir + 'MyQiu-Trends.html', ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.ShowTrends;
var
 _Height1,_Width1 : integer;
 _Source          : string;
 _TargetDate      : string;
begin


 _Height1    := WebImageControl1.Height * GLV_PictureCreateSizeFactor;
 _Width1     := WebImageControl1.Width * GLV_PictureCreateSizeFactor;
 _Source     := SourceList.Strings[SourceCB.ItemIndex];
 _TargetDate := MyTDateTimeFormatToString(targetDate);

 WaitPanel.Top := self.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= self.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetTrendsDownloadLink',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                                           _TargetDate,_Source,
                                                                           _Height1,_Width1],
  procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    WebImageControl1.URL := string(TJSObject(Response.Result)['value']);
    WaitPanel.Visible := false;
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.MoveFFLBtnClick(Sender: TObject);
var
  m1   : word;
  m2   : word;
  count: word;
begin
  count := 0;m1 := monthof(targetDate);
  while count < 3 do begin
        TargetDate := TargetDate-1;
        m2 := monthof(targetDate);
        if m2<>m1 then  begin inc(count);M1:=m2; end;
  end;
  ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.MoveFFRBtnClick(Sender: TObject);
var
  m1   : word;
  m2   : word;
  count: word;
begin
  if targetDate>=trunc(now()) then exit;
  count := 0;m1 := monthof(targetDate);
  while count < 4 do begin
        TargetDate := TargetDate+1;
        m2 := monthof(targetDate);
        if m2<>m1 then  begin inc(count);M1:=m2; end;
  end;
  TargetDate := TargetDate-1;
  ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.MoveFLBtnClick(Sender: TObject);
var
  m1   : word;
  m2   : word;
  count: word;
begin
  count := 0;m1 := monthof(targetDate);
  while count < 1 do begin
        TargetDate := TargetDate-1;
        m2 := monthof(targetDate);
        if m2<>m1 then begin inc(count);M1:=m2; end;
  end;
  ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.MoveFRBtnClick(Sender: TObject);
var
  m1   : word;
  m2   : word;
  count: word;
begin
  if targetDate>=trunc(now()) then exit;
  count := 0;m1 := monthof(targetDate);
  while count < 2 do begin
        TargetDate := TargetDate+1;
        m2 := monthof(targetDate);
        if m2<>m1 then  begin inc(count);M1:=m2; end;
  end;
  TargetDate := TargetDate-1;
  ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.MoveLeftBtnClick(Sender: TObject);
begin
   Targetdate := targetDate-7;
   ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.MoveRightBtnClick(Sender: TObject);
begin
 Targetdate := targetDate+7;
 if targetDate>now() then Targetdate := trunc(now());
 ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.RepaintTimerTimer(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.SourceCBChange(Sender: TObject);
begin
 ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.TodayBtnClick(Sender: TObject);
begin
 Targetdate := trunc(now());
 ShowTrends;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 FormClosed := true;
 RepaintTimer.Enabled := false;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.WebFormResize(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 SourceLabel.Left := 2*GLV_Dist;
 SourceCB.Left    := SourceLabel.Left + SourceLabel.Width + GLV_Dist;


 MoveRightBtn.left := TopPanel.ClientWidth div 2 + GLV_Dist div 2;
 MoveFRBtn.left    := MoveRightBtn.left + MoveRightBtn.width + GLV_Dist;
 MoveFFRBtn.left   := MoveFRBtn.left + MoveFRBtn.width + GLV_Dist;

 TodayBtn.left     := MoveFFRBtn.left + MoveFFRBtn.width + round(3*GLV_Dist);
 gotoBtn.left      := TodayBtn.left + TodayBtn.width + GLV_Dist;

 MoveLeftBtn.left  := TopPanel.ClientWidth div 2 - GLV_Dist div 2 - MoveLeftBtn.width;
 MoveFLBtn.left    := MoveLeftBtn.left - GLV_Dist - MoveFLBtn.width;
 MoveFFLBtn.left   := MoveFLBtn.left - GLV_Dist - MoveFFLBtn.width;


 HelpBtn.left       := BottomPanel.ClientWidth - round(1.5*HelpBtn.width);
 CancelBtn.left     := HelpBtn.Left - CancelBtn.width - GLV_Dist;
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuTrendsForm.WebFormShow(Sender: TObject);
var
 _Month,_Year : word;
begin
 CaptionPanel.Color := CaptionPanelColor;
 CaptionPanel.Font.Color := CaptionPanelFontColor;
 CaptionPanel.BorderColor :=  CaptionPanelBorderColor;
 CaptionPanel.Caption := SP.load(129) + GLC_1Blank + GetMyQiuUserString;
 SourceLabel.Caption  := SP.Load(133);
 MainMenuFrame1.UpdateFrame;

 MoveFLBtn.Hint    := SP.load(217);
 MoveLeftBtn.Hint  := SP.load(218);
 MoveRightBtn.Hint := SP.load(219);
 MoveFRBtn.Hint    := SP.load(220);
 TodayBtn.Hint     := SP.load(221);
 gotobtn.hint      := SP.load(222);
 CancelBtn.Hint    := SP.load(223);
 MoveFFLBtn.Hint    := SP.load(234);
 MoveFFRBtn.Hint    := SP.load(235);
 HelpBtn.Hint      := SP.Load(257);

 SourceList := TStringList.Create;
 SourceCB.Items.Clear;
  // SourceList enthält die Codierungen für den Server, nicht übersetzen!!
  if WhatToShow='HRV' then begin
     SourceCB.Items.Add('Biofeedback');
     SourceList.Add('BFB');     //##IGNORE_LINE
     SourceCB.Items.Add('Qiu');
     SourceList.Add('QIU');     //##IGNORE_LINE
     SourceCB.Items.Add('PC-Biofeedback');
     SourceList.Add('PCBFB');    //##IGNORE_LINE
     SourceCB.Items.Add('RSA-Messung');
     SourceList.Add('RSA');      //##IGNORE_LINE
     SourceCB.Items.Add('5 min. HRV');
     SourceList.Add('KURZHRV');    //##IGNORE_LINE
     SourceCB.Visible := true;SourceLabel.Visible := true;
  end;
  if WhatToShow='BP' then begin     //##IGNORE_LINE
     SourceCB.Items.Add('Blutdruck');
     SourceList.Add('BP');         //##IGNORE_LINE
     SourceCB.Visible := false;SourceLabel.Visible := false;
  end;
  if WhatToShow='WEIGHT' then begin  //##IGNORE_LINE
     SourceCB.Items.Add('Gewicht');
     SourceList.Add('WEIGHT');       //##IGNORE_LINE
     SourceCB.Visible := false; SourceLabel.Visible := false;
  end;
  if WhatToShow='GLU' then begin  //##IGNORE_LINE
     SourceCB.Items.Add('Blutzucker');
     SourceList.Add('GLU');           //##IGNORE_LINE
     SourceCB.Visible := false;SourceLabel.Visible := false;
  end;
  SourceCB.ItemIndex := 0;

  // TargetDate inital berechnen
  _month := monthof(now());_Year := yearof(now());


  if (_month=Aktmonth) and (_year=aktyear) then begin
   TargetDate := trunc(now());  // aktueller Monat, also heutigen Tag verwenden
  end else begin
   // Target auf den letzen Tag im gewählten Monat setzen
   TargetDate := encodeDate(aktyear,aktmonth,25);
   while monthof(TargetDate)=Aktmonth do Targetdate := TargetDate+1;
   Targetdate := TargetDate-1;
  end;

  ShowTrends;

end;

procedure TMyQiuTrendsForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  TopPanel := TPanel.Create(Self);
  SourceLabel := TLabel.Create(Self);
  MoveRightBtn := TSpeedButton.Create(Self);
  MoveFRBtn := TSpeedButton.Create(Self);
  MoveLeftBtn := TSpeedButton.Create(Self);
  MoveFLBtn := TSpeedButton.Create(Self);
  TodayBtn := TSpeedButton.Create(Self);
  gotoBtn := TSpeedButton.Create(Self);
  SourceCB := TComboBox.Create(Self);
  MoveFFLBtn := TSpeedButton.Create(Self);
  MoveFFRBtn := TSpeedButton.Create(Self);
  BottomPanel := TPanel.Create(Self);
  CancelBtn := TSpeedButton.Create(Self);
  HelpBtn := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  WaitPanel := TPanel.Create(Self);
  RepaintTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  WebImageControl1.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  SourceLabel.BeforeLoadDFMValues;
  MoveRightBtn.BeforeLoadDFMValues;
  MoveFRBtn.BeforeLoadDFMValues;
  MoveLeftBtn.BeforeLoadDFMValues;
  MoveFLBtn.BeforeLoadDFMValues;
  TodayBtn.BeforeLoadDFMValues;
  gotoBtn.BeforeLoadDFMValues;
  SourceCB.BeforeLoadDFMValues;
  MoveFFLBtn.BeforeLoadDFMValues;
  MoveFFRBtn.BeforeLoadDFMValues;
  BottomPanel.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  HelpBtn.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  MainMenuFrame1.LoginBtn.BeforeLoadDFMValues;
  MainMenuFrame1.KontoBtn.BeforeLoadDFMValues;
  MainMenuFrame1.RegistBtn.BeforeLoadDFMValues;
  WaitPanel.BeforeLoadDFMValues;
  RepaintTimer.BeforeLoadDFMValues;
  try
    Name := 'MyQiuTrendsForm';
    Width := 873;
    Height := 601;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 162;
    WebImageControl1.Width := 873;
    WebImageControl1.Height := 389;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Align := alClient;
    WebImageControl1.ChildOrder := 3;
    TopPanel.SetParentComponent(Self);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 112;
    TopPanel.Width := 873;
    TopPanel.Height := 50;
    TopPanel.HeightPercent := 100.000000000000000000;
    TopPanel.WidthPercent := 100.000000000000000000;
    TopPanel.Align := alTop;
    TopPanel.BorderColor := clSilver;
    TopPanel.BorderStyle := bsNone;
    TopPanel.ChildOrder := 5;
    TopPanel.Color := clWhite;
    SourceLabel.SetParentComponent(TopPanel);
    SourceLabel.Name := 'SourceLabel';
    SourceLabel.Left := 7;
    SourceLabel.Top := 16;
    SourceLabel.Width := 36;
    SourceLabel.Height := 16;
    SourceLabel.Caption := 'Quelle';
    SourceLabel.HeightPercent := 100.000000000000000000;
    SourceLabel.WidthPercent := 100.000000000000000000;
    MoveRightBtn.SetParentComponent(TopPanel);
    MoveRightBtn.Name := 'MoveRightBtn';
    MoveRightBtn.Left := 410;
    MoveRightBtn.Top := 5;
    MoveRightBtn.Width := 41;
    MoveRightBtn.Height := 41;
    MoveRightBtn.Color := clNone;
    MoveRightBtn.Glyph.LoadFromFile('myQiuTrends.TopPanel.MoveRightBtn.Glyph.bmp');
    MoveRightBtn.HeightPercent := 100.000000000000000000;
    MoveRightBtn.ShowHint := True;
    MoveRightBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveRightBtn, Self, 'OnClick', 'MoveRightBtnClick');
    MoveFRBtn.SetParentComponent(TopPanel);
    MoveFRBtn.Name := 'MoveFRBtn';
    MoveFRBtn.Left := 457;
    MoveFRBtn.Top := 5;
    MoveFRBtn.Width := 41;
    MoveFRBtn.Height := 41;
    MoveFRBtn.Color := clNone;
    MoveFRBtn.Glyph.LoadFromFile('myQiuTrends.TopPanel.MoveFRBtn.Glyph.bmp');
    MoveFRBtn.HeightPercent := 100.000000000000000000;
    MoveFRBtn.ShowHint := True;
    MoveFRBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFRBtn, Self, 'OnClick', 'MoveFRBtnClick');
    MoveLeftBtn.SetParentComponent(TopPanel);
    MoveLeftBtn.Name := 'MoveLeftBtn';
    MoveLeftBtn.Left := 354;
    MoveLeftBtn.Top := 5;
    MoveLeftBtn.Width := 41;
    MoveLeftBtn.Height := 41;
    MoveLeftBtn.Color := clNone;
    MoveLeftBtn.Glyph.LoadFromFile('myQiuTrends.TopPanel.MoveLeftBtn.Glyph.bmp');
    MoveLeftBtn.HeightPercent := 100.000000000000000000;
    MoveLeftBtn.ShowHint := True;
    MoveLeftBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveLeftBtn, Self, 'OnClick', 'MoveLeftBtnClick');
    MoveFLBtn.SetParentComponent(TopPanel);
    MoveFLBtn.Name := 'MoveFLBtn';
    MoveFLBtn.Left := 307;
    MoveFLBtn.Top := 5;
    MoveFLBtn.Width := 41;
    MoveFLBtn.Height := 41;
    MoveFLBtn.Color := clNone;
    MoveFLBtn.Glyph.LoadFromFile('myQiuTrends.TopPanel.MoveFLBtn.Glyph.bmp');
    MoveFLBtn.HeightPercent := 100.000000000000000000;
    MoveFLBtn.ShowHint := True;
    MoveFLBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFLBtn, Self, 'OnClick', 'MoveFLBtnClick');
    TodayBtn.SetParentComponent(TopPanel);
    TodayBtn.Name := 'TodayBtn';
    TodayBtn.Left := 584;
    TodayBtn.Top := 5;
    TodayBtn.Width := 41;
    TodayBtn.Height := 41;
    TodayBtn.Color := clNone;
    TodayBtn.Glyph.LoadFromFile('myQiuTrends.TopPanel.TodayBtn.Glyph.bmp');
    TodayBtn.HeightPercent := 100.000000000000000000;
    TodayBtn.ShowHint := True;
    TodayBtn.WidthPercent := 100.000000000000000000;
    SetEvent(TodayBtn, Self, 'OnClick', 'TodayBtnClick');
    gotoBtn.SetParentComponent(TopPanel);
    gotoBtn.Name := 'gotoBtn';
    gotoBtn.Left := 631;
    gotoBtn.Top := 5;
    gotoBtn.Width := 41;
    gotoBtn.Height := 41;
    gotoBtn.Color := clNone;
    gotoBtn.Glyph.LoadFromFile('myQiuTrends.TopPanel.gotoBtn.Glyph.bmp');
    gotoBtn.HeightPercent := 100.000000000000000000;
    gotoBtn.ShowHint := True;
    gotoBtn.WidthPercent := 100.000000000000000000;
    SetEvent(gotoBtn, Self, 'OnClick', 'gotoBtnClick');
    SourceCB.SetParentComponent(TopPanel);
    SourceCB.Name := 'SourceCB';
    SourceCB.Left := 54;
    SourceCB.Top := 14;
    SourceCB.Width := 145;
    SourceCB.Height := 24;
    SourceCB.HeightPercent := 100.000000000000000000;
    SourceCB.Text := 'SourceCB';
    SourceCB.WidthPercent := 100.000000000000000000;
    SetEvent(SourceCB, Self, 'OnChange', 'SourceCBChange');
    SourceCB.ItemIndex := -1;
    MoveFFLBtn.SetParentComponent(TopPanel);
    MoveFFLBtn.Name := 'MoveFFLBtn';
    MoveFFLBtn.Left := 243;
    MoveFFLBtn.Top := 5;
    MoveFFLBtn.Width := 58;
    MoveFFLBtn.Height := 41;
    MoveFFLBtn.Color := clNone;
    MoveFFLBtn.Glyph.LoadFromFile('myQiuTrends.TopPanel.MoveFFLBtn.Glyph.bmp');
    MoveFFLBtn.HeightPercent := 100.000000000000000000;
    MoveFFLBtn.ShowHint := True;
    MoveFFLBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFFLBtn, Self, 'OnClick', 'MoveFFLBtnClick');
    MoveFFRBtn.SetParentComponent(TopPanel);
    MoveFFRBtn.Name := 'MoveFFRBtn';
    MoveFFRBtn.Left := 504;
    MoveFFRBtn.Top := 5;
    MoveFFRBtn.Width := 57;
    MoveFFRBtn.Height := 41;
    MoveFFRBtn.Color := clNone;
    MoveFFRBtn.Glyph.LoadFromFile('myQiuTrends.TopPanel.MoveFFRBtn.Glyph.bmp');
    MoveFFRBtn.HeightPercent := 100.000000000000000000;
    MoveFFRBtn.ShowHint := True;
    MoveFFRBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFFRBtn, Self, 'OnClick', 'MoveFFRBtnClick');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 551;
    BottomPanel.Width := 873;
    BottomPanel.Height := 50;
    BottomPanel.HeightPercent := 100.000000000000000000;
    BottomPanel.WidthPercent := 100.000000000000000000;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderColor := clSilver;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.ChildOrder := 2;
    BottomPanel.Color := clWhite;
    CancelBtn.SetParentComponent(BottomPanel);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 771;
    CancelBtn.Top := 3;
    CancelBtn.Width := 41;
    CancelBtn.Height := 41;
    CancelBtn.Color := clNone;
    CancelBtn.Glyph.LoadFromFile('myQiuTrends.BottomPanel.CancelBtn.Glyph.bmp');
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.ShowHint := True;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    HelpBtn.SetParentComponent(BottomPanel);
    HelpBtn.Name := 'HelpBtn';
    HelpBtn.Left := 818;
    HelpBtn.Top := 3;
    HelpBtn.Width := 41;
    HelpBtn.Height := 41;
    HelpBtn.Color := clNone;
    HelpBtn.Glyph.LoadFromFile('myQiuTrends.BottomPanel.HelpBtn.Glyph.bmp');
    HelpBtn.HeightPercent := 100.000000000000000000;
    HelpBtn.ShowHint := True;
    HelpBtn.WidthPercent := 100.000000000000000000;
    SetEvent(HelpBtn, Self, 'OnClick', 'HelpBtnClick');
    CaptionPanel.SetParentComponent(Self);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 86;
    CaptionPanel.Width := 873;
    CaptionPanel.Height := 26;
    CaptionPanel.HeightPercent := 100.000000000000000000;
    CaptionPanel.WidthPercent := 100.000000000000000000;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := clBtnFace;
    CaptionPanel.BorderStyle := bsSingle;
    CaptionPanel.Caption := 'CaptionPanel';
    CaptionPanel.ChildOrder := 4;
    CaptionPanel.Color := clWhite;
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 873;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 3;
    MainMenuFrame1.LoginBtn.TabOrder := 3;
    MainMenuFrame1.KontoBtn.TabOrder := 0;
    MainMenuFrame1.RegistBtn.TabOrder := 1;
    WaitPanel.SetParentComponent(Self);
    WaitPanel.Name := 'WaitPanel';
    WaitPanel.Left := 248;
    WaitPanel.Top := 296;
    WaitPanel.Width := 361;
    WaitPanel.Height := 41;
    WaitPanel.HeightPercent := 100.000000000000000000;
    WaitPanel.WidthPercent := 100.000000000000000000;
    WaitPanel.BorderColor := clBtnFace;
    WaitPanel.BorderStyle := bsSingle;
    WaitPanel.Caption := 'Bitte warten';
    WaitPanel.ChildOrder := 5;
    WaitPanel.Color := clWhite;
    WaitPanel.Visible := False;
    RepaintTimer.SetParentComponent(Self);
    RepaintTimer.Name := 'RepaintTimer';
    RepaintTimer.Enabled := False;
    RepaintTimer.Interval := 500;
    SetEvent(RepaintTimer, Self, 'OnTimer', 'RepaintTimerTimer');
    RepaintTimer.Left := 696;
    RepaintTimer.Top := 374;
  finally
    WebImageControl1.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    SourceLabel.AfterLoadDFMValues;
    MoveRightBtn.AfterLoadDFMValues;
    MoveFRBtn.AfterLoadDFMValues;
    MoveLeftBtn.AfterLoadDFMValues;
    MoveFLBtn.AfterLoadDFMValues;
    TodayBtn.AfterLoadDFMValues;
    gotoBtn.AfterLoadDFMValues;
    SourceCB.AfterLoadDFMValues;
    MoveFFLBtn.AfterLoadDFMValues;
    MoveFFRBtn.AfterLoadDFMValues;
    BottomPanel.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    HelpBtn.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    MainMenuFrame1.LoginBtn.AfterLoadDFMValues;
    MainMenuFrame1.KontoBtn.AfterLoadDFMValues;
    MainMenuFrame1.RegistBtn.AfterLoadDFMValues;
    WaitPanel.AfterLoadDFMValues;
    RepaintTimer.AfterLoadDFMValues;
  end;
end;

end.