
unit NavigateHint;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  XData.Web.Client, Vcl.Imaging.pngimage, WEBLib.Buttons, Vcl.Graphics;

type
  TNavigateHintForm = class(TForm)
    CancelBtn: TBitBtn;
    WebMemo1: TMemo;
    WebLabel1: TLabel;
    WebSpeedButton1: TSpeedButton;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  NavigateHintForm: TNavigateHintForm;

implementation

uses Main, KVT;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TNavigateHintForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TNavigateHintForm.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 GLV_NavigateHintAlreadyShown := true;
 Action := TCloseAction.caFree;
end;
{------------------------------------------------------------------------------}
procedure TNavigateHintForm.WebFormCreate(Sender: TObject);
begin
 //
end;
{------------------------------------------------------------------------------}
procedure TNavigateHintForm.WebFormResize(Sender: TObject);
begin
 CancelBtn.Left := self.ClientWidth div 2 - CancelBtn.Width div 2;
end;
{------------------------------------------------------------------------------}
procedure TNavigateHintForm.WebFormShow(Sender: TObject);
begin
 CancelBtn.Caption := SP.Load(24);
 WebLabel1.Caption := SP.Load(280);
 WebMemo1.Lines.Clear;
 WebMemo1.Lines.Add(SP.Load(281));
 WebMemo1.Lines.Add(SP.Load(282));
 WebMemo1.Lines.Add(SP.Load(283));
 WebMemo1.Lines.Add(SP.Load(284));

 end;


procedure TNavigateHintForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebSpeedButton1 := TSpeedButton.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebSpeedButton1.BeforeLoadDFMValues;
  try
    Name := 'NavigateHintForm';
    Width := 433;
    Height := 387;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 8;
    WebLabel1.Top := 8;
    WebLabel1.Width := 417;
    WebLabel1.Height := 16;
    WebLabel1.Alignment := taCenter;
    WebLabel1.AutoSize := False;
    WebLabel1.Caption := 'Wichtiger Hinweis zur Navigation in myQiu Web';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := clWindowText;
    WebLabel1.Font.Height := -13;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [fsBold];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 166;
    CancelBtn.Top := 317;
    CancelBtn.Width := 96;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 16;
    WebMemo1.Top := 30;
    WebMemo1.Width := 393;
    WebMemo1.Height := 267;
    WebMemo1.AutoSize := False;
    WebMemo1.BorderStyle := bsNone;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('myQiu Web ');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 12;
    WebMemo1.WidthPercent := 100.000000000000000000;
    WebSpeedButton1.SetParentComponent(Self);
    WebSpeedButton1.Name := 'WebSpeedButton1';
    WebSpeedButton1.Left := 360;
    WebSpeedButton1.Top := 247;
    WebSpeedButton1.Width := 41;
    WebSpeedButton1.Height := 41;
    WebSpeedButton1.Color := clNone;
    WebSpeedButton1.Glyph.LoadFromFile('NavigateHint.WebSpeedButton1.Glyph.bmp');
    WebSpeedButton1.HeightPercent := 100.000000000000000000;
    WebSpeedButton1.ShowHint := True;
    WebSpeedButton1.TabOrder := 2;
    WebSpeedButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebSpeedButton1, Self, 'OnClick', 'CancelBtnClick');
  finally
    WebLabel1.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebSpeedButton1.AfterLoadDFMValues;
  end;
end;

end.