unit SelectTherapeut;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.Buttons, Vcl.StdCtrls,
  WEBLib.StdCtrls,XData.Web.Client, KVT,
  DatenServiceClasses, WEBLib.Grids, Vcl.Grids, WEBLib.Lists;

type
  TSelectTherapeutForm = class(TForm)
    WebLabel1: TLabel;
    WebEdit1: TEdit;
    SearchBitBtn: TBitBtn;
    SaveBtn: TBitBtn;
    CancelBtn: TBitBtn;
    WebMemo1: TMemo;
    WebListBox1: TListBox;
    procedure SaveBtnClick(Sender: TObject);
    procedure CancelBtnClick(Sender: TObject);
    procedure SearchBitBtnClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormShow(Sender: TObject);
    procedure WebListBox1Click(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    UUIDListe     : TStringList;
    SelectedUUID  : string;
    SelectedRowIndex : integer;
    FormClosed    : boolean;
  protected procedure LoadDFMValues; override; end;

var
  SelectTherapeutForm: TSelectTherapeutForm;

implementation

uses Main,CommonFunctions;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TSelectTherapeutForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 if UUIDListe <> nil then begin
  UUIDListe.Clear;
  UUIDListe.Free;
 end;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TSelectTherapeutForm.WebFormCreate(Sender: TObject);
begin
 SaveBtn.Enabled := false;
 SelectedRowIndex := -1;
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TSelectTherapeutForm.WebFormShow(Sender: TObject);
begin
 UUIDListe := TStringList.create;
 WebListBox1.Items.Clear;
 WebLabel1.Caption := SP.Load(30);
 SearchBitBtn.Caption := SP.Load(31);
 WebMemo1.Lines.add(SP.Load(32));
 SaveBtn.Caption := SP.Load(33);
 CancelBtn.Caption := SP.Load(27);
end;
{------------------------------------------------------------------------------}
procedure TSelectTherapeutForm.WebListBox1Click(Sender: TObject);
begin
 SelectedRowIndex := WebListBox1.ItemIndex;
 WebListBox1.ItemIndex := SelectedRowIndex;
end;
{------------------------------------------------------------------------------}
procedure TSelectTherapeutForm.CancelBtnClick(Sender: TObject);
begin
 Close;
 FormClosed := true;
end;
{------------------------------------------------------------------------------}
procedure TSelectTherapeutForm.SaveBtnClick(Sender: TObject);
begin
 if SelectedRowIndex = -1 then exit;
 SelectedUUID := UUIDListe[SelectedRowIndex];
 Close;
end;
{------------------------------------------------------------------------------}
procedure TSelectTherapeutForm.SearchBitBtnClick(Sender: TObject);
begin
 if length(trim(WebEdit1.Text)) = 0 then exit;
 MainForm.XDataWebClient.RawInvoke('IDatenService.GetTherapeutenListByPLZ',[GLV_AktUser.Token,trim(WebEdit1.Text)],
   procedure (Response: TXDataClientResponse)
   var
    LArray   : TJSArray;
    LObj     : TJSObject;
    i        : integer;
    s        : string;
   begin
    if FormClosed then exit;
    UUIDListe.Clear;
    LArray := TJSArray(TJSObject(Response.Result)['value']);
    if LArray.Length = 0 then showmessage(SP.Load(250));
    SaveBtn.Enabled := LArray.Length > 0;
    WebListBox1.Items.Clear;
    for i := 0 to LArray.Length-1 do begin
     LObj := TJSObject(LArray[i]);
     UUIDListe.Add(string(LObj['UUID']));
     s := string(LObj['Anrede']) + ' ' +
     string(LObj['Titel']) + ' ' +
     string(LObj['Name']) + ' ' +
     string(LObj['Vorname']) + ' ' +
     string(LObj['Institution']) + ' ' +
     string(LObj['Strasse']) + ' ' +
     string(LObj['PLZ']) + ' ' +
     string(LObj['Stadt']) + ' ' +
     string(LObj['Land']);
     WebListBox1.Items.Add(s);
     SelectedRowIndex := 0;
    end;
   end
   );
end;


procedure TSelectTherapeutForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebEdit1 := TEdit.Create(Self);
  SearchBitBtn := TBitBtn.Create(Self);
  SaveBtn := TBitBtn.Create(Self);
  CancelBtn := TBitBtn.Create(Self);
  WebMemo1 := TMemo.Create(Self);
  WebListBox1 := TListBox.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebEdit1.BeforeLoadDFMValues;
  SearchBitBtn.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  WebMemo1.BeforeLoadDFMValues;
  WebListBox1.BeforeLoadDFMValues;
  try
    Name := 'SelectTherapeutForm';
    Width := 424;
    Height := 542;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 80;
    WebLabel1.Top := 29;
    WebLabel1.Width := 262;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Bitte geben Sie die PLZ Ihres Therapeuten ein';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebEdit1.SetParentComponent(Self);
    WebEdit1.Name := 'WebEdit1';
    WebEdit1.Left := 141;
    WebEdit1.Top := 51;
    WebEdit1.Width := 121;
    WebEdit1.Height := 22;
    WebEdit1.ChildOrder := 1;
    WebEdit1.HeightPercent := 100.000000000000000000;
    WebEdit1.WidthPercent := 100.000000000000000000;
    SearchBitBtn.SetParentComponent(Self);
    SearchBitBtn.Name := 'SearchBitBtn';
    SearchBitBtn.Left := 140;
    SearchBitBtn.Top := 79;
    SearchBitBtn.Width := 122;
    SearchBitBtn.Height := 25;
    SearchBitBtn.Caption := 'suchen';
    SearchBitBtn.Color := clNone;
    SearchBitBtn.HeightPercent := 100.000000000000000000;
    SearchBitBtn.TabOrder := 1;
    SearchBitBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SearchBitBtn, Self, 'OnClick', 'SearchBitBtnClick');
    SaveBtn.SetParentComponent(Self);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 67;
    SaveBtn.Top := 477;
    SaveBtn.Width := 126;
    SaveBtn.Height := 30;
    SaveBtn.Caption := #220'bernehmen';
    SaveBtn.Color := clNone;
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.MaterialGlyph := 'save';
    SaveBtn.TabOrder := 2;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    CancelBtn.SetParentComponent(Self);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 214;
    CancelBtn.Top := 477;
    CancelBtn.Width := 131;
    CancelBtn.Height := 30;
    CancelBtn.Caption := 'Abbrechen';
    CancelBtn.Color := clNone;
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.MaterialGlyph := 'cancel';
    CancelBtn.TabOrder := 3;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    WebMemo1.SetParentComponent(Self);
    WebMemo1.Name := 'WebMemo1';
    WebMemo1.Left := 8;
    WebMemo1.Top := 110;
    WebMemo1.Width := 401;
    WebMemo1.Height := 67;
    WebMemo1.AutoSize := False;
    WebMemo1.BorderStyle := bsNone;
    WebMemo1.HeightPercent := 100.000000000000000000;
    WebMemo1.Lines.BeginUpdate;
    try
      WebMemo1.Lines.Clear;
      WebMemo1.Lines.Add('');
    finally
      WebMemo1.Lines.EndUpdate;
    end;
    WebMemo1.ReadOnly := True;
    WebMemo1.SelLength := 0;
    WebMemo1.SelStart := 2;
    WebMemo1.WidthPercent := 100.000000000000000000;
    WebListBox1.SetParentComponent(Self);
    WebListBox1.Name := 'WebListBox1';
    WebListBox1.Left := 8;
    WebListBox1.Top := 200;
    WebListBox1.Width := 401;
    WebListBox1.Height := 257;
    WebListBox1.HeightPercent := 100.000000000000000000;
    WebListBox1.WidthPercent := 100.000000000000000000;
    SetEvent(WebListBox1, Self, 'OnClick', 'WebListBox1Click');
    WebListBox1.ItemIndex := -1;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebEdit1.AfterLoadDFMValues;
    SearchBitBtn.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    WebMemo1.AfterLoadDFMValues;
    WebListBox1.AfterLoadDFMValues;
  end;
end;

end.