unit PDFViewer;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,DatenServiceClasses, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Buttons, Vcl.Grids, WEBLib.Grids, WEBLib.ExtCtrls,
  Vcl.Forms, MainMenuFrame, Vcl.Graphics, Vcl.Imaging.pngimage, WEBLib.WebCtrls;

type
  TPDFViewerForm = class(TForm)
    BottomPanel: TPanel;
    CancelBtn: TSpeedButton;
    MainMenuFrame1: TMainMenuFrame1;
    WebPanel1: TPanel;
    RepaintTimer: TTimer;
    WebBrowserControl1: TBrowserControl;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure RepaintTimerTimer(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    FormClosed         : boolean;
    _PDFLink           : string;
  protected procedure LoadDFMValues; override; end;

var
  PDFViewerForm: TPDFViewerForm;

implementation

uses Main,XData.Web.Client,KVT,MyMsgDialog,CommonFunctions,System.Generics.Collections,
     myQiuTestFragenAntwortenTrackbar,myQiuTestFragenAntwortenMC;

{$R *.dfm}

{------------------------------------------------------------------------------}
procedure TPDFViewerForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 FormClosed := true;
 RepaintTimer.Enabled := false;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TPDFViewerForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
end;
{------------------------------------------------------------------------------}
procedure TPDFViewerForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TPDFViewerForm.WebFormResize(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 CancelBtn.left       := BottomPanel.ClientWidth - CancelBtn.width - GLV_Dist;
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TPDFViewerForm.WebFormShow(Sender: TObject);
begin
 MainMenuFrame1.UpdateFrame;
 CancelBtn.Hint := SP.Load(223);
 CancelBtn.ShowHint := true;
 MainMenuFrame1.UpdateFrame;
 CancelBtn.Caption := SP.Load(27);
end;
{------------------------------------------------------------------------------}
procedure TPDFViewerForm.RepaintTimerTimer(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
end;
{------------------------------------------------------------------------------}
procedure TPDFViewerForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TPDFViewerForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BottomPanel := TPanel.Create(Self);
  CancelBtn := TSpeedButton.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebBrowserControl1 := TBrowserControl.Create(Self);
  RepaintTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  BottomPanel.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  MainMenuFrame1.LoginBtn.BeforeLoadDFMValues;
  MainMenuFrame1.KontoBtn.BeforeLoadDFMValues;
  MainMenuFrame1.RegistBtn.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebBrowserControl1.BeforeLoadDFMValues;
  RepaintTimer.BeforeLoadDFMValues;
  try
    Name := 'PDFViewerForm';
    Width := 817;
    Height := 607;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 557;
    BottomPanel.Width := 817;
    BottomPanel.Height := 50;
    BottomPanel.HeightPercent := 100.000000000000000000;
    BottomPanel.WidthPercent := 100.000000000000000000;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderColor := clSilver;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.ChildOrder := 8;
    BottomPanel.Color := clWhite;
    CancelBtn.SetParentComponent(BottomPanel);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 745;
    CancelBtn.Top := 4;
    CancelBtn.Width := 41;
    CancelBtn.Height := 41;
    CancelBtn.Color := clNone;
    CancelBtn.Glyph.LoadFromFile('PDFViewer.BottomPanel.CancelBtn.Glyph.bmp');
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.ShowHint := True;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 817;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 1;
    MainMenuFrame1.LoginBtn.TabOrder := 3;
    MainMenuFrame1.KontoBtn.TabOrder := 0;
    MainMenuFrame1.RegistBtn.TabOrder := 1;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 86;
    WebPanel1.Width := 817;
    WebPanel1.Height := 471;
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alClient;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 2;
    WebBrowserControl1.SetParentComponent(WebPanel1);
    WebBrowserControl1.Name := 'WebBrowserControl1';
    WebBrowserControl1.Left := 0;
    WebBrowserControl1.Top := 0;
    WebBrowserControl1.Width := 817;
    WebBrowserControl1.Height := 471;
    WebBrowserControl1.HeightPercent := 100.000000000000000000;
    WebBrowserControl1.WidthPercent := 100.000000000000000000;
    WebBrowserControl1.Align := alClient;
    WebBrowserControl1.ChildOrder := 1;
    WebBrowserControl1.Sandbox := [];
    RepaintTimer.SetParentComponent(Self);
    RepaintTimer.Name := 'RepaintTimer';
    RepaintTimer.Enabled := False;
    RepaintTimer.Interval := 500;
    SetEvent(RepaintTimer, Self, 'OnTimer', 'RepaintTimerTimer');
    RepaintTimer.Left := 456;
    RepaintTimer.Top := 142;
  finally
    BottomPanel.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    MainMenuFrame1.LoginBtn.AfterLoadDFMValues;
    MainMenuFrame1.KontoBtn.AfterLoadDFMValues;
    MainMenuFrame1.RegistBtn.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebBrowserControl1.AfterLoadDFMValues;
    RepaintTimer.AfterLoadDFMValues;
  end;
end;

end.