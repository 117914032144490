program myQiuXDataWebClient;





uses
  Vcl.Forms,
  WEBLib.Forms,
  WEBLib.WebTools,
  SYstem.SysUtils,
  Main in 'Main.pas'{*.html},
  KVT in 'KVT.pas',
  User in 'User.pas'{*.html},
  DBFunctions in 'DBFunctions.pas',
  CommonFunctions in 'CommonFunctions.pas',
  MyMsgDialog in 'MyMsgDialog.pas'{*.html},
  SelectTherapeut in 'SelectTherapeut.pas'{*.html},
  DatenServiceClasses in 'D:\myQiuXDataServer\DatenServiceClasses.pas',
  Accept_AGB in 'Accept_AGB.pas'{*.html},
  Texte in 'Texte.pas',
  myQiuTestverwaltung_SH in 'myQiuTestverwaltung_SH.pas'{*.html},
  myQiuOverview_SW in 'myQiuOverview_SW.pas'{*.html},
  SelectReportType in 'SelectReportType.pas'{*.html},
  myQiuTrends_SW in 'myQiuTrends_SW.pas'{*.html},
  myQiuComplicance_SH in 'myQiuComplicance_SH.pas'{*.html},
  MyQiuSettings in 'MyQiuSettings.pas'{*.html},
  myQiuScheduler_SW in 'myQiuScheduler_SW.pas'{*.html},
  myQiuSchedulerEnterDlg in 'myQiuSchedulerEnterDlg.pas'{*.html},
  MyQiuBPTable_SW in 'MyQiuBPTable_SW.pas'{*.html},
  MainMenuFrame in 'MainMenuFrame.pas',
  myQiuOverview_SH in 'myQiuOverview_SH.pas',
  myQiuTrends_SH in 'myQiuTrends_SH.pas',
  myQiuScheduler_SH in 'myQiuScheduler_SH.pas',
  MyQiuBPTable_SH in 'MyQiuBPTable_SH.pas',
  myQiuOverview in 'myQiuOverview.pas',
  MyQiuBPTable in 'MyQiuBPTable.pas',
  myQiuFragebogen in 'myQiuFragebogen.pas',
  myQiuScheduler in 'myQiuScheduler.pas',
  myQiuTrends in 'myQiuTrends.pas',
  myQiuComplicance in 'myQiuComplicance.pas',
  SelectServerBaseURL in 'SelectServerBaseURL.pas'{*.html},
  PasswortReset in 'PasswortReset.pas',
  NavigateHint in 'NavigateHint.pas',
  DatePicker in 'DatePicker.pas',
  Login2Faktor in 'Login2Faktor.pas',
  myQiuTestverwaltung in 'myQiuTestverwaltung.pas',
  TestProperties in 'TestProperties.pas',
  myQiuTestFragenAntworten in 'myQiuTestFragenAntworten.pas',
  myQiuTestFragenAntwortenMC in 'myQiuTestFragenAntwortenMC.pas',
  myQiuTestFragenAntwortenTrackbar in 'myQiuTestFragenAntwortenTrackbar.pas',
  PDFViewer in 'PDFViewer.pas',
  Login in 'Login.pas';

var
 s : string;

 //http://localhost:8000/myQiuXDataWebClient/?Param1=hofix2004&Param2=Landwind001
 //https://www.myqiu.de/?Param1=hofix2004&Param2=Landwind001
 //https://www.myqiu-server.de/myQiuWeb/?Param1=hofix2004&Param2=Landwind001

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;

  if HasQueryParam('Param1',s) then begin
   GLV_AutoLogin            := true;
   GLV_User                 := s;
   if HasQueryParam('Param2',s) then GLV_Password := s;
  end;
  Application.CreateForm(TMainForm, MainForm);
  Application.Run;
end.
