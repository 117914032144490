unit myQiuTestverwaltung;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs,DatenServiceClasses, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.Buttons, Vcl.Grids, WEBLib.Grids, WEBLib.ExtCtrls,
  Vcl.Forms, MainMenuFrame, Vcl.Graphics, Vcl.Imaging.pngimage, Types,System.DateUtils;

type
  TmyQiuTestverwaltungForm = class(TForm)
    ImageScrollBox: TScrollBox;
    ImagePanel: TPanel;
    Image: TImageControl;
    BottomPanel: TPanel;
    HFMinCB: TComboBox;
    WebLabel1: TLabel;
    HFMaxCB: TComboBox;
    WebLabel2: TLabel;
    SelectTimer: TTimer;
    CancelBtn: TSpeedButton;
    ListPanel: TPanel;
    MiddlePanel: TPanel;
    WebLabel6: TLabel;
    CommentEdit: TEdit;
    MessungenStringGrid: TStringGrid;
    ZoomBtn: TSpeedButton;
    ZoomBackBtn: TSpeedButton;
    RepaintTimer: TTimer;
    CaptionPanel: TPanel;
    MainMenuFrame1: TMainMenuFrame1;
    DeleteBtn: TSpeedButton;
    SaveBtn: TSpeedButton;
    HelpBtn: TSpeedButton;
    WaitPanel: TPanel;
    TopSelectPanel: TPanel;
    WebLabel5: TLabel;
    SelectYearCB: TComboBox;
    AuswertungBtn: TSpeedButton;
    EigenschaftenBtn: TSpeedButton;
    FragenBtn: TSpeedButton;
    procedure WebFormShow(Sender: TObject);
    procedure GetMessungsListe;
    procedure ShowMessungsListe;
    function  GetMessTyp(_Messung:TMessung):string;
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure CancelBtnClick(Sender: TObject);
    procedure HFZeigen;
    procedure HFZeigenDownLoadLink;
    procedure MessungenStringGridSelectCell(Sender: TObject; ACol, ARow: Integer;
      var CanSelect: Boolean);
    procedure HFMinCBChange(Sender: TObject);
    procedure HFMaxCBChange(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure DeleteBtnClick(Sender: TObject);
    procedure SaveBtnClick(Sender: TObject);
    procedure DeleteTest;
    procedure DeleteTests;
    procedure MessungenStringGridKeyUp(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure MessungenStringGridClick(Sender: TObject);
    procedure ShowFirstEntry;
    procedure SelectTimerTimer(Sender: TObject);
    procedure ZoomBackBtnClick(Sender: TObject);
    procedure ImageLoaded(Sender: TObject);
    procedure CheckZoomBtns;
    procedure RepaintTimerTimer(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure HelpBtnClick(Sender: TObject);
    procedure ZoomBtnClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure SelectYearCBChange(Sender: TObject);
    procedure InitSelectYearCB;
    procedure AuswertungBtnClick(Sender: TObject);
    procedure GetLongReport(_ReportMode:integer);
    procedure GetShortReport(_ReportMode:integer);
    procedure EigenschaftenBtnClick(Sender: TObject);
    procedure FragenBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
    ScrollBarHeight : integer;
  public
    { Public-Deklarationen }
    MessungsListe      : TArray<TMessung>;
    HFMin              : integer;
    HFMax              : integer;
    MonthOnly          : boolean;
    AktMonth           : word;
    AktYear            : word;
    SelectedTopRow     : integer;
    SelectedBottomRow  : integer;
    LastHFIndex        : integer;
    MessungDeleted     : boolean;
    ZoomsIDX           : integer;
    _von,_bis          : TDatetime;
    _Von_BisArray      : Array of TDatetime;
    FormClosed         : boolean;
  protected procedure LoadDFMValues; override; end;

var
  myQiuTestverwaltungForm: TmyQiuTestverwaltungForm;

const
 ZoomMinIDX    = 0;
 ZoomMaxIDX    = 4;

implementation

uses Main,XData.Web.Client,KVT,MyMsgDialog,CommonFunctions,System.Generics.Collections,
     TestProperties,SelectReportType,myQiuTestFragenAntworten,System.NetEncoding;

{$R *.dfm}

{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.WebButton1Click(Sender: TObject);
begin
 QuickSortMessungslisteDatumAufsteigend(Messungsliste,0,length(Messungsliste)-1);
 ShowMessungsliste;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.WebButton2Click(Sender: TObject);
begin
 QuickSortMessungslisteDatumAbsteigend(Messungsliste,0,length(Messungsliste)-1);
 ShowMessungsliste;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 FormClosed := true;
 RepaintTimer.Enabled := false;
 SelectTimer.Enabled := false;
 SetLength(MessungsListe,0);
 SetLength(_Von_BisArray,0);
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.WebFormCreate(Sender: TObject);
begin
 FormClosed := false;
 MessungDeleted := false;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.WebFormResize(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 DeleteBtn.Left       := GLV_Dist;
 FragenBtn.Left       := DeleteBtn.Left + DeleteBtn.Width + GLV_Dist;
 AuswertungBtn.Left   := FragenBtn.Left + FragenBtn.Width + GLV_Dist;

 if CommentEdit.Width > (MiddlePanel.ClientWidth - 7* DeleteBtn.Width) then
    CommentEdit.Width := (MiddlePanel.ClientWidth - 7* DeleteBtn.Width);

 CommentEdit.Left  := MiddlePanel.ClientWidth div 2 - CommentEdit.Width div 2;
 WebLabel6.Left    := CommentEdit.Left;
 SaveBtn.Left      := CommentEdit.Left + CommentEdit.Width + 2*GLV_Dist;
 EigenschaftenBtn.Left := MiddlePanel.ClientWidth - EigenschaftenBtn.Width - 2*GLV_Dist;

 WebLabel1.Left    := 2*GLV_Dist;
 HFMinCB.Left      := WebLabel1.Left;

 WebLabel2.Left    := HFMinCB.Left + HFMinCB.Width + GLV_Dist;
 HFMaxCB.Left      := WebLabel2.Left;

 ZoomBtn.Left      := HFMaxCB.Left + HFMaxCB.Width + 2*GLV_Dist;
 ZoomBackBtn.Left  := ZoomBtn.Left + ZoomBtn.Width + GLV_Dist;

 HelpBtn.left       := BottomPanel.ClientWidth - round(1.5*HelpBtn.width);
 CancelBtn.left     := HelpBtn.Left - CancelBtn.width - GLV_Dist;

 ListPanel.Height := self.ClientHeight div 2;
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.InitSelectYearCB;
var
 tmpdate1,
 tmpdate2  : TDatetime;
 Count,i   : integer;
 dd,mm,yyyy: word;
begin

 if MonthOnly then begin
  _bis := encodeDate(AktYear,AktMonth+1,1)-1;
  _von := encodeDate(AktYear,AktMonth,1);
  SelectYearCB.Items.Clear;
  SelectYearCB.Items.Add(formatdatetime(GLV_Datummaske,_von) + ' - ' + formatdatetime(GLV_Datummaske,_bis));
  SelectYearCB.ItemIndex := 0;
 end else begin
  tmpdate1 := (now);
  decodedate(tmpdate1,yyyy,mm,dd);
  tmpdate2 := EndOfAMonth(yyyy-1,mm);
  Count := 2;
  SetLength(_Von_BisArray,Count);
  _Von_BisArray[0] := tmpdate1;
  _Von_BisArray[1] := tmpdate2;
  _bis := tmpdate1;
  _von := tmpdate2;

  while mygetyear(tmpdate2) > 2012 do begin
   tmpdate1 := tmpdate2;
   decodedate(tmpdate1,yyyy,mm,dd);
   tmpdate2 := encodedate(yyyy-1,mm,dd);
   inc(Count);
   SetLength(_Von_BisArray,Count);
   _Von_BisArray[Count-1] := tmpdate2;
  end;

  SelectYearCB.Items.Clear;
  for i := 0 to length(_Von_BisArray)-2 do begin
   SelectYearCB.Items.Add(formatdatetime(GLV_Datummaske,_Von_BisArray[i+1]) + ' - ' + formatdatetime(GLV_Datummaske,_Von_BisArray[i]));
  end;
  SelectYearCB.ItemIndex := 0;
 end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.WebFormShow(Sender: TObject);
var
 i : integer;
begin
 CaptionPanel.Color := CaptionPanelColor;
 CaptionPanel.Font.Color := CaptionPanelFontColor;
 CaptionPanel.BorderColor :=  CaptionPanelBorderColor;
 CaptionPanel.Caption := SP.load(128) + GLC_1Blank + GetMyQiuUserString;
 MainMenuFrame1.UpdateFrame;
 CancelBtn.Hint := SP.Load(223);
 DeleteBtn.Hint := SP.Load(230);
 AuswertungBtn.Hint := SP.Load(272);
 FragenBtn.Hint := SP.Load(298);
 SaveBtn.Hint := SP.Load(231);
 ZoomBtn.Hint := SP.Load(232);
 ZoomBackBtn.Hint :=SP.Load(233);

 ZoomsIDX := 0;

 MainMenuFrame1.UpdateFrame;
 MessungenStringGrid.ColCount := 5;
 MessungenStringGrid.RowCount := 1;
 MessungenStringGrid.FixedCols := 0;
 MessungenStringGrid.FixedRows := 1;
 MessungenStringGrid.ColWidths[0] := 80;
 MessungenStringGrid.ColWidths[1] := 80;
 MessungenStringGrid.ColWidths[2] := 200;
 MessungenStringGrid.ColWidths[3] := 85;
 MessungenStringGrid.ColWidths[4] := MessungenStringGrid.ClientWidth - MessungenStringGrid.ColWidths[0] -MessungenStringGrid.ColWidths[1]
                                     -MessungenStringGrid.ColWidths[2] -MessungenStringGrid.ColWidths[3];
 if  MessungenStringGrid.ColWidths[4] < 200 then MessungenStringGrid.ColWidths[4] := 200;

 MessungenStringGrid.Cells[0,0] := SP.Load(112);
 MessungenStringGrid.Cells[1,0] := SP.Load(113);
 MessungenStringGrid.Cells[2,0] := SP.Load(114);
 MessungenStringGrid.Cells[3,0] := SP.Load(115);
 MessungenStringGrid.Cells[4,0] := SP.Load(116);

 WebLabel1.Caption := SP.Load(117);
 WebLabel2.Caption := SP.Load(118);
 WebLabel6.Caption := SP.Load(120);

 CancelBtn.Caption := SP.Load(27);
 DeleteBtn.Caption := SP.Load(121);
 SaveBtn.Caption   := SP.Load(74);

 ImagePanel.Caption := SP.Load(132);

 InitSelectYearCB;

 GetMessungsListe;

 HFMinCB.OnChange := nil;
 HFMinCB.Items.Clear;
 HFMinCB.Items.Add('0');
 HFMinCB.Items.Add('10');
 HFMinCB.Items.Add('20');
 HFMinCB.Items.Add('30');
 HFMinCB.Items.Add('40');
 HFMinCB.Items.Add('50');
 HFMinCB.Items.Add('60');
 HFMinCB.Items.Add('70');
 HFMinCB.ItemIndex := 4;
 for i := 0 to HFMinCB.Items.Count-1 do begin
  if strtoint(HFMinCB.Text) = HFMin then HFMinCB.ItemIndex := i;
 end;
 HFMinCB.OnChange := HFMinCBChange;

 HFMaxCB.OnChange := nil;
 HFMaxCB.Items.Clear;
 HFMaxCB.Items.Add('80');
 HFMaxCB.Items.Add('90');
 HFMaxCB.Items.Add('100');
 HFMaxCB.Items.Add('120');
 HFMaxCB.Items.Add('140');
 HFMaxCB.Items.Add('160');
 HFMaxCB.Items.Add('180');
 HFMaxCB.Items.Add('200');
 HFMaxCB.ItemIndex := 3;
 for i := 0 to HFMaxCB.Items.Count-1 do begin
  if strtoint(HFMaxCB.Text) = HFMax then HFMaxCB.ItemIndex := i;
 end;
 HFMaxCB.OnChange := HFMaxCBChange;
 CheckZoomBtns;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.MessungenStringGridClick(Sender: TObject);
var
 tmp : integer;
begin
 SelectedTopRow    := MessungenStringGrid.Selection.Top-1;
 SelectedBottomRow := MessungenStringGrid.Selection.Bottom-1;
 if SelectedBottomRow < SelectedTopRow then begin
  tmp := SelectedTopRow;
  SelectedTopRow := SelectedBottomRow;
  SelectedBottomRow := tmp;
 end;
 ZoomsIDX          := 0;
 CheckZoomBtns;
 CommentEdit.Text := TMessung(Messungsliste[SelectedTopRow]).Kommentar;
 HFZeigenDownLoadLink;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.MessungenStringGridKeyUp(Sender: TObject;
  var Key: Word; Shift: TShiftState);
begin
 MessungenStringGridClick(Self);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.MessungenStringGridSelectCell(Sender: TObject; ACol,
  ARow: Integer; var CanSelect: Boolean);
begin
 //
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.RepaintTimerTimer(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 HFZeigenDownLoadLink;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.SaveBtnClick(Sender: TObject);
var
 _MessungsID    : string;
 _Comment       : string;
 MessungComment : TJSObject;
begin
 if SelectedTopRow < 0 then begin showmessage(SP.load(124)); exit; end;
 if SelectedTopRow <> SelectedBottomRow then begin showmessage(SP.load(253)); exit; end;
 _MessungsID := TMessung(MessungsListe[SelectedTopRow]).TestID;

 _Comment := trim(CommentEdit.Text);

 MessungComment := TJSObject.new;
 MessungComment['Comment'] := _Comment;
 MessungComment['UserUUID'] := GLV_AktUser.UUID;
 MessungComment['MessungID'] := _MessungsID;


 MainForm.XDataWebClient.RawInvoke('IDatenService.SaveCommentENC',[GLV_AktUser.Token,MessungComment],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    case byte(TJSObject(Response.Result)['value']) of
      0: begin
          TMessung(MessungsListe[SelectedTopRow]).Kommentar := _Comment;
          ShowMessungsListe;
          showmessage(SP.load(325));
         end;
      1: showmessage(SP.load(204));
     end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.DeleteBtnClick(Sender: TObject);
var
 MyMessageDlgForm : TMyMessageDlgForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   case MyMessageDlgForm._Result of
    drYes : begin
             if SelectedTopRow = SelectedBottomRow then DeleteTest else DeleteTests;
            end;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;

begin
 if SelectedTopRow < 0 then begin showmessage(SP.load(124)); exit; end;
 MyMessageDlgForm := TMyMessageDlgForm.CreateNew(@AfterCreate);
 MyMessageDlgForm.Border := fbsingle;
 MyMessageDlgForm.Popup := true;
 MyMessageDlgForm.Titel := SP.load(20);//'Bestätigen';
 if SelectedTopRow = SelectedBottomRow then
  MyMessageDlgForm.MessageText := SP.load(122)
 else
  MyMessageDlgForm.MessageText := SP.load(123);
 MyMessageDlgForm.DialogType := dtYesNo;
 MyMessageDlgForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.AuswertungBtnClick(Sender: TObject);
var
 SelectReportTypeForm : TSelectReportTypeForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    case SelectReportTypeForm.ReportTyp of
     -1 : exit;
     0  : GetLongReport(SelectReportTypeForm.ReportMode);
     1  : GetShortReport(SelectReportTypeForm.ReportMode);
    end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;


begin
 if SelectedTopRow < 0 then begin showmessage(SP.load(124)); exit; end;

 if TMessung(MessungsListe[SelectedTopRow]).Typ = 'FRAGEBOGEN' then begin
  showmessage(SP.load(309));
  exit;
 end;

 SelectReportTypeForm := TSelectReportTypeForm.CreateNew(@AfterCreate);
 SelectReportTypeForm.caption := SP.load(290);//'Report wählen';
 SelectReportTypeForm.Border := fbDialog;
 SelectReportTypeForm.Popup := true;
 SelectReportTypeForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.GetLongReport(_ReportMode:integer);
var
 _MessungsID          : string;
begin

 _MessungsID := TMessung(MessungsListe[SelectedTopRow]).TestID;

 WaitPanel.Top := ListPanel.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= ListPanel.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetAuswertungPDFDownloadLink2',[GLV_AktUser.Token,GLV_AktUser.UUID,_MessungsID,_ReportMode],
  procedure (Response: TXDataClientResponse)
  var
   LObj    : TJSObject;
  begin
   if FormClosed then exit;
   WaitPanel.Visible := false;
   LObj := TJSObject(Response.Result);
   case integer(LObj['Result']) of
    //0: AuswertungBtnClick(Self);
    0: begin
        case _ReportMode of
         0: if trim(string(LObj['PDFLink'])) <> '' then ShowPDF(trim(string(LObj['PDFLink'])),_ReportMode);
         1: if trim(string(LObj['PDFLink'])) <> '' then ShowPDF(trim(string(LObj['PDFLink'])),_ReportMode);
         2: showmessage('Der PDF-Bericht wurde an ' + GLV_AktUser.Email + ' verschickt.');
        end;
       end;
   end;
  end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.GetShortReport(_ReportMode:integer);
var
 _MessungsID          : string;
begin

 _MessungsID := TMessung(MessungsListe[SelectedTopRow]).TestID;

 WaitPanel.Top := ListPanel.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= ListPanel.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetAuswertungPDFDownloadLinkShort2',[GLV_AktUser.Token,GLV_AktUser.UUID,_MessungsID,_ReportMode],
  procedure (Response: TXDataClientResponse)
  var
   LObj    : TJSObject;
  begin
   if FormClosed then exit;
   WaitPanel.Visible := false;
   LObj := TJSObject(Response.Result);
   case integer(LObj['Result']) of
    //0: AuswertungBtnClick(Self);
    0: begin
        case _ReportMode of
         0: if trim(string(LObj['PDFLink'])) <> '' then ShowPDF(trim(string(LObj['PDFLink'])),_ReportMode);
         1: if trim(string(LObj['PDFLink'])) <> '' then ShowPDF(trim(string(LObj['PDFLink'])),_ReportMode);
         2: showmessage('Der PDF-Bericht wurde an ' + GLV_AktUser.Email + ' verschickt.');
        end;
       end;
   end;
  end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.EigenschaftenBtnClick(Sender: TObject);
{begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.GetBiofeedbackVorgabe',[GLV_AktUser.Token,GLV_AktUser.ID,3],
  procedure (Response: TXDataClientResponse)
  var
   LObj    : TJSObject;
  begin
   if FormClosed then exit;
   WaitPanel.Visible := false;
   LObj := TJSObject(Response.Result);
   case integer(LObj['Result']) of
    -1: begin
        showmessage(trim(string(LObj['AnzahlRSA'])));
       end;

    0: begin
        showmessage(trim(string(LObj['AnzahlRSA'])));
        showmessage(trim(string(LObj['VorgabeLow'])));
        showmessage(trim(string(LObj['VorgabeHigh'])));
       end;
   end;
  end);
end;}

var
 _MessungsID        : string;
 TestPropertiesForm : TTestPropertiesForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   //
  end;


begin
 if SelectedTopRow < 0 then begin showmessage(SP.load(124)); exit; end;
 _MessungsID := TMessung(MessungsListe[SelectedTopRow]).TestID;

 WaitPanel.Top := ListPanel.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= ListPanel.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetTestDescription',[GLV_AktUser.Token,GLV_AktUser.UUID,_MessungsID],
  procedure (Response: TXDataClientResponse)
  var
   LObj    : TJSObject;
  begin
   if FormClosed then exit;
   WaitPanel.Visible := false;
   LObj := TJSObject(Response.Result);
   case integer(LObj['Result']) of
    0: begin
        TestPropertiesForm := TTestPropertiesForm.CreateNew(@AfterCreate);
        TestPropertiesForm.caption := SP.Load(285);
        TestPropertiesForm.Border := fbDialog;
        TestPropertiesForm.Popup := true;
        TestPropertiesForm.OS := trim(string(LObj['OS']));
        TestPropertiesForm.OSVersion := trim(string(LObj['OSVersion']));
        TestPropertiesForm.Device := trim(string(LObj['Device']));
        TestPropertiesForm.Hardware := trim(string(LObj['Hardware']));
        TestPropertiesForm.ShowModal(@AfterShowModal);
       end;
   end;
  end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.FragenBtnClick(Sender: TObject);
var
  myQiuTestFragenAntwortenForm : TmyQiuTestFragenAntwortenForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuTestFragenAntwortenForm.ShowModal(@AfterShowModal);
  end;
begin
 myQiuTestFragenAntwortenForm := TmyQiuTestFragenAntwortenForm.CreateNew(@AfterCreate);
 myQiuTestFragenAntwortenForm.MessungsID := TMessung(MessungsListe[SelectedTopRow]).TestID;
 myQiuTestFragenAntwortenForm._Caption := GetMessTyp(TMessung(MessungsListe[SelectedTopRow])) +
      GLC_1Blank + formatdatetime('dd.mm.yyyy hh:nn:ss',MyDateTimeFormatToTDateTime(TMessung(MessungsListe[SelectedTopRow]).Datum));
 myQiuTestFragenAntwortenForm.Popup := false;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.DeleteTest;
var
 _MessungsID : string;
begin
   _MessungsID := TMessung(MessungsListe[SelectedTopRow]).TestID;
   MainForm.XDataWebClient.RawInvoke('IDatenService.DeleteTest',[GLV_AktUser.Token,GLV_AktUser.UUID,_MessungsID],
    procedure (Response: TXDataClientResponse)
    begin
     if FormClosed then exit;
     case byte(TJSObject(Response.Result)['value']) of
      0: begin
          showmessage(SP.load(126));
          DeleteEntryFromMessungsList(MessungsListe,SelectedTopRow);
          SelectedTopRow := 0;
          SelectedBottomRow := 0;
          ShowMessungsListe;
          MessungDeleted := true;
          ShowFirstEntry;
         end;
      1: showmessage(SP.load(125));
     end;
    end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.DeleteTests;
var
 _MessungsID : string;
 i           : integer;
 MessungIDs  : string;
begin

 MessungIDs := '';
 for i := 0 to length(MessungsListe)-1 do begin
  if (SelectedTopRow <= i) and (SelectedBottomRow >= i) then begin
   _MessungsID := TMessung(MessungsListe[i]).TestID;
   MessungIDs := MessungIDs + _MessungsID;
  end;
 end;
 MainForm.XDataWebClient.RawInvoke('IDatenService.DeleteMultipleTests',[GLV_AktUser.Token,GLV_AktUser.UUID,MessungIDs],
  procedure (Response: TXDataClientResponse)
  var
   i : integer;
  begin
   if FormClosed then exit;
   case byte(TJSObject(Response.Result)['value']) of
    0: begin
        showmessage(SP.load(126));
        for i := SelectedBottomRow downto SelectedTopRow do DeleteEntryFromMessungsList(MessungsListe,i);
        SelectedTopRow := 0;
        SelectedBottomRow := 0;
        ShowMessungsListe;
        MessungDeleted := true;
        ShowFirstEntry;
       end;
    1: showmessage(SP.load(125));
   end;
  end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.ZoomBackBtnClick(Sender: TObject);
begin
 if ZoomsIDX > ZoomMinIDX then dec(ZoomsIDX) else exit;
 HFZeigenDownLoadLink;
 CheckZoomBtns;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.ZoomBtnClick(Sender: TObject);
begin
 if ZoomsIDX < ZoomMaxIDX then inc(ZoomsIDX) else exit;
 HFZeigenDownLoadLink;
 CheckZoomBtns;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.CheckZoomBtns;
begin
 ZoomBtn.enabled :=  (ZoomsIDX < ZoomMaxIDX);
 ZoomBackBtn.Enabled := (ZoomsIDX > ZoomMinIDX);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.HFMinCBChange(Sender: TObject);
begin
 HFZeigenDownLoadLink;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.HelpBtnClick(Sender: TObject);
begin
 Application.Navigate(GetHelpDir + 'MyQiu-MessungenVerwalten.html', ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.HFMaxCBChange(Sender: TObject);
begin
 HFZeigenDownLoadLink;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.SelectTimerTimer(Sender: TObject);
begin
 if length(Messungsliste) > 0 then begin
  SelectTimer.Enabled := false;
  ShowFirstEntry;
  DeleteBtn.Enabled := true;
  AuswertungBtn.Enabled := true;
  FragenBtn.Enabled := true;
  SaveBtn.Enabled   := true;
 end else begin
  DeleteBtn.Enabled := false;
  AuswertungBtn.Enabled := false;
  FragenBtn.Enabled := false;
  SaveBtn.Enabled   := false;
 end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.SelectYearCBChange(Sender: TObject);
begin
 _bis := _Von_BisArray[SelectYearCB.ItemIndex];
 _von := _Von_BisArray[SelectYearCB.ItemIndex+1];
 GetMessungsListe;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.ShowFirstEntry;
begin
 SelectedTopRow := 0;
 SelectedBottomRow := 0;
 if length(MessungsListe) > 0 then
  HFZeigenDownLoadLink
 else Image.URL := '';
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.HFZeigen;
var
 _MessungsID :string;
 _Height:integer;_Width:integer;
 _VonSec:integer;_BisSec:integer;
 _HFMin:integer;_HFMax:integer;
  ScrollBarHeight : integer;
begin

 if (SelectedTopRow < 0) or (SelectedTopRow > length(MessungsListe)) then exit;

 LastHFIndex      := SelectedTopRow;
 ImagePanel.Top   := 0;
 ImagePanel.Left  := 0;
 ImagePanel.Width := ImageScrollBox.ClientWidth;
 ImagePanel.Height := ImageScrollBox.ClientHeight;
 ImagePanel.Visible := true;
 ScrollBarHeight := 25;

 case ZoomsIdx of
  0: begin
      _Width  := ImageScrollBox.ClientWidth;
      _Height := ImageScrollBox.ClientHeight;
     end;
  1: begin
      _Width  := trunc(ImageScrollBox.ClientWidth*1.5);
      _Height := ImageScrollBox.ClientHeight - ScrollBarHeight;
     end;
  2: begin
      _Width  := trunc(ImageScrollBox.ClientWidth*2);
      _Height := ImageScrollBox.ClientHeight - ScrollBarHeight;
     end;
  3: begin
      _Width  := trunc(ImageScrollBox.ClientWidth*2.5);
      _Height := ImageScrollBox.ClientHeight - ScrollBarHeight;
     end;
  4: begin
      _Width  := trunc(ImageScrollBox.ClientWidth*5);
      _Height := ImageScrollBox.ClientHeight - ScrollBarHeight;
     end;
 end;


 _MessungsID := TMessung(MessungsListe[SelectedTopRow]).TestID;
 _VonSec     := 0;
 _BisSec     := TMessung(MessungsListe[SelectedTopRow]).Dauer;
 _HFMin      := strtoint(HFMinCB.Text);
 _HFMax      := strtoint(HFMaxCB.Text);

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetHRDiag',[GLV_AktUser.Token,GLV_AktUser.UUID,_MessungsID,
                                                              _Height,_Width,_VonSec,_BisSec,_HFMin,_HFMax],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    ImagePanel.Top := 0;
    ImagePanel.Left := 0;
    Image.URL := Response.ResponseText;
   end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.HFZeigenDownLoadLink;
var
 _MessungsID :string;
 _Height:integer;_Width:integer;
 _VonSec:integer;_BisSec:integer;
 _HFMin:integer;_HFMax:integer;
begin
 if (SelectedTopRow < 0) or (SelectedTopRow > length(MessungsListe)) then exit;
 LastHFIndex      := SelectedTopRow;
 ImagePanel.Top   := 0;
 ImagePanel.Left  := 0;
 ImagePanel.Width := ImageScrollBox.ClientWidth;
 ImagePanel.Height := ImageScrollBox.ClientHeight;
 ImagePanel.Visible := true;
 ScrollBarHeight := 25;

 case ZoomsIdx of
  0: begin
      _Width  := ImageScrollBox.ClientWidth;
      _Height := ImageScrollBox.ClientHeight;
     end;
  1: begin
      _Width  := trunc(ImageScrollBox.ClientWidth*1.5);
      _Height := ImageScrollBox.ClientHeight - ScrollBarHeight;
     end;
  2: begin
      _Width  := trunc(ImageScrollBox.ClientWidth*2);
      _Height := ImageScrollBox.ClientHeight - ScrollBarHeight;
     end;
  3: begin
      _Width  := trunc(ImageScrollBox.ClientWidth*2.5);
      _Height := ImageScrollBox.ClientHeight - ScrollBarHeight;
     end;
  4: begin
      _Width  := trunc(ImageScrollBox.ClientWidth*5);
      _Height := ImageScrollBox.ClientHeight - ScrollBarHeight;
     end;
 end;


 _MessungsID := TMessung(MessungsListe[SelectedTopRow]).TestID;
 _VonSec     := 0;
 _BisSec     := TMessung(MessungsListe[SelectedTopRow]).Dauer;
 _HFMin      := strtoint(HFMinCB.Text);
 _HFMax      := strtoint(HFMaxCB.Text);

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetHRDiagDownloadLink',[GLV_AktUser.Token,GLV_AktUser.UUID,_MessungsID,
                                                              _Height,_Width,_VonSec,_BisSec,_HFMin,_HFMax],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    ImagePanel.Top := 0;
    ImagePanel.Left := 0;
    Image.URL := string(TJSObject(Response.Result)['value']);
   end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.ImageLoaded(Sender: TObject);
begin
 case ZoomsIdx of
     0: begin
         ImagePanel.Width          := ImageScrollBox.ClientWidth;
         ImageScrollBox.ScrollBars := ssNone;
         ImagePanel.Height         := ImageScrollBox.ClientHeight;
        end;
     1: begin
         ImagePanel.Width          := trunc(ImageScrollBox.ClientWidth*1.5);
         ImageScrollBox.ScrollBars := ssHorizontal;
         ImagePanel.Height         := ImageScrollBox.ClientHeight - ScrollBarHeight;
        end;
     2: begin
         ImagePanel.Width          := trunc(ImageScrollBox.ClientWidth*2);
         ImageScrollBox.ScrollBars := ssHorizontal;
         ImagePanel.Height         := ImageScrollBox.ClientHeight - ScrollBarHeight;
        end;
     3: begin
         ImagePanel.Width          := trunc(ImageScrollBox.ClientWidth*2.5);
         ImageScrollBox.ScrollBars := ssHorizontal;
         ImagePanel.Height         := ImageScrollBox.ClientHeight - ScrollBarHeight;
        end;
     4: begin
         ImagePanel.Width          := trunc(ImageScrollBox.ClientWidth*5);
         ImageScrollBox.ScrollBars := ssHorizontal;
         ImagePanel.Height         := ImageScrollBox.ClientHeight - ScrollBarHeight;
        end;
    end;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.GetMessungsListe;
var
 s_von   : string;
 s_bis   : string;
begin
 SelectedTopRow := -1;
 SelectedBottomRow := -1;
 LastHFIndex := -1;

 if _bis > now-1 then _bis := now+1;

 s_von := MyTDateTimeFormatToString(_von);
 s_bis := MyTDateTimeFormatToString(_bis);

 ImagePanel.Top := 0;
 ImagePanel.Left := 0;
 Image.URL := '';

 WaitPanel.Top := ListPanel.ClientHeight div 2 - WaitPanel.Height div 2;
 WaitPanel.Left:= ListPanel.ClientWidth div 2 - WaitPanel.Width div 2;
 WaitPanel.Visible := true;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetMessungsListe',[GLV_AktUser.Token,GLV_AktUser.UUID,s_von,s_bis],
   procedure (Response: TXDataClientResponse)
   var
    LArray : TJSArray;
    LObj   : TJSObject;
    i      : integer;
    Rec    : TMessung;
   begin
    if FormClosed then exit;
    LArray := TJSArray(TJSObject(Response.Result)['value']);
    SetLength(MessungsListe,LArray.Length);
    MessungenStringGrid.RowCount := LArray.Length+1;
    MessungenStringGrid.BeginUpdate;
    for i := 0 to LArray.Length-1 do begin
     LObj := TJSObject(LArray[i]);
     Rec            := TMessung.Create;
     Rec.TestID     := string(LObj['TestID']);
     Rec.Typ        := string(LObj['Typ']);
     Rec.Datum      := string(LObj['Datum']);
     Rec.Dauer      := integer(LObj['Dauer']);
     Rec.Kommentar  := string(LObj['Kommentar']);
     Rec.TypFragebogen       := integer(LObj['TypFragebogen']);
     Rec.BezeichnungFragebogen := string(LObj['BezeichnungFragebogen']);
     Messungsliste[i] := Rec;
     MessungenStringGrid.Cells[0,i+1] := formatdatetime('dd.mm.yyyy',MyDateTimeFormatToTDateTime(Rec.Datum));
     MessungenStringGrid.Cells[1,i+1] := formatdatetime('hh:nn:ss',MyDateTimeFormatToTDateTime(Rec.Datum));
     MessungenStringGrid.Cells[2,i+1] := GetMessTyp(Rec);
     if (Rec.TypFragebogen = 1) and (Rec.BezeichnungFragebogen <> '') then MessungenStringGrid.Cells[2,i+1] := Rec.BezeichnungFragebogen;
     MessungenStringGrid.Cells[3,i+1] := floattostrf(Rec.Dauer/60,fffixed,5,0);
     MessungenStringGrid.Cells[4,i+1] := Rec.Kommentar;
    end;
    MessungenStringGrid.EndUpdate;
    WaitPanel.Visible := false;
    SelectTimer.Enabled := true;
   end);
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.ShowMessungsListe;
var
 i    : integer;
 Rec  : TMessung;
begin
 MessungenStringGrid.RowCount := Length(Messungsliste)+1;
 MessungenStringGrid.BeginUpdate;
 for i := 0 to Length(Messungsliste)-1 do begin
  Rec := Messungsliste[i];
  MessungenStringGrid.Cells[0,i+1] := formatdatetime('dd.mm.yyyy',MyDateTimeFormatToTDateTime(Rec.Datum));
  MessungenStringGrid.Cells[1,i+1] := formatdatetime('hh:nn:ss',MyDateTimeFormatToTDateTime(Rec.Datum));
  MessungenStringGrid.Cells[2,i+1] := GetMessTyp(Rec);
  MessungenStringGrid.Cells[3,i+1] := floattostrf(Rec.Dauer/60,fffixed,5,0);
  MessungenStringGrid.Cells[4,i+1] := Rec.Kommentar;
 end;
 MessungenStringGrid.Selection.Top    := SelectedTopRow;
 MessungenStringGrid.Selection.Bottom := SelectedBottomRow;
 MessungenStringGrid.EndUpdate;
end;
{------------------------------------------------------------------------------}
function TmyQiuTestverwaltungForm.GetMessTyp(_Messung:TMessung):string;
begin
 result := 'UNDEFINED';
 if _Messung.Typ = 'QIU' then result := SP.Load(106);
 if _Messung.Typ = 'BIOFEEDBACK' then result := SP.Load(107);
 if _Messung.Typ = 'BIOFEEDBACK2' then result := SP.Load(108);
 if _Messung.Typ = 'ONLINESPEKTRUM' then result := SP.Load(109);
 if _Messung.Typ = 'RSA' then result := SP.Load(110);
 if _Messung.Typ = 'KURZHRV' then result := SP.Load(111);
 if _Messung.Typ = 'FRAGEBOGEN' then result := SP.Load(298);
 if (_Messung.TypFragebogen = 1) then result := result + GLC_1Blank + SP.load(322);
 if (_Messung.TypFragebogen = 2) and (_Messung.BezeichnungFragebogen <> '') then result := _Messung.BezeichnungFragebogen;
end;
{------------------------------------------------------------------------------}
procedure TmyQiuTestverwaltungForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ImageScrollBox := TScrollBox.Create(Self);
  ImagePanel := TPanel.Create(Self);
  Image := TImageControl.Create(Self);
  BottomPanel := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  HFMinCB := TComboBox.Create(Self);
  HFMaxCB := TComboBox.Create(Self);
  CancelBtn := TSpeedButton.Create(Self);
  ZoomBtn := TSpeedButton.Create(Self);
  ZoomBackBtn := TSpeedButton.Create(Self);
  HelpBtn := TSpeedButton.Create(Self);
  ListPanel := TPanel.Create(Self);
  MiddlePanel := TPanel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  CommentEdit := TEdit.Create(Self);
  DeleteBtn := TSpeedButton.Create(Self);
  SaveBtn := TSpeedButton.Create(Self);
  AuswertungBtn := TSpeedButton.Create(Self);
  EigenschaftenBtn := TSpeedButton.Create(Self);
  FragenBtn := TSpeedButton.Create(Self);
  MessungenStringGrid := TStringGrid.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  WaitPanel := TPanel.Create(Self);
  TopSelectPanel := TPanel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  SelectYearCB := TComboBox.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  SelectTimer := TTimer.Create(Self);
  RepaintTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  ImageScrollBox.BeforeLoadDFMValues;
  ImagePanel.BeforeLoadDFMValues;
  Image.BeforeLoadDFMValues;
  BottomPanel.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  HFMinCB.BeforeLoadDFMValues;
  HFMaxCB.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  ZoomBtn.BeforeLoadDFMValues;
  ZoomBackBtn.BeforeLoadDFMValues;
  HelpBtn.BeforeLoadDFMValues;
  ListPanel.BeforeLoadDFMValues;
  MiddlePanel.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  CommentEdit.BeforeLoadDFMValues;
  DeleteBtn.BeforeLoadDFMValues;
  SaveBtn.BeforeLoadDFMValues;
  AuswertungBtn.BeforeLoadDFMValues;
  EigenschaftenBtn.BeforeLoadDFMValues;
  FragenBtn.BeforeLoadDFMValues;
  MessungenStringGrid.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  WaitPanel.BeforeLoadDFMValues;
  TopSelectPanel.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  SelectYearCB.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  MainMenuFrame1.LoginBtn.BeforeLoadDFMValues;
  MainMenuFrame1.KontoBtn.BeforeLoadDFMValues;
  MainMenuFrame1.RegistBtn.BeforeLoadDFMValues;
  SelectTimer.BeforeLoadDFMValues;
  RepaintTimer.BeforeLoadDFMValues;
  try
    Name := 'myQiuTestverwaltungForm';
    Width := 800;
    Height := 700;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    ImageScrollBox.SetParentComponent(Self);
    ImageScrollBox.Name := 'ImageScrollBox';
    ImageScrollBox.Left := 0;
    ImageScrollBox.Top := 437;
    ImageScrollBox.Width := 800;
    ImageScrollBox.Height := 213;
    ImageScrollBox.Align := alClient;
    ImageScrollBox.BorderStyle := bsSingle;
    ImageScrollBox.ChildOrder := 7;
    ImageScrollBox.ScrollBars := ssHorizontal;
    ImagePanel.SetParentComponent(ImageScrollBox);
    ImagePanel.Name := 'ImagePanel';
    ImagePanel.Left := 32;
    ImagePanel.Top := 16;
    ImagePanel.Width := 609;
    ImagePanel.Height := 169;
    ImagePanel.Visible := False;
    Image.SetParentComponent(ImagePanel);
    Image.Name := 'Image';
    Image.Left := 0;
    Image.Top := 0;
    Image.Width := 609;
    Image.Height := 169;
    Image.HeightPercent := 100.000000000000000000;
    Image.WidthPercent := 100.000000000000000000;
    Image.Align := alClient;
    Image.ChildOrder := 5;
    SetEvent(Image, Self, 'OnLoaded', 'ImageLoaded');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 650;
    BottomPanel.Width := 800;
    BottomPanel.Height := 50;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.ChildOrder := 8;
    BottomPanel.Color := clWhite;
    WebLabel1.SetParentComponent(BottomPanel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 6;
    WebLabel1.Top := 2;
    WebLabel1.Width := 44;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'HF min.';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(BottomPanel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 70;
    WebLabel2.Top := 2;
    WebLabel2.Width := 47;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'HF max.';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    HFMinCB.SetParentComponent(BottomPanel);
    HFMinCB.Name := 'HFMinCB';
    HFMinCB.Left := 6;
    HFMinCB.Top := 21;
    HFMinCB.Width := 50;
    HFMinCB.Height := 24;
    HFMinCB.HeightPercent := 100.000000000000000000;
    HFMinCB.Text := 'HFMinCB';
    HFMinCB.WidthPercent := 100.000000000000000000;
    SetEvent(HFMinCB, Self, 'OnChange', 'HFMinCBChange');
    HFMinCB.ItemIndex := -1;
    HFMaxCB.SetParentComponent(BottomPanel);
    HFMaxCB.Name := 'HFMaxCB';
    HFMaxCB.Left := 70;
    HFMaxCB.Top := 21;
    HFMaxCB.Width := 50;
    HFMaxCB.Height := 24;
    HFMaxCB.HeightPercent := 100.000000000000000000;
    HFMaxCB.Text := 'HFMaxCB';
    HFMaxCB.WidthPercent := 100.000000000000000000;
    SetEvent(HFMaxCB, Self, 'OnChange', 'HFMaxCBChange');
    HFMaxCB.ItemIndex := -1;
    CancelBtn.SetParentComponent(BottomPanel);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 696;
    CancelBtn.Top := 3;
    CancelBtn.Width := 41;
    CancelBtn.Height := 41;
    CancelBtn.Color := clNone;
    CancelBtn.Glyph.LoadFromFile('myQiuTestverwaltung.BottomPanel.CancelBtn.Glyph.bmp');
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.ShowHint := True;
    CancelBtn.TabOrder := 2;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    ZoomBtn.SetParentComponent(BottomPanel);
    ZoomBtn.Name := 'ZoomBtn';
    ZoomBtn.Left := 237;
    ZoomBtn.Top := 3;
    ZoomBtn.Width := 41;
    ZoomBtn.Height := 41;
    ZoomBtn.Color := clNone;
    ZoomBtn.Glyph.LoadFromFile('myQiuTestverwaltung.BottomPanel.ZoomBtn.Glyph.bmp');
    ZoomBtn.HeightPercent := 100.000000000000000000;
    ZoomBtn.ShowHint := True;
    ZoomBtn.TabOrder := 3;
    ZoomBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ZoomBtn, Self, 'OnClick', 'ZoomBtnClick');
    ZoomBackBtn.SetParentComponent(BottomPanel);
    ZoomBackBtn.Name := 'ZoomBackBtn';
    ZoomBackBtn.Left := 292;
    ZoomBackBtn.Top := 3;
    ZoomBackBtn.Width := 41;
    ZoomBackBtn.Height := 41;
    ZoomBackBtn.Color := clNone;
    ZoomBackBtn.Glyph.LoadFromFile('myQiuTestverwaltung.BottomPanel.ZoomBackBtn.Glyph.bmp');
    ZoomBackBtn.HeightPercent := 100.000000000000000000;
    ZoomBackBtn.ShowHint := True;
    ZoomBackBtn.TabOrder := 4;
    ZoomBackBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ZoomBackBtn, Self, 'OnClick', 'ZoomBackBtnClick');
    HelpBtn.SetParentComponent(BottomPanel);
    HelpBtn.Name := 'HelpBtn';
    HelpBtn.Left := 743;
    HelpBtn.Top := 3;
    HelpBtn.Width := 41;
    HelpBtn.Height := 41;
    HelpBtn.Color := clNone;
    HelpBtn.Glyph.LoadFromFile('myQiuTestverwaltung.BottomPanel.HelpBtn.Glyph.bmp');
    HelpBtn.HeightPercent := 100.000000000000000000;
    HelpBtn.ShowHint := True;
    HelpBtn.TabOrder := 5;
    HelpBtn.WidthPercent := 100.000000000000000000;
    SetEvent(HelpBtn, Self, 'OnClick', 'HelpBtnClick');
    ListPanel.SetParentComponent(Self);
    ListPanel.Name := 'ListPanel';
    ListPanel.Left := 0;
    ListPanel.Top := 86;
    ListPanel.Width := 800;
    ListPanel.Height := 351;
    ListPanel.Align := alTop;
    ListPanel.ChildOrder := 5;
    MiddlePanel.SetParentComponent(ListPanel);
    MiddlePanel.Name := 'MiddlePanel';
    MiddlePanel.Left := 0;
    MiddlePanel.Top := 301;
    MiddlePanel.Width := 800;
    MiddlePanel.Height := 50;
    MiddlePanel.Align := alBottom;
    MiddlePanel.BorderStyle := bsNone;
    MiddlePanel.ChildOrder := 8;
    MiddlePanel.Color := clWhite;
    WebLabel6.SetParentComponent(MiddlePanel);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 184;
    WebLabel6.Top := 0;
    WebLabel6.Width := 66;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'Kommentar';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    CommentEdit.SetParentComponent(MiddlePanel);
    CommentEdit.Name := 'CommentEdit';
    CommentEdit.Left := 184;
    CommentEdit.Top := 21;
    CommentEdit.Width := 409;
    CommentEdit.Height := 22;
    CommentEdit.ChildOrder := 2;
    CommentEdit.HeightPercent := 100.000000000000000000;
    CommentEdit.WidthPercent := 100.000000000000000000;
    DeleteBtn.SetParentComponent(MiddlePanel);
    DeleteBtn.Name := 'DeleteBtn';
    DeleteBtn.Left := 15;
    DeleteBtn.Top := 3;
    DeleteBtn.Width := 41;
    DeleteBtn.Height := 41;
    DeleteBtn.Color := clNone;
    DeleteBtn.Glyph.LoadFromFile('myQiuTestverwaltung.MiddlePanel.DeleteBtn.Glyph.bmp');
    DeleteBtn.HeightPercent := 100.000000000000000000;
    DeleteBtn.ShowHint := True;
    DeleteBtn.TabOrder := 1;
    DeleteBtn.WidthPercent := 100.000000000000000000;
    SetEvent(DeleteBtn, Self, 'OnClick', 'DeleteBtnClick');
    SaveBtn.SetParentComponent(MiddlePanel);
    SaveBtn.Name := 'SaveBtn';
    SaveBtn.Left := 655;
    SaveBtn.Top := 3;
    SaveBtn.Width := 41;
    SaveBtn.Height := 41;
    SaveBtn.Color := clNone;
    SaveBtn.Glyph.LoadFromFile('myQiuTestverwaltung.MiddlePanel.SaveBtn.Glyph.bmp');
    SaveBtn.HeightPercent := 100.000000000000000000;
    SaveBtn.ShowHint := True;
    SaveBtn.TabOrder := 2;
    SaveBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SaveBtn, Self, 'OnClick', 'SaveBtnClick');
    AuswertungBtn.SetParentComponent(MiddlePanel);
    AuswertungBtn.Name := 'AuswertungBtn';
    AuswertungBtn.Left := 109;
    AuswertungBtn.Top := 3;
    AuswertungBtn.Width := 41;
    AuswertungBtn.Height := 41;
    AuswertungBtn.Color := clNone;
    AuswertungBtn.Glyph.LoadFromFile('myQiuTestverwaltung.MiddlePanel.AuswertungBtn.Glyph.bmp');
    AuswertungBtn.HeightPercent := 100.000000000000000000;
    AuswertungBtn.ShowHint := True;
    AuswertungBtn.TabOrder := 3;
    AuswertungBtn.WidthPercent := 100.000000000000000000;
    SetEvent(AuswertungBtn, Self, 'OnClick', 'AuswertungBtnClick');
    EigenschaftenBtn.SetParentComponent(MiddlePanel);
    EigenschaftenBtn.Name := 'EigenschaftenBtn';
    EigenschaftenBtn.Left := 743;
    EigenschaftenBtn.Top := 3;
    EigenschaftenBtn.Width := 41;
    EigenschaftenBtn.Height := 41;
    EigenschaftenBtn.Color := clNone;
    EigenschaftenBtn.Glyph.LoadFromFile('myQiuTestverwaltung.MiddlePanel.EigenschaftenBtn.Glyph.bmp');
    EigenschaftenBtn.HeightPercent := 100.000000000000000000;
    EigenschaftenBtn.ShowHint := True;
    EigenschaftenBtn.TabOrder := 4;
    EigenschaftenBtn.WidthPercent := 100.000000000000000000;
    SetEvent(EigenschaftenBtn, Self, 'OnClick', 'EigenschaftenBtnClick');
    FragenBtn.SetParentComponent(MiddlePanel);
    FragenBtn.Name := 'FragenBtn';
    FragenBtn.Left := 62;
    FragenBtn.Top := 3;
    FragenBtn.Width := 41;
    FragenBtn.Height := 41;
    FragenBtn.Color := clNone;
    FragenBtn.Glyph.LoadFromFile('myQiuTestverwaltung.MiddlePanel.FragenBtn.Glyph.bmp');
    FragenBtn.HeightPercent := 100.000000000000000000;
    FragenBtn.ShowHint := True;
    FragenBtn.TabOrder := 5;
    FragenBtn.WidthPercent := 100.000000000000000000;
    SetEvent(FragenBtn, Self, 'OnClick', 'FragenBtnClick');
    MessungenStringGrid.SetParentComponent(ListPanel);
    MessungenStringGrid.Name := 'MessungenStringGrid';
    MessungenStringGrid.Left := 0;
    MessungenStringGrid.Top := 62;
    MessungenStringGrid.Width := 800;
    MessungenStringGrid.Height := 239;
    MessungenStringGrid.Align := alClient;
    MessungenStringGrid.ColCount := 3;
    MessungenStringGrid.DefaultColWidth := 100;
    MessungenStringGrid.FixedColor := clGray;
    MessungenStringGrid.FixedCols := 0;
    MessungenStringGrid.RowCount := 2;
    MessungenStringGrid.FixedRows := 0;
    MessungenStringGrid.GradientEndColor := clWhite;
    MessungenStringGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRangeSelect,goRowSelect];
    MessungenStringGrid.TabOrder := 1;
    SetEvent(MessungenStringGrid, Self, 'OnClick', 'MessungenStringGridClick');
    SetEvent(MessungenStringGrid, Self, 'OnKeyUp', 'MessungenStringGridKeyUp');
    SetEvent(MessungenStringGrid, Self, 'OnSelectCell', 'MessungenStringGridSelectCell');
    MessungenStringGrid.FixedTextColor := clWhite;
    MessungenStringGrid.RangeEdit.Max := 100.000000000000000000;
    MessungenStringGrid.RangeEdit.Step := 1.000000000000000000;
    MessungenStringGrid.HeightPercent := 100.000000000000000000;
    MessungenStringGrid.WidthPercent := 100.000000000000000000;
    CaptionPanel.SetParentComponent(ListPanel);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 0;
    CaptionPanel.Width := 800;
    CaptionPanel.Height := 26;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := clBtnFace;
    CaptionPanel.Caption := 'CaptionPanel';
    CaptionPanel.ChildOrder := 4;
    CaptionPanel.Color := clWhite;
    WaitPanel.SetParentComponent(ListPanel);
    WaitPanel.Name := 'WaitPanel';
    WaitPanel.Left := 207;
    WaitPanel.Top := 136;
    WaitPanel.Width := 361;
    WaitPanel.Height := 41;
    WaitPanel.BorderColor := clBtnFace;
    WaitPanel.Caption := 'Bitte warten';
    WaitPanel.ChildOrder := 5;
    WaitPanel.Color := clWhite;
    WaitPanel.Visible := False;
    TopSelectPanel.SetParentComponent(ListPanel);
    TopSelectPanel.Name := 'TopSelectPanel';
    TopSelectPanel.Left := 0;
    TopSelectPanel.Top := 26;
    TopSelectPanel.Width := 800;
    TopSelectPanel.Height := 36;
    TopSelectPanel.Align := alTop;
    TopSelectPanel.BorderColor := clBtnFace;
    TopSelectPanel.ChildOrder := 4;
    TopSelectPanel.Color := clWhite;
    WebLabel5.SetParentComponent(TopSelectPanel);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 10;
    WebLabel5.Top := 9;
    WebLabel5.Width := 51;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Zeitraum';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    SelectYearCB.SetParentComponent(TopSelectPanel);
    SelectYearCB.Name := 'SelectYearCB';
    SelectYearCB.Left := 105;
    SelectYearCB.Top := 5;
    SelectYearCB.Width := 175;
    SelectYearCB.Height := 24;
    SelectYearCB.HeightPercent := 100.000000000000000000;
    SelectYearCB.Text := 'SelectYearCB';
    SelectYearCB.WidthPercent := 100.000000000000000000;
    SetEvent(SelectYearCB, Self, 'OnChange', 'SelectYearCBChange');
    SelectYearCB.ItemIndex := -1;
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 800;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 3;
    MainMenuFrame1.LoginBtn.TabOrder := 3;
    MainMenuFrame1.KontoBtn.TabOrder := 0;
    MainMenuFrame1.RegistBtn.TabOrder := 1;
    SelectTimer.SetParentComponent(Self);
    SelectTimer.Name := 'SelectTimer';
    SelectTimer.Enabled := False;
    SelectTimer.Interval := 500;
    SetEvent(SelectTimer, Self, 'OnTimer', 'SelectTimerTimer');
    SelectTimer.Left := 344;
    SelectTimer.Top := 136;
    RepaintTimer.SetParentComponent(Self);
    RepaintTimer.Name := 'RepaintTimer';
    RepaintTimer.Enabled := False;
    RepaintTimer.Interval := 500;
    SetEvent(RepaintTimer, Self, 'OnTimer', 'RepaintTimerTimer');
    RepaintTimer.Left := 432;
    RepaintTimer.Top := 134;
  finally
    ImageScrollBox.AfterLoadDFMValues;
    ImagePanel.AfterLoadDFMValues;
    Image.AfterLoadDFMValues;
    BottomPanel.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    HFMinCB.AfterLoadDFMValues;
    HFMaxCB.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    ZoomBtn.AfterLoadDFMValues;
    ZoomBackBtn.AfterLoadDFMValues;
    HelpBtn.AfterLoadDFMValues;
    ListPanel.AfterLoadDFMValues;
    MiddlePanel.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    CommentEdit.AfterLoadDFMValues;
    DeleteBtn.AfterLoadDFMValues;
    SaveBtn.AfterLoadDFMValues;
    AuswertungBtn.AfterLoadDFMValues;
    EigenschaftenBtn.AfterLoadDFMValues;
    FragenBtn.AfterLoadDFMValues;
    MessungenStringGrid.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    WaitPanel.AfterLoadDFMValues;
    TopSelectPanel.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    SelectYearCB.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    MainMenuFrame1.LoginBtn.AfterLoadDFMValues;
    MainMenuFrame1.KontoBtn.AfterLoadDFMValues;
    MainMenuFrame1.RegistBtn.AfterLoadDFMValues;
    SelectTimer.AfterLoadDFMValues;
    RepaintTimer.AfterLoadDFMValues;
  end;
end;

end.