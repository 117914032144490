unit myQiuOverview;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, WEBLib.Buttons,
  DateUtils, WEBLib.StdCtrls, Vcl.StdCtrls, Vcl.Graphics, Vcl.Forms,
  MainMenuFrame, WEBLib.Lists;

type
  TMyQiuOverviewForm = class(TForm)
    BottomPanel: TPanel;
    PageUpBtn: TSpeedButton;
    ZoomBackBtn: TSpeedButton;
    ZoomBtn: TSpeedButton;
    TopPanel: TPanel;
    MoveRightBtn: TSpeedButton;
    MoveFRBtn: TSpeedButton;
    MoveLeftBtn: TSpeedButton;
    MoveFLBtn: TSpeedButton;
    TodayBtn: TSpeedButton;
    gotoBtn: TSpeedButton;
    PageDownBtn: TSpeedButton;
    PageLabel: TLabel;
    ListMonthBtn: TSpeedButton;
    ListAllBtn: TSpeedButton;
    verlaufBtn: TSpeedButton;
    BenchmarkBtn: TSpeedButton;
    PlanBtn: TSpeedButton;
    ComplianceBtn: TSpeedButton;
    SettingBtn: TSpeedButton;
    HRBtn: TSpeedButton;
    CancelBtn: TSpeedButton;
    WebPanel2: TPanel;
    WebImageControl2: TImageControl;
    WebImageControl1: TImageControl;
    WebSplitter1: TSplitter;
    MainMenuFrame1: TMainMenuFrame1;
    RepaintTimer: TTimer;
    CaptionPanel: TPanel;
    HelpBtn: TSpeedButton;
    procedure CancelBtnClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure ShowOverview;
    procedure GotoBtnClick(Sender: TObject);
    procedure PageUpBtnClick(Sender: TObject);
    procedure PageDownBtnClick(Sender: TObject);
    procedure ZoomBackBtnClick(Sender: TObject);
    procedure ZoomBtnClick(Sender: TObject);
    procedure TodayBtnClick(Sender: TObject);
    procedure MoveFLBtnClick(Sender: TObject);
    procedure MoveLeftBtnClick(Sender: TObject);
    procedure MoveRightBtnClick(Sender: TObject);
    procedure MoveFRBtnClick(Sender: TObject);
    procedure BenchmarkBtnClick(Sender: TObject);
    procedure ListAllBtnClick(Sender: TObject);
    procedure ListMonthBtnClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebSplitter1Moved(Sender: TObject);
    procedure verlaufBtnClick(Sender: TObject);
    procedure ComplianceBtnClick(Sender: TObject);
    procedure SettingBtnClick(Sender: TObject);
    procedure PlanBtnClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure RepaintTimerTimer(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure HRBtnClick(Sender: TObject);
    procedure HelpBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
    AktMonth        : word;
    AktYear         : word;
    NoOfMonthToShow : byte;
    AktPage,MaxPage : word;
    Cols,Rows       : byte;
    HFMin,HFmax     : word;
    Showbenchmark   : boolean;
    IsTopForm       : boolean;
    FormClosed      : boolean;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  MyQiuOverviewForm: TMyQiuOverviewForm;
const
 MaxCols = 6;
 MaxRows = 7;

implementation

uses Main,XData.Web.Client,KVT,myQiuTestverwaltung,CommonFunctions,DatePicker,
     MyQiuTrends,MyQiuComplicance,MyQiuSettings,myQiuScheduler;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 FormClosed := true;
 RepaintTimer.Enabled := false;
 WebImageControl2.URL := '';
 WebImageControl1.URL := '';
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.WebFormCreate(Sender: TObject);
begin
 NoOfMonthToShow := 5;
 AktPage         := 1;
 MaxPage         := 1;
 Cols            := 4;
 Rows            := 3;
 HFMin           := 0;
 HFmax           := 100;
 Showbenchmark   := false;
 IsTopForm       := true;
 FormClosed      := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 if not IsTopForm then exit;
 MainForm.FormControlListAddTask('SHOWOVERVIEW');
 Close;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.WebFormResize(Sender: TObject);
begin
 RepaintTimer.Enabled := false;

 MoveRightBtn.left := TopPanel.ClientWidth div 2 + GLV_Dist div 2;
 MoveFRBtn.left    := MoveRightBtn.left + MoveRightBtn.width + GLV_Dist;

 TodayBtn.left     := MoveFRBtn.left + MoveFRBtn.width + round(3*GLV_Dist);
 gotoBtn.left      := TodayBtn.left + TodayBtn.width + GLV_Dist;

 MoveLeftBtn.left  := TopPanel.ClientWidth div 2 - GLV_Dist div 2 - MoveLeftBtn.width;
 MoveFLBtn.left    := MoveLeftBtn.left - GLV_Dist - MoveFLBtn.width;


 ListMonthBtn.left := 2*GLV_Dist;
 ListAllBtn.left   := ListMonthBtn.left + ListMonthBtn.width + GLV_Dist;
 verlaufBtn.left   := ListAllBtn.left + ListAllBtn.width + GLV_Dist;
 BenchmarkBtn.left := verlaufBtn.left + verlaufBtn.width + GLV_Dist;
 PlanBtn.left      := BenchmarkBtn.left + BenchmarkBtn.width + GLV_Dist;
 ComplianceBtn.left:= PlanBtn.left + PlanBtn.width + GLV_Dist;
 SettingBtn.left   := ComplianceBtn.left + ComplianceBtn.width + GLV_Dist;
 HRBtn.left        := SettingBtn.left + SettingBtn.width + GLV_Dist;

 ZoomBtn.left       := HRBtn.left + HRBtn.width + 3*GLV_Dist;
 ZoomBackBtn.left   := ZoomBtn.left + ZoomBtn.width + GLV_Dist;
 PageUpBtn.left     := ZoomBackBtn.left + ZoomBackBtn.width + 2*GLV_Dist;
 PageDownBtn.left   := PageUpBtn.left + PageUpBtn.width + GLV_Dist;
 PageLabel.Left     := PageDownBtn.left + PageDownBtn.width + GLV_Dist;

 HelpBtn.left       := BottomPanel.ClientWidth - round(1.5*CancelBtn.width);
 CancelBtn.left     := HelpBtn.Left - CancelBtn.width - GLV_Dist;

 WebImageControl1.Height := WebImageControl1.ClientWidth div NoOfMonthToShow;

 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.WebFormShow(Sender: TObject);
var
 dd     : word;
begin
 CaptionPanel.Color := CaptionPanelColor;
 CaptionPanel.Font.Color := CaptionPanelFontColor;
 CaptionPanel.BorderColor :=  CaptionPanelBorderColor;
 CaptionPanel.Caption := SP.load(131) + GLC_1Blank + GetMyQiuUserString;

 decodeDate(now(), AktYear, AktMonth,dd);
 PageLabel.Caption := '-/-';
 MainMenuFrame1.UpdateFrame;
 ListMonthBtn.Hint := SP.load(211);
 ListAllbtn.Hint   := SP.load(212);
 VerlaufBtn.hint   := SP.load(213);
 benchmarkBtn.hint := SP.load(214);
 PlanBTn.Hint      := SP.load(215);
 ComplianceBtn.Hint:= SP.load(216);
 MoveFLBtn.Hint    := SP.load(217);
 MoveLeftBtn.Hint  := SP.load(218);
 MoveRightBtn.Hint := SP.load(219);
 MoveFRBtn.Hint    := SP.load(220);
 TodayBtn.Hint     := SP.load(221);
 gotobtn.hint      := SP.load(222);
 CancelBtn.Hint    := SP.load(223);
 SettingBtn.Hint   := SP.load(224);
 HelpBtn.Hint      := SP.Load(257);

 HRBtn.Hint        := SP.load(229);
 ZoomBackBtn.Hint  := SP.load(233);
 ZoomBtn.Hint      := SP.load(232);
 PageUpBtn.Hint    := SP.load(227);
 PageDownBtn.Hint  := SP.load(228);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.WebSplitter1Moved(Sender: TObject);
begin
 ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.ListAllBtnClick(Sender: TObject);
var
  myQiuTestverwaltungForm : TmyQiuTestverwaltungForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    HFMin := myQiuTestverwaltungForm.HFMin;
    HFMax := myQiuTestverwaltungForm.HFMax;
    if myQiuTestverwaltungForm.MessungDeleted then ShowOverview;
    IsTopForm := true;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuTestverwaltungForm.HFMin     := HFMin;
   myQiuTestverwaltungForm.HFMax     := HFMax;
   myQiuTestverwaltungForm.MonthOnly := false;
   myQiuTestverwaltungForm.ShowModal(@AfterShowModal);
  end;
begin
 IsTopForm := false;
 myQiuTestverwaltungForm := TmyQiuTestverwaltungForm.CreateNew(@AfterCreate);
 myQiuTestverwaltungForm.Popup := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.ListMonthBtnClick(Sender: TObject);
var
  myQiuTestverwaltungForm : TmyQiuTestverwaltungForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   HFMin := myQiuTestverwaltungForm.HFMin;
   HFMax := myQiuTestverwaltungForm.HFMax;
   if myQiuTestverwaltungForm.MessungDeleted then ShowOverview;
    // evaluate AValue and get values from form ...
   IsTopForm := true;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuTestverwaltungForm.HFMin     := HFMin;
   myQiuTestverwaltungForm.HFMax     := HFMax;
   myQiuTestverwaltungForm.MonthOnly := true;
   myQiuTestverwaltungForm.AktMonth := AktMonth;
   myQiuTestverwaltungForm.AktYear := AktYear;
   myQiuTestverwaltungForm.ShowModal(@AfterShowModal);
  end;
begin
 IsTopForm := false;
 myQiuTestverwaltungForm := TmyQiuTestverwaltungForm.CreateNew(@AfterCreate);
 myQiuTestverwaltungForm.Popup := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.SettingBtnClick(Sender: TObject);
var
 MyQiuSettingsForm: TMyQiuSettingsForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   IsTopForm := true;
   // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   MyQiuSettingsForm.ShowModal(@AfterShowModal);
  end;

begin
 IsTopForm := false;
 MyQiuSettingsForm := TMyQiuSettingsForm.CreateNew(@AfterCreate);
 // MyQiuSettingsForm.caption := SP.Load(135);
 MyQiuSettingsForm.Border := fbDialog;
 MyQiuSettingsForm.Popup := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.ShowOverview;
var
 _Height1,_Width1 : integer;
 _Height2,_Width2 : integer;
 _Monat           : string;
 NoOfTestsInMonth : integer;
 SNoOfTestsInMonth: string;
begin

 _Height1    := WebImageControl1.Height * GLV_PictureCreateSizeFactor;
 _Width1     := WebImageControl1.Width * GLV_PictureCreateSizeFactor;
 _Height2    := WebImageControl2.Height * GLV_PictureCreateSizeFactor;
 _Width2     := WebImageControl2.Width * GLV_PictureCreateSizeFactor;
 _Monat      := inttostr(AktMonth) + '/' + inttostr(AktYear);

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetOverviewDownloadLinks',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                                             _Monat,NoOfMonthToShow,Showbenchmark,
                                                                             _Height1,_Width1,
                                                                             _Height2,_Width2,
                                                                             Rows,Cols,AktPage],
   procedure (Response: TXDataClientResponse)
   var
    Link1,Link2,AntwortString : string;
    PosZeichen                : integer;
   begin
    if FormClosed then exit;
    AntwortString := string(TJSObject(Response.Result)['value']);
    PosZeichen := pos('@',AntwortString);
    SNoOfTestsInMonth := copy(AntwortString,1,PosZeichen-1);
    delete(AntwortString,1,length(SNoOfTestsInMonth)+1);
    PosZeichen := pos('@',AntwortString);
    Link1 := copy(AntwortString,1,PosZeichen-1);
    delete(AntwortString,1,length(Link1)+1);
    Link2 := AntwortString;
    WebImageControl1.URL := Link1;
    WebImageControl2.URL := Link2;
    NoOfTestsInMonth := 0;
    try
     NoOfTestsInMonth := strtoint(SNoOfTestsInMonth);
    except
    end;
    MaxPage  :=  NoOfTestsInMonth div(Cols*Rows);
    if MAxPage*Cols*Rows<NoOfTestsInMonth then inc(MaxPage);
    if MaxPage=0 then MaxPage := 1;
    Pagelabel.Caption := inttostr(AktPage)+'/'+inttostr(maxPage);
   end);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.TodayBtnClick(Sender: TObject);
begin
 AktMonth := monthof(now());AktYear := yearof(now());
 AktPage := 1;
 ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.verlaufBtnClick(Sender: TObject);
var
  MyQiuTrendsForm : TMyQiuTrendsForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   IsTopForm := true;
   // evaluate AValue and get values from form ...
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   MyQiuTrendsForm.AktMonth  := AktMonth;
   MyQiuTrendsForm.AktYear   := AktYear;
   MyQiuTrendsForm.WhatToShow:= 'HRV';
   MyQiuTrendsForm.ShowModal(@AfterShowModal);
  end;
begin
 IsTopForm := false;
 MyQiuTrendsForm := TMyQiuTrendsForm.CreateNew(@AfterCreate);
 MyQiuTrendsForm.Popup := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.GotoBtnClick(Sender: TObject);
var
 tmpdate        : TDate;
 Rest           : double;
 DatePickerForm : TDatePickerForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    if not DatePickerForm.Canceled then begin
     tmpdate := DatePickerForm.DatePickerDate;
     tmpdate := 1.0*(trunc(tmpdate)) + Rest;
     AktMonth := monthof(tmpDate);AktYear := yearof(tmpDate);
     AktPage := 1;
     ShowOverview;
    end;
    IsTopForm := true;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   DatePickerForm.WebDateTimePicker1.Date := tmpdate;
   DatePickerForm.ShowModal(@AfterShowModal);
  end;

begin
 IsTopForm := false;
 tmpdate := trunc(now());
 Rest    := now() - tmpdate;
 DatePickerForm := TDatePickerForm.CreateNew(@AfterCreate);
 DatePickerForm.caption := SP.Load(127);
 DatePickerForm.Border := fbDialog;
 DatePickerForm.Popup := true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.HelpBtnClick(Sender: TObject);
begin
 Application.Navigate(GetManualLink,ntBlank);//Application.Navigate(GetHelpDir + 'MyQiu-Uberblick.html', ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.HRBtnClick(Sender: TObject);
begin
 ShowBenchmark        := false;
 ShowOverview;
 BenchmarkBtn.Visible := true;
 HRBtn.Visible        := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.MoveFLBtnClick(Sender: TObject);
begin
  if AktYear=2009 then exit;
  if AktMonth>6 then dec(AktMonth,6)
  else begin AktMonth := 12-(6-AktMonth); dec(AktYear); end;
  AktPage := 1;  ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.MoveFRBtnClick(Sender: TObject);
var
 _m,_Y : word;
 NewMonth, NewYear : integer;
begin
  _M := monthof(now());_y := yearof(now());
  if (_M=AktMonth) and (_Y=AktYear) then exit;
  NewMonth := AktMonth; NewYear := AktYear;
  if AktMonth+6<=12 then inc(NewMonth,6)
  else begin NewMonth := (6-(12-AktMonth)); inc(NewYear); end;
  if (_Y*12+_m)<(NewYear*12+NewMonth) then begin
      NewMonth := _M;NewYear := _Y;
  end;
  AktMonth :=NewMonth ; AktYear :=NewYear ;

  AktPage := 1;
  ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.MoveLeftBtnClick(Sender: TObject);
begin
  if AktYear=2009 then exit;
  if AktMonth>1 then dec(AktMonth) else begin AktMonth := 12; dec(AktYear); end;
  AktPage := 1;
  ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.MoveRightBtnClick(Sender: TObject);
var
 _m,_Y : word;
begin
  _M := monthof(now());_y := yearof(now());
  if (_M=AktMonth) and (_Y=AktYear) then exit;
  if AktMonth<12 then inc(AktMonth) else begin AktMonth := 1; inc(AktYear); end;
  AktPage := 1;
  ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.ZoomBtnClick(Sender: TObject);
var
oldTestOne  : word;
NewTestOne  : word;
NewTestlast : word;
ready       : boolean;
begin
  oldtestOne := (AktPage-1)*Cols*Rows;
  if Cols>1 then dec(Cols);
  if Rows>1 then dec(Rows);
  AktPage := 0;ready := false;
  while not ready do begin
     inc(Aktpage);
     newtestOne := (AktPage-1)*Cols*Rows;
     newtestlast:= (AktPage)*Cols*Rows-1;
     if (Oldtestone>=newtestone) and (Oldtestone<=newtestlast)  then ready := true;
  end;
  ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.PageDownBtnClick(Sender: TObject);
begin
 if AktPage>=MaxPage then exit;
 inc(AktPage);
 ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.ZoomBackBtnClick(Sender: TObject);
var
oldTestOne  : word;
NewTestOne  : word;
NewTestlast : word;
ready       : boolean;
begin
 oldtestOne := (AktPage-1)*Cols*Rows;
 if Cols<MaxCols then inc(Cols);
 if (Rows<MaxRows) and (Rows<= Cols-2) then inc(Rows);
 AktPage := 0;ready := false;
 while not ready do begin
     inc(Aktpage);
     newtestOne := (AktPage-1)*Cols*Rows;
     newtestlast:= (AktPage)*Cols*Rows-1;
     if (Oldtestone>=newtestone) and (Oldtestone<=newtestlast)  then ready := true;
 end;
 ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.PageUpBtnClick(Sender: TObject);
begin
 if AktPage<=1 then exit;
 dec(AktPage);
 ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.PlanBtnClick(Sender: TObject);
var
  SchedulerForm : TSchedulerForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   IsTopForm := true;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   SchedulerForm.ShowModal(@AfterShowModal);
  end;
begin
 IsTopForm := false;
 SchedulerForm := TSchedulerForm.CreateNew(@AfterCreate);
 SchedulerForm.Popup := false;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.RepaintTimerTimer(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 ShowOverview;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.BenchmarkBtnClick(Sender: TObject);
begin
 ShowBenchmark := true;
 HRBtn.Left   :=  BenchmarkBtn.Left;
 HRBtn.Top    :=  BenchmarkBtn.Top;
 ShowOverview;
 BenchmarkBtn.Visible := false;
 HRBtn.Visible:= true;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TMyQiuOverviewForm.ComplianceBtnClick(Sender: TObject);
var
  MyQiuComplicanceForm : TMyQiuComplicanceForm;

 procedure AfterShowModal(AValue: TModalResult);
  begin
    // evaluate AValue and get values from form ...
    IsTopForm := true;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   MyQiuComplicanceForm.AktMonth  := AktMonth;
   MyQiuComplicanceForm.AktYear   := AktYear;
   MyQiuComplicanceForm.ShowModal(@AfterShowModal);
  end;
begin
 IsTopForm := false;
 MyQiuComplicanceForm := TMyQiuComplicanceForm.CreateNew(@AfterCreate);
 MyQiuComplicanceForm.Popup := false;
end;
{------------------------------------------------------------------------------}


procedure TMyQiuOverviewForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  BottomPanel := TPanel.Create(Self);
  PageLabel := TLabel.Create(Self);
  PageUpBtn := TSpeedButton.Create(Self);
  ZoomBackBtn := TSpeedButton.Create(Self);
  ZoomBtn := TSpeedButton.Create(Self);
  PageDownBtn := TSpeedButton.Create(Self);
  ListMonthBtn := TSpeedButton.Create(Self);
  ListAllBtn := TSpeedButton.Create(Self);
  verlaufBtn := TSpeedButton.Create(Self);
  BenchmarkBtn := TSpeedButton.Create(Self);
  PlanBtn := TSpeedButton.Create(Self);
  ComplianceBtn := TSpeedButton.Create(Self);
  SettingBtn := TSpeedButton.Create(Self);
  HRBtn := TSpeedButton.Create(Self);
  CancelBtn := TSpeedButton.Create(Self);
  HelpBtn := TSpeedButton.Create(Self);
  TopPanel := TPanel.Create(Self);
  MoveRightBtn := TSpeedButton.Create(Self);
  MoveFRBtn := TSpeedButton.Create(Self);
  MoveLeftBtn := TSpeedButton.Create(Self);
  MoveFLBtn := TSpeedButton.Create(Self);
  TodayBtn := TSpeedButton.Create(Self);
  gotoBtn := TSpeedButton.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebImageControl2 := TImageControl.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebSplitter1 := TSplitter.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  RepaintTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  BottomPanel.BeforeLoadDFMValues;
  PageLabel.BeforeLoadDFMValues;
  PageUpBtn.BeforeLoadDFMValues;
  ZoomBackBtn.BeforeLoadDFMValues;
  ZoomBtn.BeforeLoadDFMValues;
  PageDownBtn.BeforeLoadDFMValues;
  ListMonthBtn.BeforeLoadDFMValues;
  ListAllBtn.BeforeLoadDFMValues;
  verlaufBtn.BeforeLoadDFMValues;
  BenchmarkBtn.BeforeLoadDFMValues;
  PlanBtn.BeforeLoadDFMValues;
  ComplianceBtn.BeforeLoadDFMValues;
  SettingBtn.BeforeLoadDFMValues;
  HRBtn.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  HelpBtn.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  MoveRightBtn.BeforeLoadDFMValues;
  MoveFRBtn.BeforeLoadDFMValues;
  MoveLeftBtn.BeforeLoadDFMValues;
  MoveFLBtn.BeforeLoadDFMValues;
  TodayBtn.BeforeLoadDFMValues;
  gotoBtn.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebImageControl2.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebSplitter1.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  RepaintTimer.BeforeLoadDFMValues;
  try
    Name := 'MyQiuOverviewForm';
    Width := 868;
    Height := 718;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 668;
    BottomPanel.Width := 868;
    BottomPanel.Height := 50;
    BottomPanel.HeightPercent := 100.000000000000000000;
    BottomPanel.WidthPercent := 100.000000000000000000;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderColor := clWhite;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.Color := clWhite;
    PageLabel.SetParentComponent(BottomPanel);
    PageLabel.Name := 'PageLabel';
    PageLabel.Left := 676;
    PageLabel.Top := 18;
    PageLabel.Width := 58;
    PageLabel.Height := 16;
    PageLabel.Caption := 'PageLabel';
    PageLabel.HeightPercent := 100.000000000000000000;
    PageLabel.WidthPercent := 100.000000000000000000;
    PageUpBtn.SetParentComponent(BottomPanel);
    PageUpBtn.Name := 'PageUpBtn';
    PageUpBtn.Left := 572;
    PageUpBtn.Top := 3;
    PageUpBtn.Width := 41;
    PageUpBtn.Height := 41;
    PageUpBtn.Color := clNone;
    PageUpBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.PageUpBtn.Glyph.bmp');
    PageUpBtn.HeightPercent := 100.000000000000000000;
    PageUpBtn.ShowHint := True;
    PageUpBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PageUpBtn, Self, 'OnClick', 'PageUpBtnClick');
    ZoomBackBtn.SetParentComponent(BottomPanel);
    ZoomBackBtn.Name := 'ZoomBackBtn';
    ZoomBackBtn.Left := 516;
    ZoomBackBtn.Top := 3;
    ZoomBackBtn.Width := 41;
    ZoomBackBtn.Height := 41;
    ZoomBackBtn.Color := clNone;
    ZoomBackBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.ZoomBackBtn.Glyph.bmp');
    ZoomBackBtn.HeightPercent := 100.000000000000000000;
    ZoomBackBtn.ShowHint := True;
    ZoomBackBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ZoomBackBtn, Self, 'OnClick', 'ZoomBackBtnClick');
    ZoomBtn.SetParentComponent(BottomPanel);
    ZoomBtn.Name := 'ZoomBtn';
    ZoomBtn.Left := 461;
    ZoomBtn.Top := 3;
    ZoomBtn.Width := 41;
    ZoomBtn.Height := 41;
    ZoomBtn.Color := clNone;
    ZoomBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.ZoomBtn.Glyph.bmp');
    ZoomBtn.HeightPercent := 100.000000000000000000;
    ZoomBtn.ShowHint := True;
    ZoomBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ZoomBtn, Self, 'OnClick', 'ZoomBtnClick');
    PageDownBtn.SetParentComponent(BottomPanel);
    PageDownBtn.Name := 'PageDownBtn';
    PageDownBtn.Left := 619;
    PageDownBtn.Top := 3;
    PageDownBtn.Width := 41;
    PageDownBtn.Height := 41;
    PageDownBtn.Color := clNone;
    PageDownBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.PageDownBtn.Glyph.bmp');
    PageDownBtn.HeightPercent := 100.000000000000000000;
    PageDownBtn.ShowHint := True;
    PageDownBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PageDownBtn, Self, 'OnClick', 'PageDownBtnClick');
    ListMonthBtn.SetParentComponent(BottomPanel);
    ListMonthBtn.Name := 'ListMonthBtn';
    ListMonthBtn.Left := 9;
    ListMonthBtn.Top := 3;
    ListMonthBtn.Width := 41;
    ListMonthBtn.Height := 41;
    ListMonthBtn.Color := clNone;
    ListMonthBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.ListMonthBtn.Glyph.bmp');
    ListMonthBtn.HeightPercent := 100.000000000000000000;
    ListMonthBtn.ShowHint := True;
    ListMonthBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ListMonthBtn, Self, 'OnClick', 'ListMonthBtnClick');
    ListAllBtn.SetParentComponent(BottomPanel);
    ListAllBtn.Name := 'ListAllBtn';
    ListAllBtn.Left := 56;
    ListAllBtn.Top := 3;
    ListAllBtn.Width := 41;
    ListAllBtn.Height := 41;
    ListAllBtn.Color := clNone;
    ListAllBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.ListAllBtn.Glyph.bmp');
    ListAllBtn.HeightPercent := 100.000000000000000000;
    ListAllBtn.ShowHint := True;
    ListAllBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ListAllBtn, Self, 'OnClick', 'ListAllBtnClick');
    verlaufBtn.SetParentComponent(BottomPanel);
    verlaufBtn.Name := 'verlaufBtn';
    verlaufBtn.Left := 105;
    verlaufBtn.Top := 3;
    verlaufBtn.Width := 41;
    verlaufBtn.Height := 41;
    verlaufBtn.Color := clNone;
    verlaufBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.verlaufBtn.Glyph.bmp');
    verlaufBtn.HeightPercent := 100.000000000000000000;
    verlaufBtn.ShowHint := True;
    verlaufBtn.WidthPercent := 100.000000000000000000;
    SetEvent(verlaufBtn, Self, 'OnClick', 'verlaufBtnClick');
    BenchmarkBtn.SetParentComponent(BottomPanel);
    BenchmarkBtn.Name := 'BenchmarkBtn';
    BenchmarkBtn.Left := 152;
    BenchmarkBtn.Top := 3;
    BenchmarkBtn.Width := 41;
    BenchmarkBtn.Height := 41;
    BenchmarkBtn.Color := clNone;
    BenchmarkBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.BenchmarkBtn.Glyph.bmp');
    BenchmarkBtn.HeightPercent := 100.000000000000000000;
    BenchmarkBtn.ShowHint := True;
    BenchmarkBtn.WidthPercent := 100.000000000000000000;
    SetEvent(BenchmarkBtn, Self, 'OnClick', 'BenchmarkBtnClick');
    PlanBtn.SetParentComponent(BottomPanel);
    PlanBtn.Name := 'PlanBtn';
    PlanBtn.Left := 201;
    PlanBtn.Top := 3;
    PlanBtn.Width := 41;
    PlanBtn.Height := 41;
    PlanBtn.Color := clNone;
    PlanBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.PlanBtn.Glyph.bmp');
    PlanBtn.HeightPercent := 100.000000000000000000;
    PlanBtn.ShowHint := True;
    PlanBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PlanBtn, Self, 'OnClick', 'PlanBtnClick');
    ComplianceBtn.SetParentComponent(BottomPanel);
    ComplianceBtn.Name := 'ComplianceBtn';
    ComplianceBtn.Left := 248;
    ComplianceBtn.Top := 3;
    ComplianceBtn.Width := 41;
    ComplianceBtn.Height := 41;
    ComplianceBtn.Color := clNone;
    ComplianceBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.ComplianceBtn.Glyph.bmp');
    ComplianceBtn.HeightPercent := 100.000000000000000000;
    ComplianceBtn.ShowHint := True;
    ComplianceBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ComplianceBtn, Self, 'OnClick', 'ComplianceBtnClick');
    SettingBtn.SetParentComponent(BottomPanel);
    SettingBtn.Name := 'SettingBtn';
    SettingBtn.Left := 297;
    SettingBtn.Top := 3;
    SettingBtn.Width := 41;
    SettingBtn.Height := 41;
    SettingBtn.Color := clNone;
    SettingBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.SettingBtn.Glyph.bmp');
    SettingBtn.HeightPercent := 100.000000000000000000;
    SettingBtn.ShowHint := True;
    SettingBtn.WidthPercent := 100.000000000000000000;
    SetEvent(SettingBtn, Self, 'OnClick', 'SettingBtnClick');
    HRBtn.SetParentComponent(BottomPanel);
    HRBtn.Name := 'HRBtn';
    HRBtn.Left := 344;
    HRBtn.Top := 3;
    HRBtn.Width := 41;
    HRBtn.Height := 41;
    HRBtn.Color := clNone;
    HRBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.HRBtn.Glyph.bmp');
    HRBtn.HeightPercent := 100.000000000000000000;
    HRBtn.ShowHint := True;
    HRBtn.Visible := False;
    HRBtn.WidthPercent := 100.000000000000000000;
    SetEvent(HRBtn, Self, 'OnClick', 'HRBtnClick');
    CancelBtn.SetParentComponent(BottomPanel);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 765;
    CancelBtn.Top := 3;
    CancelBtn.Width := 41;
    CancelBtn.Height := 41;
    CancelBtn.Color := clNone;
    CancelBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.CancelBtn.Glyph.bmp');
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.ShowHint := True;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    HelpBtn.SetParentComponent(BottomPanel);
    HelpBtn.Name := 'HelpBtn';
    HelpBtn.Left := 812;
    HelpBtn.Top := 3;
    HelpBtn.Width := 41;
    HelpBtn.Height := 41;
    HelpBtn.Color := clNone;
    HelpBtn.Glyph.LoadFromFile('myQiuOverview.BottomPanel.HelpBtn.Glyph.bmp');
    HelpBtn.HeightPercent := 100.000000000000000000;
    HelpBtn.ShowHint := True;
    HelpBtn.WidthPercent := 100.000000000000000000;
    SetEvent(HelpBtn, Self, 'OnClick', 'HelpBtnClick');
    TopPanel.SetParentComponent(Self);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 111;
    TopPanel.Width := 868;
    TopPanel.Height := 50;
    TopPanel.HeightPercent := 100.000000000000000000;
    TopPanel.WidthPercent := 100.000000000000000000;
    TopPanel.Align := alTop;
    TopPanel.BorderColor := clWhite;
    TopPanel.BorderStyle := bsNone;
    TopPanel.ChildOrder := 5;
    TopPanel.Color := clWhite;
    MoveRightBtn.SetParentComponent(TopPanel);
    MoveRightBtn.Name := 'MoveRightBtn';
    MoveRightBtn.Left := 473;
    MoveRightBtn.Top := 3;
    MoveRightBtn.Width := 41;
    MoveRightBtn.Height := 41;
    MoveRightBtn.Color := clNone;
    MoveRightBtn.Glyph.LoadFromFile('myQiuOverview.TopPanel.MoveRightBtn.Glyph.bmp');
    MoveRightBtn.HeightPercent := 100.000000000000000000;
    MoveRightBtn.ShowHint := True;
    MoveRightBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveRightBtn, Self, 'OnClick', 'MoveRightBtnClick');
    MoveFRBtn.SetParentComponent(TopPanel);
    MoveFRBtn.Name := 'MoveFRBtn';
    MoveFRBtn.Left := 520;
    MoveFRBtn.Top := 3;
    MoveFRBtn.Width := 45;
    MoveFRBtn.Height := 41;
    MoveFRBtn.Color := clNone;
    MoveFRBtn.Glyph.LoadFromFile('myQiuOverview.TopPanel.MoveFRBtn.Glyph.bmp');
    MoveFRBtn.HeightPercent := 100.000000000000000000;
    MoveFRBtn.ShowHint := True;
    MoveFRBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFRBtn, Self, 'OnClick', 'MoveFRBtnClick');
    MoveLeftBtn.SetParentComponent(TopPanel);
    MoveLeftBtn.Name := 'MoveLeftBtn';
    MoveLeftBtn.Left := 417;
    MoveLeftBtn.Top := 3;
    MoveLeftBtn.Width := 41;
    MoveLeftBtn.Height := 41;
    MoveLeftBtn.Color := clNone;
    MoveLeftBtn.Glyph.LoadFromFile('myQiuOverview.TopPanel.MoveLeftBtn.Glyph.bmp');
    MoveLeftBtn.HeightPercent := 100.000000000000000000;
    MoveLeftBtn.ShowHint := True;
    MoveLeftBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveLeftBtn, Self, 'OnClick', 'MoveLeftBtnClick');
    MoveFLBtn.SetParentComponent(TopPanel);
    MoveFLBtn.Name := 'MoveFLBtn';
    MoveFLBtn.Left := 370;
    MoveFLBtn.Top := 3;
    MoveFLBtn.Width := 45;
    MoveFLBtn.Height := 41;
    MoveFLBtn.Color := clNone;
    MoveFLBtn.Glyph.LoadFromFile('myQiuOverview.TopPanel.MoveFLBtn.Glyph.bmp');
    MoveFLBtn.HeightPercent := 100.000000000000000000;
    MoveFLBtn.ShowHint := True;
    MoveFLBtn.WidthPercent := 100.000000000000000000;
    SetEvent(MoveFLBtn, Self, 'OnClick', 'MoveFLBtnClick');
    TodayBtn.SetParentComponent(TopPanel);
    TodayBtn.Name := 'TodayBtn';
    TodayBtn.Left := 584;
    TodayBtn.Top := 3;
    TodayBtn.Width := 41;
    TodayBtn.Height := 41;
    TodayBtn.Color := clNone;
    TodayBtn.Glyph.LoadFromFile('myQiuOverview.TopPanel.TodayBtn.Glyph.bmp');
    TodayBtn.HeightPercent := 100.000000000000000000;
    TodayBtn.ShowHint := True;
    TodayBtn.WidthPercent := 100.000000000000000000;
    SetEvent(TodayBtn, Self, 'OnClick', 'TodayBtnClick');
    gotoBtn.SetParentComponent(TopPanel);
    gotoBtn.Name := 'gotoBtn';
    gotoBtn.Left := 631;
    gotoBtn.Top := 3;
    gotoBtn.Width := 41;
    gotoBtn.Height := 41;
    gotoBtn.Color := clNone;
    gotoBtn.Glyph.LoadFromFile('myQiuOverview.TopPanel.gotoBtn.Glyph.bmp');
    gotoBtn.HeightPercent := 100.000000000000000000;
    gotoBtn.ShowHint := True;
    gotoBtn.WidthPercent := 100.000000000000000000;
    SetEvent(gotoBtn, Self, 'OnClick', 'GotoBtnClick');
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 161;
    WebPanel2.Width := 868;
    WebPanel2.Height := 507;
    WebPanel2.HeightPercent := 100.000000000000000000;
    WebPanel2.WidthPercent := 100.000000000000000000;
    WebPanel2.Align := alClient;
    WebPanel2.BorderColor := clSilver;
    WebPanel2.BorderStyle := bsSingle;
    WebPanel2.ChildOrder := 5;
    WebImageControl2.SetParentComponent(WebPanel2);
    WebImageControl2.Name := 'WebImageControl2';
    WebImageControl2.Left := 0;
    WebImageControl2.Top := 243;
    WebImageControl2.Width := 868;
    WebImageControl2.Height := 264;
    WebImageControl2.HeightPercent := 100.000000000000000000;
    WebImageControl2.WidthPercent := 100.000000000000000000;
    WebImageControl2.Align := alClient;
    WebImageControl2.ChildOrder := 1;
    WebImageControl1.SetParentComponent(WebPanel2);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 0;
    WebImageControl1.Width := 868;
    WebImageControl1.Height := 233;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Align := alTop;
    WebImageControl1.ChildOrder := 1;
    WebSplitter1.SetParentComponent(WebPanel2);
    WebSplitter1.Name := 'WebSplitter1';
    WebSplitter1.Left := 0;
    WebSplitter1.Top := 233;
    WebSplitter1.Width := 868;
    WebSplitter1.Height := 10;
    WebSplitter1.HeightPercent := 100.000000000000000000;
    WebSplitter1.WidthPercent := 100.000000000000000000;
    WebSplitter1.Align := alTop;
    WebSplitter1.ChildOrder := 2;
    WebSplitter1.Color := clGray;
    SetEvent(WebSplitter1, Self, 'OnMoved', 'WebSplitter1Moved');
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 868;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 3;
    CaptionPanel.SetParentComponent(Self);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 86;
    CaptionPanel.Width := 868;
    CaptionPanel.Height := 25;
    CaptionPanel.HeightPercent := 100.000000000000000000;
    CaptionPanel.WidthPercent := 100.000000000000000000;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := clBtnFace;
    CaptionPanel.BorderStyle := bsSingle;
    CaptionPanel.Caption := 'CaptionPanel';
    CaptionPanel.ChildOrder := 4;
    CaptionPanel.Color := clWhite;
    RepaintTimer.SetParentComponent(Self);
    RepaintTimer.Name := 'RepaintTimer';
    RepaintTimer.Enabled := False;
    RepaintTimer.Interval := 500;
    SetEvent(RepaintTimer, Self, 'OnTimer', 'RepaintTimerTimer');
    RepaintTimer.Left := 688;
    RepaintTimer.Top := 446;
  finally
    BottomPanel.AfterLoadDFMValues;
    PageLabel.AfterLoadDFMValues;
    PageUpBtn.AfterLoadDFMValues;
    ZoomBackBtn.AfterLoadDFMValues;
    ZoomBtn.AfterLoadDFMValues;
    PageDownBtn.AfterLoadDFMValues;
    ListMonthBtn.AfterLoadDFMValues;
    ListAllBtn.AfterLoadDFMValues;
    verlaufBtn.AfterLoadDFMValues;
    BenchmarkBtn.AfterLoadDFMValues;
    PlanBtn.AfterLoadDFMValues;
    ComplianceBtn.AfterLoadDFMValues;
    SettingBtn.AfterLoadDFMValues;
    HRBtn.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    HelpBtn.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    MoveRightBtn.AfterLoadDFMValues;
    MoveFRBtn.AfterLoadDFMValues;
    MoveLeftBtn.AfterLoadDFMValues;
    MoveFLBtn.AfterLoadDFMValues;
    TodayBtn.AfterLoadDFMValues;
    gotoBtn.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebImageControl2.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebSplitter1.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    RepaintTimer.AfterLoadDFMValues;
  end;
end;

end.