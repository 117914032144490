unit myQiuScheduler_SW;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, Vcl.Graphics,
  WEBLib.Buttons,DatenServiceClasses, Vcl.Grids, WEBLib.Grids, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, MainMenuFrame, Vcl.Imaging.pngimage,
  WEBLib.ComCtrls, Types;

type
  TSchedulerFormSW = class(TForm)
    BottomPanel: TPanel;
    TopPanel: TPanel;
    WebImageControl1: TImageControl;
    SelectTimer: TTimer;
    WebPanel1: TPanel;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    TypusCB: TComboBox;
    IntervallCB: TComboBox;
    WochenTagCB: TComboBox;
    BezEdit: TEdit;
    RememberCB: TCheckBox;
    HHEdit: TEdit;
    HHUpSE: TSpeedButton;
    HHDownSE: TSpeedButton;
    MMEdit: TEdit;
    MMUpSE: TSpeedButton;
    MMDownSE: TSpeedButton;
    DDEdit: TEdit;
    DDEditDownSB: TSpeedButton;
    DDEditUpSB: TSpeedButton;
    WebPanel2: TPanel;
    WebPanel3: TPanel;
    SchedulerTasksStringGrid: TStringGrid;
    WebPanel5: TPanel;
    SchedulerStringGrid: TStringGrid;
    RepaintTimer: TTimer;
    CaptionPanel: TPanel;
    MainMenuFrame1: TMainMenuFrame1;
    PlanSetDayBtn: TSpeedButton;
    PlanNewBtn: TSpeedButton;
    PlanDelBtn: TSpeedButton;
    CancelBtn: TSpeedButton;
    WebPageControl1: TPageControl;
    WebPageControl1Sheet1: TTabSheet;
    WebPageControl1Sheet2: TTabSheet;
    TaskAddBtn: TSpeedButton;
    TaskEditbtn: TSpeedButton;
    WebPageControl1Sheet3: TTabSheet;
    HelpBtn: TSpeedButton;
    WebPanel4: TPanel;
    TaskDelbtn: TSpeedButton;
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure GetScheduler;
    procedure GetSchedulerTasks(SchedulerID:integer);
    procedure GetSchedulerBild(SchedulerID:integer);
    procedure SelectTimerTimer(Sender: TObject);
    procedure CancelBtnClick(Sender: TObject);
    procedure ShowFirstEntry;
    procedure SchedulerStringGridClick(Sender: TObject);
    procedure SchedulerStringGridKeyUp(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure SchedulerStringGridSelectCell(Sender: TObject; ACol,
      ARow: Integer; var CanSelect: Boolean);
    procedure TypusCBChange(Sender: TObject);
    procedure IntervallCBChange(Sender: TObject);
    procedure HHDownSEClick(Sender: TObject);
    procedure HHUpSEClick(Sender: TObject);
    procedure MMUpSEClick(Sender: TObject);
    procedure MMDownSEClick(Sender: TObject);
    procedure SchedulerTasksStringGridClick(Sender: TObject);
    procedure SchedulerTasksStringGridKeyUp(Sender: TObject; var Key: Word;
      Shift: TShiftState);
    procedure SchedulerTasksStringGridSelectCell(Sender: TObject; ACol,
      ARow: Integer; var CanSelect: Boolean);
    procedure ShowTask;
    procedure DDEditUpSBClick(Sender: TObject);
    procedure DDEditDownSBClick(Sender: TObject);
    procedure PlanNewBtnClick(Sender: TObject);
    procedure SaveNewPlan(_Bezeichnung:string;_PlanVorlageID:integer;_ValidDate:string);
    procedure PlanSetDayBtnClick(Sender: TObject);
    procedure SavePlanChangedDate(_PlanVorlageID:integer;_ValidDate:string);
    procedure PlanDelBtnClick(Sender: TObject);
    procedure DeletePlan(_PlanVorlageID:integer);
    procedure TaskDelbtnClick(Sender: TObject);
    procedure DeleteTask(_PlanID:integer;_TaskID:integer);
    procedure TaskAddbtnClick(Sender: TObject);
    procedure OverTakeTaskChanges(var Task:TSchedulerItemClass);
    procedure AddNewTask(_PlanID:integer;Task:TSchedulerItemClass);
    procedure EditTask(_PlanID:integer;Task:TSchedulerItemClass);
    procedure TaskEditbtnClick(Sender: TObject);
    procedure RepaintTimerTimer(Sender: TObject);
    procedure WebFormOrientationChange(Sender: TObject;
      AOrientation: TScreenOrientation);
    procedure HelpBtnClick(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    Scheduler                   : TArray<TSchedulerClass>;
    SchedulerTaskList           : TArray<TSchedulerItemClass>;
    SelectedSchedulerTopRow     : integer;
    SelectedSchedulerBottomRow  : integer;
    SelectedTasksTopRow         : integer;
    SelectedTasksBottomRow      : integer;
    FormClosed                  : boolean;
  protected procedure LoadDFMValues; override; end;

var
  SchedulerFormSW: TSchedulerFormSW;

implementation

uses Main,XData.Web.Client,KVT,MyMsgDialog,myQiuSchedulerEnterDlg,CommonFunctions;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.CancelBtnClick(Sender: TObject);
begin
 Close;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.PlanDelBtnClick(Sender: TObject);
var
  Plan  : TSchedulerClass;
  MyMessageDlgForm : TMyMessageDlgForm;
  s                : string;

 procedure AfterShowModal(AValue: TModalResult);
  begin
   case MyMessageDlgForm._Result of
    drYes : DeletePlan(Plan.ID);
    drNo  : exit;
   end;
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin

  end;
begin
  if SelectedSchedulerTopRow < 0 then begin
    showMessage(SP.Load(162));
    exit;
  end;

  Plan := Scheduler[SelectedSchedulerTopRow];

  if Plan.isBasis then begin
    showMessage(SP.load(178));
    exit;
  end;

  MyMessageDlgForm := TMyMessageDlgForm.CreateNew(@AfterCreate);
  MyMessageDlgForm.Border := fbsingle;
  MyMessageDlgForm.Popup := true;
  MyMessageDlgForm.Titel := SP.load(20);
  s := SP.Load(179);
  ReplacePlatzhalter(s,'@@@PLAN',Plan.Bez);
  MyMessageDlgForm.MessageText := s;
  MyMessageDlgForm.DialogType := dtYesNo;
  MyMessageDlgForm.ShowModal(@AfterShowModal);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.PlanNewBtnClick(Sender: TObject);
var
  Bez      : string;
  Vorlage  : TSchedulerClass;
  myQiuSchedulerEnterDialog : TmyQiuSchedulerEnterDialog;

  procedure AfterShowModal(AValue: TModalResult);
  begin
   // evaluate AValue and get values from form ...
   bez := myQiuSchedulerEnterDialog.webedit1.Text;
   if trim(Bez) = '' then begin
    showmessage(SP.load(164));
   end else SaveNewPlan(bez,Vorlage.ID,MyTDateFormatToString(now));
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuSchedulerEnterDialog.modus := 0;
   myQiuSchedulerEnterDialog.ShowModal(@AfterShowModal);
  end;

begin
 if SelectedSchedulerTopRow < 0 then begin
    showMessage(SP.load(163));
    exit;
 end;

 Vorlage :=  Scheduler[SelectedSchedulerTopRow];

 myQiuSchedulerEnterDialog := TmyQiuSchedulerEnterDialog.CreateNew(@AfterCreate);
 myQiuSchedulerEnterDialog.caption := SP.Load(168);
 myQiuSchedulerEnterDialog.Border := fbDialog;
 myQiuSchedulerEnterDialog.Popup := true;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.PlanSetDayBtnClick(Sender: TObject);
var
 Plan                      : TSchedulerClass;
 myQiuSchedulerEnterDialog : TmyQiuSchedulerEnterDialog;
 _date                     : TDateTime;

  procedure AfterShowModal(AValue: TModalResult);
  begin
   // evaluate AValue and get values from form ...
   _date := myQiuSchedulerEnterDialog.WebDateTimePicker1.Date;
   SavePlanChangedDate(Plan.ID,MyTDateFormatToString(_date));
  end;
 // will be called after form has been created
 procedure AfterCreate(AForm: TObject);
  begin
   myQiuSchedulerEnterDialog.modus := 1;
   myQiuSchedulerEnterDialog.ShowModal(@AfterShowModal);
  end;

begin

  if SelectedSchedulerTopRow < 0 then begin
    showMessage(SP.Load(162));
    exit;
  end;

  Plan := Scheduler[SelectedSchedulerTopRow];

  if Plan.isBasis then begin
    showMessage(SP.load(165));
    exit;
  end;

  myQiuSchedulerEnterDialog := TmyQiuSchedulerEnterDialog.CreateNew(@AfterCreate);
  myQiuSchedulerEnterDialog.caption := SP.load(168);
  myQiuSchedulerEnterDialog.Border := fbDialog;
  myQiuSchedulerEnterDialog.Popup := true;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.RepaintTimerTimer(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 GetSchedulerBild(SelectedSchedulerTopRow);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.TaskAddbtnClick(Sender: TObject);
var
 Plan       : TSchedulerClass;
 Task       : TSchedulerItemClass;
begin
 if SelectedSchedulerTopRow < 0 then begin
    showMessage(SP.Load(162));
    exit;
  end;

  Plan := Scheduler[SelectedSchedulerTopRow];

  if Plan.isBasis then begin
    showMessage(SP.load(178));
    exit;
  end;

  Task := TSchedulerItemClass.Create;
  OverTakeTaskChanges(Task);
  AddNewTask(Plan.ID,Task);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.AddNewTask(_PlanID:integer;Task:TSchedulerItemClass);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.Scheduler_AddTask',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                              _PlanID,Task],
   procedure (Response: TXDataClientResponse)
   begin
    if integer(TJSObject(Response.Result)['value']) < 0 then
     showmessage(SP.load(161))//Speichern fehlgeschlagen
    else begin
     GetSchedulerTasks(TSchedulerClass(Scheduler[SelectedSchedulerTopRow]).ID);
     GetSchedulerBild(TSchedulerClass(Scheduler[SelectedSchedulerTopRow]).ID);
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.OverTakeTaskChanges(var Task:TSchedulerItemClass);
begin
 Task.TextFeld := '';
 Task.TYP := 'BFB';         //##IGNORE_LINE
 case TypusCB.ItemIndex of
    0 : Task.TYP := 'BFB';     //##IGNORE_LINE
    1 : Task.TYP := 'QIU';      //##IGNORE_LINE
    2 : Task.TYP := 'PCBFB';    //##IGNORE_LINE
    3 : Task.TYP := 'RSA';       //##IGNORE_LINE
    4 : Task.TYP := 'KURZHRV';   //##IGNORE_LINE
    5 : Task.TYP := 'BP';        //##IGNORE_LINE
    6 : Task.TYP := 'GLU';      //##IGNORE_LINE
    7 : Task.TYP := 'WEIGHT';    //##IGNORE_LINE
    8 : begin Task.TYP := 'SONST'; Task.TextFeld := BezEdit.Text; end;  //##IGNORE_LINE
 end;
 Task.MODE :='DAILY';     //##IGNORE_LINE
 case IntervallCB.ItemIndex of
   0 : Task.MODE :='DAILY';   //##IGNORE_LINE
   1 : Task.MODE :='WEEKLY';  //##IGNORE_LINE
   2 : Task.MODE :='MONTHLY';  //##IGNORE_LINE
 end;
 if Task.MODE='WEEKLY' then begin      //##IGNORE_LINE
    case WochenTagCB.ItemIndex of
       0 : Task.WochenTag :='MO';    //##IGNORE_LINE
       1 : Task.WochenTag :='DI';    ///##IGNORE_LINE
       2 : Task.WochenTag :='MI';     //##IGNORE_LINE
       3 : Task.WochenTag :='DO';     //##IGNORE_LINE
       4 : Task.WochenTag :='FR';     //##IGNORE_LINE
       5 : Task.WochenTag :='SA';    //##IGNORE_LINE
       6 : Task.WochenTag :='SO';      //##IGNORE_LINE
    end;
 end;
 if Task.MODE='MONTHLY' then begin    //##IGNORE_LINE
    Task.MonatsTag := trim(DDEdit.Text);
 end;
 Task.Zeit     := trim(HHedit.Text)+':'+trim(MMEdit.Text);
 Task.Remember := RememberCB.Checked;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.TaskDelbtnClick(Sender: TObject);
var
 Plan       : TSchedulerClass;
 Task       : TSchedulerItemClass;
begin
 if SelectedSchedulerTopRow < 0 then begin
    showMessage(SP.Load(162));
    exit;
  end;

  Plan := Scheduler[SelectedSchedulerTopRow];

  if Plan.isBasis then begin
    showMessage(SP.load(178));
    exit;
  end;

  if SelectedTasksTopRow < 0 then begin
    showMessage(SP.Load(180));
    exit;
  end;

  Task := SchedulerTaskList[SelectedTasksTopRow];

  DeleteTask(Plan.ID,Task.ID);

end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.TaskEditbtnClick(Sender: TObject);
var
 Plan       : TSchedulerClass;
 Task       : TSchedulerItemClass;
begin
  if SelectedSchedulerTopRow < 0 then begin
    showMessage(SP.Load(162));
    exit;
  end;

  Plan := Scheduler[SelectedSchedulerTopRow];

  if Plan.isBasis then begin
    showMessage(SP.load(178));
    exit;
  end;

 if SelectedTasksTopRow < 0 then begin
    showMessage(SP.Load(180));
    exit;
  end;

  Task := SchedulerTaskList[SelectedTasksTopRow];
  OverTakeTaskChanges(Task);
  EditTask(Plan.ID,Task);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.EditTask(_PlanID:integer;Task:TSchedulerItemClass);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.Scheduler_EditTask',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                              _PlanID,Task],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    if integer(TJSObject(Response.Result)['value']) <> 0 then
     showmessage(SP.load(161))//Speichern fehlgeschlagen
    else begin
     GetSchedulerTasks(TSchedulerClass(Scheduler[SelectedSchedulerTopRow]).ID);
     GetSchedulerBild(TSchedulerClass(Scheduler[SelectedSchedulerTopRow]).ID);
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.DeleteTask(_PlanID:integer;_TaskID:integer);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.Scheduler_DeleteTask',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                              _PlanID,_TaskID],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    if integer(TJSObject(Response.Result)['value'])= 1 then
     showmessage(SP.load(161))//Speichern fehlgeschlagen
    else begin
     SelectedTasksTopRow         := -1;
     SelectedTasksBottomRow      := -1;
     GetSchedulerTasks(TSchedulerClass(Scheduler[SelectedSchedulerTopRow]).ID);
     GetSchedulerBild(TSchedulerClass(Scheduler[SelectedSchedulerTopRow]).ID);
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SaveNewPlan(_Bezeichnung:string;_PlanVorlageID:integer;_ValidDate:string);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.GetScheduler_NewPlan',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                              _Bezeichnung,_PlanVorlageID,_ValidDate],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    if integer(TJSObject(Response.Result)['value'])= 0 then
     showmessage(SP.load(161))//Speichern fehlgeschlagen
    else GetScheduler;
   end);

end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SavePlanChangedDate(_PlanVorlageID:integer;_ValidDate:string);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.Scheduler_ChangePlanValidDate',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                                                  _PlanVorlageID,_ValidDate],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    GetScheduler;
   end);

end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.DeletePlan(_PlanVorlageID:integer);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.Scheduler_DeletePlan',[GLV_AktUser.Token,GLV_AktUser.ID,
                                                                                  _PlanVorlageID],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    GetScheduler;
   end);

end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.WebFormClose(Sender: TObject;
  var Action: TCloseAction);
begin
 FormClosed := true;
 RepaintTimer.Enabled := false;
 SelectTimer.Enabled := false;
 Action := caFree;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.WebFormCreate(Sender: TObject);
begin
 SelectedSchedulerTopRow     := -1;
 SelectedSchedulerBottomRow  := -1;
 SelectedTasksTopRow         := -1;
 SelectedTasksBottomRow      := -1;
 FormClosed                  := false;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.WebFormOrientationChange(Sender: TObject;
  AOrientation: TScreenOrientation);
begin
 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.WebFormResize(Sender: TObject);
begin
 RepaintTimer.Enabled := false;
 HelpBtn.left       := BottomPanel.ClientWidth - round(1.5*CancelBtn.width);
 CancelBtn.left     := HelpBtn.Left - CancelBtn.width - GLV_Dist;

 PlanNewBtn.Left    := 2*GLV_Dist;
 PlanSetDayBtn.Left := PlanNewBtn.Left + PlanNewBtn.Width + GLV_Dist;
 PlanDelBtn.Left    := PlanSetDayBtn.Left + PlanSetDayBtn.Width + GLV_Dist;

 TaskAddbtn.Left    := 2*GLV_Dist;
 TaskEditbtn.Left   := TaskAddbtn.Left + TaskAddbtn.Width + GLV_Dist;
 TaskDelbtn.Left    := 2*GLV_Dist;

 RepaintTimer.Enabled := true;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.WebFormShow(Sender: TObject);
begin
 CaptionPanel.Color := CaptionPanelColor;
 CaptionPanel.Font.Color := CaptionPanelFontColor;
 CaptionPanel.BorderColor :=  CaptionPanelBorderColor;
 CaptionPanel.Caption := SP.load(137) + GLC_1Blank + GetMyQiuUserString;
 MainMenuFrame1.UpdateFrame;

 TypusCB.Items.Clear;
 TypusCB.Items.add(SP.load(138));
 TypusCB.Items.add(SP.load(106));
 TypusCB.Items.add(SP.load(139));
 TypusCB.Items.add(SP.load(110));
 TypusCB.Items.add(SP.load(111));
 TypusCB.Items.add(SP.load(140));
 TypusCB.Items.add(SP.load(141));
 TypusCB.Items.add(SP.load(142));
 TypusCB.Items.add(SP.load(143));
 TypusCB.ItemIndex := 0;
 TypusCBChange(self);
 WebLabel1.Caption    := SP.load(144);
 WebLabel2.Caption    := SP.load(145);
 WebLabel3.Caption    := SP.load(146);
 WebLabel4.Caption    := SP.load(147);
 WebLabel5.Caption    := SP.load(148);
 WebLabel6.Caption    := SP.load(149);
 RememberCB.Caption    := SP.load(150);
 IntervallCB.Items.Clear;
 IntervallCB.Items.add(SP.load(151));
 IntervallCB.Items.add(SP.load(152));
 IntervallCB.Items.add(SP.load(153));
 IntervallCB.Itemindex := 0;
 IntervallCBchange(self);

 Wochentagcb.Items.Clear;
 Wochentagcb.Items.add(SP.load(154));
 Wochentagcb.Items.add(SP.load(155));
 Wochentagcb.Items.add(SP.load(156));
 Wochentagcb.Items.add(SP.load(157));
 Wochentagcb.Items.add(SP.load(158));
 Wochentagcb.Items.add(SP.load(159));
 Wochentagcb.Items.add(SP.load(160));
 Wochentagcb.ItemIndex := 0;

 WebPageControl1Sheet1.Caption := SP.load(171);
 WebPageControl1Sheet2.Caption := SP.load(170);
 WebPageControl1Sheet3.Caption := SP.load(172);


 PlanDelBtn.Hint   := SP.load(236);
 PlanNewBtn.Hint   := SP.load(237);
 PlanSetDayBtn.Hint:= SP.load(238);
 TaskDelbtn.Hint   := SP.load(239);
 TaskAddbtn.Hint   := SP.load(240);
 TaskEditbtn.Hint  := SP.load(241);
 CancelBtn.Hint    := SP.load(233);
 HelpBtn.Hint      := SP.Load(257);


 SchedulerStringGrid.ColCount := 1;
 SchedulerStringGrid.RowCount := 0;
 SchedulerStringGrid.FixedCols := 0;
 SchedulerStringGrid.FixedRows := 0;
 SchedulerStringGrid.ColWidths[0] := SchedulerStringGrid.ClientWidth;

 SchedulerTasksStringGrid.ColCount := 4;
 SchedulerTasksStringGrid.RowCount := 1;
 SchedulerTasksStringGrid.FixedCols := 0;
 SchedulerTasksStringGrid.FixedRows := 1;
 SchedulerTasksStringGrid.ColWidths[0] := SchedulerTasksStringGrid.ClientWidth div 4;
 SchedulerTasksStringGrid.ColWidths[1] := SchedulerTasksStringGrid.ClientWidth div 4;
 SchedulerTasksStringGrid.ColWidths[2] := SchedulerTasksStringGrid.ClientWidth div 4;
 SchedulerTasksStringGrid.ColWidths[3] := SchedulerTasksStringGrid.ClientWidth div 4;
 SchedulerTasksStringGrid.Cells[0,0] := SP.load(144);
 SchedulerTasksStringGrid.Cells[1,0] := SP.load(173);
 SchedulerTasksStringGrid.Cells[2,0] := SP.load(175);
 SchedulerTasksStringGrid.Cells[3,0] := SP.load(174);
 BezEdit.Text := '';
 GetScheduler;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SelectTimerTimer(Sender: TObject);
begin
 if length(Scheduler) > 0 then begin
  SelectTimer.Enabled := false;
  ShowFirstEntry;
 end else begin
  //
 end;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SchedulerStringGridClick(Sender: TObject);
var
 tmp : integer;
begin
 SelectedSchedulerTopRow    := SchedulerStringGrid.Selection.Top;
 SelectedSchedulerBottomRow := SchedulerStringGrid.Selection.Bottom;
 if SelectedSchedulerBottomRow < SelectedSchedulerTopRow then begin
  tmp := SelectedSchedulerTopRow;
  SelectedSchedulerTopRow := SelectedSchedulerBottomRow;
  SelectedSchedulerBottomRow := tmp;
 end;
 SelectedTasksTopRow    := 0;
 SelectedTasksBottomRow := 0;
 GetSchedulerTasks(SelectedSchedulerTopRow);
 GetSchedulerBild(SelectedSchedulerTopRow);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SchedulerStringGridKeyUp(Sender: TObject;
  var Key: Word; Shift: TShiftState);
begin
  SchedulerStringGridClick(Self);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SchedulerStringGridSelectCell(Sender: TObject; ACol,
  ARow: Integer; var CanSelect: Boolean);
begin
  //
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SchedulerTasksStringGridClick(Sender: TObject);
var
 tmp : integer;
begin
 SelectedTasksTopRow    := SchedulerTasksStringGrid.Selection.Top-1;
 SelectedTasksBottomRow := SchedulerTasksStringGrid.Selection.Bottom-1;
 if SelectedTasksBottomRow < SelectedTasksTopRow then begin
  tmp := SelectedTasksTopRow;
  SelectedTasksTopRow := SelectedTasksBottomRow;
  SelectedTasksBottomRow := tmp;
 end;
 ShowTask;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SchedulerTasksStringGridKeyUp(Sender: TObject;
  var Key: Word; Shift: TShiftState);
begin
 SchedulerTasksStringGridClick(Self);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.SchedulerTasksStringGridSelectCell(Sender: TObject;
  ACol, ARow: Integer; var CanSelect: Boolean);
begin
 //
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.ShowTask;
var
  Task : TSchedulerItemClass;
  hh,mm: string;
begin

 if SelectedTasksTopRow < 0 then exit;

 Task := SchedulerTaskList[SelectedTasksTopRow];

 if Task.TYP = 'BFB' then begin    // BFB,QIU,PCBFB,RSA,KURZHRV,BP,GLU,SONST         //##IGNORE_LINE
    TypusCB.ItemIndex := 0;
 end;
 if Task.TYP = 'QIU' then begin  TypusCB.ItemIndex := 1; end;   //##IGNORE_LINE
 if Task.TYP = 'PCBFB' then begin TypusCB.ItemIndex := 2; end;   //##IGNORE_LINE
 if Task.TYP = 'RSA' then begin TypusCB.ItemIndex := 3; end;    //##IGNORE_LINE
 if Task.TYP = 'KURZHRV' then begin TypusCB.ItemIndex := 4; end;  //##IGNORE_LINE
 if Task.TYP = 'BP' then begin TypusCB.ItemIndex := 5; end;       //##IGNORE_LINE
 if Task.TYP = 'GLU' then begin TypusCB.ItemIndex := 6; end;     //##IGNORE_LINE
 if Task.TYP = 'WEIGHT' then begin TypusCB.ItemIndex := 7; end;   //##IGNORE_LINE
 if Task.TYP = 'SONST' then begin TypusCB.ItemIndex := 8; BezEdit.Text := Task.TextFeld; end;   //##IGNORE_LINE
 TypusCBChange(self);
 if Task.MODE='DAILY' then IntervallCB.ItemIndex := 0;  //##IGNORE_LINE
 if Task.MODE='WEEKLY' then begin                       //##IGNORE_LINE
    IntervallCB.ItemIndex := 1;
    WochenTagCB.ItemIndex := 0;
    if Task.WochenTag='MO' then WochenTagCB.ItemIndex := 0;   //##IGNORE_LINE
    if Task.WochenTag='DI' then WochenTagCB.ItemIndex := 1;  //##IGNORE_LINE
    if Task.WochenTag='MI' then WochenTagCB.ItemIndex := 2;   //##IGNORE_LINE
    if Task.WochenTag='DO' then WochenTagCB.ItemIndex := 3;   //##IGNORE_LINE
    if Task.WochenTag='FR' then WochenTagCB.ItemIndex := 4;   //##IGNORE_LINE
    if Task.WochenTag='SA' then WochenTagCB.ItemIndex := 5;    //##IGNORE_LINE
    if Task.WochenTag='SO' then WochenTagCB.ItemIndex := 6;    //##IGNORE_LINE
 end;
 if Task.MODE='MONTHLY' then begin                      //##IGNORE_LINE
    IntervallCB.ItemIndex := 2;
    DDEdit.Text := '1';
    try
     DDEdit.Text := Task.MonatsTag;
    except
     DDEdit.Text := '1';
    end;
 end;
 IntervallCBChange(self);
 try
   hh := copy(string(Task.Zeit),1,2);mm := copy(string(Task.Zeit),4,2);
 except
   hh := '18';mm:='00';           //##IGNORE_LINE
 end;
 HHedit.Text := hh; MMEdit.Text := mm;
 RememberCB.Checked := Task.Remember;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.ShowFirstEntry;
begin
 SelectedSchedulerTopRow := 0;
 SelectedSchedulerBottomRow := 0;
 GetSchedulerTasks(SelectedSchedulerTopRow);
 GetSchedulerBild(SelectedSchedulerTopRow);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.TypusCBChange(Sender: TObject);
begin
    if TypusCB.ItemIndex=TypusCB.Items.Count-1 then begin
      bezEdit.Visible := true;
   end else begin
      bezEdit.Visible := false;
   end;
   WebLabel2.Visible := bezEdit.Visible;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.DDEditDownSBClick(Sender: TObject);
var
  dd  : integer;
begin
  dd := strtoint(DDEdit.Text);
  if dd = 1 then exit;
  dec(dd);
  DDEdit.Text := inttostr(dd);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.DDEditUpSBClick(Sender: TObject);
var
  dd  : integer;
begin
  dd := strtoint(DDEdit.Text);
  if dd = 28 then exit;
  inc(dd);
  DDEdit.Text := inttostr(dd);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.GetScheduler;
begin
 SelectedSchedulerTopRow := -1;
 SelectedSchedulerBottomRow := -1;

 MainForm.XDataWebClient.RawInvoke('IDatenService.GetScheduler',[GLV_AktUser.Token,GLV_AktUser.ID],
   procedure (Response: TXDataClientResponse)
   var
    LArray : TJSArray;
    LObj   : TJSObject;
    i      : integer;
    Rec    : TSchedulerClass;
   begin
    if FormClosed then exit;
    LArray := TJSArray(TJSObject(Response.Result)['value']);
    SchedulerStringGrid.RowCount := LArray.Length;
    SchedulerStringGrid.BeginUpdate;
    SetLength(Scheduler,LArray.Length);
    for i := 0 to LArray.Length-1 do begin
     LObj := TJSObject(LArray[i]);
     Rec            := TSchedulerClass.Create;
     Rec.isBasis    := boolean(LObj['isBasis']);
     Rec.ValidDate  := string(LObj['ValidDate']);
     Rec.Bez        := string(LObj['Bez']);
     Rec.ID         := integer(LObj['ID']);
     Scheduler[i]   := Rec;
     SchedulerStringGrid.Cells[0,i] := Rec.Bez + ' - ' + SP.Load(169) + formatdatetime(GLV_Datummaske,MyDateFormatToTDate(Rec.ValidDate));
    end;
    SchedulerStringGrid.EndUpdate;
    SelectTimer.Enabled := true;
   end);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.GetSchedulerTasks(SchedulerID:integer);
begin
 MainForm.XDataWebClient.RawInvoke('IDatenService.GetSchedulerTasks',[GLV_AktUser.Token,GLV_AktUser.ID,SchedulerID],
   procedure (Response: TXDataClientResponse)
   var
    LArray : TJSArray;
    LObj   : TJSObject;
    i      : integer;
    ItemRec: TSchedulerItemClass;
    Typus  : string;
    Modus  : string;
   begin
    if FormClosed then exit;
    LArray := TJSArray(TJSObject(Response.Result)['value']);
    SetLength(SchedulerTaskList,LArray.Length);
    SchedulerTasksStringGrid.RowCount := LArray.Length + 1;
    SchedulerTasksStringGrid.BeginUpdate;
    for i := 0 to LArray.Length-1 do begin
     LObj := TJSObject(LArray[i]);
     ItemRec            := TSchedulerItemClass.Create;

     ItemRec.TYP       := string(LObj['TYP']);       // BFB,QIU,PCBFB,RSA,KURZHRV,BP,GLU,WEIGHT,SONST
     ItemRec.MODE      := string(LObj['MODE']);       // DAILY,WEEKLY,MONTHLY
     ItemRec.Zeit      := string(LObj['Zeit']);
     ItemRec.WochenTag := string(LObj['WochenTag']);       // MO,DI,MI...
     ItemRec.MonatsTag := string(LObj['MonatsTag']);       // 1,2,3,4,5...29!! nicht 30,31
     ItemRec.TextFeld  := string(LObj['TextFeld']);
     ItemRec.ID        := integer(LObj['ID']);
     ItemRec.Remember  := boolean(LObj['Remember']);
     SchedulerTaskList[i] := ItemRec;
     Typus         := 'UNKNOWN';    //##IGNORE_LINE
      if ItemRec.TYP='BFB'          //##IGNORE_LINE
       then Typus := SP.load(176); //BFB,QIU,PCBFB,BP,GLU,SONST
      if ItemRec.TYP='PCBFB'        //##IGNORE_LINE
       then Typus := SP.load(139);
      if ItemRec.TYP='QIU' //##IGNORE_LINE
       then Typus := SP.load(106);
      if ItemRec.TYP='RSA' //##IGNORE_LINE
       then Typus := SP.load(110);
      if ItemRec.TYP='KURZHRV'//##IGNORE_LINE
       then Typus := SP.load(111);
      if ItemRec.TYP='BP'      //##IGNORE_LINE
       then Typus := SP.load(140);
      if ItemRec.TYP='GLU'        //##IGNORE_LINE
       then Typus := SP.load(141);
      if ItemRec.TYP='WEIGHT'  //##IGNORE_LINE
       then Typus := SP.load(142);
      if ItemRec.TYP='SONST' then  //##IGNORE_LINE
       Typus := ItemRec.TextFeld;
      SchedulerTasksStringGrid.Cells[0,i+1] := Typus;
      Modus := SP.load(177);
      if ItemRec.MODE='DAILY'         //##IGNORE_LINE
       then  Modus := SP.load(151);
      if ItemRec.MODE='WEEKLY'       //##IGNORE_LINE
       then Modus := SP.load(152);
      if ItemRec.MODE='MONTHLY'     //##IGNORE_LINE
       then Modus := SP.load(153);
      SchedulerTasksStringGrid.Cells[1,i+1] := Modus;

      SchedulerTasksStringGrid.Cells[2,i+1] := ItemRec.Zeit;

      if ItemRec.Remember then SchedulerTasksStringGrid.Cells[3,i+1] := SP.load(26)
        else SchedulerTasksStringGrid.Cells[3,i+1] := SP.load(25);
    end;
    SchedulerTasksStringGrid.EndUpdate;

    if length(SchedulerTaskList) > 0 then begin
     SchedulerTasksStringGrid.Selection.Top := SelectedTasksTopRow;
     SchedulerTasksStringGrid.Selection.Bottom := SelectedTasksBottomRow;
     ShowTask;
    end else begin
     SelectedTasksTopRow    := -1;
     SelectedTasksBottomRow := -1;
     SchedulerTasksStringGrid.Selection.Top := SelectedTasksTopRow;
     SchedulerTasksStringGrid.Selection.Bottom := SelectedTasksBottomRow;
    end;
   end);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.HelpBtnClick(Sender: TObject);
begin
  Application.Navigate(GetManualLink,ntBlank);//Application.Navigate(GetHelpDir + 'MyQiu-Ubungsplaner.html', ntBlank);
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.HHDownSEClick(Sender: TObject);
var
  hh : integer;
  hhS: string;
begin
  hh := strtoint(HHEdit.Text);
  if hh=0 then exit;
  dec(hh);
  hhs := inttostr(hh);
  if length(hhs)=1 then hhs := '0'+hhs;
  HHedit.Text := hhs;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.HHUpSEClick(Sender: TObject);
var
  hh : integer;
  hhS: string;
begin
  hh := strtoint(HHEdit.Text);
  if hh=23 then exit;
  inc(hh);
  hhs := inttostr(hh);
  if length(hhs)=1 then hhs := '0'+hhs;
  HHedit.Text := hhs;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.IntervallCBChange(Sender: TObject);
begin
 if IntervallCB.ItemIndex=1 then begin
    WochentagCB.Visible := true;
    DDEdit.Visible        := false;
 end;
 if IntervallCB.ItemIndex=0 then begin
    WochentagCB.Visible := false;
    DDEdit.Visible        := false;
 end;
 if IntervallCB.ItemIndex=2 then begin
    WochentagCB.Visible := false;
    DDEdit.Visible        := true;
 end;
 Weblabel5.Visible := WochentagCB.Visible;
 Weblabel6.Visible := DDEdit.Visible;
 DDEditUpSB.Visible  := DDEdit.Visible;
 DDEditDownSB.Visible  := DDEdit.Visible;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.MMDownSEClick(Sender: TObject);
var
  mm : integer;
  MMS: string;
begin
  mm := strtoint(mmEdit.Text);
  if MM=0 then exit;
  dec(mm);
  mms := inttostr(mm);
  if length(mms)=1 then mms := '0'+mms;
  MMedit.Text := mms;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.MMUpSEClick(Sender: TObject);
var
  mm : integer;
  MMS: string;
begin
  mm := strtoint(mmEdit.Text);
  if MM=59 then exit;
  inc(mm);
  mms := inttostr(mm);
  if length(mms)=1 then mms := '0'+mms;
  MMedit.Text := mms;
end;
{------------------------------------------------------------------------------}
procedure TSchedulerFormSW.GetSchedulerBild(SchedulerID:integer);
var
 _Height,
 _Width : integer;
begin
 _Height := WebImageControl1.Height * GLV_PictureCreateSizeFactor;
 _Width  := WebImageControl1.Width * GLV_PictureCreateSizeFactor;
 MainForm.XDataWebClient.RawInvoke('IDatenService.GetSchedulerBildDownloadLink',[GLV_AktUser.Token,GLV_AktUser.ID,SchedulerID,_Height,_Width],
   procedure (Response: TXDataClientResponse)
   begin
    if FormClosed then exit;
    WebImageControl1.URL := string(TJSObject(Response.Result)['value']);
   end);
end;
procedure TSchedulerFormSW.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  BottomPanel := TPanel.Create(Self);
  CancelBtn := TSpeedButton.Create(Self);
  HelpBtn := TSpeedButton.Create(Self);
  TopPanel := TPanel.Create(Self);
  WebPageControl1 := TPageControl.Create(Self);
  WebPageControl1Sheet1 := TTabSheet.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  SchedulerStringGrid := TStringGrid.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  PlanSetDayBtn := TSpeedButton.Create(Self);
  PlanNewBtn := TSpeedButton.Create(Self);
  PlanDelBtn := TSpeedButton.Create(Self);
  WebPageControl1Sheet2 := TTabSheet.Create(Self);
  WebPanel3 := TPanel.Create(Self);
  SchedulerTasksStringGrid := TStringGrid.Create(Self);
  WebPanel4 := TPanel.Create(Self);
  TaskDelbtn := TSpeedButton.Create(Self);
  WebPageControl1Sheet3 := TTabSheet.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  TypusCB := TComboBox.Create(Self);
  IntervallCB := TComboBox.Create(Self);
  WochenTagCB := TComboBox.Create(Self);
  BezEdit := TEdit.Create(Self);
  RememberCB := TCheckBox.Create(Self);
  HHEdit := TEdit.Create(Self);
  HHUpSE := TSpeedButton.Create(Self);
  HHDownSE := TSpeedButton.Create(Self);
  MMEdit := TEdit.Create(Self);
  MMUpSE := TSpeedButton.Create(Self);
  MMDownSE := TSpeedButton.Create(Self);
  DDEdit := TEdit.Create(Self);
  DDEditDownSB := TSpeedButton.Create(Self);
  DDEditUpSB := TSpeedButton.Create(Self);
  TaskAddBtn := TSpeedButton.Create(Self);
  TaskEditbtn := TSpeedButton.Create(Self);
  CaptionPanel := TPanel.Create(Self);
  MainMenuFrame1 := TMainMenuFrame1.Create(Self);
  SelectTimer := TTimer.Create(Self);
  RepaintTimer := TTimer.Create(Self);

  MainMenuFrame1.LoadFromForm;
  WebImageControl1.BeforeLoadDFMValues;
  BottomPanel.BeforeLoadDFMValues;
  CancelBtn.BeforeLoadDFMValues;
  HelpBtn.BeforeLoadDFMValues;
  TopPanel.BeforeLoadDFMValues;
  WebPageControl1.BeforeLoadDFMValues;
  WebPageControl1Sheet1.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  SchedulerStringGrid.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  PlanSetDayBtn.BeforeLoadDFMValues;
  PlanNewBtn.BeforeLoadDFMValues;
  PlanDelBtn.BeforeLoadDFMValues;
  WebPageControl1Sheet2.BeforeLoadDFMValues;
  WebPanel3.BeforeLoadDFMValues;
  SchedulerTasksStringGrid.BeforeLoadDFMValues;
  WebPanel4.BeforeLoadDFMValues;
  TaskDelbtn.BeforeLoadDFMValues;
  WebPageControl1Sheet3.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  TypusCB.BeforeLoadDFMValues;
  IntervallCB.BeforeLoadDFMValues;
  WochenTagCB.BeforeLoadDFMValues;
  BezEdit.BeforeLoadDFMValues;
  RememberCB.BeforeLoadDFMValues;
  HHEdit.BeforeLoadDFMValues;
  HHUpSE.BeforeLoadDFMValues;
  HHDownSE.BeforeLoadDFMValues;
  MMEdit.BeforeLoadDFMValues;
  MMUpSE.BeforeLoadDFMValues;
  MMDownSE.BeforeLoadDFMValues;
  DDEdit.BeforeLoadDFMValues;
  DDEditDownSB.BeforeLoadDFMValues;
  DDEditUpSB.BeforeLoadDFMValues;
  TaskAddBtn.BeforeLoadDFMValues;
  TaskEditbtn.BeforeLoadDFMValues;
  CaptionPanel.BeforeLoadDFMValues;
  MainMenuFrame1.BeforeLoadDFMValues;
  MainMenuFrame1.LoginBtn.BeforeLoadDFMValues;
  MainMenuFrame1.KontoBtn.BeforeLoadDFMValues;
  MainMenuFrame1.RegistBtn.BeforeLoadDFMValues;
  SelectTimer.BeforeLoadDFMValues;
  RepaintTimer.BeforeLoadDFMValues;
  try
    Name := 'SchedulerFormSW';
    Width := 929;
    Height := 677;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnOrientationChange', 'WebFormOrientationChange');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 0;
    WebImageControl1.Top := 441;
    WebImageControl1.Width := 929;
    WebImageControl1.Height := 186;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.Align := alClient;
    WebImageControl1.ChildOrder := 2;
    BottomPanel.SetParentComponent(Self);
    BottomPanel.Name := 'BottomPanel';
    BottomPanel.Left := 0;
    BottomPanel.Top := 627;
    BottomPanel.Width := 929;
    BottomPanel.Height := 50;
    BottomPanel.Align := alBottom;
    BottomPanel.BorderStyle := bsNone;
    BottomPanel.Color := clWhite;
    CancelBtn.SetParentComponent(BottomPanel);
    CancelBtn.Name := 'CancelBtn';
    CancelBtn.Left := 819;
    CancelBtn.Top := 3;
    CancelBtn.Width := 41;
    CancelBtn.Height := 41;
    CancelBtn.Color := clNone;
    CancelBtn.Glyph.LoadFromFile('myQiuScheduler_SW.BottomPanel.CancelBtn.Glyph.bmp');
    CancelBtn.HeightPercent := 100.000000000000000000;
    CancelBtn.ShowHint := True;
    CancelBtn.TabOrder := 0;
    CancelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(CancelBtn, Self, 'OnClick', 'CancelBtnClick');
    HelpBtn.SetParentComponent(BottomPanel);
    HelpBtn.Name := 'HelpBtn';
    HelpBtn.Left := 866;
    HelpBtn.Top := 3;
    HelpBtn.Width := 41;
    HelpBtn.Height := 41;
    HelpBtn.Color := clNone;
    HelpBtn.Glyph.LoadFromFile('myQiuScheduler_SW.BottomPanel.HelpBtn.Glyph.bmp');
    HelpBtn.HeightPercent := 100.000000000000000000;
    HelpBtn.ShowHint := True;
    HelpBtn.TabOrder := 1;
    HelpBtn.WidthPercent := 100.000000000000000000;
    SetEvent(HelpBtn, Self, 'OnClick', 'HelpBtnClick');
    TopPanel.SetParentComponent(Self);
    TopPanel.Name := 'TopPanel';
    TopPanel.Left := 0;
    TopPanel.Top := 112;
    TopPanel.Width := 929;
    TopPanel.Height := 329;
    TopPanel.Align := alTop;
    TopPanel.ChildOrder := 1;
    WebPageControl1.SetParentComponent(TopPanel);
    WebPageControl1.Name := 'WebPageControl1';
    WebPageControl1.Left := 0;
    WebPageControl1.Top := 0;
    WebPageControl1.Width := 929;
    WebPageControl1.Height := 329;
    WebPageControl1.Align := alClient;
    WebPageControl1.TabIndex := 0;
    WebPageControl1.TabOrder := 0;
    WebPageControl1Sheet1.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet1.Name := 'WebPageControl1Sheet1';
    WebPageControl1Sheet1.Left := 0;
    WebPageControl1Sheet1.Top := 20;
    WebPageControl1Sheet1.Width := 929;
    WebPageControl1Sheet1.Height := 309;
    WebPageControl1Sheet1.Caption := 'WebPageControl1Sheet1';
    WebPanel5.SetParentComponent(WebPageControl1Sheet1);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 0;
    WebPanel5.Top := 0;
    WebPanel5.Width := 929;
    WebPanel5.Height := 259;
    WebPanel5.Align := alClient;
    WebPanel5.ChildOrder := 2;
    SchedulerStringGrid.SetParentComponent(WebPanel5);
    SchedulerStringGrid.Name := 'SchedulerStringGrid';
    SchedulerStringGrid.Left := 0;
    SchedulerStringGrid.Top := 0;
    SchedulerStringGrid.Width := 929;
    SchedulerStringGrid.Height := 259;
    SchedulerStringGrid.Align := alClient;
    SchedulerStringGrid.ColCount := 3;
    SchedulerStringGrid.DefaultColWidth := 100;
    SchedulerStringGrid.FixedColor := clGray;
    SchedulerStringGrid.FixedCols := 0;
    SchedulerStringGrid.RowCount := 2;
    SchedulerStringGrid.FixedRows := 0;
    SchedulerStringGrid.GradientEndColor := clWhite;
    SchedulerStringGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    SchedulerStringGrid.TabOrder := 0;
    SetEvent(SchedulerStringGrid, Self, 'OnClick', 'SchedulerStringGridClick');
    SetEvent(SchedulerStringGrid, Self, 'OnKeyUp', 'SchedulerStringGridKeyUp');
    SetEvent(SchedulerStringGrid, Self, 'OnSelectCell', 'SchedulerStringGridSelectCell');
    SchedulerStringGrid.FixedTextColor := clWhite;
    SchedulerStringGrid.RangeEdit.Max := 100.000000000000000000;
    SchedulerStringGrid.RangeEdit.Step := 1.000000000000000000;
    SchedulerStringGrid.HeightPercent := 100.000000000000000000;
    SchedulerStringGrid.WidthPercent := 100.000000000000000000;
    WebPanel2.SetParentComponent(WebPageControl1Sheet1);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 259;
    WebPanel2.Width := 929;
    WebPanel2.Height := 50;
    WebPanel2.Align := alBottom;
    WebPanel2.BorderStyle := bsNone;
    WebPanel2.ChildOrder := 3;
    WebPanel2.Color := clWhite;
    PlanSetDayBtn.SetParentComponent(WebPanel2);
    PlanSetDayBtn.Name := 'PlanSetDayBtn';
    PlanSetDayBtn.Left := 71;
    PlanSetDayBtn.Top := 3;
    PlanSetDayBtn.Width := 41;
    PlanSetDayBtn.Height := 41;
    PlanSetDayBtn.Color := clNone;
    PlanSetDayBtn.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel2.PlanSetDayBtn.Glyph.bmp');
    PlanSetDayBtn.HeightPercent := 100.000000000000000000;
    PlanSetDayBtn.ShowHint := True;
    PlanSetDayBtn.TabOrder := 0;
    PlanSetDayBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PlanSetDayBtn, Self, 'OnClick', 'PlanSetDayBtnClick');
    PlanNewBtn.SetParentComponent(WebPanel2);
    PlanNewBtn.Name := 'PlanNewBtn';
    PlanNewBtn.Left := 24;
    PlanNewBtn.Top := 3;
    PlanNewBtn.Width := 41;
    PlanNewBtn.Height := 41;
    PlanNewBtn.Color := clNone;
    PlanNewBtn.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel2.PlanNewBtn.Glyph.bmp');
    PlanNewBtn.HeightPercent := 100.000000000000000000;
    PlanNewBtn.ShowHint := True;
    PlanNewBtn.TabOrder := 1;
    PlanNewBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PlanNewBtn, Self, 'OnClick', 'PlanNewBtnClick');
    PlanDelBtn.SetParentComponent(WebPanel2);
    PlanDelBtn.Name := 'PlanDelBtn';
    PlanDelBtn.Left := 118;
    PlanDelBtn.Top := 3;
    PlanDelBtn.Width := 41;
    PlanDelBtn.Height := 41;
    PlanDelBtn.Color := clNone;
    PlanDelBtn.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel2.PlanDelBtn.Glyph.bmp');
    PlanDelBtn.HeightPercent := 100.000000000000000000;
    PlanDelBtn.ShowHint := True;
    PlanDelBtn.TabOrder := 2;
    PlanDelBtn.WidthPercent := 100.000000000000000000;
    SetEvent(PlanDelBtn, Self, 'OnClick', 'PlanDelBtnClick');
    WebPageControl1Sheet2.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet2.Name := 'WebPageControl1Sheet2';
    WebPageControl1Sheet2.Left := 0;
    WebPageControl1Sheet2.Top := 20;
    WebPageControl1Sheet2.Width := 929;
    WebPageControl1Sheet2.Height := 309;
    WebPageControl1Sheet2.Caption := 'WebPageControl1Sheet2';
    WebPageControl1Sheet2.ChildOrder := 1;
    WebPanel3.SetParentComponent(WebPageControl1Sheet2);
    WebPanel3.Name := 'WebPanel3';
    WebPanel3.Left := 0;
    WebPanel3.Top := 0;
    WebPanel3.Width := 929;
    WebPanel3.Height := 309;
    WebPanel3.Align := alClient;
    WebPanel3.ChildOrder := 2;
    SchedulerTasksStringGrid.SetParentComponent(WebPanel3);
    SchedulerTasksStringGrid.Name := 'SchedulerTasksStringGrid';
    SchedulerTasksStringGrid.Left := 0;
    SchedulerTasksStringGrid.Top := 0;
    SchedulerTasksStringGrid.Width := 929;
    SchedulerTasksStringGrid.Height := 259;
    SchedulerTasksStringGrid.Align := alClient;
    SchedulerTasksStringGrid.ColCount := 3;
    SchedulerTasksStringGrid.DefaultColWidth := 100;
    SchedulerTasksStringGrid.FixedColor := clGray;
    SchedulerTasksStringGrid.FixedCols := 0;
    SchedulerTasksStringGrid.RowCount := 2;
    SchedulerTasksStringGrid.FixedRows := 0;
    SchedulerTasksStringGrid.GradientEndColor := clWhite;
    SchedulerTasksStringGrid.Options := [goFixedVertLine,goFixedHorzLine,goVertLine,goHorzLine,goRowSelect];
    SchedulerTasksStringGrid.TabOrder := 0;
    SetEvent(SchedulerTasksStringGrid, Self, 'OnClick', 'SchedulerTasksStringGridClick');
    SetEvent(SchedulerTasksStringGrid, Self, 'OnKeyUp', 'SchedulerTasksStringGridKeyUp');
    SetEvent(SchedulerTasksStringGrid, Self, 'OnSelectCell', 'SchedulerTasksStringGridSelectCell');
    SchedulerTasksStringGrid.FixedTextColor := clWhite;
    SchedulerTasksStringGrid.RangeEdit.Max := 100.000000000000000000;
    SchedulerTasksStringGrid.RangeEdit.Step := 1.000000000000000000;
    SchedulerTasksStringGrid.HeightPercent := 100.000000000000000000;
    SchedulerTasksStringGrid.WidthPercent := 100.000000000000000000;
    WebPanel4.SetParentComponent(WebPanel3);
    WebPanel4.Name := 'WebPanel4';
    WebPanel4.Left := 0;
    WebPanel4.Top := 259;
    WebPanel4.Width := 929;
    WebPanel4.Height := 50;
    WebPanel4.Align := alBottom;
    WebPanel4.BorderStyle := bsNone;
    WebPanel4.ChildOrder := 1;
    WebPanel4.Color := clWhite;
    TaskDelbtn.SetParentComponent(WebPanel4);
    TaskDelbtn.Name := 'TaskDelbtn';
    TaskDelbtn.Left := 44;
    TaskDelbtn.Top := 4;
    TaskDelbtn.Width := 41;
    TaskDelbtn.Height := 41;
    TaskDelbtn.Color := clNone;
    TaskDelbtn.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel4.TaskDelbtn.Glyph.bmp');
    TaskDelbtn.HeightPercent := 100.000000000000000000;
    TaskDelbtn.ShowHint := True;
    TaskDelbtn.TabOrder := 0;
    TaskDelbtn.WidthPercent := 100.000000000000000000;
    SetEvent(TaskDelbtn, Self, 'OnClick', 'TaskDelbtnClick');
    WebPageControl1Sheet3.SetParentComponent(WebPageControl1);
    WebPageControl1Sheet3.Name := 'WebPageControl1Sheet3';
    WebPageControl1Sheet3.Left := 0;
    WebPageControl1Sheet3.Top := 20;
    WebPageControl1Sheet3.Width := 929;
    WebPageControl1Sheet3.Height := 309;
    WebPageControl1Sheet3.Caption := 'WebPageControl1Sheet3';
    WebPageControl1Sheet3.ChildOrder := 2;
    WebPanel1.SetParentComponent(WebPageControl1Sheet3);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 929;
    WebPanel1.Height := 309;
    WebPanel1.Align := alClient;
    WebPanel1.BorderStyle := bsNone;
    WebPanel1.ChildOrder := 2;
    WebPanel1.Color := clWhite;
    WebLabel1.SetParentComponent(WebPanel1);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 8;
    WebLabel1.Top := 13;
    WebLabel1.Width := 63;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'WebLabel1';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(WebPanel1);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 40;
    WebLabel2.Width := 63;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'WebLabel1';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(WebPanel1);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 8;
    WebLabel3.Top := 71;
    WebLabel3.Width := 63;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'WebLabel1';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(WebPanel1);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 103;
    WebLabel4.Width := 63;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'WebLabel1';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(WebPanel1);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 8;
    WebLabel5.Top := 150;
    WebLabel5.Width := 63;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'WebLabel1';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(WebPanel1);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 8;
    WebLabel6.Top := 188;
    WebLabel6.Width := 63;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'WebLabel1';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    TypusCB.SetParentComponent(WebPanel1);
    TypusCB.Name := 'TypusCB';
    TypusCB.Left := 116;
    TypusCB.Top := 10;
    TypusCB.Width := 145;
    TypusCB.Height := 24;
    TypusCB.HeightPercent := 100.000000000000000000;
    TypusCB.Text := 'TypusCB';
    TypusCB.WidthPercent := 100.000000000000000000;
    SetEvent(TypusCB, Self, 'OnChange', 'TypusCBChange');
    TypusCB.ItemIndex := -1;
    IntervallCB.SetParentComponent(WebPanel1);
    IntervallCB.Name := 'IntervallCB';
    IntervallCB.Left := 116;
    IntervallCB.Top := 68;
    IntervallCB.Width := 145;
    IntervallCB.Height := 24;
    IntervallCB.HeightPercent := 100.000000000000000000;
    IntervallCB.Text := 'WebComboBox1';
    IntervallCB.WidthPercent := 100.000000000000000000;
    SetEvent(IntervallCB, Self, 'OnChange', 'IntervallCBChange');
    IntervallCB.ItemIndex := -1;
    WochenTagCB.SetParentComponent(WebPanel1);
    WochenTagCB.Name := 'WochenTagCB';
    WochenTagCB.Left := 116;
    WochenTagCB.Top := 147;
    WochenTagCB.Width := 145;
    WochenTagCB.Height := 24;
    WochenTagCB.HeightPercent := 100.000000000000000000;
    WochenTagCB.Text := 'WebComboBox1';
    WochenTagCB.WidthPercent := 100.000000000000000000;
    WochenTagCB.ItemIndex := -1;
    BezEdit.SetParentComponent(WebPanel1);
    BezEdit.Name := 'BezEdit';
    BezEdit.Left := 116;
    BezEdit.Top := 40;
    BezEdit.Width := 145;
    BezEdit.Height := 22;
    BezEdit.ChildOrder := 9;
    BezEdit.HeightPercent := 100.000000000000000000;
    BezEdit.Text := 'BezEdit';
    BezEdit.WidthPercent := 100.000000000000000000;
    RememberCB.SetParentComponent(WebPanel1);
    RememberCB.Name := 'RememberCB';
    RememberCB.Left := 6;
    RememberCB.Top := 223;
    RememberCB.Width := 267;
    RememberCB.Height := 22;
    RememberCB.Caption := 'RememberCB';
    RememberCB.ChildOrder := 13;
    RememberCB.HeightPercent := 100.000000000000000000;
    RememberCB.WidthPercent := 100.000000000000000000;
    HHEdit.SetParentComponent(WebPanel1);
    HHEdit.Name := 'HHEdit';
    HHEdit.Left := 116;
    HHEdit.Top := 103;
    HHEdit.Width := 57;
    HHEdit.Height := 22;
    HHEdit.ChildOrder := 12;
    HHEdit.HeightPercent := 100.000000000000000000;
    HHEdit.Text := '00';
    HHEdit.WidthPercent := 100.000000000000000000;
    HHUpSE.SetParentComponent(WebPanel1);
    HHUpSE.Name := 'HHUpSE';
    HHUpSE.Left := 179;
    HHUpSE.Top := 94;
    HHUpSE.Width := 24;
    HHUpSE.Height := 24;
    HHUpSE.Color := clNone;
    HHUpSE.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel1.HHUpSE.Glyph.bmp');
    HHUpSE.HeightPercent := 100.000000000000000000;
    HHUpSE.TabOrder := 6;
    HHUpSE.WidthPercent := 100.000000000000000000;
    SetEvent(HHUpSE, Self, 'OnClick', 'HHUpSEClick');
    HHDownSE.SetParentComponent(WebPanel1);
    HHDownSE.Name := 'HHDownSE';
    HHDownSE.Left := 179;
    HHDownSE.Top := 119;
    HHDownSE.Width := 24;
    HHDownSE.Height := 24;
    HHDownSE.Color := clNone;
    HHDownSE.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel1.HHDownSE.Glyph.bmp');
    HHDownSE.HeightPercent := 100.000000000000000000;
    HHDownSE.TabOrder := 7;
    HHDownSE.WidthPercent := 100.000000000000000000;
    SetEvent(HHDownSE, Self, 'OnClick', 'HHDownSEClick');
    MMEdit.SetParentComponent(WebPanel1);
    MMEdit.Name := 'MMEdit';
    MMEdit.Left := 209;
    MMEdit.Top := 103;
    MMEdit.Width := 57;
    MMEdit.Height := 22;
    MMEdit.ChildOrder := 12;
    MMEdit.HeightPercent := 100.000000000000000000;
    MMEdit.Text := '00';
    MMEdit.WidthPercent := 100.000000000000000000;
    MMUpSE.SetParentComponent(WebPanel1);
    MMUpSE.Name := 'MMUpSE';
    MMUpSE.Left := 272;
    MMUpSE.Top := 94;
    MMUpSE.Width := 24;
    MMUpSE.Height := 24;
    MMUpSE.Color := clNone;
    MMUpSE.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel1.MMUpSE.Glyph.bmp');
    MMUpSE.HeightPercent := 100.000000000000000000;
    MMUpSE.TabOrder := 9;
    MMUpSE.WidthPercent := 100.000000000000000000;
    SetEvent(MMUpSE, Self, 'OnClick', 'MMUpSEClick');
    MMDownSE.SetParentComponent(WebPanel1);
    MMDownSE.Name := 'MMDownSE';
    MMDownSE.Left := 272;
    MMDownSE.Top := 119;
    MMDownSE.Width := 24;
    MMDownSE.Height := 24;
    MMDownSE.Color := clNone;
    MMDownSE.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel1.MMDownSE.Glyph.bmp');
    MMDownSE.HeightPercent := 100.000000000000000000;
    MMDownSE.TabOrder := 10;
    MMDownSE.WidthPercent := 100.000000000000000000;
    SetEvent(MMDownSE, Self, 'OnClick', 'MMDownSEClick');
    DDEdit.SetParentComponent(WebPanel1);
    DDEdit.Name := 'DDEdit';
    DDEdit.Left := 116;
    DDEdit.Top := 185;
    DDEdit.Width := 57;
    DDEdit.Height := 22;
    DDEdit.ChildOrder := 12;
    DDEdit.HeightPercent := 100.000000000000000000;
    DDEdit.ReadOnly := True;
    DDEdit.Text := '1';
    DDEdit.WidthPercent := 100.000000000000000000;
    DDEditDownSB.SetParentComponent(WebPanel1);
    DDEditDownSB.Name := 'DDEditDownSB';
    DDEditDownSB.Left := 179;
    DDEditDownSB.Top := 201;
    DDEditDownSB.Width := 24;
    DDEditDownSB.Height := 24;
    DDEditDownSB.Color := clNone;
    DDEditDownSB.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel1.DDEditDownSB.Glyph.bmp');
    DDEditDownSB.HeightPercent := 100.000000000000000000;
    DDEditDownSB.TabOrder := 12;
    DDEditDownSB.WidthPercent := 100.000000000000000000;
    SetEvent(DDEditDownSB, Self, 'OnClick', 'DDEditDownSBClick');
    DDEditUpSB.SetParentComponent(WebPanel1);
    DDEditUpSB.Name := 'DDEditUpSB';
    DDEditUpSB.Left := 179;
    DDEditUpSB.Top := 176;
    DDEditUpSB.Width := 24;
    DDEditUpSB.Height := 24;
    DDEditUpSB.Color := clNone;
    DDEditUpSB.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel1.DDEditUpSB.Glyph.bmp');
    DDEditUpSB.HeightPercent := 100.000000000000000000;
    DDEditUpSB.TabOrder := 13;
    DDEditUpSB.WidthPercent := 100.000000000000000000;
    SetEvent(DDEditUpSB, Self, 'OnClick', 'DDEditUpSBClick');
    TaskAddBtn.SetParentComponent(WebPanel1);
    TaskAddBtn.Name := 'TaskAddBtn';
    TaskAddBtn.Left := 3;
    TaskAddBtn.Top := 254;
    TaskAddBtn.Width := 41;
    TaskAddBtn.Height := 41;
    TaskAddBtn.Color := clNone;
    TaskAddBtn.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel1.TaskAddBtn.Glyph.bmp');
    TaskAddBtn.HeightPercent := 100.000000000000000000;
    TaskAddBtn.ShowHint := True;
    TaskAddBtn.TabOrder := 14;
    TaskAddBtn.WidthPercent := 100.000000000000000000;
    SetEvent(TaskAddBtn, Self, 'OnClick', 'TaskAddbtnClick');
    TaskEditbtn.SetParentComponent(WebPanel1);
    TaskEditbtn.Name := 'TaskEditbtn';
    TaskEditbtn.Left := 50;
    TaskEditbtn.Top := 254;
    TaskEditbtn.Width := 41;
    TaskEditbtn.Height := 41;
    TaskEditbtn.Color := clNone;
    TaskEditbtn.Glyph.LoadFromFile('myQiuScheduler_SW.WebPanel1.TaskEditbtn.Glyph.bmp');
    TaskEditbtn.HeightPercent := 100.000000000000000000;
    TaskEditbtn.ShowHint := True;
    TaskEditbtn.TabOrder := 15;
    TaskEditbtn.WidthPercent := 100.000000000000000000;
    SetEvent(TaskEditbtn, Self, 'OnClick', 'TaskEditbtnClick');
    CaptionPanel.SetParentComponent(Self);
    CaptionPanel.Name := 'CaptionPanel';
    CaptionPanel.Left := 0;
    CaptionPanel.Top := 86;
    CaptionPanel.Width := 929;
    CaptionPanel.Height := 26;
    CaptionPanel.Align := alTop;
    CaptionPanel.BorderColor := clBtnFace;
    CaptionPanel.Caption := 'CaptionPanel';
    CaptionPanel.ChildOrder := 4;
    CaptionPanel.Color := clWhite;
    MainMenuFrame1.SetParentComponent(Self);
    MainMenuFrame1.Name := 'MainMenuFrame1';
    MainMenuFrame1.Left := 0;
    MainMenuFrame1.Top := 0;
    MainMenuFrame1.Width := 929;
    MainMenuFrame1.Height := 86;
    MainMenuFrame1.Align := alTop;
    MainMenuFrame1.Font.Charset := DEFAULT_CHARSET;
    MainMenuFrame1.Font.Color := clWindowText;
    MainMenuFrame1.Font.Height := -13;
    MainMenuFrame1.Font.Name := 'Tahoma';
    MainMenuFrame1.Font.Style := [];
    MainMenuFrame1.ParentFont := False;
    MainMenuFrame1.TabOrder := 3;
    MainMenuFrame1.LoginBtn.TabOrder := 3;
    MainMenuFrame1.KontoBtn.TabOrder := 0;
    MainMenuFrame1.RegistBtn.TabOrder := 1;
    SelectTimer.SetParentComponent(Self);
    SelectTimer.Name := 'SelectTimer';
    SelectTimer.Enabled := False;
    SelectTimer.Interval := 500;
    SetEvent(SelectTimer, Self, 'OnTimer', 'SelectTimerTimer');
    SelectTimer.Left := 536;
    SelectTimer.Top := 544;
    RepaintTimer.SetParentComponent(Self);
    RepaintTimer.Name := 'RepaintTimer';
    RepaintTimer.Enabled := False;
    RepaintTimer.Interval := 500;
    SetEvent(RepaintTimer, Self, 'OnTimer', 'RepaintTimerTimer');
    RepaintTimer.Left := 664;
    RepaintTimer.Top := 542;
  finally
    WebImageControl1.AfterLoadDFMValues;
    BottomPanel.AfterLoadDFMValues;
    CancelBtn.AfterLoadDFMValues;
    HelpBtn.AfterLoadDFMValues;
    TopPanel.AfterLoadDFMValues;
    WebPageControl1.AfterLoadDFMValues;
    WebPageControl1Sheet1.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    SchedulerStringGrid.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    PlanSetDayBtn.AfterLoadDFMValues;
    PlanNewBtn.AfterLoadDFMValues;
    PlanDelBtn.AfterLoadDFMValues;
    WebPageControl1Sheet2.AfterLoadDFMValues;
    WebPanel3.AfterLoadDFMValues;
    SchedulerTasksStringGrid.AfterLoadDFMValues;
    WebPanel4.AfterLoadDFMValues;
    TaskDelbtn.AfterLoadDFMValues;
    WebPageControl1Sheet3.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    TypusCB.AfterLoadDFMValues;
    IntervallCB.AfterLoadDFMValues;
    WochenTagCB.AfterLoadDFMValues;
    BezEdit.AfterLoadDFMValues;
    RememberCB.AfterLoadDFMValues;
    HHEdit.AfterLoadDFMValues;
    HHUpSE.AfterLoadDFMValues;
    HHDownSE.AfterLoadDFMValues;
    MMEdit.AfterLoadDFMValues;
    MMUpSE.AfterLoadDFMValues;
    MMDownSE.AfterLoadDFMValues;
    DDEdit.AfterLoadDFMValues;
    DDEditDownSB.AfterLoadDFMValues;
    DDEditUpSB.AfterLoadDFMValues;
    TaskAddBtn.AfterLoadDFMValues;
    TaskEditbtn.AfterLoadDFMValues;
    CaptionPanel.AfterLoadDFMValues;
    MainMenuFrame1.AfterLoadDFMValues;
    MainMenuFrame1.LoginBtn.AfterLoadDFMValues;
    MainMenuFrame1.KontoBtn.AfterLoadDFMValues;
    MainMenuFrame1.RegistBtn.AfterLoadDFMValues;
    SelectTimer.AfterLoadDFMValues;
    RepaintTimer.AfterLoadDFMValues;
  end;
end;

end.