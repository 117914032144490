unit SelectReportType;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.Buttons, WEBLib.StdCtrls,
  Vcl.StdCtrls;

type
  TSelectReportTypeForm = class(TForm)
    LongReportBtn: TBitBtn;
    ShortReportBtn: TBitBtn;
    WebLabel1: TLabel;
    WebComboBox1: TComboBox;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    procedure LongReportBtnClick(Sender: TObject);
    procedure WebFormResize(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure ShortReportBtnClick(Sender: TObject);
    procedure WebComboBox1Change(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    ReportTyp       : integer;
    ReportMode      : integer;
  protected procedure LoadDFMValues; override; end;

var
  SelectReportTypeForm: TSelectReportTypeForm;

implementation

uses CommonFunctions,KVT;

{$R *.dfm}
{------------------------------------------------------------------------------}
procedure TSelectReportTypeForm.LongReportBtnClick(Sender: TObject);
begin
 ReportTyp  := 0;
 ReportMode := WebComboBox1.ItemIndex;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TSelectReportTypeForm.ShortReportBtnClick(Sender: TObject);
begin
 ReportTyp := 1;
 ReportMode := WebComboBox1.ItemIndex;
 Close;
end;
{------------------------------------------------------------------------------}
procedure TSelectReportTypeForm.WebComboBox1Change(Sender: TObject);
begin
 WebLabel3.Visible := WebComboBox1.ItemIndex = 0;
 GLV_ShowReportMode :=  WebComboBox1.ItemIndex;
end;
{------------------------------------------------------------------------------}
procedure TSelectReportTypeForm.WebFormResize(Sender: TObject);
begin
 LongReportBtn.left := self.ClientWidth div 2 - LongReportBtn.Width div 2;
 ShortReportBtn.left := self.ClientWidth div 2 - ShortReportBtn.Width div 2;
end;
{------------------------------------------------------------------------------}
procedure TSelectReportTypeForm.WebFormShow(Sender: TObject);
begin
 LongReportBtn.Caption := SP.load(291);//'Ausführlicher Bericht';
 ShortReportBtn.Caption := SP.load(292);//'Kurz-Bericht';
 WebLabel1.Caption := SP.load(293);//'Bitte wählen Sie:';
 WebLabel2.Caption := SP.load(334);//'Bericht anzeigen / versenden';
 WebComboBox1.Items.Clear;
 WebComboBox1.Items.Add(SP.load(335));//'PDF-Bericht in neuem Fenster anzeigen');
 WebComboBox1.Items.Add(SP.load(336));//'PDF-Bericht im gleichen Fenster anzeigen');
 WebComboBox1.Items.Add(SP.load(337));//'PDF-Bericht an die hinterlegte E-Mail versenden');
 WebLabel3.Caption := SP.load(338);//'Hinweis: Bitte Popup-Blocker im Webbrowser deaktivieren!';
 WebComboBox1.ItemIndex := GLV_AktUser.ShowReportMode;
 GLV_ShowReportMode := GLV_AktUser.ShowReportMode;
 self.WebFormResize(self);
 ReportTyp := -1;
end;
{------------------------------------------------------------------------------}
procedure TSelectReportTypeForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  LongReportBtn := TBitBtn.Create(Self);
  ShortReportBtn := TBitBtn.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);

  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  LongReportBtn.BeforeLoadDFMValues;
  ShortReportBtn.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  try
    Name := 'SelectReportTypeForm';
    Width := 378;
    Height := 239;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 12;
    WebLabel1.Top := 12;
    WebLabel1.Width := 70;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Bitte w'#228'hlen';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 12;
    WebLabel2.Top := 114;
    WebLabel2.Width := 166;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Bericht anzeigen / versenden';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 12;
    WebLabel3.Top := 166;
    WebLabel3.Width := 166;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Bericht anzeigen / versenden';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    LongReportBtn.SetParentComponent(Self);
    LongReportBtn.Name := 'LongReportBtn';
    LongReportBtn.Left := 12;
    LongReportBtn.Top := 34;
    LongReportBtn.Width := 350;
    LongReportBtn.Height := 30;
    LongReportBtn.Caption := 'Ausf'#252'hrlicher Bericht';
    LongReportBtn.Color := clNone;
    LongReportBtn.HeightPercent := 100.000000000000000000;
    LongReportBtn.MaterialGlyph := 'save';
    LongReportBtn.WidthPercent := 100.000000000000000000;
    SetEvent(LongReportBtn, Self, 'OnClick', 'LongReportBtnClick');
    ShortReportBtn.SetParentComponent(Self);
    ShortReportBtn.Name := 'ShortReportBtn';
    ShortReportBtn.Left := 12;
    ShortReportBtn.Top := 73;
    ShortReportBtn.Width := 350;
    ShortReportBtn.Height := 30;
    ShortReportBtn.Caption := 'Kurz-Bericht';
    ShortReportBtn.Color := clNone;
    ShortReportBtn.HeightPercent := 100.000000000000000000;
    ShortReportBtn.MaterialGlyph := 'save';
    ShortReportBtn.TabOrder := 1;
    ShortReportBtn.WidthPercent := 100.000000000000000000;
    SetEvent(ShortReportBtn, Self, 'OnClick', 'ShortReportBtnClick');
    WebComboBox1.SetParentComponent(Self);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 12;
    WebComboBox1.Top := 136;
    WebComboBox1.Width := 350;
    WebComboBox1.Height := 24;
    WebComboBox1.HeightPercent := 100.000000000000000000;
    WebComboBox1.Text := 'WebComboBox1';
    WebComboBox1.WidthPercent := 100.000000000000000000;
    SetEvent(WebComboBox1, Self, 'OnChange', 'WebComboBox1Change');
    WebComboBox1.ItemIndex := -1;
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    LongReportBtn.AfterLoadDFMValues;
    ShortReportBtn.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
  end;
end;

end.
