unit Texte;

interface

uses System.Generics.Collections,System.SysUtils,JS;


type
 TSprachRec = record
   Number    : integer;
   Deutsch   : string;
   Englisch  : string;
 end;

type

 TSprachManagement = class
  OldSprache : integer;
  TextListe  : TArray<TSprachRec>;
  LastNo     : integer;
  procedure Init(_Sprache:integer);
  function  Load(_No:integer):string;
  procedure FillList;
  procedure AddTexte(_No:integer;Deutsch,Englisch:string);
  procedure ChangeLanguage(_Sprache:integer);
  procedure Close;
 end;



implementation

uses KVT,VCL.Dialogs,Main;

{------------------------------------------------------------------------------}
procedure TSprachManagement.Init(_Sprache:integer);
begin
 OldSprache := _Sprache;
 FillList;
 LastNo     := 0;
end;
{------------------------------------------------------------------------------}
function  TSprachManagement.Load(_No:integer):string;
var
 _Rec : TSprachRec;
begin
 result := 'not found';
 _Rec   := TextListe[_no-1];
 case GLV_AppSprache of
  0 : result := _Rec.Deutsch;
  1 : result := _Rec.Englisch;
 end;
end;
{------------------------------------------------------------------------------}
procedure TSprachManagement.ChangeLanguage(_Sprache:integer);
begin
 if _Sprache = OldSprache then exit;
 OldSprache := _Sprache;
 MainForm.MainMenuFrame1.InitForm;
 MainForm.InitForm;
end;
{------------------------------------------------------------------------------}
procedure TSprachManagement.Close;
begin
 SetLength(TextListe,0);
end;
{------------------------------------------------------------------------------}
procedure TSprachManagement.AddTexte(_No:integer;Deutsch,Englisch:string);
var
 _Rec : TSprachRec;
begin
 if _No = LastNo then showmessage('ACHTUNG_ TSprachManagement.AddTexte _No = LastNo');
 _Rec.Number := _No;
 _Rec.Deutsch := Deutsch;
 _Rec.Englisch := Englisch;
 SetLength(TextListe,_No);
 TextListe[_No-1] := _Rec;
 LastNo := _No;
end;
{------------------------------------------------------------------------------}
procedure TSprachManagement.FillList;
begin
 AddTexte(1,'Abmelden','Logout');
 AddTexte(2,'Anmelden','Login');
 AddTexte(3,'Bitte anmelden','Please login');
 AddTexte(4,'Konto erstellen','Create Account');
 AddTexte(5,'Mein Konto','My Account');
 AddTexte(6,'Token ungültig','Invalid token');
 AddTexte(7,'User nicht gefunden!','User not found!');
 AddTexte(8,'Herr','Mr.');
 AddTexte(9,'Frau','Mrs.');
 AddTexte(10,'Firma','Company');
 AddTexte(11,'Deutschland','Germany');
 AddTexte(12,'Österreich','Austria');
 AddTexte(13,'Schweiz','Switzerland');
 AddTexte(14,'Benutzer @@@USER  nicht gefunden!','User @@@USER  not found!');
 AddTexte(15,'Passwort falsch','Wrong password');
 AddTexte(16,'Ihr myQiu Zugang wurde noch nicht aktiviert! Bitte klicken Sie auf den Link in der E-Mail, die an die von Ihnen bei der Anmeldung angegebene E-Mail Adresse gesendet wurde.','Your myQiu access has not yet been activated! Please click on the link in the email that was sent to the email address you provided when you registered.');
 AddTexte(17,'Ihr myQiu Zugang wurde gesperrt! Bitte wenden Sie sich an BioSign (support@biosign.de).','Your myQiu access has been blocked! Please contact BioSign (support@biosign.de).');
 AddTexte(18,'Der abonnierte Zeitraum für myQiu ist abgelaufen! Bitte verlängern Sie Ihren Zugang  in "myQiu Account verwalten".','The subscription period for myQiu has expired! Please extend your access in "My account".');
 AddTexte(19,'Der Demozeitraum für myQiu ist abgelaufen! Bitte verlängern Sie Ihren Zugang in "myQiu Account verwalten".','The demo period for myQiu has expired! Please extend your access in "My account".');
 AddTexte(20,'Bestätigen','Confirm');
 AddTexte(21,'Verifizierungs-Mail erneut zusenden?','Send verification email again?');
 AddTexte(22,'Die Aktivierungs-Mail für Ihren Account wurde an die angegebene E-Mail Adresse versendet!','The activation email for your account has been sent to the specified email address!');
 AddTexte(23,'Beim Versenden der Akitiverungsmail ist ein Fehler aufgetreten.','An error occurred while sending the activation email.');
 AddTexte(24,'OK','OK');
 AddTexte(25,'Nein','No');
 AddTexte(26,'Ja','Yes');
 AddTexte(27,'Abbrechen','Cancel');
 AddTexte(28,'Benutzername','Username');
 AddTexte(29,'Passwort','Password');
 AddTexte(30,'Bitte geben Sie die PLZ Ihres Therapeuten ein','Please enter the postcode of your therapist');
 AddTexte(31,'Suchen','Search');
 AddTexte(32,'Markieren Sie den gewünschten Therapeuten und drücken Sie "Übernehmen"','Highlight the desired therapist and press "Apply"');
 AddTexte(33,'Übernehmen','Apply');
 AddTexte(34,'männlich','male');
 AddTexte(35,'weiblich','femal');
 AddTexte(36,'Deutsch','German');
 AddTexte(37,'Englisch','English');
 AddTexte(38,'Der eingegebene Benutzername ist ungültig (mind. @@@ZEICHEN Zeichen)! Bitte erneut eingeben!','The entered user name is invalid (at least @@@ZEICHEN characters)! Please re-enter!');
 AddTexte(39,'Der eingegebene Benutzername ist ungültig (max. @@@ZEICHEN Zeichen)! Bitte erneut eingeben!','The entered username is invalid (max. @@@ZEICHEN characters)! Please re-enter!');
 AddTexte(40,'Das eingegebene Passwort ist ungültig (mind. @@@ZEICHEN Zeichen)! Bitte erneut eingeben!','The entered password is invalid (at least @@@ZEICHEN characters)! Please re-enter!');
 AddTexte(41,'Das eingegebene Passwort ist ungültig (max. @@@ZEICHEN Zeichen)! Bitte erneut eingeben!','The entered password is invalid (max. @@@ZEICHEN characters)! Please re-enter!');
 AddTexte(42,'Die Wiederholung des Passworts ist ungleich! Bitte erneut eingeben!','The repetition of the password is not the same! Please re-enter!');
 AddTexte(43,'Die eingegebene E-Mail Adresse ist ungültig! Bitte erneut eingeben!','The entered e-Mail address is invalid! Please re-enter!');
 AddTexte(44,'Die Wiederholung der E-Mail Adresse ist ungleich! Bitte erneut eingeben!','The repetition of the email address is not the same! Please re-enter!');
 AddTexte(45,'Bitte Namen eingeben!','Please enter your name!');
 AddTexte(46,'Bitte Vornamen eingeben!','Please enter your first name!');
 AddTexte(47,'Bitte geben Sie ein gültiges Geburtsdatum ein!','Please enter a valid date of birth!');
 AddTexte(48,'Bitte Geschlecht eingeben!','Please enter your gender!');
 AddTexte(49,'Bitte eine gültige Körpergröße (zwischen 100 und 250 cm) eingeben!','Please enter a valid body height (between 100 and 250 cm)!');
 AddTexte(50,'Der Benutzer wurde erfolgreich gespeichert!','The user was saved successfully!');
 AddTexte(51,'Der Benutzer konnte nicht gespeichert werden! Bitte versuchen Sie es noch einmal.','The user could not be saved! Please try again.');
 AddTexte(52,'Der neue Benutzer wurde erfolgreich gespeichert! Die Aktivierungs-Mail für Ihren Account wurde an die angegebene E-Mail Adresse versendet!','The new user was saved successfully! The activation email for your account has been sent to the specified email address!');
 AddTexte(53,'Der eingegebene Benutzername wird schon verwendet! Bitte geben Sie einen anderen Benutzernamen ein.','The username entered is already in use! Please enter a different username.');
 AddTexte(54,'Die eingegebene E-Mail Adresse wird schon verwendet! Bitte geben Sie eine andere E-Mail Adresse ein.','The entered email address is already in use! Please enter a different email address.');
 AddTexte(55,'Der eingegebene Benutzername und die eingegebene E-Mail Adresse werden schon verwendet! Bitte geben Sie einen anderen Benutzernamen und eine andere E-Mail Adresse ein.','The entered user name and the entered e-mail address are already in use! Please enter a different username and email address.');
 AddTexte(56,'Der neue Benutzer konnte nicht gespeichert werden! Bitte versuchen Sie es noch einmal.','The new user could not be saved! Please try again.');
 AddTexte(57,'Der Lizenz-Code wurde erfolgreich aktiviert!','The license code was successfully activated!');
 AddTexte(58,'Der Lizenz-Code ist ungültig (Decodieren)!','The license code is invalid (decoding)!');
 AddTexte(59,'Der Benutzer konnte anhand des Tokens nicht gefunden werden','The user could not be found using the token');
 AddTexte(60,'Der Lizenz-Code wurde schon von diesem Benutzer verwendet','The license code has already been used by this user');
 AddTexte(61,'Keine Verbindung zur Code-Datenbank möglich.','No connection to the code database possible.');
 AddTexte(62,'Der Lizenz-Code ist ungültig (Datenbank)!','The license code is invalid (database)!');
 AddTexte(63,'Keine Verbindung zur Datenbank möglich!','No connection to the database possible!');
 AddTexte(64,'Server down!','Server down!');
 AddTexte(65,'Der Account wurde erfolgreich gelöscht!','The account was successfully deleted!');
 AddTexte(66,'Der Account konnte nicht gelöscht werden!','The account could not be deleted!');
 AddTexte(67,'Wollen Sie Ihren myQiu-Zugang wirklich löschen?','Do you really want to delete your myQiu account?');
 AddTexte(68,'Therapeut auswählen','Select therapist');
 AddTexte(69,'Benutzername/Passwort','Username/Password');
 AddTexte(70,'Persönliche Daten','Personal data');
 AddTexte(71,'Lizenzmodell','License model');
 AddTexte(72,'Therapeut','Therapist');
 AddTexte(73,'myQiu-Zugang löschen','Delete account');
 AddTexte(74,'Speichern','Save');
 AddTexte(75,'E-Mail Adresse','e-mail address');
 AddTexte(76,'Titel','Title');
 AddTexte(77,'Anrede','Salutation');
 AddTexte(78,'Name','Name');
 AddTexte(79,'Vorname','First name');
 AddTexte(80,'Geboren','Date of birth');
 AddTexte(81,'Geschlecht','Gender');
 AddTexte(82,'Sprache','Language');
 AddTexte(83,'Körpergröße[cm]','Body height [cm]');
 AddTexte(84,'Newsletter abonieren','Subscribe to Newsletter');
 AddTexte(85,'E-Mail Adresse wiederholen','Repeat email address');
 AddTexte(86,'Passwort wiederholen','Repeat password');
 AddTexte(87,'Therapeut wählen','Select therapist');
 AddTexte(88,'Therapeut entfernen','Remove therapist');
 AddTexte(89,'AutoTimeZone','AutoTimeZone');
 AddTexte(90,'AGBS öffnen','Open ToS');
 AddTexte(91,'Datenschutzerklärung öffnen','Open privacy policy');
 AddTexte(92,'Aktuelle Lizenz gültig bis:','Current license valid until:');
 AddTexte(93,'Lizenz-Code einlösen','Redeem license code');
 AddTexte(94,'Gewählter Zugang','Selected access');
 AddTexte(95,'Neuer Lizenz-Code','New license code');
 AddTexte(96,'Account löschen','Delete account');
 AddTexte(97,'Therapeut 1','Therapist 1');
 AddTexte(98,'Therapeut 2','Therapist 2');
 AddTexte(99,'Prepaid','Prepaid');
 AddTexte(100,'Die AGBs und die Datenschutzerklärung müssen beim ersten Login und danach auch bei Aktualisierungen akzeptiert werden.','The terms and conditions and the privacy policy must be accepted when logging in for the first time and then also when updating.');
 AddTexte(101,'*Hinweise zum Datenschutz: Der Schutz Ihrer Daten ist uns wichtig.','* Notes on privacy policy: The protection of your data is important to us.');
 AddTexte(102,'Deswegen werden Ihre Daten verschlüsselt und getrennt von medizinischen Daten gespeichert.','That is why your data is encrypted and stored separately from medical data.');
 AddTexte(103,'Medizinische Daten werden anonymisiert und enthalten keinerlei persönliche Informationen wie Name, Geburtsdatum oder Adresse.','Medical data is anonymized and does not contain any personal information such as name, date of birth or address.');
 AddTexte(104,'Neuen Benutzer aufnehmen','Create new user');
 AddTexte(105,'Benutzer bearbeiten','Edit user');
 AddTexte(106,'Qiu','Qiu');
 AddTexte(107,'Biofeedback','Biofeedback');
 AddTexte(108,'Biofeedback','Biofeedback');
 AddTexte(109,'Biofeedback','Biofeedback');
 AddTexte(110,'RSA-Messung','Deep breathing test');
 AddTexte(111,'Kurzzeit-HRV','Short term HRV analysis');
 AddTexte(112,'Datum','Date');
 AddTexte(113,'Uhrzeit','Time');
 AddTexte(114,'Typ','Type');
 AddTexte(115,'Dauer [min.]','Duration [min.]');
 AddTexte(116,'Bemerkung','Comment');
 AddTexte(117,'HF min.','HR min.');
 AddTexte(118,'HF max.','HR max.');
 AddTexte(119,'Zoom','Zoom');
 AddTexte(120,'Kommentar','Comment');
 AddTexte(121,'Löschen','Delete');
 AddTexte(122,'Wollen Sie die gewählte Messung wirklich löschen?','Do you really want to delete the selected measurement?');
 AddTexte(123,'Wollen Sie die gewählten Messungen wirklich löschen?','Do you really want to delete the selected measurements?');
 AddTexte(124,'Bitte markieren Sie zuerst einen Eintrag','Please first mark an entry');
 AddTexte(125,'Beim Löschen ist ein Fehler aufgetreten!','An error occurred while deleting!');
 AddTexte(126,'Messung(en) erfolgreich gelöscht!','Measurement (s) deleted successfully!');
 AddTexte(127,'Bitte wählen Sie ein Datum','Please choose a date');
 AddTexte(128,'myQiu-Messungen:','myQiu-Measurements:');
 AddTexte(129,'myQiu-Trend:','myQiu-Trends:');
 AddTexte(130,'myQiu-Compliance:','myQiu-Compliance:');
 AddTexte(131,'myQiu-Übersicht:','myQiu-Overview:');
 AddTexte(132,'Keine Messungen','No measurements');
 AddTexte(133,'Quelle','Source');
 AddTexte(134,'Wenn Sie die AGBS und die Datenschutzerklärung nicht gelesen haben und nicht akzeptieren, können Sie sich nicht mehr bei myQiu einloggen.','If you have not read the AGBS and the Privacy Policy and do not accept it, you will not be able to log in to myQiu.');
 AddTexte(135,'Messqualität','Measurement quality');
 AddTexte(136,'(* Messungen geringerer Qualität werden in den Auswertungen nicht berücksichtigt.)','(* Measurements of lower quality are not considered in the evaluations.)');
 AddTexte(137,'myQiu - Übungsplan:','myQiu - Scheduler:');
 AddTexte(138,'HRV-Biofeedback','HRV biofeedback');
 AddTexte(139,'Biofeedback','biofeedback');
 AddTexte(140,'Blutdruck','Blood pressure');
 AddTexte(141,'Blutzucker','Blood glucose');
 AddTexte(142,'Gewicht','Weight');
 AddTexte(143,'Sonstiges','Miscellaneous');
 AddTexte(144,'Typ','Type');
 AddTexte(145,'Bezeichnung:','Description:');
 AddTexte(146,'Intervall:','Interval:');
 AddTexte(147,'Zeit (hh:mm):','Time (hh:mm):');
 AddTexte(148,'Wochentag:','Day of week:');
 AddTexte(149,'Tag im Monat:','Day in month:');
 AddTexte(150,'An Termin erinnern','Remember appointment');
 AddTexte(151,'täglich','daily');
 AddTexte(152,'wöchentlich','weekly');
 AddTexte(153,'monatlich','monthly');
 AddTexte(154,'Mo','Mo');
 AddTexte(155,'Di','Tue');
 AddTexte(156,'Mi','Wed');
 AddTexte(157,'Do','Thu');
 AddTexte(158,'Fr','Fr');
 AddTexte(159,'Sa','Sa');
 AddTexte(160,'So','Su');
 AddTexte(161,'Speichern auf Server fehlgeschlagen!','Save failed on server!');
 AddTexte(162,'Noch kein Übungsplan ausgewählt','No exercise plan selected');
 AddTexte(163,'Noch kein Übungsplan als Vorlage ausgewählt','No exercise plan as template selected');
 AddTexte(164,'Bezeichnung darf nicht leer sein!','Please enter a name!');
 AddTexte(165,'Dieser Eintrag darf nicht geändert werden','This entry can not be edited');
 AddTexte(166,'Bezeichnung:','Description:');
 AddTexte(167,'gültig ab:','valid from:');
 AddTexte(168,'Übungsplan','Scheduler');
 AddTexte(169,'gültig ab:','valid from:');
 AddTexte(170,'Termine','Appointments');
 AddTexte(171,'Übungspläne','Scheduler');
 AddTexte(172,'Termin bearbeiten','Edit appointments');
 AddTexte(173,'Art','Type');
 AddTexte(174,'Erinnern','Remind');
 AddTexte(175,'Zeit','Time');
 AddTexte(176,'Biofeedback','Biofeedback');
 AddTexte(177,'unbekannt','unkown');
 AddTexte(178,'Dieser Eintrag darf nicht gelöscht werden','This entry can not be deleted');
 AddTexte(179,'Übungsplan @@@PLAN wirklich löschen?','Scheduler @@@PLAN really delete?');
 AddTexte(180,'Kein Eintrag gewählt','No entry selected');
 AddTexte(181,'myQiu - Werte:','myQiu - Values:');
 AddTexte(182,'Werte:','Values:');
 AddTexte(183,'BMI:','BMI:');
 AddTexte(184,'WHR:','WHR:');
 AddTexte(185,'mmol/l','mmol/l');
 AddTexte(186,'mg/dl','mg/dl');
 AddTexte(187,'Zielgewicht:','Target weight:');
 AddTexte(188,'Systole','Systole');
 AddTexte(189,'Diastole','Diastole');
 AddTexte(190,'Puls','Pulse');
 AddTexte(191,'Bauchumfang:','Waist circumference:');
 AddTexte(192,'Hüftumfang:','Hips:');
 AddTexte(193,'Sys[mmHg]','Sys[mmHg]');
 AddTexte(194,'Dia[mmHg]','Dia[mmHg]');
 AddTexte(195,'Puls[1/min.]','Pulse[1/min.]');
 AddTexte(196,'Gewicht (kg)','Weight (kg)');
 AddTexte(197,'Bauchumfang (cm)','Waist circumference (cm)');
 AddTexte(198,'WHR','WHR');
 AddTexte(199,'BMI','BMI');
 AddTexte(200,'Einheit','Unit');
 AddTexte(201,'Eintrag liegt in der Zukunft!','Entry is in the future!');
 AddTexte(202,'Eintrag mit gleichem Datum / Uhrzeít bereits vorhanden!','Entry with the same date / time already exists!');
 AddTexte(203,'Bitte Eingabe prüfen!','Please check entry!');
 AddTexte(204,'Server-Fehler!','Server error!');
 AddTexte(205,'(BMI:@@@VALUE)','(BMI:@@@VALUE)');
 AddTexte(206,'Zugang nicht aktiviert','Account not activated');
 AddTexte(207,'Account gesperrt','Account locked');
 AddTexte(208,'Demo-Account:','Demo account:');
 AddTexte(209,'gültiger Account:','valid account:');
 AddTexte(210,'@@@ANZAHL Tage verbleiben','@@@ANZAHL days remaining');
 AddTexte(211,'Liste der Messungen des gewählten Monats; Eingeben von Kommentaren;','List of the measurements of the selected month; To provide comments');
 AddTexte(212,'Liste aller Messungen','List of all measurements');
 AddTexte(213,'Trendanalyse','Trend analysis');
 AddTexte(214,'Ergebnisse mit anderen vergleichen','Compare results with other');
 AddTexte(215,'Übungsplan festlegen','Set exercise plan');
 AddTexte(216,'Übungsplan kontrollieren','Check exercise plan');
 AddTexte(217,'6 Monate zurück','6 month back');
 AddTexte(218,'1 Monat zurück','1 month back');
 AddTexte(219,'1 Monat vor','1 month ahead');
 AddTexte(220,'6 Monate vor','6 month ahead');
 AddTexte(221,'zum aktuellen Monat','go to current month');
 AddTexte(222,'zu einem bestimmten Datum gehen','go to a specific date');
 AddTexte(223,'Beenden','Exit');
 AddTexte(224,'myQiu-Einstellungen ändern','myQiu - edit settings');
 AddTexte(225,'Vergrößern','Zoom in');
 AddTexte(226,'Verkleinern','Zoom out');
 AddTexte(227,'Nächste Seite','Next page');
 AddTexte(228,'Vorherige Seite','Previous page');
 AddTexte(229,'Zurück zur Herzfrequenzansicht','Back to heart rate view');
 AddTexte(230,'Messung löschen','Delete measurement');
 AddTexte(231,'Kommentar speichern','Save comment');
 AddTexte(232,'Zeitachse vergrößern','Increase time base');
 AddTexte(233,'Zeitachse verkleinern','Decrease time base');
 AddTexte(234,'3 Monate zurück','3 month back');
 AddTexte(235,'3 Monate vor','3 month ahead');
 AddTexte(236,'Übungsplan löschen','Delete exercise plan');
 AddTexte(237,'Neuen Übungsplan erstellen','Create a new exercise plan');
 AddTexte(238,'Gültigkeitsdatum ändern','Change effective date');
 AddTexte(239,'Termin löschen','Delete appointment');
 AddTexte(240,'Termin hinzufügen','Add appointment');
 AddTexte(241,'Änderungen übernehmen','Save changes');
 AddTexte(242,'myQiu-Übersicht','myQiu-Overview');
 AddTexte(243,'myQiu-Werte','myQiu-Tracker');
 AddTexte(244,'Bearbeiten','Edit');
 AddTexte(245,'Werte','Values');
 AddTexte(246,'Stunde','Hour');
 AddTexte(247,'Minute','Minute');
 AddTexte(248,'Bitte loggen Sie sich zuerst ein!','Please login first');
 AddTexte(249,'ausblenden','hide');
 AddTexte(250,'Für die genannte Postleitzahl wurde kein Therapeut gefunden!','No therapist was found for the postal code mentioned!');
 AddTexte(251,'Möchten Sie unseren Newsletter abonnieren?','Would you like to subscribe to our newsletter?');
 AddTexte(252,'Zeitraum:','Time period:');
 AddTexte(253,'Bitte markieren Sie nur einen Eintrag','Please mark only one entry');
 AddTexte(254,'Es sind keine Werte für den gewählten Zeitraum vorhanden','There are no values available for the selected period');
 AddTexte(255,'Wollen Sie den gewählten Wert wirklich löschen?','Do you really want to delete the selected entry?');
 AddTexte(256,'Wollen Sie die gewählten Werte wirklich löschen?','Do you really want to delete the selected entries?');
 AddTexte(257,'Hilfe anzeigen','Show help');
 AddTexte(258,'Neuer Eintrag','New entry');
 AddTexte(259,'Eintrag ändern','Edit entry');
 AddTexte(260,'Eintrag löschen','Delete entry');
 AddTexte(261,'Trendanalyse','Trend analysis');
 AddTexte(262,'Zielgewicht:','Target weight:');
 AddTexte(263,'Zielwert speichern','Save target values');
 AddTexte(264,'Datum festlegen','Set the date');
 AddTexte(265,'Ich habe die AGBs von myQiu gelesen und akzeptiere diese hiermit.','I have read the terms and conditions of myQiu and hereby accept them.');
 AddTexte(266,'Bitte bestätigen Sie, dass Sie die AGBs zu myQiu gelesen haben und akzeptieren!','Please confirm that you have read the terms and conditions of myQiu and that you agree to!');
 AddTexte(267,'Ich habe die Datenschutzerklärung von myQiu gelesen und akzeptiere diese hiermit.','I have read the Privacy Policy of myQiu and hereby accept them.');
 AddTexte(268,'Bitte bestätigen Sie, dass Sie die Datenschutzerklärung zu myQiu gelesen haben und akzeptieren!','Please confirm that you have read the Privacy Policy of myQiu and that you agree to!');
 AddTexte(269,'Bitte öffnen Sie zuerst die AGBs, um diese zu lesen!','Please open the terms and conditions first to read them!');
 AddTexte(270,'Bitte öffnen Sie zuerst die Datenschutzerklärung, um diese zu lesen!','Please open the privacy policy first to read it!');
 AddTexte(271,'Bitte loggen Sie sich ein!','Please login!');
 AddTexte(272,'Messung auswerten','Analyze test');
 AddTexte(273,'Die ersten 7 Tage nach der Anmeldung auf myQiu sind eine kostenfrei "Schnupperphase". Danach können Sie Lizenzen in unserem Online Shop kaufen.','The first 7 days after registering on myQiu are a free "trial phase". You can then buy licenses in our online shop.');
 AddTexte(274,'Impressum','Imprint');
 AddTexte(275,'Datenschutzerklärung','Privacy Policy');
 AddTexte(276,'BioSign Webseite','BioSign Webpage');
 AddTexte(277,'BioSign Online-Shop','BioSign Online Shop');
 AddTexte(278,'AGB','Terms of Service');
 AddTexte(279,'Wichtiger Hinweis','Important note');
 AddTexte(280,'Wichtiger Hinweis zur Navigation in myQiu Web','Important note on navigation in myQiu Web');
 AddTexte(281,'myQiu Web ist eine moderne "Single-Page-Webanwendung". Das bedeutet, die gesamte Anwendung besteht aus einem einzigen HTML-Dokument dessen Inhalte dynamisch nachgeladen werden.','myQiu Web is a modern "single-page web application". This means that the entire application consists of a single HTML document, the content of which is dynamically loaded.');
 AddTexte(282,'Das ist der Grund dafür, dass die Navigation über den "Vor" und "Zurück" Button Ihres Web-Browsers nicht funktioniert und die Anwendung beendet.','That is the reason why the navigation via the "Forward" and "Back" button of your web browser does not work and terminates the application.');
 AddTexte(283,'Nutzen Sie stattdessen die Buttons zum Schließen von Dialogen und Fenstern innerhalb der Anwendung.','Instead, use the buttons to close dialog boxes and windows within the application.');
 AddTexte(284,'Den Button zum Schließen eines Fensters finden Sie jeweils im Fenster rechts unten.','The button for closing a window can be found in the lower right corner of the window.');
 AddTexte(285,'Eigenschaften','Properties');
 AddTexte(286,'Betriebssystem','OS');
 AddTexte(287,'Betriebssystem Version','OS Version');
 AddTexte(288,'Device','Device');
 AddTexte(289,'Hardware','Hardware');
 AddTexte(290,'Report wählen','Select report');
 AddTexte(291,'Ausführlicher Bericht','Detailed report');
 AddTexte(292,'Kurz-Bericht','Short report');
 AddTexte(293,'Bitte wählen Sie:','Please select:');
 AddTexte(294,'Passwort vergessen','Forgot password');
 AddTexte(295,'Benutzername oder E-Mail eingeben','Please enter username or email adress');
 AddTexte(296,'Geben Sie Ihren Benutzernamen oder die im myQiu-Zugang hinterlegte e-Mail Adresse in das obige Feld ein.','Enter your user name or the e-mail address stored in the myQiu account in the field above.');
 AddTexte(297,'Mit "OK" wird das Passwort zurückgesetzt und an Ihre e-Mail Adresse verschickt.','With pressing "OK" the password will be reset and sent to your email address.');
 AddTexte(298,'Fragebogen','Questionnaire');
 AddTexte(299,'Wollen Sie den gewählten Eintrag wirklich löschen?','Do you really want to delete the selected entry?');
 AddTexte(300,'Wollen Sie die gewählten Einträge wirklich löschen?','Do you really want to delete the selected entries?');
 AddTexte(301,'Zeitpunkt','Time');
 AddTexte(302,'Typ','Type');
 AddTexte(303,'Wert','Value');
 AddTexte(304,'Fragetext','Question text');
 AddTexte(305,'Fragebogen','Questionnaire');
 AddTexte(306,'vor dem Test','before test');
 AddTexte(307,'nach dem Test','after test');
 AddTexte(308,'Der Eintrag wurde erfolgreich gelöscht','The entry was successfully deleted');
 AddTexte(309,'Für Fragebögen gibt es keinen Bericht!','There is no report for questionnaires!');
 AddTexte(310,'Antworten bearbeiten','Edit answers');
 AddTexte(311,'Antwort bearbeiten','Edit answer');
 AddTexte(312,'Wert über Trackbar','Trackbar value');
 AddTexte(313,'Multiple Choice Einfachauswahl','Multiple choice single select');
 AddTexte(314,'Multiple Choice Mehrfachauswahl','Multiple choice multiple select');
 AddTexte(315,'Anzahl Fragen','Number of Questions');
 AddTexte(316,'Fragebogen auf den Server speichern?','Save questionnaire on the server?');
 AddTexte(317,'Frage','Question');
 AddTexte(318,'Antwortmöglichkeiten (Einfachauswahl)','');
 AddTexte(319,'Antwortmöglichkeiten (Mehrachauswahl)','');
 AddTexte(320,'weiter','next');
 AddTexte(321,'zurück','previous');
 AddTexte(322,'(mit Fragebogen)','(with questionnaire)');
 AddTexte(323,'Aktuelles BioSign HRV App APK','Current BioSign HRV App APK');
 AddTexte(324,'Aktuelles BioSign HRV App APK von @@@LINK herunterladen?','Download current BioSign HRV App APK from @@@LINK?');
 AddTexte(325,'Der Kommentar wurde gespeichert','The comment has been saved');
 AddTexte(326,'Das zurückgesetzte Passwort wurde Ihnen per Mail zugeschickt','The reset password has been emailed to you');
 AddTexte(327,'Es wurde kein zum Benutzernamen passendes myQiu-Konto gefunden!','No myQiu account matching the username was found!');
 AddTexte(328,'Das Passwort konnte nicht zurückgesetzt werden!','The password could not be reset!');
 AddTexte(329,'Das geänderte Passwort konnte nicht zugeschickt werden!','The changed password could not be sent!');
 AddTexte(330,'Der eingegebene Benutzername ist ungültig (Leerzeichen enthalten)! Bitte erneut eingeben!','The entered username is invalid (contains spaces)! Please re-enter!');
 AddTexte(331,'Das eingegebene Passwort ist ungültig (Leerzeichen enthalten)! Bitte erneut eingeben!','The entered password is invalid (contains spaces)! Please re-enter!');
 AddTexte(332,'Qiu-Seriennummern','Qiu serials');
 AddTexte(333,'Qiu-Serial','Qiu-Serial');
 AddTexte(334,'Bericht anzeigen / versenden','View / send report');
 AddTexte(335,'PDF-Bericht in neuem Fenster anzeigen','View PDF report in new window');
 AddTexte(336,'PDF-Bericht im gleichen Fenster anzeigen','View PDF report in the same window');
 AddTexte(337,'PDF-Bericht an die hinterlegte E-Mail versenden','Send PDF report to the stored email');
 AddTexte(338,'Hinweis: Bitte Popup-Blocker im Webbrowser deaktivieren!','Note: Please deactivate the pop-up blocker in the web browser!');
 AddTexte(339,'Sicherheitscode eingeben','Enter security code');
 AddTexte(340,'Code erneut zusenden','Send code again');
 AddTexte(341,'Bitte geben Sie hier den Code ein, der an die in myQiu hinterlegte E-Mail Adresse @@@MAIL verschickt wurde.','Please enter the code that was sent to the email address @@@MAIL stored in myQiu.');
 AddTexte(342,'Bitte geben Sie einen 6-stelligen Zahlen-Code ein!','Please enter a 6-digit code!');
 AddTexte(343,'Fehler! Der Sicherheitscode konnte nicht an die in myQiu hinterlegte E-Mail Adresse gesendet werden.','Mistake! The security code could not be sent to the email address stored in myQiu.');
 AddTexte(344,'Der Sicherheitscode wurde erfolgreich an die in myQiu hinterlegte E-Mail Adresse gesendet.','The security code was successfully sent to the email address stored in myQiu.');
 AddTexte(345,'Der eingegeben Code ist ungültig!','The entered code is invalid!');
 AddTexte(346,'Der eingegeben Code ist abgelaufen!','The entered code has expired!');
 AddTexte(347,'Login-Verfahren','Login mode');
 AddTexte(348,'Benutzer/Passwort','User/password');
 AddTexte(349,'Benutzer/Passwort/Sicherheitscode per Mail','User/password/security code by mail');
 AddTexte(350,'Handbuch','Manual');

end;
end.
